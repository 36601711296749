import React from "react";
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaDatabase,
  FaBrain,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const AeroAegisLanding = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Unlock the Power of AI and Data
          </h1>
          <p className="text-xl mb-8">
            At AeroAegis Inc., our <strong>AI and Data Services</strong> enable
            businesses to unlock the power of data and artificial intelligence.
            Whether you’re building custom AI models, streamlining data
            pipelines, or leveraging generative AI for automation, we deliver
            solutions that accelerate innovation, drive efficiency, and create
            value.
          </p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </button>
        </div>
      </section>

      {/* Why Choose AeroAegis Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Why Choose AeroAegis for AI and Data Services?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
            <FeatureCard
              icon={<FaBrain className="text-4xl text-blue-600" />}
              title="Technology Expertise"
              description="Deep expertise in AI frameworks (TensorFlow, PyTorch), data tools (Apache Spark, Snowflake), and cloud platforms (Azure, AWS, Alibaba Cloud, GCP)."
            />
            <FeatureCard
              icon={<FaCog className="text-4xl text-blue-600" />}
              title="End-to-End Solutions"
              description="From data infrastructure to advanced AI/ML models, we cover the entire lifecycle."
            />
            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="Industry-Focused Innovation"
              description="Tailored solutions to solve unique challenges across industries like healthcare, finance, manufacturing, and logistics."
            />
          </div>
          <div className="flex-wrap lg:col-span-3 flex justify-center gap-8 w-full">
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Scalable & Future-Ready"
              description="Solutions designed to scale seamlessly and support emerging technologies like Generative AI."
            />
            <FeatureCard
              icon={<FaDatabase className="text-4xl text-blue-600" />}
              title="Data-Driven Approach"
              description="Transform raw data into actionable intelligence that drives measurable business outcomes."
            />
            {/* <FeatureCard
              icon={<FaChartLine className="text-4xl text-blue-600" />}
              title="Accelerate Innovation"
              description="Drive efficiency and create value with our AI and data solutions."
            /> */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">
            Ready to harness the power of AI and data? Let's innovate together!
            🚀
          </p>
          <Link
            to={"/contact-us"}
            className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300"
          >
            Contact Us
          </Link>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default AeroAegisLanding;
