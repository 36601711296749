import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import "../../assets/css/custom.css"
import Tiles from "./Tiles";
import AboutSection from "./AboutSection";
import WhatWeOffer from "./WhatweOffer";
import WhatsetsUsApart from "./WhatsetsUsApart";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Testimonials from "./Testimonials";
import Herosection3 from "./herosection3";
import Innovation from "./Innovation";
import HorizontalScrollCarasoul  from "./HorizontalScrollCarousel"
import PoweredbyAero from "./PoweredbyAero";
import Products from "./Products";
export default function Homepage() {
  return (
    <>
         <div className="min-h-screen">
            <div className="h-screen absolute top-0 left-0 w-full overflow-hidden">
            <Herosection3 />
            </div>
          
        <Tiles/>
        <AboutSection />
        <WhatsetsUsApart />
        <HorizontalScrollCarasoul/>
        <AnimationRevealPage>
        {/* <Products /> */}
        <Innovation />
        <WhatWeOffer />
        <Section5 />
        <Section6 />
        <PoweredbyAero />
        {/* <Testimonials /> */}
      </AnimationRevealPage>
        </div>
       
      
    </>
  );
}
