import React from 'react'
import { Accordion, AccordionContent, AccordionPanel, AccordionTitle } from 'flowbite-react';
import { FaEnvelope, FaPhone, FaFileDownload, FaChevronRight } from "react-icons/fa"
import img_last from "../../assets/img/Investor_relations/rb_8949.png"

import pdf1 from '../../assets/pdf/InvestorRelations/Investor Pitch Document for AeroAegis Pvt Ltd.pdf'
import pdf2 from '../../assets/pdf/InvestorRelations/Discover AeroAegis Pvt Ltd.pdf'
import pdf3 from '../../assets/pdf/InvestorRelations/Leadership Team.pdf'
import excel1 from '../../assets/excel/Aero Aegis_Financial Model_Final.xlsx'
import { Link } from 'react-router-dom';

export default function MainSection() {



    const resources = [
        { name: "Investor Pitch Deck", file: pdf1 },
        { name: "Company Overview", file: pdf2 },
        { name: "Leadership Profiles", file: pdf3 },
        { name: "Financial Projections", file: excel1 },
      ]



  return (
    <div className="bg-gradient-to-b from-gray-50 to-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          How to Invest
        </h1>
        <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-500">
          Join AeroAegis on a transformative journey. Support deep-tech innovation and align with a product-first organization shaping the future of industries.
        </p>
      </div>

      <div className="mt-16 grid gap-8 md:grid-cols-2">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
              <FaEnvelope className="mr-3 text-indigo-600" />
              Contact Us
            </h2>
            <p className="text-gray-600 mb-6">
              Our Investor Relations team is here to guide you through the process, provide detailed insights, and ensure a seamless investment experience.
            </p>
            <div className="space-y-4">
              <div className="flex items-center text-gray-700">
                <FaEnvelope className="mr-3 text-indigo-600" />
                <span>Email: <a href="mailto:invest@aeroaegis.com" className="text-indigo-600 hover:underline">invest@aeroaegis.com</a></span>
              </div>
              <div className="flex items-center text-gray-700">
                <FaPhone className="mr-3 text-indigo-600" />
                <span>Phone: <a href="tel:+1 (302) 497-4435" className="text-indigo-600 hover:underline">+1 (302) 497-4435</a></span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
              <FaFileDownload className="mr-3 text-indigo-600" />
              Downloadable Resources
            </h2>
            <p className="text-gray-600 mb-6">
              Access key resources to support your decision-making process:
            </p>
            <ul className="space-y-3">
              {resources.map((resource, index) => (
                <li key={index}>
                  <a
                    href={resource.file}
                    download={resource.file}
                    className="flex items-center p-3 text-base font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 group rounded-md transition duration-150 ease-in-out"
                  >
                    <FaFileDownload className="mr-3 text-indigo-600 group-hover:text-indigo-500" />
                    {resource.name}
                    <FaChevronRight className="ml-auto text-gray-400 group-hover:text-gray-500" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-16 bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="pt-3 pb-20 px-6 sm:pt-6 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div className="lg:self-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">Ready to dive in?</span>
              <span className="block">Start your investment journey today.</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-indigo-200">
              Discover the unparalleled potential of investing in our mission. Let's shape the future of technology together.
            </p>
            <Link
              to="/contact-us"
              className="mt-5 md:mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
            >
              Contact Us Now
            </Link>
          </div>
        </div>
        <div className="relative -mt-16 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <div className="absolute inset-0 transform  skew-y-6 sm:skew-y-0 sm:-rotate-5 sm:rounded-3xl">
            <div className='flex justify-center items-center'>
            <img src={img_last} alt='money' className='w-[440px] transform translate-y-10'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
                         
  
  )
}
