import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoAeroAegis from "../assets/img/logo.png";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const API_ENDPOINT = "https://aeroaegis.com/newsletter/usermails.php";

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    const formData = new FormData();
    formData.append("user_email", email);

    try {
      setLoading(true);
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
      },
        body: JSON.stringify({'user_email' : email}),
        
      });

      const data = await response.json()

      if (response.ok && data.status === "success") {
        setLoading(false);
        setSuccessMessage(data.message);
        setEmail("");
        setErrorMessage("");
      } else {
        setLoading(false);
        setErrorMessage(data.message);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("Email already subscribed!");
    }
  };
  return (
    <footer class="w-full bg-white   py-8 sm:py-12 mx-auto shadow-2xl ">
      <div className="px-8">
        <div className="flex flex-col  sm:justify-center sm:items-center pb-8 sm:pb-12">
          {/* <div>
            <h2 className="text-4xl sm:text-6xl font-semibold text-[#252f80]">
              Join our team.
            </h2>
          </div> */}
          {/* <div className="mt-4 text-left sm:text-center">
            <p className="text-lg font-semibold ">Realize your potential.</p>

            <div className="mt-4 border border-solid border-[#252f80] px-4 py-3 rounded-full text-lg transition hover:bg-[#252f80]  hover:text-white text-center">
              <Link to="/careers">Explore AeroAegis careers</Link>
            </div>
          </div> */}
          <p className="py-1 inline-block px-10 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
        </div>
        <hr className="bg-gray-300 h-[2px]" />
        <div className="py-8">
          <div>
            <p className="font-semibold text-[#252f80]">SOCIAL</p>
            <h2 className="text-lg md:text-4xl font-semibold">
              Follow us for the latest updates
            </h2>
          </div>

          <div class="mt-8 grid grid-cols-4   mx-auto ">
            <div>
              <a href="javascript:void(0)">
                <div className="flex items-center">
                  <div>
                    <svg
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="text-xl transition-colors duration-200 text-[#252f80] "
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
                    </svg>
                  </div>
                  <div className="ml-3 text-sm font-semibold hidden sm:block ">
                    <p className=" ">FACEBOOK</p>
                    <p className=" hidden lg:block">AEROAEGIS</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-[25%]">
              <a href="javascript:void(0)">
                <div className="flex items-center">
                  <div>
                    <svg
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="text-xl transition-colors duration-200 text-[#252f80] "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>
                  </div>
                  <div className="ml-3 text-sm font-semibold hidden sm:block">
                    <p>X</p>
                    <p className=" hidden lg:block">AEROAEGIS</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-[25%]">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/96893605"
              >
                <div className="flex items-center">
                  <div>
                    <svg
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="text-xl transition-colors duration-200 text-[#252f80] "
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
                    </svg>
                  </div>
                  <div className="ml-3 text-sm font-semibold hidden sm:block">
                    <p>LINKEDIN</p>
                    <p className=" hidden lg:block">AEROAEGIS</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-[25%]">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCZDzOYE4W-stKNR9AY2dUmg"
              >
                <div className="flex items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-youtube text-xl transition-colors duration-200 text-[#252f80]"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                    </svg>
                  </div>
                  <div className="ml-3 text-sm font-semibold hidden sm:block">
                    <p>YOUTUBE</p>
                    <p className=" hidden lg:block">AEROAEGIS</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-[1px] bg-[0000]" />
      <div className="px-8 pt-8 sm:pt-12">
        <div class="grid grid-cols-1 gap-10 mb-3 md:grid-cols-3 lg:grid-cols-10 lg:gap-10 xl:grid-cols-12  xl:gap-20">
          <div class="col-span-3">
            <Link
              to="/"
              class="text-xl font-black leading-none text-gray-900 select-none logo"
            >
              <img src={LogoAeroAegis} alt="AeroAegis Logo" className="w-20" />
            </Link>
            <p class="my-4 text-xs leading-normal text-gray-500">
              At AeroAegis, we are in the business of innovation, primarily
              through our suite of groundbreaking products designed to
              revolutionize how organizations navigate the digital landscape.
            </p>
          </div>

          <nav class="col-span-3 md:col-span-1 lg:col-span-2">
            <p class="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase">
              More Information
            </p>
            <Link to="/locations">
              <a class="flex mb-3 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
                Locations
              </a>
            </Link>
            <Link to="/news-room">
              <a class="flex mb-3 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
                News Room
              </a>
            </Link>
            {/* <Link to="/investor-relations">
              <a
                href="#"
                class="flex mb-3 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 "
              >
                Investor Relations
              </a>
            </Link> */}
            <Link to="/careers">
              <a class="flex mb-3 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
                Careers
              </a>
            </Link>
            <Link to="/disclaimer">
              <a class="flex mb-3 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
                Disclaimer
              </a>
            </Link>
            <Link to="/terms-conditions">
              <a class="flex mb-3 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
                Terms & Conditions
              </a>
            </Link>
            <Link to="/csr">
              <a class="flex mb-3 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
                CSR
              </a>
            </Link>
          </nav>
          {/* <nav class="col-span-1 md:col-span-1 lg:col-span-2">
            <p class="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase">
              WORKING HOURS
            </p>
            <ul className="">
              <li class="mb-4 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2  ">
                <p>Monday - Friday</p>
                <p className=" ">09:00 am - 07:00 pm</p>
              </li>
              <li class="mb-4 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
                <p>Saturday</p>
                <p className=" ">09:00 am - 12:00 pm</p>
              </li>
              <li class="mb-4 text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2  ">
                <p>Sunday</p>
                <p className=" ">Closed</p>
              </li>
            </ul>
          </nav> */}
          <nav class="col-span-2 md:col-span-1 lg:col-span-2">
            <p class="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase">
              Contact
            </p>

            <div className="flex text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2 ">
              <p className="mr-1 ">Phone :</p>
              <a
                href="tel:+1 (302) 497-4435"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors  text-[12px] duration-300 text-[#252f80] hover:text-gray-700"
              >
                +1 (302) 497-4435
              </a>
            </div>
            <div className="flex text-sm font-medium text-gray-500 transition hover:text-[#252f80] md:mb-2">
              <p className="mr-1 ">Email :</p>
              <a
                href="mailto:info@aeroaegis.com"
                aria-label="Our email"
                title="Our email"
                className="transition-colors text-[12px] duration-300 text-[#252f80] hover:text-gray-700"
              >
                info@aeroaegis.com
              </a>
            </div>
          </nav>
          <div class="col-span-3">
            <p class="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase">
              SUBSCRIBE TO OUR NEWSLETTER
            </p>
            <form class="mb-2">
           

              <div class="relative flex items-center overflow-hidden border border-gray-300 rounded-lg">
                <input
                  class="w-full px-3 py-2 text-base leading-normal transition duration-150 border-none ease-in-out bg-white appearance-none focus:outline"
                  type="email"
                  name="user_email"
                  id="form-subscribe-Subscribe"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter your email"
                />
                <button
                  disabled={loading}
                  onClick={handleSubscribe}
                  class="px-3 py-2  text-sm font-medium text-center text-white no-underline bg-[#252f80] border-2 border-[#252f80]"
                  type="submit"
                >
                  Subscribe
                </button>
              </div>
              {successMessage && (
                <div className="text-green-500 font-medium py-1">
                  <span>{successMessage}</span>
                </div>
              )}
              {errorMessage && (
                <div className="text-red-500 font-medium py-1">
                  <span>{errorMessage}</span>
                </div>
              )}
            </form>
            <p class="text-xs leading-normal text-gray-500">
              Get the latest updates and news about our service.
            </p>
          </div>
        </div>
        <div class="flex flex-col items-start justify-between pt-10 mt-10 border-t border-gray-100 md:flex-row md:items-center">
          <p class="mb-6 text-sm text-left text-gray-600 md:mb-0">
            © Copyright {new Date().getFullYear()} AEROAEGIS. All Rights
            Reserved.
          </p>
          <div class="flex items-start justify-start space-x-6 md:items-center md:justify-center">
            {/* <Link to="/terms-conditions">
              {" "}
              <a
                href="#_"
                class="text-sm text-gray-600 transition hover:text-[#252f80]"
              >
                Terms & Conditions
              </a>
            </Link> */}
            {/* <a
              href="#_"
              class="text-sm text-gray-600 transition hover:text-primary"
            >
              Privacy
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
