import {
  MdOutlineDirectionsCar,
  MdOutlineSecurity,
  MdOutlineScience,
} from "react-icons/md";

import img1 from "../../../assets/img/Industries/Automotive/Picture02-article5-W0087-Smart-auf-smart_original-scaled.jpg";
import { FiMonitor } from "react-icons/fi";
import { MdOutlineLocalHospital } from "react-icons/md";
import { GiArtificialIntelligence } from "react-icons/gi";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              AI-Driven Autonomous Driving and Safety Systems
            </h1>
            <p className="text-lg text-gray-600">
              Harness advanced AI and machine learning to drive innovation in
              autonomous vehicles and safety systems.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDirectionsCar className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Autonomous Driving Algorithms
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Develop cutting-edge AI algorithms for autonomous vehicles,
                ensuring accurate navigation, obstacle detection, and adaptive
                decision-making in real time.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Advanced Driver Assistance Systems (ADAS)
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable safety features like lane departure warnings, collision
                detection, and adaptive cruise control powered by machine
                learning and computer vision.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineScience className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Simulation and Testing
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI-based simulations to test autonomous systems in diverse
                scenarios, accelerating development cycles while ensuring safety
                compliance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
