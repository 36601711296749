import { IoChatbubblesOutline } from "react-icons/io5"; // 24/7 Virtual Assistance
import { MdOutlineInsights } from "react-icons/md"; // Personalized Energy Insights
import { RiRobotLine } from "react-icons/ri"; // Automation of Routine Tasks
import img1 from "../../../assets/img/Industries/Energy/agentai.png";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Agent AI for Customer Engagement and Operations
            </h1>
            <p className="text-lg text-gray-600">
              Deploy Agent AI to provide real-time support for customer
              inquiries, enhancing satisfaction and operational efficiency.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoChatbubblesOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  24/7 Virtual Assistance
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Provide real-time support for customer inquiries related to
                billing, energy usage, and service requests.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineInsights className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Personalized Energy Insights
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Deliver tailored recommendations for energy-saving practices and
                optimal usage based on customer consumption patterns.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiRobotLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Automation of Routine Tasks
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate repetitive tasks like outage notifications, billing
                updates, and meter readings for faster and more efficient
                service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
