import React from "react";
import hands_img from "../../assets/img/Csr/crowd.jpg";
import { LiaHandsHelpingSolid } from "react-icons/lia";

export default function Section2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={hands_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Community Engagement
            </h1>
            <p className="text-lg text-gray-600">
              We actively engage with the communities where we operate. Our goal
              is to contribute to their well-being by supporting local
              initiatives, educational programs, and charitable organizations.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaHandsHelpingSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Supporting Local Communities
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                We foster well-being by backing educational programs, charitable
                organizations, and local initiatives in the areas we serve.
              </p>
            </div>

            {/* Feature 2 */}
            {/* <div className="space-y-3">
                  <div className="flex items-center space-x-2">
                    <IoCloudUploadOutline className="w-6 h-6 text-indigo-600" />
                    <h3 className="font-semibold text-gray-900">
                      Predictive Cloud Performance Insights
                    </h3>
                  </div>
                  <p className="text-gray-600 text-sm">
                    This allows us to offer predictive insights into your cloud
                    performance, giving you the foresight to address problems before
                    they impact your operations.
                  </p>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
