import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineSchool } from 'react-icons/md'; // Advanced Model Training
import { MdOutlineCloudDone } from 'react-icons/md'; 
import img1 from "../../../assets/img/EngineeringExcellence/Ip/solutions.png"
import { MdOutlineLocalHospital } from 'react-icons/md';

import { MdOutlineDashboardCustomize } from "react-icons/md";

export default function MainSection3() { 
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Domain-Specific Solutions
            </h1>
            <p className="text-lg text-gray-600">
            Combining domain expertise with advanced technologies to create IPs that address industry-specific challenges. These solutions drive real-world impact, faster adoption, and improved ROI for clients.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDashboardCustomize className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Blockchain-Powered Supply Chain Optimization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Our IPs are tailored to address industry-specific challenges. By integrating domain expertise with cutting-edge technologies, we deliver solutions that solve real-world problems, enabling faster adoption and better ROI for our clients.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLocalHospital className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Predictive Analytics in Healthcare</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Examples include IPs for predictive analytics in healthcare, blockchain-powered supply chain optimization, and AI-driven operational efficiency for manufacturing.
              </p>
            </div>

            
            {/* Feature 3 */}
           {/*  <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiArtificialIntelligence className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Digital Twins for Healthcare</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Simulate patient conditions or treatment scenarios to innovate care delivery virtually.
              </p>
            </div> */}

           
          </div>
        </div>

      
      </div>
    </div>
  )
}