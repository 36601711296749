import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/StrategicConsulting/19.png";
import { FaBullseye, FaChartLine, FaMap, FaRocket, FaSearch, FaSync, FaUserGraduate } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { AiOutlineDeploymentUnit } from "react-icons/ai";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            AI Roadmap Creation
            </h1>
            <p className="text-lg text-gray-600">
            Define a strategic roadmap for implementing AI and ML solutions aligned with business goals.
						 </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineDeploymentUnit className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Iterative Model Deployment</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Implement AI/ML solutions in phases, allowing for adjustments and improvements.


               </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaUserGraduate className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Skill Development</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Build in-house expertise through training and hiring specialized talent.
              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}