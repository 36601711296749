import React from "react";
import img1 from "../../../assets/img/Products/SmartChaosLab/business.jpg";

// export default function Feature7() {
//   return (
//     <div>
//     <section className="dark:bg-gray-800 dark:text-gray-100">

//       <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
//       <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
//         <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
//         Enhanced Business Continuity
//         </h3>
//         <div className="flex space-x-2 sm:space-x-4">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           stroke="currentColor"
//           className="flex-shrink-0 w-6 h-6"
//         >
//           <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           strokeWidth="2"
//           d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
//           ></path>
//         </svg>

//         <div className="space-y-2">
//           <p className="text-lg font-medium leadi">
//           By minimizing potential disruptions and optimizing system performance, SmartChaosLab directly contributes to improved business continuity, ensuring that critical services remain uninterrupted under various conditions.
//           </p>
//         </div>
//         </div>

//       </div>
//       <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
//         <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
//         <img
//           src={business_img}
//           alt=""
//           className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
//         />
//         </div>
//       </div>
//       </div>
//     </section>
//     </div>
//   );
// }

import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
//import img1 from "../../../../assets/img/Services/product_dev1.jpg";
import {
  IoShieldCheckmarkOutline,
  IoStatsChartOutline,
  IoDesktopOutline,
  IoSyncOutline,
} from "react-icons/io5";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Enhanced Business Continuity
            </h1>
            <p className="text-lg text-gray-600">
              By minimizing potential disruptions and optimizing system
              performance, SmartChaosLab directly contributes to improved
              business continuity, ensuring that critical services remain
              uninterrupted under various conditions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoShieldCheckmarkOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Improved Risk Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Ensures services are safeguarded against disruptions.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoStatsChartOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Optimized Performance
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Continuous performance monitoring and optimization.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoDesktopOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Seamless User Experience
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Maintains a smooth experience even during disruptions.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoSyncOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Continuous Sync</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Ensures system data remains synchronized across platforms.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
