import React from 'react'
import img1 from '../../../assets/img/Products/MS360/2149301535.jpg'
import { MdKeyboardArrowDown } from "react-icons/md";
export default function MainSection1() {
  return (
    <div>
         {/* <section class=" mt-5 py-20 md:py-10 px-4  relative">
          <div
            class="absolute  w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat bg-fixed"
            style={{
              backgroundImage:`url(${img1})`,
            }}
          ></div>
          <div className="max-w-4xl  mb-10 md:mx-auto text-center   lg:max-w-[62rem] md:mb-12">
            <p className="relative   tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            Maturity Spectrum 360 is cloud-ready and can be used for self or AI-enabled assessments across various domains such as chaos, SRE, DevOps, security, cloud, etc. The platform's design is guided by the need for a comprehensive, customizable, and user-friendly tool for assessing an organization's maturity level and guiding its improvement efforts.
            </p>
          </div>
        </section> */}
         <section
      className="relative text-white py-20 md:py-10 bg-cover bg-center"
      style={{
        backgroundImage:
          `url(${img1})`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="container mx-auto px-4 text-center relative z-10">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
        Navigating Digital Transformation
        </h1>
        <p className="text-xl mb-8">
        Maturity Spectrum 360 is cloud-ready and can be used for self or AI-enabled assessments across various domains such as chaos, SRE, DevOps, security, cloud, etc. The platform's design is guided by the need for a comprehensive, customizable, and user-friendly tool for assessing an organization's maturity level and guiding its improvement efforts.
        </p>
        <div className=' flex justify-center items-center flex-col space-y-2'>
        <button to={'/contact-us'} className="bg-white cursor-pointer text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
          Explore more
        </button>
        <MdKeyboardArrowDown className=' text-white animate-bounce ' size={20}/>
      </div>
      </div>
    </section>
          
    </div>
  )
}
