import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/ProductEngineering/9.png";
import { FaBullseye, FaChartBar, FaChartLine, FaMap, FaUserAlt } from "react-icons/fa";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
             <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
         
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            AI Model Development
            </h1>
            <p className="text-lg text-gray-600">
            Build and integrate customized AI/ML models for automation, personalization, and decision-making.                       </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaChartBar className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Decision-Making</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Incorporate AI-driven insights to assist in business decision-making processes.
               </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaUserAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Personalization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Integrate AI models to offer personalized user experiences and recommendations.

                            </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}