import React from 'react';



import hero_img from '../../../../assets/img/Services/systemIntegration/three/2149370109.jpg';
import Img1 from '../../../../assets/img/Services/systemIntegration/three/2149311909.jpg';
import Img2 from '../../../../assets/img/Services/systemIntegration/three/2150038853.jpg';
import Img3 from '../../../../assets/img/Services/systemIntegration/three/2149311915.jpg';
import Img4 from '../../../../assets/img/Services/systemIntegration/three/2149595848.jpg';
import {
  IoCheckmarkCircleOutline,
  IoLockClosedOutline,
} from 'react-icons/io5';
import { BiCoinStack, BiLike } from 'react-icons/bi';
import { FaCogs, FaLightbulb, FaPencilRuler, FaRocket } from 'react-icons/fa';
import HeroSection from '../components/HeroSection';
import FeatureSection from '../components/FeatureSection';
import Section from '../components/Section';
import AnimationRevealPage from '../../../../helpers/AnimationRevealPage';

const index = () => {

  const HeroData = {
    title: "Automation & RPA Solutions",
    description: "AeroAegis enables businesses to achieve operational efficiency and scalability through<strong> Automation and RPA Solutions.</strong>By automating repetitive tasks and workflows, we help enterprises improve accuracy, speed, and productivity across operations.",
    image: hero_img, // Replace with an appropriate image path
  };
  
  

  // Features data for "Key Benefits"
  const features = [
    {
      icon: <FaRocket />,
      title: 'Reduced Operational Costs',
      description: 'Lower operational expenses while boosting efficiency.',
    },
    {
      icon: <FaLightbulb />,
      title: 'Faster, Accurate Processes',
      description: 'Achieve speed and consistency in operations.',
    },
    {
      icon: <FaPencilRuler />,
      title: 'Scalable Frameworks',
      description: 'Implement scalable automation solutions for growth.',
    },
    {
      icon: <FaCogs />,
      title: 'Employee Empowerment',
      description: 'Enable teams to focus on high-value, strategic tasks.',
    },
  ];
  
  

  // Section data
  const sectionData = [
    {
      imageSrc: Img1, // Replace with an appropriate image
      title: 'Process Discovery',
      description: 'Identify repetitive, rule-based processes that are ideal for automation.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Task Analysis',
          description: 'Analyze workflows to identify automation opportunities.',
        },
        {
          icon: BiLike,
          title: 'Prioritization',
          description: 'Rank processes by complexity and ROI potential.',
        },
        {
          icon: BiCoinStack,
          title: 'Optimization Insights',
          description: 'Gain actionable insights for process improvement.',
        },
      ],
    },
    {
      imageSrc: Img2,
      title: 'RPA Implementation',
      description: 'Deploy tools like UiPath, Blue Prism, and Automation Anywhere to automate routine business processes.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Custom RPA Solutions',
          description: 'Develop tailored RPA workflows for your business.',
        },
        {
          icon: IoLockClosedOutline,
          title: 'Secure Automation',
          description: 'Ensure compliance and data security in automated processes.',
        },
        {
          icon: BiLike,
          title: 'Zero Disruption',
          description: 'Implement RPA with minimal impact on current operations.',
        },
      ],
    },
    {
      imageSrc: Img3,
      title: 'Intelligent Automation',
      description: 'Integrate AI and machine learning with RPA for intelligent, decision-based workflows.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'AI Integration',
          description: 'Combine RPA with AI for smart, decision-based tasks.',
        },
        {
          icon: BiCoinStack,
          title: 'Data-Driven Decisions',
          description: 'Leverage AI for real-time insights and automation.',
        },
        {
          icon: IoLockClosedOutline,
          title: 'Adaptive Systems',
          description: 'Implement adaptive workflows with machine learning.',
        },
      ],
    },
    {
      imageSrc: Img4,
      title: 'Workflow Optimization',
      description: 'Streamline complex processes across systems, improving efficiency and eliminating manual errors.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Process Streamlining',
          description: 'Eliminate bottlenecks with optimized workflows.',
        },
        {
          icon: BiCoinStack,
          title: 'Error Elimination',
          description: 'Reduce manual errors through automation.',
        },
        {
          icon: BiLike,
          title: 'Increased Productivity',
          description: 'Boost output with streamlined operations.',
        },
      ],
    },
  ];
  
  

  return (
    <>
      <HeroSection
        title={ HeroData.title}
        description={HeroData.description}
        image={hero_img}
      />

<AnimationRevealPage >

      <FeatureSection
        mainTitle="Key Benefits"
        description="Automate processes with Robotic Process Automation (RPA) and intelligent workflows."
        features={features}
      />
      </AnimationRevealPage>
      <AnimationRevealPage>
        <div className="flex px-2 flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
                  <h2 className="md:px-10 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
                    <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
                    <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
                  </h2>
                  
                </div>
      {sectionData.map((section, index) => (
       
        <Section
          key={index}
          imageSrc={section.imageSrc}
          title={section.title}
          description={section.description}
          features={section.features}
          isImageLeft={index % 2 !== 0}// Alternate image position
        />
      ))}
      </AnimationRevealPage>
    </>
  );
};

export default index;
