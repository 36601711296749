import React from 'react'
import img1 from '../../assets/img/careers/vacany.png'
import img2 from '../../assets/img/careers/intern.jpg'
import img3 from '../../assets/img/careers/joinus.jpg'
export default function OtherSection9() {
  return (
    <div>
              <div class="relative group mt-4">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img1}
    alt=""
  />
  <div class="absolute top-1/2 bg-black bg-opacity-55 w-full h-full left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white  font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36'> Current Openings</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Are you ready to join us on this exciting journey? Check out our current job openings and see if there's a role that matches your skills and aspirations. We're always on the lookout for individuals who are driven, innovative, and ready to make a difference.
    </p>
  </div>
</div>   <div class="relative group mt-4">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img2}
    alt=""
  />
  <div class="absolute top-1/2 bg-black bg-opacity-55 w-full h-full left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-6xl font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36'>Internship Opportunities</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Embark on a learning journey with AeroAegis through our internship programs. Gain practical experience, work on real projects, and learn from experts in the field. If you're a student or recent graduate looking to kickstart your career, our internship opportunities are a perfect way to get hands-on experience.
    </p>
  </div>
</div>
<div class="relative group mt-4">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img3}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full  text-center transform -translate-x-1/2 -translate-y-1/2 text-white text-6xl font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36'>Join Us and Shape the Future</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    At AeroAegis, we're not just building IT solutions; we're shaping the future of businesses. If you're ready to be a part of a dynamic team that's driving innovation and transformation, we'd love to hear from you. Apply now or reach out to our HR team at careers@aeroaegis.com for more information. Let's work together to create a brighter IT landscape.
    </p>
  </div>
</div>
    </div>
  )
}
