import React from 'react'
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import HeroSection from './NewHeroSection'
import MainSection1 from './MainSection1'
import Feature1 from './Feature1'
import Feature2 from './Feature2'
import Feature3 from './Feature3'
import Feature4 from './Feature4'
import Feature5 from './Feature5'

import FinalQuote from './FinalQuote'
import Advancedfeature1 from './Advancedfeature1';
import Advancedfeature2 from './Advancedfeature2';
import Advancedfeature3 from './Advancedfeature3';
import Advancedfeature4 from './Advancedfeature4';
import Advancedfeature5 from './Advancedfeature5';
import Advancedfeature6 from './Advancedfeature6';
import Productbenefit1 from './Productbenefit1';
import Whytochoose from './Whytochoose';
import Deployment from './Deployment';

export default function index() {
  return (
    <div>
        <HeroSection/>
        <MainSection1/>
        <AnimationRevealPage>
        <Feature1/>
        <Feature2/>
        <Feature3/>
        <Feature4/>
        <Feature5/>
        <Advancedfeature1/>
        <Advancedfeature2/>
        <Advancedfeature3/>
        <Advancedfeature4/>
        <Advancedfeature5/>
        <Advancedfeature6/> 
        <Deployment/>
        <Whytochoose/>
        <Productbenefit1/>
        
        </AnimationRevealPage>
        <FinalQuote/>

    </div>
  )
}
