import React from 'react';
import feature_img from '../../../assets/img/Products/AeroGenix/2149126943.jpg';
import { LiaFileAlt } from "react-icons/lia";
import { BiCustomize } from "react-icons/bi";
import { HiOutlineLink } from "react-icons/hi";

export default function Feature1() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Key Features</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Build Your AI Agents
            </h1>
            <p className="text-lg text-gray-600">
              With AeroGenix, creating an autonomous AI agent is as simple as
              drag-and-drop. Our platform offers:
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaFileAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Pre-built Templates
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Pre-built templates for common workflows (e.g., customer
                service, ticketing, document automation).
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiCustomize className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Custom Agent</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Custom agent creation tailored to your business processes.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <HiOutlineLink className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Task Chaining</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Task chaining to handle complex operations seamlessly.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={feature_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
