import React from 'react'
import generation_img from "../../../assets/img/Products/MS360/2150377128.jpg"
import { MdOutlineCheckCircle } from 'react-icons/md';
import { FiClipboard } from 'react-icons/fi';
export default function Feature8() {
  return ( 
    <div>
	{/* <section className="dark:bg-gray-800 pt-5 dark:text-gray-100">
   
		 
   <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
   <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
		   <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
			   <img src={generation_img} alt="" className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96" />
		   </div>
	   </div>
	   <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
	   <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50"> Action Plan Generation
</h3>
		   <div className="flex space-x-2 sm:space-x-4">
			   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
				   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
			   </svg>
			   <div className="space-y-2">
				   <p className="text-lg font-medium leadi">  Automatically create actionable plans based on assessment results and recommendations, specifying tasks, deadlines, and required resources.</p>
				   
			   </div>
		   </div>
		 
		 
	   </div>
	   
   </div>
</section> */}

<div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={generation_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
			Action Plan Generation
            </h1>
            <p className="text-lg text-gray-600">
			Automatically create actionable plans based on assessment results and recommendations, specifying tasks, deadlines, and required resources.</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCheckCircle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Automated Action Plans</h3>
              </div>
              <p className="text-gray-600 text-sm">
			  Generate actionable plans directly from assessment results, detailing specific tasks, deadlines, and required resources for execution.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiClipboard className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Streamlined Task Management</h3>         
              </div>
              <p className="text-gray-600 text-sm">
			  Ensure efficient execution by providing clear instructions and timelines, helping teams prioritize and track progress toward improvement goals.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaPaintBrush className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Aesthetic Appeal</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Create visually appealing interfaces that align with brand identity.
              </p>
            </div> */}

          </div>
        </div>

      
      </div>
    </div>
</div>
  )
}
