import React from 'react';


import hero_img from '../../../../assets/img/Services/systemIntegration/four/1700641682156.jpg';
import Img1 from '../../../../assets/img/Services/systemIntegration/four/2149092255.jpg';
import Img2 from '../../../../assets/img/Services/systemIntegration/four/2149739756.jpg';
import Img3 from '../../../../assets/img/Services/systemIntegration/four/2149092253.jpg';
import Img4 from '../../../../assets/img/Services/systemIntegration/four/2150165975.jpg';
import {
  IoCheckmarkCircleOutline,
  IoCodeSlashOutline,
  IoLockClosedOutline,
  IoSpeedometerOutline,
} from 'react-icons/io5';
import { BiCoinStack, BiLike, BiLinkAlt, BiShieldQuarter } from 'react-icons/bi';
import { FaCogs, FaCubes, FaLightbulb, FaLock, FaPencilRuler, FaRocket } from 'react-icons/fa';
import HeroSection from '../components/HeroSection';
import FeatureSection from '../components/FeatureSection';
import Section from '../components/Section';
import AnimationRevealPage from '../../../../helpers/AnimationRevealPage';
import { FaChartLine, FaPuzzlePiece, FaServer } from 'react-icons/fa6';
import FinalSection from '../components/FinalSection';

const index = () => {

  const HeroData = {
    title: "API and Microservices Integration",
    description: "Modern applications demand flexibility, scalability, and interoperability. AeroAegis specializes in building and integrating<strong> APIs</strong> and <strong>microservices architectures</strong> to enable seamless, modular solutions that drive agility and innovation.",
    image: hero_img, // Replace with an appropriate image path
  };
  
  

  // Features data for "Key Benefits"
  const features = [
    {
      icon: <FaPuzzlePiece />,
      title: 'Modular, Flexible Architecture',
      description: 'Support rapid innovation with a modular and adaptive design.',
    },
    {
      icon: <FaChartLine />,
      title: 'Enhanced Scalability',
      description: 'Enable businesses to scale and adapt to evolving needs.',
    },
    {
      icon: <FaLock />,
      title: 'Secure API Governance',
      description: 'Ensure APIs are secure, governed, and monitored for reliability.',
    },
    {
      icon: <FaRocket />,
      title: 'Faster Development',
      description: 'Reduce time-to-market with streamlined development and deployment.',
    },
  ];
  
  

  // Section data
  const sectionData = [
    {
      imageSrc: Img1, // Replace with an appropriate image
      title: 'API Development & Integration',
      description: 'Design and implement RESTful and GraphQL APIs that enable communication across applications and platforms.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'RESTful APIs',
          description: 'Develop secure, scalable RESTful APIs for diverse use cases.',
        },
        {
          icon: IoCodeSlashOutline,
          title: 'GraphQL Integration',
          description: 'Simplify data fetching with powerful GraphQL APIs.',
        },
        {
          icon: BiLinkAlt,
          title: 'Cross-Platform Communication',
          description: 'Enable seamless interaction across systems and platforms.',
        },
      ],
    },
    {
      imageSrc: Img2,
      title: 'Microservices Architecture',
      description: 'Break down monolithic systems into modular, containerized microservices for flexibility and scalability.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Containerization',
          description: 'Deploy microservices in containers for modularity and efficiency.',
        },
        {
          icon: FaCubes,
          title: 'Service-Oriented Design',
          description: 'Architect systems with a focus on individual, reusable services.',
        },
        {
          icon: IoSpeedometerOutline,
          title: 'Improved Performance',
          description: 'Achieve faster, more reliable operations through microservices.',
        },
      ],
    },
    {
      imageSrc: Img3,
      title: 'API Management',
      description: 'Manage, monitor, and secure APIs using tools like MuleSoft, Dell Boomi, Apigee, Azure API Management, and AWS API Gateway.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Comprehensive Monitoring',
          description: 'Track API usage and performance with robust tools.',
        },
        {
          icon: BiShieldQuarter,
          title: 'Enhanced Security',
          description: 'Protect APIs with authentication, encryption, and access controls.',
        },
        {
          icon: FaLightbulb,
          title: 'Analytics & Reporting',
          description: 'Leverage data insights to optimize API performance.',
        },
      ],
    },
    {
      imageSrc: Img4,
      title: 'Enterprise Service Bus (ESB)',
      description: 'Integrate systems using modern ESB platforms like MuleSoft and WSO2 for reliable and high-performance data exchange.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Reliable Integration',
          description: 'Ensure consistent, high-performance system communication.',
        },
        {
          icon: BiCoinStack,
          title: 'Scalable Solutions',
          description: 'Support enterprise growth with scalable ESB platforms.',
        },
        {
          icon: FaServer,
          title: 'Fault Tolerance',
          description: 'Minimize disruptions with fault-tolerant data exchange.',
        },
      ],
    },
  ];
  
  

  return (
    <>
      <HeroSection
        title={ HeroData.title}
        description={HeroData.description}
        image={hero_img}
      />

<AnimationRevealPage >

      <FeatureSection
        mainTitle="Key Benefits"
        description="Design and implement APIs and microservices for a scalable, modular architecture."
        features={features}
      />
      
      </AnimationRevealPage>
      <AnimationRevealPage>
        <div className="flex flex-col px-2 mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
                  <h2 className="md:px-10 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
                    <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
                    <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
                  </h2>
                  
                </div>
      {sectionData.map((section, index) => (
       
        <Section
          key={index}
          imageSrc={section.imageSrc}
          title={section.title}
          description={section.description}
          features={section.features}
          isImageLeft={index % 2 !== 0}// Alternate image position
        />
      ))}
      </AnimationRevealPage>

      <FinalSection />
    </>
  );
};

export default index;
