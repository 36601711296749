import React from "react";
import {
  FaCloudUploadAlt,
  FaCloud,
  FaBolt,
  FaShieldAlt,
  FaChartBar,
  FaChartLine,
  FaLightbulb,
  FaUserCheck,
  FaUsers,
  FaLock,
  FaClock,
  FaRocket,
} from "react-icons/fa";
import {
  HiOutlineCog,
  HiOutlineDesktopComputer,
  HiOutlineClipboardList,
  HiOutlineUserGroup,
} from "react-icons/hi";

const CaseStudyPage = () => {
  return (
    <div className="mx-auto px-4 md:px-24 py-8 w-full max-w-7xl">
      <div className="uppercase tracking-wide text-1xl font-bold text-indigo-500">
        Case Study
      </div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white">
        Transforming Aerospace Operations with Agent AI and Predictive Analytics
      </h1>
      <h2 className="mt-2 text-2xl font-semibold text-gray-700 dark:text-gray-300">
        AeroAegis Inc. Delivers a Revolutionary Solution for Aerospace
        Operations with Agent AI and Predictive Analytics
      </h2>
      <div className="mt-4 flex flex-wrap gap-4">
        <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
          <HiOutlineCog className="mr-1 h-3 w-3" />
          Predictive Analytics
        </span>
        <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
          <HiOutlineDesktopComputer className="mr-1 h-3 w-3" />
          AI-Powered Automation
        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
          <HiOutlineClipboardList className="mr-1 h-3 w-3" />
          Compliance
        </span>
        <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-900/20 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-300 ring-1 ring-inset ring-red-600/20 dark:ring-red-300/20">
          <HiOutlineUserGroup className="mr-1 h-3 w-3" />
          Customer Experience
        </span>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4 mt-6">Case Study Description</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis partnered with a leading aerospace organization to enhance
          fleet management, predictive maintenance, and customer service through
          Agent AI and Predictive Analytics. The solution improved operational
          efficiency, reduced downtime, and ensured regulatory compliance in a
          complex, global aerospace environment.
        </p>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="flex items-center space-x-3">
            <FaChartLine className="text-2xl text-blue-500" />
            <p className="text-gray-700 dark:text-gray-300">
              Enhanced fleet management efficiency.
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaUserCheck className="text-2xl text-green-500" />
            <p className="text-gray-700 dark:text-gray-300">
              Optimized predictive maintenance to minimize downtime.
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaLock className="text-2xl text-yellow-500" />
            <p className="text-gray-700 dark:text-gray-300">
              Streamlined regulatory compliance and reporting.
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaClock className="text-2xl text-purple-500" />
            <p className="text-gray-700 dark:text-gray-300">
              Accelerated customer service response times.
            </p>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Client Background</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          The aerospace industry is a dynamic and highly complex sector where
          operational efficiency, predictive maintenance, and regulatory
          compliance are critical. Organizations in this space manage large
          fleets, manufacturing facilities, and maintenance operations,
          requiring advanced systems to ensure seamless data integration,
          real-time decision-making, and safety compliance.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Our client, a leading aerospace organization specializing in
          commercial aviation and defense solutions, operates a global network
          of aircraft fleets, manufacturing facilities, and maintenance
          operations. The company faced significant challenges in managing a
          growing fleet, improving maintenance processes, and enhancing customer
          service efficiency. They sought to leverage AI-driven automation and
          predictive analytics to streamline operations, reduce downtime, and
          improve customer satisfaction.
        </p>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
          <li>
            Inefficient Fleet Management: Manual monitoring of aircraft
            components led to delays in identifying and resolving potential
            issues.
          </li>
          <li>
            Complex Maintenance Operations: Lack of real-time visibility into
            asset performance made it difficult to predict and prevent failures.
          </li>
          <li>
            Customer Service Bottlenecks: Fragmented communication channels led
            to inconsistent support for customers and suppliers.
          </li>
          <li>
            Regulatory Compliance: Meeting strict aviation safety and compliance
            standards required significant manual oversight.
          </li>
        </ul>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Recognizing the need for advanced solutions, the client partnered with
          AeroAegis to implement Agent AI and Predictive Analytics. AeroAegis’s
          expertise in AI-driven automation and predictive maintenance helped
          address key operational inefficiencies, improve customer service, and
          ensure regulatory compliance.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          By partnering with AeroAegis, the client aimed to achieve:
        </p>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
          <li>
            Enhanced fleet management through predictive maintenance and
            real-time data integration.
          </li>
          <li>
            Improved customer service with AI-driven automation, reducing
            response times and increasing satisfaction.
          </li>
          <li>
            Streamlined regulatory compliance with automated reporting tools and
            real-time audit trails.
          </li>
        </ul>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          The collaboration with AeroAegis marked a significant step in
          modernizing the client’s operations, positioning them for future
          success in the aerospace sector.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Challenges Faced by the Client
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-2">
          Our client, a leading aerospace organization specializing in
          commercial aviation and defense solutions, faced growing operational
          complexities due to the increasing demand for efficient fleet
          management, predictive maintenance, and seamless customer service. The
          organization operates a global network of aircraft fleets,
          manufacturing facilities, and maintenance operations, servicing both
          government and private sector clients.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Inefficient Fleet Management</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Manual monitoring of aircraft components led to delays in
              identifying and resolving potential issues. High costs were
              incurred due to unplanned maintenance and fleet downtime.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Delays in identifying maintenance needs due to manual systems.
              </li>
              <li>
                Increased costs related to unexpected downtime and repairs.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Complex Maintenance Operations</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Lack of real-time visibility into asset performance made it
              difficult to predict and prevent failures. There was also limited
              data integration between manufacturing, operations, and
              maintenance teams.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Difficulty in predicting failures due to disconnected data
                systems.
              </li>
              <li>
                Inconsistent and siloed data between teams leading to
                inefficiencies.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Customer Service Bottlenecks</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Fragmented customer communication channels led to inconsistent
              support for aviation customers and suppliers. High response times
              for resolving queries related to supply chain, spare parts, and
              maintenance schedules.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Delays in responding to maintenance and supply chain queries.
              </li>
              <li>Inefficiencies due to disconnected communication methods.</li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Regulatory Compliance</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Meeting strict aviation safety and compliance standards (e.g., FAA
              and EASA) required significant manual oversight and reporting
              efforts.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Complex manual processes for regulatory reporting and audits.
              </li>
              <li>
                Challenges in meeting safety standards due to limited
                automation.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">AeroAegis's Approach</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          To address the client’s challenges, AeroAegis partnered with a leading
          aerospace organization to implement AI-driven automation and
          predictive analytics. Our strategy centered on delivering a
          cloud-native, event-driven solution powered by real-time data
          integration, machine learning, and conversational AI to optimize fleet
          management, improve customer service, and ensure regulatory
          compliance.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Comprehensive Assessment of Operations</strong>
            <p className="ml-6 mt-2">
              AeroAegis initiated the project with a thorough assessment of the
              client’s existing operations, focusing on fleet management,
              maintenance processes, and customer service capabilities. This
              evaluation highlighted key inefficiencies and areas for
              improvement.
            </p>
            <p className="ml-6 mt-2">
              Key findings from the assessment included:
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Manual fleet monitoring leading to delays in identifying
                potential issues.
              </li>
              <li>
                Lack of real-time visibility into asset performance and
                maintenance needs.
              </li>
              <li>
                Fragmented customer communication channels and slow response
                times.
              </li>
            </ul>
            <p className="ml-6 mt-2">
              Based on these insights, a tailored roadmap was developed to guide
              the transformation of the client's operations using AI and
              predictive analytics.
            </p>
          </li>
          <li className="mb-4">
            <strong>Predictive Maintenance with Machine Learning</strong>
            <p className="ml-6 mt-2">
              AeroAegis designed and implemented predictive maintenance
              analytics, integrating IoT sensors across the fleet to collect
              real-time data on aircraft performance. Machine learning
              algorithms were applied to predict potential failures, proactively
              schedule maintenance, and reduce unplanned downtime.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Real-time monitoring of engine health, fuel efficiency, and
                wear-and-tear.
              </li>
              <li>
                Machine learning models to predict component failures and
                optimize maintenance schedules.
              </li>
              <li>
                Centralized dashboard for actionable insights and faster
                decision-making.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>AI-Powered Customer Service Automation</strong>
            <p className="ml-6 mt-2">
              To streamline customer interactions, AeroAegis deployed AI-powered
              virtual assistants that handled routine queries related to
              maintenance schedules, spare parts, and supply chain logistics.
              Natural Language Processing (NLP) was used to support global
              customers in multiple languages.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Automated routine inquiries and escalated complex issues to
                human agents.
              </li>
              <li>
                Enabled faster response times and improved customer
                satisfaction.
              </li>
              <li>
                Leveraged NLP for multilingual support, ensuring seamless
                communication with global clients.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Regulatory Compliance Automation</strong>
            <p className="ml-6 mt-2">
              AeroAegis implemented AI-driven compliance tools to automate
              reporting and ensure adherence to aviation safety standards like
              FAA and EASA. These tools reduced manual oversight, streamlined
              regulatory reporting, and improved audit readiness.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Automated compliance checks and real-time audit trails for
                maintenance activities.
              </li>
              <li>
                Ensured 100% adherence to aviation safety standards, reducing
                the time for regulatory reviews.
              </li>
              <li>
                Streamlined reporting processes, reducing manual effort by 60%.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Optimizing Fleet Deployment and Performance</strong>
            <p className="ml-6 mt-2">
              Predictive models were developed to optimize fleet deployment,
              ensuring efficient use of aircraft and minimizing operational
              costs. Real-time tracking of aircraft performance metrics helped
              identify underutilized assets and improve scheduling.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>Optimized scheduling to improve fleet utilization by 25%.</li>
              <li>
                Identified underutilized assets and reduced operational costs.
              </li>
              <li>
                Enabled real-time performance tracking for better
                decision-making.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Big Data Analytics and Insights</strong>
            <p className="ml-6 mt-2">
              AeroAegis integrated a big data analytics platform to enable
              real-time insights across fleet operations, maintenance, and
              customer service. This platform leveraged predictive analytics to
              anticipate maintenance needs and enhance decision-making.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Real-time data integration for faster insights and
                decision-making.
              </li>
              <li>
                Predictive models to forecast maintenance needs and optimize
                resource allocation.
              </li>
              <li>
                Centralized data access across teams, improving operational
                visibility.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Solution Implementation</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          The solution implementation phase was a critical component of the
          client’s transformation journey in the aerospace sector. AeroAegis
          partnered with the client to integrate Agent AI and Predictive
          Analytics, addressing operational inefficiencies and improving
          customer service and regulatory compliance.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Predictive Analytics Framework</strong>
            <p className="ml-6 mt-2">
              The solution focused on developing a predictive analytics
              framework to enhance fleet performance and proactively manage
              maintenance needs. Key elements included:
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Predictive Maintenance:</strong> Advanced machine
                learning algorithms were used to predict component failures,
                reducing unplanned downtime and optimizing maintenance
                scheduling.
              </li>
              <li>
                <strong>Real-Time Data Integration:</strong> IoT sensors were
                deployed to collect data across the fleet, providing real-time
                insights into aircraft performance, including engine health and
                fuel efficiency.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Agent AI for Customer Service Automation</strong>
            <p className="ml-6 mt-2">
              To streamline customer interactions, AI-powered virtual assistants
              were integrated to handle routine queries, improving response
              times and customer satisfaction:
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Multilingual Support:</strong> Natural Language
                Processing (NLP) was used to enable seamless communication with
                global customers and suppliers, ensuring efficient service
                across regions.
              </li>
              <li>
                <strong>Automated Inquiry Handling:</strong> Agent AI automated
                responses to frequently asked questions, allowing human agents
                to focus on more complex issues.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Fleet Optimization and Predictive Maintenance</strong>
            <p className="ml-6 mt-2">
              Predictive models were created to optimize fleet deployment and
              reduce operational costs. Key strategies included:
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Performance Tracking:</strong> Real-time tracking
                systems were developed to monitor aircraft usage and identify
                underutilized assets.
              </li>
              <li>
                <strong>Proactive Maintenance Scheduling:</strong> Maintenance
                schedules were optimized using predictive analytics to avoid
                unnecessary downtime and ensure fleet readiness.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Regulatory Compliance Automation</strong>
            <p className="ml-6 mt-2">
              Compliance with FAA, EASA, and other aviation safety standards was
              streamlined using AI-driven tools:
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Compliance Automation:</strong> AI tools were employed
                to automate regulatory reporting, ensuring adherence to strict
                safety standards and reducing manual oversight.
              </li>
              <li>
                <strong>Real-Time Audit Trails:</strong> A real-time audit
                system was created to track maintenance activities, enhancing
                transparency and reducing compliance risks.
              </li>
            </ul>
          </li>
          <li>
            <strong>Continuous Improvement and Cost Optimization</strong>
            <p className="ml-6 mt-2">
              To ensure ongoing operational efficiency, AeroAegis implemented
              continuous monitoring and optimization practices:
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Predictive Models for Cost Efficiency:</strong>{" "}
                Predictive models were used to identify cost-saving
                opportunities, including reducing maintenance costs and
                optimizing spare parts inventory.
              </li>
              <li>
                <strong>AI-Driven Decision Making:</strong> Data-driven insights
                were provided through AI models, allowing the client to make
                faster, more informed decisions and achieve higher fleet
                utilization rates.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">Key Outcomes and Benefits</h2>
        <ol class="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li class="mb-4">
            <strong>Enhanced Operational Efficiency</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Reduced Unplanned Maintenance:</strong> By implementing
                predictive analytics and AI, the client reduced unplanned
                maintenance by 35%, improving fleet uptime.
              </li>
              <li>
                <strong>Fleet Deployment Optimization:</strong> Predictive
                models increased fleet deployment efficiency by 25%, leading to
                reduced operational costs and improved scheduling.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Cost Savings and Resource Optimization</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Maintenance Cost Reduction:</strong> The adoption of
                predictive models for maintenance reduced costs by 20% and
                optimized spare parts inventory, lowering holding costs by 15%.
              </li>
              <li>
                <strong>Operational Cost Reduction:</strong> Predictive
                analytics for fleet management minimized unnecessary downtime
                and ensured efficient use of aircraft, reducing overall
                operational costs.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Improved Customer Experience</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Faster Response Times:</strong> Agent AI handled 70% of
                routine customer queries, reducing response times by 50% and
                improving customer satisfaction scores by 40%.
              </li>
              <li>
                <strong>Multilingual Support:</strong> The integration of NLP in
                Agent AI provided seamless communication with global customers
                and suppliers, ensuring a consistent support experience.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Regulatory Compliance and Audit Readiness</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Automated Compliance Reporting:</strong> AI-driven tools
                automated regulatory compliance processes, ensuring 100%
                adherence to safety standards and reducing manual oversight by
                60%.
              </li>
              <li>
                <strong>Enhanced Audit Transparency:</strong> Real-time audit
                trails for maintenance activities improved transparency and
                minimized the risk of non-compliance during regulatory reviews.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Foundation for Future Innovation</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Scalable AI Solutions:</strong> The integration of AI
                and predictive analytics laid the foundation for future
                technological advancements in fleet management and customer
                support.
              </li>
              <li>
                <strong>Increased Agility:</strong> The system's flexibility
                enables the client to adopt emerging technologies, such as IoT
                for real-time asset monitoring and AI for further customer
                service automation.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Transforming Aerospace Operations with Agent AI and Predictive
          Analytics
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
          The aerospace organization's transformation, guided by AeroAegis,
          demonstrates the powerful impact of Agent AI and Predictive Analytics
          in tackling the complex operational challenges of the aerospace
          industry. By implementing AI-driven automation and predictive
          maintenance strategies, the client achieved remarkable improvements in
          fleet efficiency, customer satisfaction, and regulatory compliance.
        </p>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaCloudUploadAlt className="mr-2 text-blue-500" />
              AI-Powered Customer Service Automation
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Deployed AI-powered virtual assistants to handle routine
              inquiries, reducing response times by 50%. AI technology provided
              seamless communication with global customers and suppliers,
              increasing customer satisfaction by 40%.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaBolt className="mr-2 text-yellow-500" />
              Predictive Maintenance Analytics
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Integrated IoT sensors to gather real-time data on aircraft
              performance, enabling predictive maintenance and reducing
              unplanned downtime by 35%. Machine learning algorithms allowed
              proactive identification of potential failures.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaShieldAlt className="mr-2 text-green-500" />
              Regulatory Compliance Automation
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Automated compliance checks and reporting, ensuring 100% adherence
              to FAA, EASA, and other regulatory standards. This streamlined
              process reduced manual oversight by 60% and improved audit
              readiness.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaChartBar className="mr-2 text-purple-500" />
              Fleet Optimization
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Developed predictive models for fleet optimization, improving
              fleet deployment efficiency by 25%. Real-time tracking of aircraft
              performance helped in identifying underutilized assets, reducing
              operational costs.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 p-4 mb-8">
        <p className="text-blue-700 dark:text-blue-300">
          This case study demonstrates AeroAegis's expertise in transforming
          aerospace operations by leveraging Agent AI and Predictive Analytics.
          By integrating real-time data, predictive maintenance, and AI-driven
          customer service, AeroAegis helped the client streamline operations,
          reduce downtime, and improve customer satisfaction in the highly
          regulated aerospace industry.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Why Choose AeroAegis?</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis brings deep expertise in aerospace operations, combining
          advanced technologies like Agent AI and Predictive Analytics with a
          thorough understanding of industry challenges. Our tailored solutions
          empowered the client to enhance operational efficiency, ensure
          compliance with aviation safety standards, and achieve significant
          cost savings. Whether you're looking to improve fleet management,
          optimize maintenance schedules, or enhance customer support, AeroAegis
          has the expertise and vision to make it happen.
        </p>
        <div className="grid md:grid-cols-3 gap-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaLightbulb className="text-3xl text-blue-500 mb-2" />
            <h3 className="font-semibold mb-2">Innovative Solutions</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Cutting-edge AI and predictive analytics tailored to aerospace
              needs
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaUsers className="text-3xl text-green-500 mb-2" />
            <h3 className="font-semibold mb-2">Expert Team</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Industry professionals providing customized, actionable insights
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaRocket className="text-3xl text-purple-500 mb-2" />
            <h3 className="font-semibold mb-2">Accelerated Transformation</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Fast, reliable AI-driven solutions that optimize operations
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Let's Transform Your Aerospace Operations
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Are you ready to elevate your aerospace operations with intelligent,
          scalable solutions? AeroAegis is here to help. Contact us today to
          explore how our AI and predictive analytics can streamline your fleet
          management, maintenance schedules, and customer service.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Visit{" "}
          <a
            href="http://www.aeroaegis.com"
            className="text-blue-500 hover:underline"
          >
            www.aeroaegis.com
          </a>{" "}
          or reach out to our experts for a personalized consultation. Together,
          we can build innovative, resilient, and scalable solutions that drive
          operational excellence in the aerospace industry.
        </p>
        <p className="text-gray-700 dark:text-gray-300 font-semibold">
          Let's shape the future of aerospace operations—one solution at a time.
        </p>
      </div>
    </div>
  );
};

export default CaseStudyPage;
