import React from 'react'
import customization_img from "../../../assets/img/iiot/features/124680.jpg"
import { AiOutlineAppstore } from 'react-icons/ai';
import { FiCpu } from 'react-icons/fi';

export default function Advancedfeature1() {
  return (
    <div>
	{/* <section className=" dark:bg-gray-800 dark:text-gray-100">
    <span class="mb-10  pt-10 block text-center text-4xl font-bold ">
            Advanced <span className='text-[#23307F]'> Features</span>
              <div>
              <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black">
               </p></div>
              </span>

	  <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
		<div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
		  <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
          Edge AI Model Optimization
		  </h3>
		  <div className="flex space-x-2 sm:space-x-4">
			<svg
			  xmlns="http://www.w3.org/2000/svg"
			  fill="none"
			  viewBox="0 0 24 24"
			  stroke="currentColor"
			  className="flex-shrink-0 w-6 h-6"
			>
			  <path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
			  ></path>
			</svg>

			<div className="space-y-2">
			  <p className="text-lg font-medium leadi">
			  <div className=' inline font-bold'>Functionality :</div> Deploys lightweight AI models optimized for edge devices, capable of complex analytics.				
			  </p>
			</div>
		  </div>
		  <div className="flex space-x-2 sm:space-x-4">
			<svg
			  xmlns="http://www.w3.org/2000/svg"
			  fill="none"
			  viewBox="0 0 24 24"
			  stroke="currentColor"
			  className="flex-shrink-0 w-6 h-6"
			>
			  <path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
			  ></path>
			</svg>

			<div className="space-y-2">
			  <p className="text-lg font-medium leadi">
			  <div className=' inline font-bold'>Benefits :</div> Delivers high-performance analytics with minimal computational resources, suitable for edge environments.		
			  </p>
			</div>
		  </div>
	
		</div>
		<div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
		  <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
			<img
			  src={customization_img}
			  alt=""
			  className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
			/>
		  </div>
		</div>
	  </div>
	</section> */}

<div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Advanced Features</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
			  Edge AI Model Optimization
              </h1>
              <p className="text-lg text-gray-600">
			  Deploys optimized AI models for edge devices, enabling complex analytics with minimal computational resources, perfect for edge environments.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineAppstore className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
				  Edge-Optimized AI
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
				Deploys lightweight AI models optimized for edge devices, capable of complex analytics.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FiCpu className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
				  Efficient Analytics
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
				Delivers high-performance analytics with minimal computational resources, suitable for edge environments.
                </p>
              </div>
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={customization_img}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
  </div>
  )
}
