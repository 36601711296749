import React from "react";
import { MdOutlineSmartToy } from "react-icons/md";
import { RiRoadMapLine } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import { AiOutlineBarChart } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import { IoCloudDoneOutline } from "react-icons/io5";
import { MdOutlineDeviceHub } from "react-icons/md";

import img1 from "../../../assets/img/aeroaegis_capabilities/innovation.jpg";

export default function CoreCapabilities1() {
  return (
    <section className=" ">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <span class="mb-10 mt-10 block text-center text-4xl font-bold text-[#23307F]">
        Core Capabilities
            <div>
              <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black"></p>
            </div>
          </span>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Product Innovation
              </h1>
              <p className="text-lg text-gray-600">
              AeroAegis is a pioneer in creating intelligent, scalable, and industry-leading products that address the complex challenges organizations face today. Each of our platforms is designed to drive transformation, operational efficiency, and innovation.
              </p>
              <p className="font-medium text-[18px]">Key Highlights:</p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FaRobot className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                  AI-Driven Solutions
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                Incorporating advanced AI capabilities to unlock new possibilities for decision-making, automation, and optimization.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <IoCloudDoneOutline className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                  Cloud-First Approach
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                Developing cloud-native products that ensure scalability, resilience, and seamless integration.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineDeviceHub className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                  Edge Intelligence
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                Leveraging Edge AI to process data in real time for industries requiring low-latency solutions.
                </p>
              </div>
              {/* Feature 4 */}
              {/* <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineBarChart className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Performance Metrics
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Monitoring key metrics to track progress and ensure successful
                  transformation.
                </p>
                <p className="font-medium text-[18px]">Key Highlghts:</p>
              </div> */}
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
