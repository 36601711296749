import React from 'react'
import Herosection from './Herosection'
import OtherSection from './OtherSection'
import FinalSection from './FinalSection'
import MainSection from './MainSection'
import MainSection2 from './MainSection2'
import { FeatureSection } from './FeatureSection'
import { FaChartPie, FaFlask, FaHandshake, FaRobot, FaTrophy } from "react-icons/fa6";
import Roadmap from './Roadmap'
import LeaderShipSec from './LeaderShipSec'
import FP from './FP'

export default function index() {
    const features = [
    {
      icon: <FaTrophy />,
      title: 'Product-Centric Leadership',
      description: "AeroAegis’s flagship products—Maturity Spectrum 360, SmartChaos Lab, AeroPulse AI, and AeroEdge IIoT—define the industry standard for cloud, AI, and IoT solutions.",
    },
    {
      icon: <FaRobot />,
      title: 'AI and Deep-Tech Focus',
      description: 'A commitment to innovation in Generative AI, Neural Processing Units (NPUs), quantum computing, and Web3 solidifies AeroAegis’s position as a forward-thinking technology company.',
    },
    
    {
      icon: <FaHandshake />,
      title: 'Consulting as a Value Add',
      description: 'Complementing our product focus, our Centers of Excellence (COEs) and consulting services accelerate clients’ adoption of our solutions while enhancing ROI.',
    },
    {
      icon: <FaFlask />,
      title: 'Research & Development Excellence',
      description: 'Investments in cutting-edge R&D drive patented innovations and ensure AeroAegis remains at the forefront of technological evolution.',
    },
    {
      icon: <FaChartPie />,
      title: 'Market Opportunity',
      description: 'The global demand for digital transformation solutions is soaring, and AeroAegis is strategically positioned to capture a significant share of the rapidly growing global demand for digital transformation solutions.',
    },
  ];
  return (
    <div>
        <Herosection/>
        <OtherSection/>
        <FeatureSection
                      mainTitle="Key Reasons to Invest"
                      description="Join us on our mission to redefine the future of IT and be part of our transformative journey."
                      features={features}
                    />
                    <FP />
        
        <LeaderShipSec />    
        <Roadmap />
        <FinalSection/>
        <MainSection/>  
        
        
        
    </div>
  )
}
