import Iiot_img from "../../../../assets/img/Partnerships/trust.webp";

import { AiOutlineCloudServer } from "react-icons/ai";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoIosGitNetwork } from "react-icons/io";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { BsShieldCheck } from "react-icons/bs";

function Section5() { 
  return (
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
             <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
               <div className="space-y-8">
                 <div className="space-y-4">
                   <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                   Strengthened Trust and Credibility
                   </h1>
                   <p className="text-lg text-gray-600">
                   AeroAegis's ISV certification reinforces its position as a trusted partner, ensuring clients can rely on proven expertise.
                   </p>
                 </div>
       
                 <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                   {/* Feature 1 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <MdOutlineVerifiedUser className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Strengthened Credibility
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     The ISV certification establishes AeroAegis as a trusted and reliable technology partner for enterprises, ensuring confidence in its expertise.
                     </p>
                   </div>
       
                   {/* Feature 2 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <BsShieldCheck className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Assurance of Excellence
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     Clients benefit from certified expertise that guarantees compliance, robust security, and superior performance in their Azure cloud initiatives.
                     </p>
                   </div>
                   
                 </div>
               </div>
       
               <div className="relative h-[500px] rounded-3xl overflow-hidden">
                 <img
                   src={Iiot_img}
                   alt="Workspace with laptop and plant"
                   className="object-cover w-full h-full rounded-3xl"
                 />
               </div>
             </div>
           </div>
  );
}
export default Section5;