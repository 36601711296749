import React from "react";
import HeroSection from "./NewHeroSection";
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature3 from "./Feature3";
import Feature4 from "./Feature4";
import Feature5 from "./Feature5";
import Feature6 from "./Feature6";

import Section1 from "./Section1";
import FinalSection from "./FinalSection";

import Benifits from "./Benifits"

export default function index() {
  return (
    <div>
      <HeroSection />
      <Section1/>
      <AnimationRevealPage>
        <Feature1 />
        <Feature2 />
        <Feature3 />
        <Feature4 />
        <Feature5 />
        <Feature6 />
        <Benifits />
        {/* <Feature7 />
        <Feature8 />
        <Feature9 />
        <Feature10/>
        <Feature11/> */}
      </AnimationRevealPage>
      <FinalSection/>
    </div>
  );
}
