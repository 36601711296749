import React from "react";
import industry_img from "../../../../assets/img/Partnerships/pexels-stas-knop-1168746 (1).jpg";
import {
  IoCheckmarkCircleOutline,
  IoCloudUploadOutline,
} from "react-icons/io5";
import { AiOutlineSolution } from "react-icons/ai";
import { AiOutlineExperiment } from "react-icons/ai";

export default function Section2() {
  return (
    <div>
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={industry_img}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Academic and Research Collaborations
              </h1>
              <p className="text-lg text-gray-600">
                Our commitment to innovation is powered by partnerships with
                leading academic institutions and research organizations,
                ensuring that our solutions are informed by the latest
                discoveries and breakthroughs.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineExperiment className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Cutting-Edge R&D
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Collaborating on research in AI, quantum computing, neural
                  processing units (NPUs), and other emerging technologies to
                  push the boundaries of what’s possible.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Talent Development
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Supporting joint programs that cultivate the next generation
                  of innovators and tech leaders.
                </p>
              </div>

              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineSolution className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Product Innovation
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Incorporating the latest scientific methodologies into product
                  development, ensuring AeroAegis solutions remain at the
                  forefront of technological advancement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-blue-600 text-white py-8 my-20">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">
            These collaborations keep AeroAegis’s innovation pipeline strong,
            delivering products that lead in capability, performance, and
            relevance.
          </p>
        </div>
      </footer>
    </div>
  );
}
