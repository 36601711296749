
import img1 from '../../../../assets/img/Services/IotEdgeSolution/smarturban.jpg'; 

import { LuSmartphoneNfc } from "react-icons/lu";
import { LuMonitorCheck } from "react-icons/lu";
import { CiCircleCheck } from "react-icons/ci";

export default function MainSection5() {
  return ( 
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Digital Twin for Smart Cities & Factories
            </h1>
            <p className="text-lg text-gray-600">
            Enable digital replicas of urban infrastructure, smart grids, and connected factories to drive efficiency and innovation.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuSmartphoneNfc className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Urban Infrastructure Digitization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Develop digital twins for smarter urban planning and management.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuMonitorCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Connected Factories</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Monitor and optimize smart factory operations in real time.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <CiCircleCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Sustainable Smart Grids
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Boost energy efficiency with digital twin-enabled smart grids.
              </p>
            </div>

            {/* Feature 4 */}
           {/*  <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoMdTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Cost Efficiency</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize resource utilization to manage costs effectively as you
                scale.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
