import { AiOutlineAppstore } from 'react-icons/ai';
import { FiCpu } from 'react-icons/fi';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { IoMdWifi } from 'react-icons/io';
import img1 from "../../../../assets/img/Services/IotEdgeSolution/124694.jpg"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            AI at the Edge
            </h1>
            <p className="text-lg text-gray-600">
            Enable AI/ML capabilities for real-time analytics and intelligent automation at the edge.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineAppstore className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900"> Real-Time Analytics</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Implement AI/ML models at the edge to process data instantly, enabling real-time decision-making and insights.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiCpu className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Intelligent Automation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage edge computing for autonomous operations, allowing devices to act on data locally without relying on cloud infrastructure.
              </p>
            </div>

           
          </div>
        </div>

      
      </div>
    </div>
  )
}