import React from 'react'
import HeroSection from './HeroSection'
import AnimationRevealPage from '../../../helpers/AnimationRevealPage'
import MainSection1 from './MainSection1'
import MainSection2 from './MainSection2'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'
import MainSection5 from './MainSection5'
import KeyAreasSection from './KeyAreasSection'
import RealImpact from './RealImpactSection'
import FinalSection from './FinalSection'
import MainSection6 from './MainSection6'
import MainSection7 from './MainSection7'


export default function FinanceBanking() {

  return (
    <div>
      <HeroSection />

      {/* <Content1/> */}
        <AnimationRevealPage>
        <MainSection1/>
        <MainSection2 />
        <MainSection3/>
        <MainSection4/>
        <MainSection5/>
        <MainSection6 />
        <MainSection7 />
        <KeyAreasSection />
        <RealImpact/>

        <FinalSection/>
        </AnimationRevealPage>
    </div>
  )
}
