import React from 'react';
import { MdOutlineLightbulb } from 'react-icons/md'; // AI/ML Solutions
import { MdOutlineCloudQueue } from 'react-icons/md'; // Cloud & Edge Scaling
import { MdOutlineSpeed } from 'react-icons/md'; // Optimization
import { MdOutlineBuild } from 'react-icons/md'; // Automation
import { FaLightbulb, FaPencilRuler, FaRocket, FaCogs } from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import { LuBrain } from 'react-icons/lu';
import { BsListColumnsReverse } from 'react-icons/bs';
import { AiOutlineCloudSync } from 'react-icons/ai';
import { GiRecycle } from 'react-icons/gi';

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-center mb-6 lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">
              Why Choose AeroAegis for Energy & Utilities?
            </span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-20">
              {[
                {
                  icon: <LuBrain />,
                  title: 'Industry Expertise',
                  description:
                    'With deep experience in energy and utilities, we understand the unique challenges and opportunities of the sector.',
                },
                {
                  icon: <AiOutlineCloudSync/>,
                  title: 'Innovative Technology',
                  description:
                    <span>From <strong>Agent AI</strong> to <strong>blockchain</strong> and <strong>IoT</strong>, our cutting-edge solutions are designed to drive efficiency, transparency, and growth.</span>,
                },
                {
                  icon: <BsListColumnsReverse className='size-8'/>,
                  title: 'Proactive Approach',
                  description:
                    'Our predictive analytics and AI solutions empower utilities to move from reactive to proactive operations, ensuring reliability and cost savings.',
                },
                {
                  icon: <GiRecycle />,
                  title: 'Global Vision',
                  description:
                    'We leverage strategic partnerships and global expertise to deliver solutions tailored to the evolving needs of the energy and utilities industry.',
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 lg:w-[300px]"
                >
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">
                      {item.icon}
                    </div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
