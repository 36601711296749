import React from "react";
import { MdOutlineSpeed, MdOutlineAnalytics } from "react-icons/md";
import { BiCloud } from "react-icons/bi";
import { RiLineChartLine } from "react-icons/ri";
import { FaCogs } from "react-icons/fa";
import { TbDeviceAnalytics } from "react-icons/tb";
import { FaConnectdevelop } from "react-icons/fa";
import { BiTrendingUp } from "react-icons/bi";

import img1 from "../../../assets/img/aeroaegis_capabilities/Technology.png";
export default function CoreCapabilities3() {
  return (
    <section className=" ">
      {/* <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
            <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] ">
              <h3 className="text-2xl font-bold tracki sm:text-3xl ">
              AeroPulse AI
              </h3>
              <div className="flex space-x-2 sm:space-x-4 pt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                <div className="space-y-2">
                  <p className="text-lg font-medium leadi">
                  A next generation cloud performance management offering a comprehensive solution for monitoring, predicting, and optimizing the performance of your cloud-hosted applications and systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 xl:w-3/5 ">
              <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                <img
                  src={aeroai_img}
                  alt=""
                  className="w-52"
                />
              </div>
            </div>
          </div> */}
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Technology Integration and Customization
              </h1>
              <p className="text-lg text-gray-600">
              At AeroAegis, we recognize that no two businesses are the same. Our <strong>integration services</strong> ensure that our platforms and tools are seamlessly aligned with your existing systems.
              </p>
              <p className="font-medium text-[18px]">Capabilities Include:</p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FaCogs className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                  Customized Implementation
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                Tailoring our solutions to meet your unique business needs and IT landscape.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FaConnectdevelop className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                  System Compatibility
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                Ensuring seamless interoperability with third-party tools and legacy systems.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <BiTrendingUp className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                  Continuous Optimization
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                Providing ongoing improvements to maximize performance and ROI.
                </p>
              </div>
              {/* Feature 4 */}
             {/*  <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <RiLineChartLine className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Advanced Analytics
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Utilize detailed analytics to uncover trends and enhance
                  decision-making.
                </p>
              </div> */}
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Performance Monitoring Dashboard"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
