import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { IoLockClosedOutline } from 'react-icons/io5';
import { BiLike } from 'react-icons/bi';
import img1 from '../../../../assets/img/Services/IotEdgeSolution/alerts.jpg';

import { IoCloudUploadOutline } from 'react-icons/io5';
import { AiOutlineBarChart } from 'react-icons/ai';
import { FiServer } from 'react-icons/fi';
import { IoMdTrendingUp } from 'react-icons/io';
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Automated Alerts
            </h1>
            <p className="text-lg text-gray-600">
              Automate alerts and maintenance schedules using predictive models.
              Keep operations smooth with timely notifications and planned
              interventions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoAlertCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Predictive Maintenance Alerts
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Receive notifications before potential failures occur.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineBarChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Dynamic Scheduling
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate and optimize maintenance planning for minimal
                disruption.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Operational Continuity
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Ensure smooth workflows with proactive alerts and interventions.
              </p>
            </div> 

            {/* Feature 4 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoMdTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Cost Efficiency</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize resource utilization to manage costs effectively as you
                scale.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
