import React from "react";
import { FaClock, FaChartBar, FaClipboardCheck } from "react-icons/fa";

export default function RealImpactSection() {
  return (
    <section className="py-10 bg-gradient-to-b from-indigo-50 to-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
          Real Impact: A Case Point
        </h2>
        <div className="bg-white rounded-xl shadow-2xl overflow-hidden">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 p-8 lg:p-12">
              <h3 className="text-2xl font-bold text-indigo-800 mb-6">
                Leading the Future of Smart Manufacturing Solutions
              </h3>
              <p className="text-gray-600 mb-8">
                {/* A leading healthcare provider partnered with Aero Aegis to
                modernize its infrastructure and enhance patient care. Using our
                proprietary <strong>Maturity Spectrum 360</strong> framework, we
                deployed a cloud-native, AI-driven solution that delivered: */}
                A global manufacturer partnered with Aero Aegis to digitize its
                production and supply chain. Using <strong>IoT</strong> and{" "}
                <strong>advanced analytics</strong>, we implemented a smart
                factory solution that delivered
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaClock className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                    25%
                  </h4>
                  <p className="text-gray-700">
                    Reduction in Downtime Through Predictive Maintenance
                  </p>
                </div>
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaChartBar className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                    20%
                  </h4>
                  <p className="text-gray-700">
                    Increase in Production Efficiency
                  </p>
                </div>
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaClipboardCheck className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                    15%
                  </h4>
                  <p className="text-gray-700">
                    Cost Savings Through Supply Chain Optimization
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 relative">
              <img
                src="https://img.freepik.com/free-photo/factory-workers-with-face-masks-protected-against-corona-virus-doing-quality-control-production-factory_342744-96.jpg?semt=ais_hybrid"
                alt="Manufacturing"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-indigo-800 opacity-20"></div>
              <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-indigo-900 to-transparent">
                <p className="text-white text-lg font-semibold">
                  Revolutionizing Manufacturing with Cutting-Edge Smart
                  Technologies
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
