
import { 
  FaCrosshairs, 
  FaEye, 
  FaBullseye 
} from 'react-icons/fa'

export default function MissionVisionGoal() {
  const sections = [
    {
      icon: FaCrosshairs,
      title: "Mission",
      description: "Our mission is to build cutting-edge AI and automation solutions that seamlessly integrate across cloud, edge, and IIoT environments, transforming businesses by optimizing operations, enhancing system resilience, and fostering sustainable growth and innovation.",
      gradient: "from-rose-500 to-red-500",
      shadowColor: "shadow-rose-200",
      iconColor: "text-rose-500",
      borderColor: "border-rose-200"
    },
    {
      icon: FaEye,
      title: "Vision",
      description: "To be the global leader in AI-powered digital transformation, enabling industries to achieve unparalleled operational excellence and resilience through intelligent, cloud and edge-integrated automation solutions.",
      gradient: "from-violet-500 to-purple-500",
      shadowColor: "shadow-violet-200",
      iconColor: "text-violet-500",
      borderColor: "border-violet-200"
    },
    {
      icon: FaBullseye,
      title: "Goal",
      description: "Our goal is to provide enriching IT experience to businesses and customers using the power of Automation and AI.",
      gradient: "from-blue-500 to-cyan-500",
      shadowColor: "shadow-blue-200",
      iconColor: "text-blue-500",
      borderColor: "border-blue-200"
    }
  ]

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-20 space-y-4">
          <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold text-gray-900">
            We continuously strive to
          </h2>
          <div className="text-4xl md:text-5xl lg:text-5xl font-bold">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-violet-600">
              Adapt
            </span>
            {" and "}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-indigo-600">
              Improve
            </span>
            <span className="text-gray-900">.</span>
          </div>
          <div className="w-32 h-2 bg-gradient-to-r from-blue-600 to-indigo-600 mx-auto rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12">
          {sections.map((section, index) => {
            const Icon = section.icon
            return (
              <div
                key={index}
                className="relative group"
              >
                <div className={`absolute -inset-0.5 bg-gradient-to-r ${section.gradient} rounded-2xl blur opacity-30 group-hover:opacity-40 transition duration-500`}></div>
                <div className="relative bg-white rounded-xl p-8 border border-gray-100 h-full flex flex-col items-center text-center">
                  <div className={`w-20 h-20 rounded-full flex items-center justify-center mb-6 ${section.shadowColor} shadow-sm transform -translate-y-12 bg-white border ${section.borderColor} group-hover:scale-110 group-hover:rotate-3 transition duration-300`}>
                    <Icon className={`w-10 h-10 ${section.iconColor}`} />
                  </div>
                  <div className="-mt-8">
                    <h3 className="text-2xl font-bold text-gray-900 mb-4 group-hover:bg-clip-text group-hover:text-black group-hover:bg-gradient-to-r group-hover:${section.gradient} transition duration-300">
                      {section.title}
                    </h3>
                    <p className="text-gray-600 leading-relaxed">
                      {section.description}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

