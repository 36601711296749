import React from "react";
import img3 from "../../assets/img/careers/2149006852.jpg";
import { MdOutlineDiversity3, MdOutlinePeopleAlt } from 'react-icons/md';


export default function Section3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Diverse and Inclusive Culture
            </h1>
            <p className="text-lg text-gray-600">
              At AeroAegis, we celebrate diversity and embrace inclusivity. We
              believe that diverse perspectives foster innovation and
              creativity, making our team stronger and more dynamic.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDiversity3 className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Celebrating Diversity
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              We value and celebrate the unique backgrounds, experiences, and perspectives that each team member brings, fostering a vibrant and innovative workplace.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePeopleAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Embracing Inclusivity
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Our inclusive culture ensures that everyone feels valued, respected, and empowered to contribute their best.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img3}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
