import React from 'react';
import { FeatureCard } from './FeatureCard';

export function FeatureSection({ mainTitle, description, features }) {
  return (
    <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          {mainTitle}
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
        <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md italic">
          "{description}"
        </p>
      </div>
      <section className="py-4 bg-base-100">
        <div className="container mx-auto px-4">
          {/* Grid for the first three features */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.slice(0, 4).map((feature, index) => (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                className={index === 3 ? 'lg:hidden' : ''}
              />
            ))}
          </div>
          {/* Separate row for the last two features */}
          <div className="flex flex-col sm:flex-row justify-center mt-8 gap-8">
            {features.slice(3).map((feature, index) => (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                className={index === 0 ? 'hidden lg:block' : ''}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
