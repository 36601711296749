import React from "react";
import img1 from "../../../assets/img/Products/SmartChaosLab/analytics.jpg";

// export default function Feature4() {
//   return (
//     <div>
// 	<section className="dark:bg-gray-800 pt-5 dark:text-gray-100">

//    <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
//    <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
// 		   <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
// 			   <img src={analytics_img} alt="" className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96" />
// 		   </div>
// 	   </div>
// 	   <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
// 	   <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Comprehensive Analytics and Insights
// </h3>
// 		   <div className="flex space-x-2 sm:space-x-4">
// 			   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
// 				   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
// 			   </svg>
// 			   <div className="space-y-2">
// 				   <p className="text-lg font-medium leadi"> SmartChaosLab’s deep analytics dashboard goes beyond basic metrics, offering detailed insights into system performance under stress. This level of analysis enables more informed decision-making and strategic planning for IT teams.</p>

// 			   </div>
// 		   </div>

// 	   </div>

//    </div>
// </section>
// </div>
//   );
// }

import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlinePhone, MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
//import img1 from "../../../assets/img/Industries/RetailEcommerce/b.jpg"
import {
  MdOutlineInsights,
  MdOutlineTrendingUp,
  MdOutlineSpeed,
  MdOutlineBarChart,
} from "react-icons/md";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Comprehensive Analytics and Insights
            </h1>
            <p className="text-lg text-gray-600">
              SmartChaosLab’s deep analytics dashboard offers detailed insights
              into system performance under stress.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineInsights className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Advanced Analytics
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Get actionable insights into system performance with advanced
                metrics and analysis.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Growth Tracking</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Track system performance over time to identify trends and
                opportunities for improvement.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSpeed className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Performance Under Stress
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Evaluate system robustness and response during high-stress
                conditions.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineBarChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Informed Decision-Making
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Make strategic decisions based on real-time data and
                comprehensive analytics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
