import React from 'react';
import { FaRocket, FaChartLine, FaCog, FaIndustry, FaBrain, FaDatabase } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AeroAegisLanding = () => {
  return (
    <div className="min-h-screen bg-gray-100">
  
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Unlock the Power of AI and Data</h1>
          <p className="text-xl mb-8">
          At AeroAegis Inc., we don’t just build products — <strong>we engineer experiences, enable intelligence, and empower businesses</strong> to thrive in a competitive digital landscape. Partner with us to bring your vision to life with cutting-edge <strong>Product Engineering Services</strong>.
          </p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </button>
        </div>
      </section>

      {/* Why Choose AeroAegis Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose AeroAegis for Product Engineering Services?</h2>
          <div className="grid justify-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<FaBrain className="text-4xl text-blue-600" />}
              title="End-to-End Expertise"
              description="From design to deployment, we cover the entire product lifecycle."
            />
            <FeatureCard
              icon={<FaCog className="text-4xl text-blue-600" />}
              title="Cutting-Edge Technology"
              description="Expertise in emerging technologies like AI, IoT, and Cloud ensures future-ready solutions."
            />
            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="Agile and Scalable Delivery"
              description="Agile methodologies and CI/CD pipelines for faster, flexible product development."
            />
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Quality and Innovation"
              description="Focused on delivering products that meet the highest performance, security, and quality standards."
            />
            <FeatureCard
              icon={<FaChartLine className="text-4xl text-blue-600" />}
              title="Customer-Centric Approach"
              description="Tailored solutions that align with your business goals and market demands."
            />
        <FeatureCard
  icon={<FaDatabase className="text-4xl text-blue-600" />}
  title="Data-Driven Insights"
  description="Harness the power of analytics and big data to make informed decisions and stay ahead of the competition."
/>
          </div>
        </div>
      </section>

      

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">Ready to build your next big product? Let’s talk! 🚀 </p>
          <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </Link>
        
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};



export default AeroAegisLanding;