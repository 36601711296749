import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/ProductEngineering/generAi.jpg";
import { FaBullseye, FaChartLine, FaMap, FaRobot, FaRocket, FaSync } from "react-icons/fa";
import { AiOutlineAntDesign, AiOutlineAppstoreAdd, AiOutlineFileText } from "react-icons/ai";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Generative AI Applications
            </h1>
            <p className="text-lg text-gray-600">
            Use generative models to automate content creation, personalization, and product enhancements.
									 </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineAppstoreAdd className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Product Enhancement</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Implement generative AI to design new product features or improve existing ones through data-driven insights.
			  </p>
            </div>


                        {/* Feature 2 */}
                        <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineAntDesign className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Creative Design Automation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Automate the creation of logos, images, and multimedia content using AI-driven tools.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineFileText className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Automated Content Creation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage generative models to automatically generate articles, blogs, and social media posts.

			                              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}