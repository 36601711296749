import { IoCheckmarkCircleOutline } from "react-icons/io5";
import img1 from "../../../assets/img/Industries/Energy/sustainablity.jpeg";
import { GiWindTurbine } from "react-icons/gi"; // Renewable Energy Adoption
import { BiLeaf } from "react-icons/bi";
import { IoTrendingUp } from "react-icons/io5"; // Empower Smart Cities

export default function MainSection7() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Driving Sustainability and Innovation
            </h1>
            <p className="text-lg text-gray-600">
              At AeroAegis, we understand the critical role of sustainability in
              the energy sector. Our solutions are designed to:
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiWindTurbine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Promote Renewable Energy Adoption
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Integrate wind, solar, and other renewable energy sources into
                existing grids with minimal disruption.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiLeaf className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Reduce Carbon Footprint
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize energy distribution and consumption to lower emissions
                and support global sustainability goals.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Empower Smart Cities
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable utilities to play a key role in smart city initiatives
                with IoT-powered energy management solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
