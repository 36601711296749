import React, { useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import pic1_img from '../../../assets/img/Profile/aravinda-profile-pic.jpeg'
import pic2_img from '../../../assets/img/Profile/bala_pic.jpg'

import pic3_img from '../../../assets/img/Profile/balasubramanian-palaniappan-profile-pic.jpeg'
import pic4_img from '../../../assets/img/Profile/venkat_profile_pic.jpeg'

const teamMembers = [
  {
    name: "Dr. Aravinda C V",
    role: "Advisory Board Member",
    image: pic1_img,
    description:
      "Dr. Aravinda C V, a distinguished member of our Advisory Board, is a highly respected Professor at NMAMIT and Nitte institutions. With an extensive background in academia and research, Dr. Aravinda brings a wealth of knowledge in the fields of Artificial Intelligence (AI), Machine Learning (ML), Natural Language Processing (NLP), generative AI, and advanced BERT models. Holding a doctoral degree, he has made significant contributions to these domains, both through his scholarly work and practical applications. In addition to his academic role, Dr. Aravinda is currently working as a Project Manager at NIDEC COMPANY COE India, where he spearheads cutting-edge projects that integrate AI and ML into real-world solutions. His leadership and innovative approaches in AI have been instrumental in driving forward critical initiatives at NIDEC, making him a key figure in the industry. His expertise continues to be a driving force behind AeroAegis's mission to push the boundaries of technology.",
    linkedin: "https://www.linkedin.com/in/dr-aravinda-c-v-b21a5115/",
    highlights: [
      "Professor at NMAMIT and Nitte",
      "Expert in AI, ML, and NLP",
      "Project Manager at NIDEC COE India",
      "AI solutions innovator",
    ],
  },
  {
    name: "Balasubramanian Venkataraman",
    role: "Advisory Board Member",
    image: pic2_img,
    description:
      "Balasubramanian Venkataraman offers over 20 years of expertise in financial operations, statutory compliance, and strategic management. His experience spans industries across Africa, India, and the USA. As a seasoned finance professional, Bala has excelled in project financing, debt restructuring, and implementing corporate policies to drive efficiency. He has held pivotal roles like CFO and Finance Director in multinational corporations and start-ups, where he led financial restructuring, managed ERP implementations, and optimized cash flow. Bala’s expertise in SAP FICO, MS-Dynamics AX, and other ERP systems has significantly enhanced financial reporting and operational processes. As an Advisory Board Member at Aeroaegis, Bala’s experience in strategic financial management and international business operations offers insights that support scaling operations, optimizing financial strategies, and navigating global markets.",
    linkedin: "https://www.linkedin.com/in/balasubramanianvenkataraman/",
    highlights: [
      "20+ years in finance and strategy",
      "Expert in project financing",
      "ERP and SAP FICO specialist",
      "Former CFO and Finance Director",
    ],
  },
  {
    name: "Balasubramanian Palaniappan",
    role: "Advisory Board Member",
    image: pic3_img,
    description:
      "Balasubramanian Palaniappan, an esteemed member of our Advisory Board, brings to AeroAegis a distinguished career spanning over two decades in the technology industry. His extensive experience and leadership have been pivotal in shaping the direction and success of numerous high-profile projects. As an Engineering Leader at Delta Air Lines, Balasubramanian has spearheaded transformative initiatives, particularly in the areas of cloud migration and development. His expertise has driven significant advancements, enhancing the company's technological capabilities. His deep understanding of cloud technologies and innovative development practices has made him a recognized authority in the field. Balasubramanian's contributions at Delta Air Lines have set new standards for efficiency and scalability in aviation. His strategic insights and hands-on experience are instrumental in guiding AeroAegis as the company strives for excellence and innovation in its pursuits.",
    linkedin: "https://www.linkedin.com/in/vpbalasubramanian/",
    highlights: [
      "20+ years in IT industry",
      "Engineering Leader at Delta Air Lines",
      "Cloud operations specialist",
      "Pioneered in aviation",
    ],
  },
  {
    name: "Venkatraghav Ramamurthy",
    role: "Advisory Board Member",
    image: pic4_img,
    description:
      "Venkat Raghav, an accomplished Advisory Board Member, has over 25 years of experience in the IT industry. Currently serving as a Solution Architect at Tech Mahindra, he has previously held significant roles at Hewlett Packard and Oracle, where he developed extensive expertise in advanced technologies. His background includes deep knowledge in AI/ML/NLP, Big Data analytics, and streaming platforms like Kafka. Venkat excels in managing and architecting multi-cloud environments, including AWS, Azure, and GCP. His strong skills in solutioning and product design have been pivotal in driving innovation and ensuring project success. At AeroAegis, Venkat’s technical acumen and strategic vision are crucial in shaping the company’s direction. His contributions foster continuous growth and adaptability, ensuring AeroAegis remains at the forefront of technological advancements.",
    linkedin: "https://linkedin.com",
    highlights: [
      "25+ years in the IT industry",
      "Solution Architect at Tech Mahindra",
      "Expert in AI/ML",
      "Multi-cloud specialis",
    ],
  },
];

export default function TeamSection() {
  const [expandedCards, setExpandedCards] = useState({});

  const toggleCard = (index) => {
    setExpandedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
          Advisory Board Members
          </h2>
          <p className="text-xl text-gray-600">
          Guiding Minds Behind Innovation and Success
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
            >
              <div className="p-6">
                <div className="flex flex-col sm:flex-row items-center gap-6 mb-6">
                  <div className="relative w-48 h-48 sm:w-40 sm:h-40 flex-shrink-0">
                    <div className="absolute  inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-full" />
                    <img
                      src={member.image}
                      alt={member.name}
                      className="relative rounded-full w-full h-full overflow-hidden object-cover  border-4 border-white shadow-md"
                    />
                  </div>

                  <div className="flex-1 text-center sm:text-left">
                    <h3 className="text-2xl font-bold text-gray-900 mb-1">
                      {member.name}
                    </h3>
                    <p className="text-blue-600 font-medium mb-3">
                      {member.role}
                    </p>

                    <div className="mb-4">
                      <div className="grid grid-cols-2 gap-2">
                        {member.highlights.map((highlight, i) => (
                          <div
                            key={i}
                            className="text-sm text-gray-600 bg-gray-100 rounded-full px-3 py-1 flex items-center  text-start"
                          >
                            {highlight}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative">
                  <p
                    className={`text-gray-600 text-sm ${
                      expandedCards[index] ? "" : "line-clamp-3"
                    }`}
                  >
                    {member.description}
                  </p>

                  {!expandedCards[index] && (
                    <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent" />
                  )}
                </div>

                <div className="flex items-center justify-between mt-4 pt-4 border-t">
                  <button
                    onClick={() => toggleCard(index)}
                    className="flex items-center gap-2 text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors"
                    aria-expanded={expandedCards[index]}
                  >
                    {expandedCards[index] ? (
                      <>
                        <IoChevronUpOutline className="w-4 h-4" />
                        Show Less
                      </>
                    ) : (
                      <>
                        <IoChevronDownOutline className="w-4 h-4" />
                        Read More
                      </>
                    )}
                  </button>

                  <a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
                  >
                    <FaLinkedin className="w-5 h-5" />
                    <span className="text-sm font-medium">Connect</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
