import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/OperationalExe/5077.jpg";

import { BsDatabase } from "react-icons/bs";
import { AiOutlineApi } from "react-icons/ai";
import { AiOutlineControl } from "react-icons/ai";
import { RxCountdownTimer } from "react-icons/rx";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Optimization Strategies
            </h1> 
            <p className="text-lg text-gray-600">
            Optimization strategies focus on fine-tuning databases, APIs, and infrastructure to reduce latency and improve response times.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsDatabase className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Database Optimization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enhance database performance by fine-tuning queries and indexing for faster data retrieval.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                < AiOutlineApi className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">API Performance</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Optimize API endpoints to reduce response times and improve overall system efficiency.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineControl className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Infrastructure Tuning</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Streamline infrastructure components to minimize latency and boost scalability.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RxCountdownTimer  className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Faster Response Times</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Achieve quicker system responses through targeted optimizations across the stack.
              </p>
            </div>
          </div>
        </div> 

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}