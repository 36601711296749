import React from 'react';
import alibabaCloudLogo from '../../../../assets/img/Partnerships/azureLogo.png';

export default function HeroSection() {
  return (
    <div>
      <div className="relative p-4 flex flex-col lg:flex-row h-full  justify-center items-center lg:justify-start lg:px-8 lg:py-20 mx-auto max-w-[1300px]">
        <div className="lg:w-[70%] flex-shrink-0 md:w-[576px] sm:w-[544px]">
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
            Strategic Partnership <br></br>Microsoft Azure ISV Partner:
          </h2>
          <div>
            <h3 className="mb-5 font-sans text-3xl  tracking-wide font-normal text-blue-800 lg:text-5xl sm:leading-none">
              Empowering Business with Azure
            </h3>
            <div className="flex justify-center items-center flex-shrink w-[30%] lg:absolute right-4 top-[50%] lg:translate-y-[-50%]">
              <img
                src={alibabaCloudLogo}
                alt="alibba cloud logo"
                className="w-50 h-24"
              />
            </div>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
              At AeroAegis, we are committed to enabling digital transformation
              and driving business success through innovation. As a certified
              Microsoft Azure ISV Partner, we deliver a comprehensive range of
              services including tailored consulting, accelerated solution
              development, and access to advanced technologies. With privileged
              access to Azure's resources, we offer secure, scalable, and
              cutting-edge solutions that ensure compliance, enhance
              performance, and strengthen trust and credibility for enterprises
              worldwide.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
