import React from "react";
import metadata_img from "../../../assets/img/Products/InfraVue/pexels-tranmautritam-326502.jpg";
import { FaMousePointer } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";

export default function Feature1() {
  const section = {
    imageSrc: metadata_img, // Replace with an appropriate image
    title: "Drag-and-Drop Design Interface",
    description:
      "AeroInfra Vue's Drag-and-Drop Design Interface revolutionizes infrastructure design by enabling seamless, user-friendly interaction.",
    features: [
      {
        icon: FaMousePointer,
        title: "Simplified Design",
        description:
          "Create infrastructure components with an intuitive drag-and-drop interface.",
      },
      {
        icon: FaUsers,
        title: "For All Users",
        description:
          "Empowers both technical and non-technical users to design infrastructure.",
      },
    ],
  };

  return (
    <div>
      <section className="dark:bg-gray-800 dark:text-gray-100 ">
     

        <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
          <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
            <div className="relative h-[500px] rounded-3xl overflow-hidden">
              <img
                src={section.imageSrc}
                alt="Section image"
                className="object-cover w-full h-full rounded-3xl"
              />
            </div>
            <div className="space-y-8">
              <div className="space-y-4">
                <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                  {section.title}
                </h1>
                <p className="text-lg text-gray-600">{section.description}</p>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                {section.features.map((feature, index) => (
                  <div key={index} className="space-y-3">
                    <div className="flex items-center space-x-2">
                      <feature.icon className="w-6 h-6 text-indigo-600" />
                      <h3 className="font-semibold text-gray-900">
                        {feature.title}
                      </h3>
                    </div>
                    <p className="text-gray-600 text-sm">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
