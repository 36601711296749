import React, { useEffect, useState } from "react";
import {
  FiPhone,
  FiMail,
  FiMapPin,
  FiSend,
  FiArrowRight,
} from "react-icons/fi";

import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";

//BACKGROUND IMAGE
import bg_img from "../../assets/img/ContactUs/writing-note-while-phone.jpg";

//ICONS
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { MdEmail } from "react-icons/md";

import { FaUser } from "react-icons/fa";

//SOCIAL ICONS
import { FiFacebook } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";
import { FiYoutube } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
const API_PATH = "https://aeroaegis.com/contact.php";

export default function Contact() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [captchaError, setCaptchaError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    loadCaptchaEnginge(6, "white");
    const interval = setInterval(() => {
      loadCaptchaEnginge(6);
    }, 60000);
    // Clear the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_message: "",
    // phone: "",
    // preferred_method: "email",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userCaptchaValue = e.target.captcha.value;
    // Validate the captcha
    if (!validateCaptcha(userCaptchaValue)) {
      setCaptchaError("Captcha Does Not Match");
      return;
    }
    // Reset the captcha value
    setCaptchaValue("");
    setCaptchaError("");
    setIsSubmitting(true);
    setShowSpinner(true);
    setIsOverlayVisible(true); // Show overlay

    try {
      const response = await axios.post(API_PATH, formData);
      if (response.status === 200) {
        setIsSuccessModalOpen(true);
        setFormData({
          user_name: "",
          user_email: "",
          user_message: "",
          // phone:"",
          // preferred-method:""
        });
        loadCaptchaEnginge(6);
        window.location.reload(); // Reload the page
      } else {
        console.error("Unsuccessful response:", response.status, response.data);
      }
    } catch (error) {
      console.error("error", error);
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received from server.");
      } else {
        console.error("Error during request setup:", error.message);
      }
    } finally {
      setIsSubmitting(false);
      setShowSpinner(false);
      setIsOverlayVisible(false); // Hide overlay after form submission
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
  };

  useEffect(() => {
    if (isSuccessModalOpen) {
      const timer = setTimeout(() => {
        setShowSpinner(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSuccessModalOpen]);

  return (
    // // <div>
    // {/* {isOverlayVisible && (
    //     <div
    //       className="fixed inset-0 z-20 bg-black opacity-50 cursor-not-allowed"
    //       style={{ pointerEvents: "auto" }}
    //     ></div>
    //   )}

    //                   <div className=" py-2 col-span-2 mt-5">
    //                     <div className=" flex items-center bg-transparent">
    //                       <LoadCanvasTemplateNoReload />
    //                       <IconButton
    //                         aria-label="Reload Captcha"
    //                         size="small"
    //                         color="primary"
    //                         onClick={() => loadCaptchaEnginge(6, "white")}
    //                       >
    //                         <RefreshIcon />
    //                       </IconButton>
    //                     </div>
    //                     <input
    //                       type="text"
    //                       name="captcha"
    //                       value={captchaValue}
    //                       onChange={(e) => setCaptchaValue(e.target.value)}
    //                       className="text-sm mt-2  px-2 py-3 w-40 border-solid border-0 border-b-2 border-white  bg-transparent placeholder:text-white placeholder:text-opacity-70 focus:outline-0  focus:border-b-[#252f80]  focus:ring-0"
    //                       placeholder="Enter Captcha"
    //                       required
    //                     />
    //                     {captchaError && (
    //                       <p className="text-red-500 text-sm mt-1">
    //                         {captchaError}
    //                       </p>
    //                     )}
    //                   </div>

    //                   <div className="col-span-2 flex justify-center mt-5">
    //                     <button
    //                       className="btn bg-[#252f80] text-white hover:bg-[#2d3da5] rounded-full  px-32 md:px-48 lg:px-40 xl:px-52 border border-[#252f80]"
    //                       type="submit"
    //                       disabled={isSubmitting}
    //                     >
    //                       {showSpinner ? (
    //                         <div className="h-5 w-5 animate-spin rounded-full border-t-4 border-blue-600 mx-auto" />
    //                       ) : (
    //                         "SUBMIT"
    //                       )}
    //                     </button>
    //                   </div>
    //                 </div>
    //               </form>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //
    // </div> */}

    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      {isOverlayVisible && (
        <div
          className="fixed inset-0 z-20 bg-black opacity-50 cursor-not-allowed"
          style={{ pointerEvents: "auto" }}
        ></div>
      )}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2  bg-white rounded-2xl overflow-hidden shadow-2xl ">
          {/* Left Section - Contact Info */}
          <div className="relative overflow-hidden">
            {/* Background Image */}
            <div
              className="absolute inset-0 bg-cover"
              style={{
                backgroundImage: `url(${bg_img})`,
              }}
            >
              <div className="absolute inset-0 bg-[#253080a3] backdrop-blur-sm"></div>
            </div>

            {/* Content */}
            <div className="relative p-8 sm:p-12 text-white h-full flex flex-col justify-between">
              <div>
                <h2 className="text-2xl sm:text-4xl font-bold ">Contact us</h2>
                <p className="text-indigo-100/90 text-base mt-5 max-w-md">
                  Get in touch with our team and let us know how we can help you
                  transform your ideas into reality.
                </p>

                <div className="space-y-4 sm:space-y-6 mt-8 ">
                  <div className="flex items-center space-x-4 group cursor-pointer">
                    <div className="w-10 h-10 sm:w-12 sm:h-12 bg-white/10 rounded-full flex items-center justify-center group-hover:bg-white/20 transition-all duration-300">
                      <FiPhone className="w-4 h-4 sm:w-5 sm:h-5" />
                    </div>
                    <div>
                      <p className="text-sm text-indigo-100/70">Call us</p>
                      <p className="font-sm font-semibold sm:font-medium">
                        +1 (302) 497-4435
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-4 group cursor-pointer">
                    <div className="w-10 h-10 sm:w-12 sm:h-12 bg-white/10 rounded-full flex items-center justify-center group-hover:bg-white/20 transition-all duration-300">
                      <FiMail className="w-4 h-4 sm:w-5 sm:h-5" />
                    </div>
                    <div>
                      <p className="text-sm text-indigo-100/70">Email us</p>
                      <p className="dont-base font-semibold sm:font-medium">
                        info@aeroaegis.com
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-4 group cursor-pointer">
                    <div className="w-10 h-10 sm:w-12 sm:h-12 bg-white/10 rounded-full flex items-center justify-center group-hover:bg-white/20 transition-all duration-300">
                      <FiMapPin className="w-4 h-4 sm:w-5 sm:h-5" />
                    </div>
                    <div>
                      <p className="text-sm text-indigo-100/70">Visit us</p>
                      <p className="font-base font-semibold sm:font-medium">
                        8, The Green, STE A
                      </p>
                      <p className="font-base font-semibold sm:font-medium">
                        Dover, Delaware 19901, United States
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-12">
                <p className="text-sm text-indigo-100/70">Follow us</p>
                <div className="flex space-x-4 mt-3">
                  <div className="w-10 h-10 bg-white/10 rounded-full flex items-center justify-center hover:bg-white/20 transition-all duration-300 cursor-pointer">
                    <a href="#">
                      {" "}
                      <FiFacebook className="w-4 h-4" />
                    </a>
                  </div>
                  <div className="w-10 h-10 bg-white/10 rounded-full flex items-center justify-center hover:bg-white/20 transition-all duration-300 cursor-pointer">
                    <a href="#">
                      {" "}
                      <FaXTwitter className="w-4 h-4" />
                    </a>
                  </div>
                  <div className="w-10 h-10 bg-white/10 rounded-full flex items-center justify-center hover:bg-white/20 transition-all duration-300 cursor-pointer">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/96893605"
                    >
                      {" "}
                      <FiLinkedin className="w-4 h-4" />
                    </a>
                  </div>
                  <div className="w-10 h-10 bg-white/10 rounded-full flex items-center justify-center hover:bg-white/20 transition-all duration-300 cursor-pointer">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCZDzOYE4W-stKNR9AY2dUmg"
                    >
                      <FiYoutube className="w-4 h-4" />
                    </a>
                  </div>
                  {/* Add more social icons as needed */}
                </div>
              </div>
            </div>
          </div>

          {/* Right Section - Contact Form */}
          <div className="p-8 sm:p-12">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
              Send Us A Message
            </h2>
            <p className="text-gray-600 mb-8">
              Fill out the form below and we'll get back to you shortly.
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <div className="flex flex-row items-center w-full px-4 py-1 rounded-lg border border-gray-200 focus-within:border-[#252f80] focus-within:ring-2 focus-within:ring-[#25308094] outline-none transition-all duration-300">
                  <div className="text-[#737373]">
                    <FaUser className="w-5 h-5 " />
                  </div>
                  <input
                    type="text"
                    name="user_name"
                    value={formData.user_name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    className="border-none w-full focus:ring-0 focus:outline-none  focus:border-none focus:bg-white  autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]"
                    required
                  />
                </div>
              </div>

              <div>
                <div className="flex flex-row items-center w-full px-4 py-1 rounded-lg border border-gray-200 focus-within:border-[#252f80] focus-within:ring-2 focus-within:ring-[#25308094] outline-none transition-all duration-300">
                  <div className="text-[#737373]">
                    <MdEmail className="w-5 h-5" />
                  </div>
                  <input
                    type="email"
                    name="user_email"
                    value={formData.user_email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    className="border-none w-full focus:ring-0 focus:outline-none  focus:border-none autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]"
                    required
                  />
                </div>
              </div>

              {/* PHONE INPUT */}

              {/* <div className="">
                <div className="flex flex-row items-center w-full px-4 py-1 rounded-lg border border-gray-200 focus-within:border-[#252f80] focus-within:ring-2 focus-within:ring-[#25308094]  outline-none transition-all duration-300">
                  <div className="text-[#737373]">
                    <BsTelephoneFill className="w-5 h-5" />
                  </div>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Your Phone"
                    className="w-full border-none focus:ring-0 focus:outline-none  focus:border-none autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]"
                  />{" "}
                </div>
              </div>*/}

              {/* PREFERRED METHOD */}

              {/*
              <div>
                <p className="text-gray-700 mb-3">
                  Preferred method of communication
                </p>
                <div className="flex space-x-6">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="radio"
                      name="preferredMethod"
                      value="email"
                      checked={formData.preferred_method === "email"}
                      onChange={handleChange}
                      className="w-4 h-4 text-[#252f80] focus:border-[#252f80] focus:ring-[#3341AF]  border-gray-300"
                    />
                    <span className="text-gray-700">Email</span>
                  </label>
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="radio"
                      name="preferredMethod"
                      value="phone"
                      checked={formData.preferred_method === "phone"}
                      onChange={handleChange}
                      className="w-4 h-4 text-[#252f80] focus:border-[#252f80] focus:ring-[#3341AF] border-gray-300"
                    />
                    <span className="text-gray-700">Phone</span>
                  </label>
                </div>
              </div> */}

              <div>
                <textarea
                  name="user_message"
                  value={formData.user_message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  rows="4"
                  className="w-full resize-none px-4 py-3 rounded-lg border border-gray-200 focus:border-[#252f80] focus:ring-2 focus:ring-[#25308094] outline-none transition-all duration-300 autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] "
                  required
                ></textarea>
              </div>
              <div className="">
                <div className=" flex items-center bg-transparent">
                  <LoadCanvasTemplateNoReload />
                  <IconButton
                    aria-label="Reload Captcha"
                    size="small"
                    color="primary"
                    onClick={() => loadCaptchaEnginge(6, "white")}
                  >
                    <RefreshIcon />
                  </IconButton>
                </div>
                <input
                  type="text"
                  name="captcha"
                  value={captchaValue}
                  onChange={(e) => setCaptchaValue(e.target.value)}
                  className=" mt-4 px-4 py-3 w-40 rounded-lg border border-gray-200 focus:border-[#252f80] focus:ring-2 focus:ring-[#25308094] outline-none transition-all duration-300"
                  placeholder="Enter Captcha"
                  required
                />
                {captchaError && (
                  <p className="text-red-500 text-sm mt-1">{captchaError}</p>
                )}
              </div>

              <button
                type="submit"
                className="w-full bg-[#252f80] text-white py-3 px-6 rounded-lg hover:bg-[#3341AF] transition-all duration-300 flex items-center justify-center space-x-2 group"
                disabled={isSubmitting}
              >
              
                
                {showSpinner ? (
                            <div className="h-5 w-5 animate-spin rounded-full border-t-4 border-blue-600 mx-auto" />
                          ) : (
                            "Send Message"
                          )}
                          <FiArrowRight className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform duration-300" />
              </button>
             
            </form>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <Transition appear show={isSuccessModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen flex items-center justify-center">
            <Dialog.Overlay className="fixed inset-0  " />

            <div className="inline-block align-middle p-8 bg-slate-50 rounded-lg text-left z-20 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
              <Dialog.Title className="text-lg md:text-3xl lg:text-4xl font-bold mb-4">
                Thank you for contacting us.
              </Dialog.Title>
              <p className="text-gray-700 mb-4 text-center text-base md:text-xl lg:text-xl">
                We will get back to you shortly.
              </p>

              <div className="mt-4 flex justify-center">
                <button
                  onClick={closeModal}
                  className="bg-[#252f80] text-white px-4 py-2 rounded-lg cursor-pointer hover:!bg-[#2d3da5]"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
