import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import hero_img from '../../../assets/img/iiot/IIoT.png'
import logo_img from "../../../assets/img/iiot/aero_edge_iiot.jpg"
export default () => {
  return (
    <div className="relative">
      <div
        className="absolute inset-0 blur-xl h-[580px]"
        style={{
          background:
            "linear-gradient(143.6deg, rgba(192, 132, 252, 0) 20.79%, rgba(232, 121, 249, 0.26) 40.92%, rgba(204, 171, 238, 0) 70.35%)",
        }}
      ></div>
      <div className="relative">
        <section>
          <div className="max-w-screen-xl mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8">
            <div className="flex justify-center pb-5">
              <img src={hero_img} className="w-80 drop-shadow-md" alt="" />
            </div>
            <div className="space-y-5 max-w-4xl mx-auto text-center">
              <h1 className="text-sm md:text-xl text-indigo-600 font-medium">
              Transforming
              Industrial Operations
              </h1>
              <div className="flex flex-col md:flex-row justify-center space-x-5 items-center ">
              <img src={logo_img} className=" w-16" alt=""/>
              <div>
              <h2 className="text-4xl text-gray-800 font-extrabold mx-auto md:text-6xl">
               <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#4F46E5] to-[#E114E5]">
                AeroEdge IIoT
                </span>
              </h2></div>
              </div>
              <p className="max-w-2xl mx-auto">
              AeroEdge IIoT, the flagship product from AeroAegis Inc., represents a breakthrough in industrial automation and intelligence. Designed to seamlessly integrate with any Industrial IoT (IIoT) sensors and systems, AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration. This platform empowers industries to enhance operational efficiency, reduce downtime, and optimize maintenance strategies, all while ensuring robust data security and compliance.
              </p>
              <div className="items-center justify-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
                <a
                 href="javascript:void(0)"
                  className="block py-2 px-4 text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none"
                >
                  Buy Now
                </a>
                <a
                  href="javascript:void(0)"
                  className="block py-2 px-4 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg"
                >
                  Get access
                </a>
              </div>
            </div>
            {/* <div className="mt-14">
                    <img src="https://raw.githubusercontent.com/sidiDev/remote-assets/main/Safari%20(Big%20Sur)%20-%20Light.png" className="w-full shadow-lg rounded-lg border" alt="" />
                </div> */}
          </div>
        </section>
      </div>
    </div>
  );
};