import React from 'react'
import hero_img from '../../../../assets/img/Partnerships/hands.jpg'

export default function FooterFinalPage() {
  return (
    <section
    className="relative text-white py-20 bg-cover bg-center"
    style={{
      backgroundImage: `url('${hero_img}')`,
    }}
  > 
    {/* Overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
    
    {/* Content */}
    <div className="container mx-auto px-4 text-center relative z-10">
      {/* <h1 className="text-4xl md:text-6xl font-bold mb-4">
        Partner with Aero Aegis
      </h1> */}
      <p className="text-3xl mb-8">
      Together with our partners, AeroAegis is not just meeting the demands of today’s world but shaping the future of technology and business.
      </p>
      <p className='py-1 text-xl'>Let’s innovate together. Partner with AeroAegis.</p>
      {/* <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        Contact Us
      </button> */}
    </div>
  </section>
  )
}
