import React from 'react';
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaDatabase,
  FaBrain,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { MdHealthAndSafety } from 'react-icons/md';
import { BsBank2 } from 'react-icons/bs';
import { GiFactory } from 'react-icons/gi';
import { FaBuildingCircleCheck } from 'react-icons/fa6';

const Services = () => {
  return (
    <div className=" bg-gray-100 py-10">
      <section className="py-5 ">
        <div className="container mx-auto px-4">
          <div className="flex flex-col mb-6 justify-center items-center lg:justify-center lg:flex-row md:mb-8">
            <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group text-center">
              <span className="inline-block mb-1 sm:mb-4 mx-auto">
                Who Is AeroGenix For?
              </span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
            </h2>
          </div>

          <div className="grid justify-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
            <FeatureCard
              icon={<MdHealthAndSafety className="text-4xl text-blue-600" />}
              title="Healthcare"
              description="Automate patient data management, claims processing, and compliance checks."
            />
            <FeatureCard
              icon={<BsBank2 className="text-4xl text-blue-600" />}
              title="Finance"
              description="Streamline fraud detection, loan approvals, and regulatory reporting."
            />
            <FeatureCard
              icon={<GiFactory className="text-4xl text-blue-600" />}
              title="Retail"
              description=" Optimize inventory management, personalize customer interactions, and analyze trends."
            />
            <FeatureCard
              icon={
                <FaBuildingCircleCheck className="text-4xl text-blue-600" />
              }
              title="Manufacturing"
              description="Enhance supply chain efficiency, predictive maintenance, and workflow automation."
              className="lg:hidden"
            />
          </div>
          <div className="flex flex-col sm:flex-row justify-center mt-8 gapx-0 gap-8">
            <FeatureCard
              icon={
                <FaBuildingCircleCheck className="text-4xl text-blue-600" />
              }
              title="Manufacturing"
              description="Enhance supply chain efficiency, predictive maintenance, and workflow automation."
              className="hidden lg:block"
            />
            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="Any Industry"
              description="AeroGenix adapts to any enterprise workflow, empowering teams with AI-driven solutions."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export function FeatureCard({ icon, title, description, className }) {
  return (
    <div
      className={`card bg-white p-6 rounded-lg shadow-md
    ${className ? className : ''}
    `}
    >
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
}

export default Services;
