import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices } from 'react-icons/md';
import img1 from '../../../assets/img/Industries/TelecomandNetworks/rpa.png';

import { MdOutlineSpaceDashboard } from "react-icons/md";
import { CiCircleCheck } from "react-icons/ci";
import { LuNetwork } from "react-icons/lu";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Automation and RPA in Telecom
            </h1>
            <p className="text-lg text-gray-600">
            Streamline provisioning, network configuration, and billing with advanced automation and RPA solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSpaceDashboard className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Service Provisioning
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Automate customer onboarding and service activation processes with RPA.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuNetwork className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Network Configuration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Simplify complex network setups with automation, reducing errors and deployment times.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <CiCircleCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Billing Automation
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Streamline invoicing and payment processes for enhanced accuracy and efficiency.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
