import React from "react";
import img1 from "../../../assets/img/discover_aeroaegis/4.jpg";
import { MdOutlineTune, MdOutlineAdjust } from "react-icons/md";
import { BiCustomize } from "react-icons/bi";
import { AiOutlineSolution } from "react-icons/ai";

export default function MainSection5() {
  return (
    <section className=" pt-5 ">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>

          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Customization and Flexibility: Tailored for Transformation
              </h1>
              <p className="text-lg text-gray-600">
                Our solutions are designed to adapt to your unique strategic
                needs, enabling transformation.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineTune className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Personalized Solutions
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Tailored offerings to align seamlessly with your business
                  strategies and goals.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <BiCustomize className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Dynamic Adaptability
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Flexible solutions designed to evolve as your business grows
                  and innovates.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineSolution className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Strategic Alignment
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Ensuring your transformation journey is aligned with long-term
                  objectives.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineAdjust className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Scalable Design
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Solutions built to scale, accommodating your enterprise's
                  evolving demands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
