import alibabaCloudLogo from "../../assets/img/PartnershipsCollaboration/alibabaCloudLogo.svg";
import digital_img from "../../assets/img/HomePage/digital.jpg";
import products_transformation_img from "../../assets/img/HomePage/products_transformation.jpg";
import advanced_ai_img from "../../assets/img/HomePage/advanced_ai.jpg";
import industry_img from "../../assets/img/HomePage/68277.jpg";
import { NavLink } from "react-router-dom";
import handshake from "../../assets/img/HomePage/handshake_1.jpg";
import globe from "../../assets/img/HomePage/globe.jpg";
import { FaLocationDot } from "react-icons/fa6";
import investor from "../../assets/img/HomePage/2324.jpg";
import { FaArrowRightLong } from "react-icons/fa6";

function Tiles() {
  const screenSize = window.innerWidth;

  function animation_one(element) {
    if (element.style.transform !== "translateX(-100%)") {
      element.style.transform = "translateX(-100%)";
    } else {
      element.style.transform = "translateX(0)";
    }
  }

  function animation_two(element1, element2) {
    if (element1.style.transform !== "translateY(100%)") {
      element1.style.transform = "translateY(100%)";
      element2.style.transform = "translateY(0%)";
    } else {
      element1.style.transform = "translateY(0%)";
      element2.style.transform = "translateY(100%)";
    }
  }

  return (
    <div className="flex flex-col ">
      <div className="h-screen "></div>
      <div className="grid grid-cols-1 mb-5 md:grid-cols-2 lg:grid-cols-3 lg:grid-rows-[repeat(2,350px)] md:grid-rows-[repeat(3,350px)] grid-rows-[repeat(6,250px)]">
        {/*Box-1 */}
        <div
          className="overflow-clip relative  bg-[#242f81]  bg-cover bg-center "
          style={{
            backgroundImage: `url(${digital_img})`,
          }}
        >
          <div
            className="flex h-full w-full"
            style={{ transition: "all 300ms ease-in-out" }}
            onMouseEnter={(event) => {
              if (screenSize > 768)
                event.currentTarget.style.transform = "translateX(-100%)";
            }}
            onMouseLeave={(event) => {
              if (screenSize > 768)
                event.currentTarget.style.transform = "translateX(0)";
            }}
            onClick={(event) => {
              if (screenSize <= 768) animation_one(event.currentTarget);
            }}
          >
            <div className="w-full h-full bg-[rgb(0,0,0,0.25)] flex-shrink-0 md:px-10 px-6 flex justify-center items-center">
              <h3 className="lg:text-4xl md:text-3xl text-2xl leading-relaxed text-white ">
                Pioneering Products for{" "}
                <span className="font-bold text-3xl drop-shadow-md">
                  Digital Transformation
                </span>
                <span className="md:hidden lg:hidden text-xs">
                  <FaArrowRightLong />
                </span>
              </h3>
            </div>
            <div className="flex-shrink-0  bg-[rgb(0,0,0,0.5)] h-full w-full  md:px-10 px-6 flex justify-evenly items-start flex-col">
              <p className="md:text-lg text-md text-white font-semibold">
                At AeroAegis, we are in the business of innovation, primarily
                through our suite of groundbreaking products designed to
                revolutionize how organizations navigate the digital landscape.
              </p>
              <NavLink
                to="/products"
                className=" bg-blue-600 text-white rounded-sm py-2 px-3 font-bold  text-xs "
              >
                Explore Our Products
              </NavLink>
            </div>
          </div>
        </div>

        {/*Box-2 */}
        <div
          className="overflow-clip relative bg-red-400 bg-center bg-cover "
          style={{
            backgroundImage: `url(${handshake})`,
          }}
        >
          <div
            onMouseEnter={() => {
              if (screenSize > 768) {
                let element1 = document.getElementById("partnership-banner-1");
                let element2 = document.getElementById("partnership-banner-2");
                element1.style.transform = "translateY(100%)";
                element2.style.transform = "translateY(0%)";
              }
            }}
            onClick={() => {
              if (screenSize <= 768) {
                let element1 = document.getElementById("partnership-banner-1");
                let element2 = document.getElementById("partnership-banner-2");
                animation_two(element1, element2);
              }
            }}
            id="partnership-banner-1"
            className="absolute w-full h-full flex flex-col justify-evenly bottom-0 bg-[rgb(0,0,0,0.5)]  flex-shrink-0 transition-all duration-300 ease-in-out md:px-10 px-6"
          >
            <p className="self-start font-bold md:text-lg text-md text-white pb-1 border-b-2">
              Cloud partner
            </p>
            <h3 className="lg:text-3xl md:text-2xl text-xl  text-white">
              AeroAegis Partners with Alibaba Cloud to Enhance Digital
              Transformation Services
              <span className="md:hidden lg:hidden text-xs">
                <FaArrowRightLong />
              </span>
            </h3>
            <div className="w-[200px]">
              <img src={alibabaCloudLogo} alt="alibaba cloud logo" />
            </div>
          </div>
          <div
            id="partnership-banner-2"
            className="bg-[rgb(0,0,0,0.5)] flex flex-col justify-evenly items-start absolute h-full translate-y-[100%] bottom-0 transition-all duration-300 ease-in-out md:px-10 px-6"
            onMouseLeave={() => {
              if (screenSize > 768) {
                let element1 = document.getElementById("partnership-banner-1");
                let element2 = document.getElementById("partnership-banner-2");
                element1.style.transform = "translateY(0%)";
                element2.style.transform = "translateY(100%)";
              }
            }}
            onClick={() => {
              if (screenSize <= 768) {
                let element1 = document.getElementById("partnership-banner-1");
                let element2 = document.getElementById("partnership-banner-2");
                animation_two(element1, element2);
              }
            }}
          >
            <p className="md:text-lg text-md text-white">
              AeroAegis Inc., a leader in AI-driven digital transformation
              solutions, is pleased to announce its official partnership with
              Alibaba Cloud, the digital technology and intelligence backbone of
              Alibaba Group.
            </p>
            <NavLink
              to="/partnerships/alibaba-cloud"
              className="bg-white rounded-sm py-2 px-3 font-bold text-xs "
            >
              Explore more
            </NavLink>
          </div>
        </div>

        {/*Box-3 */}
        <div
          style={{
            backgroundImage: `url(${products_transformation_img})`,
          }}
          className="overflow-clip relative bg-cover bg-center  bg-gray-600 lg:col-auto md:col-[2/3] md:row-[2/3] lg:row-auto row-auto"
        >
          <div
            className="flex h-full w-full"
            style={{ transition: "all 300ms ease-in-out" }}
            onMouseEnter={(event) => {
              if (screenSize > 768)
                event.currentTarget.style.transform = "translateX(-100%)";
            }}
            onMouseLeave={(event) => {
              if (screenSize > 768)
                event.currentTarget.style.transform = "translateX(0)";
            }}
            onClick={(event) => {
              if (screenSize <= 768) animation_one(event.currentTarget);
            }}
          >
            <div className="w-full h-full bg-[rgb(0,0,0,0.25)] flex-shrink-0 md:px-10 px-6 flex justify-center items-center">
              <h3 className="lg:text-4xl md:text-3xl text-2xl leading-relaxed text-white">
                Technology behind our{" "}
                <span className="font-bold drop-shadow-md text-3xl">
                  Innovative products
                </span>
                <span className="md:hidden lg:hidden text-xs">
                  <FaArrowRightLong />
                </span>
              </h3>
            </div>
            <div className="flex-shrink-0 bg-[rgb(0,0,0,0.5)] h-full w-full  md:px-10 px-6 flex justify-evenly items-start flex-col">
              <p className="md:text-lg text-md text-white font-semibold">
                We believe in continuously pushing the boundaries of what is
                possible, pioneering new ideas, and embracing a future-oriented
                mindset.
              </p>
              <NavLink
                to="/white-papers"
                className=" bg-[rgb(255,255,0)] text-[rgb(0,0,0,0.65)] rounded-sm  py-2 px-3 font-bold  text-xs"
              >
                Explore more
              </NavLink>
            </div>
          </div>
        </div>

        {/*Box-4 */}
        <div
          className="overflow-clip relative bg-[red] bg-center bg-cover "
          style={{
            backgroundImage: `url(${industry_img})`,
          }}
        >
          <div
            onMouseEnter={() => {
              if (screenSize > 768) {
                let element1 = document.getElementById("locations-banner-1");
                let element2 = document.getElementById("locations-banner-2");
                element1.style.transform = "translateY(100%)";
                element2.style.transform = "translateY(0%)";
              }
            }}
            onClick={() => {
              if (screenSize <= 768) {
                let element1 = document.getElementById("locations-banner-1");
                let element2 = document.getElementById("locations-banner-2");
                animation_two(element1, element2);
              }
            }}
            id="locations-banner-1"
            className="absolute w-full h-full flex flex-col justify-evenly bottom-0 bg-[rgb(0,0,0,0.25)]  flex-shrink-0 transition-all duration-300 ease-in-out md:px-10 px-6"
          >
            <p className="self-start font-bold  md:text-lg text-md text-white pb-1 border-b-2">
              Industries
            </p>
            <h3 className="lg:text-3xl md:text-2xl text-xl text-white">
              Transforming industries and organizations
              <span className="md:hidden lg:hidden text-xs">
                <FaArrowRightLong />
              </span>
            </h3>
          </div>
          <div
            id="locations-banner-2"
            className="bg-[rgb(0,0,0,0.5)] flex flex-col justify-center gap-1 items-start absolute h-full w-full translate-y-[100%] bottom-0  transition-all duration-300 ease-in-out md:px-10 px-6"
            onMouseLeave={() => {
              if (screenSize > 768) {
                let element1 = document.getElementById("locations-banner-1");
                let element2 = document.getElementById("locations-banner-2");
                element1.style.transform = "translateY(0%)";
                element2.style.transform = "translateY(100%)";
              }
            }}
            onClick={() => {
              if (screenSize <= 768) {
                let element1 = document.getElementById("locations-banner-1");
                let element2 = document.getElementById("locations-banner-2");
                animation_two(element1, element2);
              }
            }}
          >
            <h3 className="text-white font-bold md:text-xl text-sm mb-2">
              Transforming industries and organizations
            </h3>
            <p className="md:text-md text-sm text-white py-2 pb-1 flex gap-4 items-center font-semibold">
              <FaLocationDot />
              At AeroAegis, we are committed to empowering businesses and
              organizations by driving transformative change across industries.
            </p>
            <p className="md:text-md text-sm text-white py-2  pb-1 flex gap-4 items-center font-semibold">
              <FaLocationDot /> Through our expertise in innovation and IT, we
              enable our clients to navigate complex challenges, harness
              emerging technologies, and achieve sustainable growth.
            </p>
          </div>
        </div>

        {/*Box-5 */}
        <div
          className="overflow-clip relative bg-[white] text-black bg-center bg-cover"
          style={{
            backgroundImage: `url(${advanced_ai_img})`,
          }}
        >
          <div
            className="flex  h-full w-full"
            style={{ transition: "all 300ms ease-in-out" }}
            onMouseEnter={(event) => {
              if (screenSize > 768)
                event.currentTarget.style.transform = "translateX(-100%)";
            }}
            onMouseLeave={(event) => {
              if (screenSize > 768)
                event.currentTarget.style.transform = "translateX(0)";
            }}
            onClick={(event) => {
              if (screenSize <= 768) animation_one(event.currentTarget);
            }}
          >
            <div className="w-full h-full md:px-10 px-6  bg-[rgb(0,0,0,0.25)]  flex-shrink-0 flex justify-center items-center">
              <h3 className="lg:text-4xl text-[white] md:text-3xl text-2xl leading-relaxed  ">
                Powered by{" "}
                <span className="font-bold text-3xl drop-shadow-md whitespace-nowrap">
                  Advanced AI
                </span>{" "}
                and{" "}
                <span className="font-bold text-3xl drop-shadow-md whitespace-nowrap">
                  ML Technologies
                </span>
                <span className="md:hidden lg:hidden text-xs">
                  <FaArrowRightLong />
                </span>
              </h3>
            </div>
            <div className="w-full h-full  bg-[rgb(0,0,0,0.5)]  flex-shrink-0 md:px-10 px-6 flex justify-center items-center">
              <p className="md:text-lg text-md text-white font-semibold">
                At AeroAegis, our AI & ML Solutions are meticulously crafted to
                enhance the capabilities of our innovative product suite through
                the integration of cutting-edge artificial intelligence and
                machine learning technologies.
              </p>
            </div>
          </div>
        </div>

        {/*Box-6 */}
        <div
          className="overflow-clip relative bg-red-400 bg-center bg-cover"
          style={{
            backgroundImage: `url(${investor})`,
          }}
        >
          <div
            onMouseEnter={() => {
              if (screenSize > 768) {
                let element1 = document.getElementById(
                  "investor-relations-banner-1"
                );
                let element2 = document.getElementById(
                  "investor-relations-banner-2"
                );
                element1.style.transform = "translateY(100%)";
                element2.style.transform = "translateY(0%)";
              }
            }}
            onClick={() => {
              if (screenSize <= 768) {
                let element1 = document.getElementById(
                  "investor-relations-banner-1"
                );
                let element2 = document.getElementById(
                  "investor-relations-banner-2"
                );
                animation_two(element1, element2);
              }
            }}
            id="investor-relations-banner-1"
            className="absolute w-full h-full flex flex-col justify-evenly bottom-0 bg-[rgb(0,0,0,0.35)] self-end flex-shrink-0 transition-all duration-300 ease-in-out md:px-10 px-6"
          >
            <p className="self-start font-bold md:text-lg text-md text-white py-2 pb-1 border-b-2">
              AI for the Future
            </p>
            <h3 className="lg:text-3xl md:text-2xl text-xl leading-relaxed text-white ">
              Driving innovation with cutting-edge AI solutions for smarter
              decision-making and automation.
              <span className="md:hidden lg:hidden text-xs">
                <FaArrowRightLong />
              </span>
            </h3>
          </div>
          <div
            id="investor-relations-banner-2"
            className="bg-[rgb(0,0,0,0.65)] flex flex-col justify-evenly items-start absolute h-full translate-y-[100%] bottom-0  transition-all duration-300 ease-in-out md:px-10 px-6"
            onMouseLeave={() => {
              if (screenSize > 768) {
                let element1 = document.getElementById(
                  "investor-relations-banner-1"
                );
                let element2 = document.getElementById(
                  "investor-relations-banner-2"
                );
                element1.style.transform = "translateY(0%)";
                element2.style.transform = "translateY(100%)";
              }
            }}
            onClick={() => {
              if (screenSize <= 768) {
                let element1 = document.getElementById(
                  "investor-relations-banner-1"
                );
                let element2 = document.getElementById(
                  "investor-relations-banner-2"
                );
                animation_two(element1, element2);
              }
            }}
          >
            <p className="md:text-lg text-md text-white">
              Revolutionizing industries with next-generation AI solutions that
              empower organizations to make smarter decisions, automate
              processes, and drive innovation. From predictive analytics to
              intelligent automation, we deliver scalable AI systems tailored to
              your needs.
            </p>
            <NavLink
              to="/discover-aeroaegis"
              className="inline-block bg-white rounded-sm  py-2 px-3 font-bold mt-2 text-xs "
            >
              Explore more
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Tiles;
