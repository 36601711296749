import React from 'react'
import img1 from "../../../assets/img/iiot/adrien-olichon-RCAhiGJsUUE-unsplash.jpg"
import { MdKeyboardArrowDown } from "react-icons/md";
export default function MainSection1() {
  return (
    <div>
         {/* <section class=" mt-5  pt-20 pb-20 px-4  relative">
          <div
            class="absolute  w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat bg-fixed"
            style={{
              backgroundImage:`url(${img1})`,
            }}
          ></div>
          <div className="max-w-4xl  mb-10 md:mx-auto text-center   lg:max-w-[62rem] md:mb-12">
            <p className="relative   tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            AeroEdge IIoT, the flagship product from AeroAegis Inc., represents a breakthrough in industrial automation and intelligence. Designed to seamlessly integrate with any Industrial IoT (IIoT) sensors and systems, AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration. This platform empowers industries to enhance operational efficiency, reduce downtime, and optimize maintenance strategies, all while ensuring robust data security and compliance.
            </p>
          </div>
        </section> */}
          
          
<section
      className="relative text-white py-20 md:py-10 bg-cover bg-center"
      style={{
        backgroundImage:
          `url(${img1})`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="container mx-auto px-4 text-center relative z-10">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
        Transforming Industrial Operations
        </h1>
        <p className="text-xl mb-8">
        Revolutionize your industrial processes with AeroEdge IIoT, the ultimate solution for seamless integration, real-time data analytics, and predictive maintenance. Powered by advanced Edge AI, AeroEdge IIoT helps you maximize operational efficiency, minimize downtime, and ensure robust data security. Experience smarter, faster, and more reliable industrial automation. Transform your operations today!
        </p>
        <div className=' flex justify-center items-center flex-col space-y-2'>
        <button to={'/contact-us'} className="bg-white cursor-pointer text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
          Explore more
        </button>
        <MdKeyboardArrowDown className=' text-white animate-bounce ' size={20}/>
      </div>
      </div>
    </section>
    </div>
  )
}
