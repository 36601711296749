import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { IoLockClosedOutline } from 'react-icons/io5';
import { BiLike } from 'react-icons/bi';
import img1 from '../../../../assets/img/Services/IotEdgeSolution/centralised.jpg';

import { GoGraph } from "react-icons/go";
import { BiAnalyse } from "react-icons/bi";
import { FiAlertCircle } from "react-icons/fi";
import { RiDashboard2Line } from "react-icons/ri";

import { MdOutlineDashboard } from "react-icons/md";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Centralized Monitoring
            </h1>
            <p className="text-lg text-gray-600">
            Utilize centralized dashboards and analytics tools for real-time insights. Ensure informed decision-making with comprehensive views of asset performance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDashboard className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Integrated Dashboards</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Access all equipment data on a unified monitoring platform.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiAnalyse className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Performance Optimization
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Gain actionable insights to enhance operational efficiency.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiDashboard2Line className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Data-Driven Decision Making
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Empower teams with real-time asset performance analytics.
              </p>
            </div>  

              {/* Feature 4 */}
             {/*  <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoLockClosedOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Scalable Solutions
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Scale your CI/CD pipeline to meet the growing demands of your
                projects.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
