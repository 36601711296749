import { FaLink } from 'react-icons/fa';
import React from 'react';
import aeroaegis from '../../../../assets/img/Partnerships/aeroaegis.png';
import azureLogo from '../../../../assets/img/Partnerships/azureLogo.png';

export default function LastSection() {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-blue-100 py-20">
      <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,white,transparent)] dark:bg-grid-slate-700/25"></div>

      <div className="relative mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center space-y-8 sm:flex-row sm:space-x-12 sm:space-y-0">
          <div className="group relative">
            <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-blue-600 to-blue-400 opacity-0 blur transition duration-200 group-hover:opacity-20"></div>
            <div className="w-[128px]">
              <img className="object-cover w-fit  " src={aeroaegis} alt="" />
            </div>
          </div>

          <FaLink className="hidden h-6 w-6 text-stone-500 sm:block " />

          <div className="group relative">
            <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-blue-500 to-blue-300 opacity-0 blur transition duration-200 group-hover:opacity-20"></div>
            <div className="w-fit">
              <img
                src={azureLogo}
                alt="alibaba cloud logo"
                className="w-50 h-16"
              />
            </div>
          </div>
        </div>

        <div className="mt-16 space-y-8 text-center">
          <div className="space-y-6">
            <h2 className="mx-auto max-w-3xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              AeroAegis, in partnership with Azure Cloud, is focused on
              providing innovative and effective solutions.
            </h2>
            <p className="mx-auto max-w-2xl text-lg leading-relaxed text-gray-600">
              AeroAegis proudly achieves certification as a Microsoft Azure ISV
              Partner, significantly enhancing its cloud consulting
              capabilities. This milestone enables the delivery of cutting-edge
              Azure-powered solutions tailored to meet the needs of modern
              enterprises.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
