import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
import img1 from "../../../assets/img/Industries/TelecomandNetworks/aidriven.jpeg";

import { GiChart } from "react-icons/gi";
import { CiRoute } from "react-icons/ci";
import { SiTestcafe } from "react-icons/si";

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              AI-Driven Network Operations
            </h1>
            <p className="text-lg text-gray-600">
              Enhance performance with predictive maintenance, real-time traffic
              optimization, and personalized customer experience management.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Predictive Maintenance
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI to identify and resolve network issues before they impact
                performance, reducing downtime and maintenance costs.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <CiRoute className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Traffic Optimization
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize network traffic in real time to ensure seamless
                connectivity and service quality.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <SiTestcafe className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Customer Experience Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Analyze customer behavior and service usage to deliver
                personalized offerings and improve satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
