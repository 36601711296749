import React, { useState, useEffect } from "react";
import {
  FaHospital,
  FaPills,
  FaFileInvoiceDollar,
  FaMedkit,
} from "react-icons/fa";

import img1 from "../../../assets/img/Industries/TelecomandNetworks/infra.png";
import img2 from "../../../assets/img/Industries/TelecomandNetworks/netoperation.jpeg";
import img3 from "../../../assets/img/Industries/TelecomandNetworks/engagement.jpeg";
import img4 from "../../../assets/img/Industries/TelecomandNetworks/smart.png";

const HealthcareCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl md:w-80 w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Logistics = () => {
  const healthcareAreas = [
    {
      title: "Infrastructure Modernization",
      description:
        "Upgrade legacy networks to 5G and cloud-native architectures for enhanced performance and scalability.",
      icon: <FaHospital />,
      imageUrl: img1,
    },
    {
      title: "Network Operations",
      description:
        "Use AI and automation to improve network reliability, optimize performance, and reduce operational costs.",
      icon: <FaPills />,
      imageUrl: img2,
    },
    {
      title: "Customer Engagement",
      description:
        "Deliver personalized, seamless experiences with advanced analytics and AI-driven insights.",
      icon: <FaFileInvoiceDollar />,
      imageUrl: img4,
    },
    {
      title: "IoT and Smart Services",
      description:
        "Enable new-age applications such as smart cities, connected vehicles, and industrial IoT with reliable and scalable networks.",
      icon: <FaMedkit />,
      imageUrl: img3,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
          Transforming Key Areas of Telecom & Networks
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
          Modernize infrastructure, optimize operations, enhance engagement, and
          power IoT with advanced telecom technologies.
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-4 gap-14">
          {healthcareAreas.map((area, index) => (
            <HealthcareCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Logistics;
