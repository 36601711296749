import React from 'react';
import feature_img from '../../../assets/img/Products/AeroGenix/2150010144.jpg';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { AiOutlineSync } from 'react-icons/ai';

import { LiaDocker } from 'react-icons/lia';

export default function Feature4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={feature_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Real-Time Deployment
            </h1>
            <p className="text-lg text-gray-600">
              Deploy AI agents and models with flexibility:
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCloudUploadOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Cloud-Agnostic Deployment
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Cloud-agnostic deployment across AWS, Azure, GCP, or
                on-premises.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaDocker className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Kubernetes Scaling
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Kubernetes-based scaling for enterprise workloads.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSync className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Task Orchestration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Task orchestration with real-time decision-making.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
