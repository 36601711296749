import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdCloud, MdOutlineBlock, MdOutlineCloudUpload, MdOutlineWeb, MdPayment } from "react-icons/md"; // Cloud-Based Model Deployment
import { MdOutlineDevices } from "react-icons/md";
import img1 from "../../../assets/img/Industries/RetailEcommerce/a.png";
import { SiHiveBlockchain } from "react-icons/si";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              E-Commerce Platform Modernization
            </h1>
            <p className="text-lg text-gray-600">
              Revamping e-commerce platforms to enhance performance,
              scalability, and user experience.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdCloud className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Cloud-Native Platforms
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Transition legacy systems to secure and scalable cloud-native
                platforms for enhanced performance.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineWeb className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Headless Commerce
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Decouple the front-end experience from the back-end
                infrastructure for greater flexibility and faster innovation.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdPayment className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Payment Gateway Integration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Ensure seamless and secure payment processing with
                multi-currency and mobile wallet support.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
