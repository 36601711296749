import React from "react";
import cross_cloud_img from '../../../assets/img/Products/InfraVue/2149399284.jpg'
import { FaCloud } from "react-icons/fa6";
import { FaArrowsAlt } from "react-icons/fa";

export default function Feature3() {
  const section = 

  {
    imageSrc: cross_cloud_img, // Replace with an appropriate image
    title: "Cross-Cloud Compatibility",
    description:
      "Enable agile and adaptable cloud infrastructure with AeroInfra Vue's cross-cloud capabilities.",
    features: [
      {
        icon: FaCloud,
        title: "Multi-Cloud Management",
        description: "Deploy and manage infrastructure across multiple cloud platforms.",
      },
      {
        icon: FaArrowsAlt,
        title: "Enhanced Flexibility",
        description: "Strategically adapt to changing cloud needs with ease.",
      },
    ],
  };
  
  return (
    <div>
    <section className="dark:bg-gray-800 dark:text-gray-100">
                <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              {section.title}
            </h1>
            <p className="text-lg text-gray-600">{section.description}</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {section.features.map((feature, index) => (
              <div key={index} className="space-y-3">
                <div className="flex items-center space-x-2">
                  <feature.icon className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">{feature.title}</h3>
                </div>
                <p className="text-gray-600 text-sm">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={section.imageSrc}
            alt="Section image"
            className="object-cover w-full h-full rounded-3xl"
            
          />
        </div>
      </div>
    </div>
    </section>
    </div>
  );
}
