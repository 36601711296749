import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices } from 'react-icons/md'; 
import img1 from "../../../assets/img/Industries/Healthcare/Advantages-of-Cloud-Computing-in-Healthcare-Sector.jpg"
import { RiExchangeLine } from 'react-icons/ri';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { FiRefreshCcw } from 'react-icons/fi';



export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Cloud Solutions for Healthcare
            </h1>
            <p className="text-lg text-gray-600">
            Enabling seamless data exchange, scalable management, and resilient recovery in healthcare with secure, cloud-native solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiExchangeLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Healthcare Data Interoperability</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Facilitate seamless data exchange among systems and stakeholders with secure, cloud-native platforms.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineCloudServer className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Scalable Infrastructure</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Manage growing data volumes efficiently while reducing IT infrastructure costs.
              </p>
            </div>
{/* Feature 3 */}
<div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiRefreshCcw className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Disaster Recovery and Backup</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Ensure compliance and data resilience with robust backup and recovery solutions.
              </p>
            </div>
      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}