import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { MdOutlinePermMedia } from "react-icons/md";
import { AiOutlineNotification } from "react-icons/ai";
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/AI&ML/scott-graham-5fNmWej4tAA-unsplash.jpg";

export default function MainSection1() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
       <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Content Generation
            </h1>
            <p className="text-lg text-gray-600">
            Leverage generative AI for automated text generation, marketing content, and creative media.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Automated Text Generation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Use generative AI to create high-quality written content, from articles to product descriptions, reducing manual effort.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineNotification className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Personalized Marketing </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Generate tailored marketing copy, ads, and social media posts based on customer data and preferences.
              </p>
            </div>
 {/* Feature 3 */}
 <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePermMedia className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Creative Media Production</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage AI to produce creative media, including video scripts, graphics, and music, enhancing content variety and engagement.
              </p>
            </div>
           
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}