import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BsCloudUpload } from "react-icons/bs";
import img1 from "../../../../assets/img/Services/AI&ML/160252.jpg";
import { IoCloudUpload } from 'react-icons/io5';
import { FaDatabase } from 'react-icons/fa';
import { TbDatabaseHeart } from "react-icons/tb";

export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Cloud-Based Data Platforms
            </h1>
            <p className="text-lg text-gray-600">
            Migrate and manage data infrastructure on Azure, AWS, GCP, and Alibaba Cloud.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsCloudUpload  className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Seamless Cloud Migration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Migrate existing data infrastructure to cloud platforms like Azure, AWS, GCP, and Alibaba Cloud for enhanced scalability and performance.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TbDatabaseHeart  className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Optimized Cloud Data Management</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Manage and optimize cloud-based data environments to ensure efficiency, security, and cost-effectiveness.
              </p>
            </div>

      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}