import React from "react";
import img1 from "../../../assets/img/discover_aeroaegis/3.jpg";
import { AiOutlineRobot } from "react-icons/ai";
import { HiOutlineCloud } from "react-icons/hi";
import { FiLayers } from "react-icons/fi";
import { BiBrain } from "react-icons/bi";

export default function MainSection4() {
  return (
    <section className=" ">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Expertise and Diverse Innovation: Our Strategic Edge
              </h1>
              <p className="text-lg text-gray-600">
                Broad expertise in AI, ML, and cloud technologies ensures
                versatile and effective solutions.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineRobot className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Artificial Intelligence
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Applying AI for automation and predictive insights tailored to
                  industry demands.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineCloud className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Cloud Technologies
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Utilizing cloud systems to enable scalable, efficient, and
                  secure solutions.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FiLayers className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Diverse Expertise
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Merging industry knowledge to create innovative, tailored
                  strategies.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <BiBrain className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Machine Learning
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Implementing ML models to derive actionable insights and drive
                  innovation.
                </p>
              </div>
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
