import React from "react";
import img1 from "../../../assets/img/discover_aeroaegis/2.jpg";
import { MdOutlineScience, MdOutlineAutorenew } from "react-icons/md";
import { FaFlask, FaRocket } from "react-icons/fa";

export default function MainSection3() {
  return (
    <section className=" pt-5 ">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Innovative Products: The Core of Our Identity
              </h1>
              <p className="text-lg text-gray-600">
                Innovation drives transformation and growth through
                groundbreaking solutions.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineScience className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Maturity Spectrum 360
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  A comprehensive product redefining benchmarks with innovative
                  metrics.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FaFlask className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    SmartChaos Lab
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  A groundbreaking platform designed to foster controlled
                  innovation.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineAutorenew className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Endless Evolution
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Products designed to adapt and grow alongside emerging
                  technologies.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <FaRocket className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Catalysts for Growth
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Solutions crafted to drive business transformation and
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
