import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
import img1 from "../../../assets/img/Industries/Healthcare/10532.jpg";
import { FiMonitor } from "react-icons/fi";
import { MdOutlineLocalHospital } from "react-icons/md";
import { GiArtificialIntelligence } from "react-icons/gi";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              IoT and Connected Health
            </h1>
            <p className="text-lg text-gray-600">
              Harnessing IoT, digital twins, and smart technologies to enhance
              real-time patient monitoring, optimize hospital operations, and
              innovate virtual care delivery.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiMonitor className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Remote Patient Monitoring (RPM)
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable real-time health insights through wearable devices and
                IoT technology.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLocalHospital className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Smart Hospitals</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize operations with IoT-powered systems for patient flow
                management, asset tracking, and energy efficiency.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiArtificialIntelligence className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Digital Twins for Healthcare
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Simulate patient conditions or treatment scenarios to innovate
                care delivery virtually.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
