import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineCloudUpload } from "react-icons/md"; // Cloud-Based Model Deployment
import { MdOutlineDevices } from "react-icons/md";
import { TbSettingsAutomation } from "react-icons/tb";
import { FaHandshakeSimple } from "react-icons/fa6";
import img1 from "../../../assets/img/Industries/Manufacturing/robotics.jpg";

export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8"> 
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Robotics and Workflow Automation
            </h1>
            <p className="text-lg text-gray-600">
              Revolutionize operations with cutting-edge robotics and
              streamlined workflow automation, boosting efficiency and precision
              across your business processes.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TbSettingsAutomation className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Automated Production Lines
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Increase throughput and efficiency with robotic process
                automation (RPA) for repetitive manufacturing tasks.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDevices className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  {" "}
                  Logistics Automation
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize warehouse management, order picking, and delivery with
                AI and robotic solutions.
              </p>
            </div>
            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaHandshakeSimple className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Human-Machine Collaboration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Empower workers with collaborative robots (cobots) for safe and
                efficient operations.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
