import React from 'react'
import HeroSection from './HeroSection'
import MainSection1 from './MainSection1'
import HeroSectionNew from "./NewHeroSection"
export default function index() {
  return (
    <div>
        <HeroSectionNew/>
        <MainSection1/>
    </div>
  )
}
