import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices } from 'react-icons/md';
import img1 from '../../../assets/img/Industries/TelecomandNetworks/cloudnet.png';

import { MdOutlineSpaceDashboard } from "react-icons/md";
import { CiCircleCheck } from "react-icons/ci";

import { LuNetwork } from "react-icons/lu";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Cloud-Native Network Management 
            </h1>
            <p className="text-lg text-gray-600">
            Achieve scalability, seamless multi-cloud orchestration, and resilience with cloud-native network solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuNetwork className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Virtualized Network Functions (VNFs)
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Migrate legacy infrastructure to cloud-native VNFs for improved scalability and flexibility.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDevices className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Multi-Cloud Orchestration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Manage hybrid and multi-cloud environments seamlessly for global telecom operations.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <CiCircleCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Disaster Recovery and Resilience
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Ensure business continuity with robust disaster recovery solutions in cloud environments.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
