import React from "react";
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaDatabase,
  FaBrain,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const AeroAegisLanding = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Unlock the Power of AI and Data
          </h1>
          <p className="text-xl mb-8">
            At AeroAegis Inc., our{" "}
            <strong>Strategic Consulting Services</strong> go beyond advisory —
            we are your <strong>partners in transformation</strong>, helping you
            achieve excellence through innovation and technology. Let us guide
            your enterprise to success in a rapidly changing digital world.
          </p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </button>
        </div>
      </section>

      {/* Why Choose AeroAegis Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Why Choose AeroAegis Strategic Consulting?
          </h2>
          <div className="justify-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
            <FeatureCard
              icon={<FaBrain className="text-4xl text-blue-600" />}
              title="Holistic Approach"
              description="We offer end-to-end consulting, from discovery and maturity assessments to execution and advocacy."
            />
            <FeatureCard
              icon={<FaCog className="text-4xl text-blue-600" />}
              title="Technology Expertise"
              description="Leverage cutting-edge technologies like AI, Cloud, and Blockchain to future-proof your enterprise."
            />
            <FeatureCard
              icon={<FaChartLine className="text-4xl text-blue-600" />}
              title="Innovation Advocacy"
              description="We advocate for emerging technologies that drive transformation, efficiency, and growth."
            />
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Proven Frameworks"
              description="With proprietary frameworks like Maturity Spectrum 360, we assess readiness and develop actionable roadmaps."
              className="lg:hidden"
            />
          </div>
          <div className="flex flex-col sm:flex-row justify-center mt-8 gap-8 w-full">
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Proven Frameworks"
              description="With proprietary frameworks like Maturity Spectrum 360, we assess readiness and develop actionable roadmaps."
              className="hidden lg:block"
            />

            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="Business-Driven Focus"
              description="Our strategies are tailored to align with your business goals and deliver measurable outcomes."
            />
            {/*  <FeatureCard
  icon={<FaDatabase className="text-4xl text-blue-600" />}
  title="Data-Driven Insights"
  description="Harness the power of analytics and big data to make informed decisions that drive strategic growth and innovation."
/> */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">
            Ready to begin your transformation journey? Let’s collaborate! 🚀
          </p>
          <Link
            to={"/contact-us"}
            className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300"
          >
            Contact Us
          </Link>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description, className }) => {
  return (
    <div
      className={`bg-white p-6 rounded-lg shadow-md ${
        className ? className : ""
      }`}
    >
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default AeroAegisLanding;
