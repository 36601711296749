import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
import img1 from "../../../assets/img/Industries/LogisticsandSupplyChain/analytics.jpg";

import { GiChart } from "react-icons/gi";
import { CiRoute } from "react-icons/ci";
import { SiTestcafe } from "react-icons/si";

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              AI and Advanced Analytics
            </h1>
            <p className="text-lg text-gray-600">
              Utilize AI for demand forecasting, route optimization, and anomaly
              detection to enhance efficiency and reduce costs.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Demand Forecasting
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage AI to predict demand fluctuations and optimize
                inventory levels.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <CiRoute className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Route Optimization
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI-powered tools to determine the most efficient routes,
                reducing fuel costs and delivery times.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <SiTestcafe className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Anomaly Detection
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Identify inefficiencies or risks in the supply chain with
                advanced analytics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
