import React from 'react';
import ethical_img from '../../assets/img/Csr/ethical.jpg';
import { LiaBalanceScaleLeftSolid } from "react-icons/lia";
import { PiGavel } from "react-icons/pi";

export default function Section3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Ethical Business Practices
            </h1>
            <p className="text-lg text-gray-600">
            We uphold the highest standards of ethics and integrity in our business operations. This includes fair and transparent business practices, responsible sourcing, and compliance with all relevant laws and regulations.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaBalanceScaleLeftSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Fair and Transparent Practices
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Our commitment to ethics ensures fairness and transparency across all aspects of our business operations.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <PiGavel className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Responsible Sourcing and Compliance
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              We prioritize responsible sourcing and adhere strictly to all relevant laws and regulations.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={ethical_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}

