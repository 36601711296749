import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/AI&ML/ETl.png";
import { BsDatabaseGear } from "react-icons/bs";
import { SiJfrogpipelines } from "react-icons/si";
import { BsDatabaseCheck } from "react-icons/bs";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            ETL/ELT Services
            </h1>
            <p className="text-lg text-gray-600">
            Automate Extract, Transform, Load processes for seamless data integration.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsDatabaseGear className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Efficient Data Integration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Automate ETL/ELT workflows to streamline the extraction, transformation, and loading of data across systems.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <SiJfrogpipelines className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Scalable Data Processing</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Design scalable ETL/ELT pipelines to handle large volumes of data, ensuring timely and accurate integration for analytics.
              </p>
            </div>

      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}