import React from 'react';
import data_storage_img from '../../../assets/img/Products/InfraVue/infra.png';
import { FaLayerGroup, FaSyncAlt } from 'react-icons/fa';

export default function Feature2() {
  const section = {
    imageSrc: data_storage_img, // Replace with an appropriate image
    title: 'Immutable Infrastructure',
    description:
      'AeroInfra Vue adopts a gold-standard model for consistent and reliable infrastructure management.',
    features: [
      {
        icon: FaSyncAlt,
        title: 'Unchanged Components',
        description:
          'Deployed infrastructure remains unchanged throughout its lifecycle.',
      },
      {
        icon: FaLayerGroup,
        title: 'Consistent Deployment',
        description:
          'New instances replace old ones, ensuring reliability and predictability.',
      },
    ],
  };

  return (
    <div>
      <section className="dark:bg-gray-800 dark:text-gray-100 mt-5">
        <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Core Features</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>
        <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="space-y-8">
              <div className="space-y-4">
                <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                  {section.title}
                </h1>
                <p className="text-lg text-gray-600">{section.description}</p>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                {section.features.map((feature, index) => (
                  <div key={index} className="space-y-3">
                    <div className="flex items-center space-x-2">
                      <feature.icon className="w-6 h-6 text-indigo-600" />
                      <h3 className="font-semibold text-gray-900">
                        {feature.title}
                      </h3>
                    </div>
                    <p className="text-gray-600 text-sm">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative h-[500px] rounded-3xl overflow-hidden">
              <img
                src={section.imageSrc}
                alt="Section image"
                className="object-cover w-full h-full rounded-3xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
