// import React from 'react'
// import { Link } from 'react-router-dom'
// import img1 from '../../assets/img/HomePage/2583.jpg'
// export default function PoweredbyAero() {
//   return (
//     <div>
//      <section class="bg-white ">
//     <div class="container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row">
//         <div class="flex justify-center xl:w-1/2">
//             <img class="h-80 w-80 sm:w-[28rem] sm:h-[28rem] flex-shrink-0 object-cover rounded-full" 
//             src={img1} alt=""/>
//         </div>

//         <div class="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
//             <h2 class="text-2xl font-semibold tracking-tight text-gray-800 xl:text-4xl ">
//             Your Success, Powered by <span className=' text-[#252f80]'>AeroAegis</span>

//             </h2>

//             <p class="block max-w-2xl mt-4  text-base lg:text-lg  text-gray-700 ">
//             Embark on a journey with AeroAegis where your business is not just transformed but
// transcendent. Our products lay the foundation for unparalleled success, bolstered by our
// commitment to your growth. In partnership, we venture beyond the conventional, turning
// challenges into triumphs in the digital epoch.
//              </p>

//             <div class="mt-6 sm:-mx-2">

//               <Link to="/aeroaegis-capabilities">  <a 
//                     class="inline-flex items-center justify-center w-full px-4 text-sm py-2.5 mt-4 overflow-hidden text-white transition-colors duration-300 bg-blue-600 rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                   

//                     <span class="mx-2">
//                         Learn More
//                     </span>
//                 </a></Link>
//             </div>
//         </div>
//     </div>
// </section>
//     </div>
//   )
// }



import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaRocket, FaArrowRight } from 'react-icons/fa'
import img1 from "../../assets/img/HomePage/2583.jpg"
export default function HeroSection() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50 flex items-center">
      <div className="container mx-auto px-6 py-16 md:py-20">
        <div className="flex flex-col justify-center md:flex-row items-center gap-12">
          {/* Image Section */}
          <div className={`w-full md:w-1/3 transition-all duration-1000 ${
            isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'
          }`}>
            <div className="relative">
              <div className="absolute -inset-4 bg-blue-600/10 rounded-full blur-xl"></div>
              <div className="relative rounded-full overflow-hidden aspect-square shadow-2xl">
                <img
                  src={img1}
                  alt="Business success celebration"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          {/* Content Section */}
          <div className={`w-full md:w-1/2 transition-all duration-1000 delay-300 ${
            isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'
          }`}>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-6">
              Your Success, Powered by{' '}
              <span className="text-blue-600 inline-flex items-center gap-2">
                AeroAegis
                <FaRocket className="inline-block w-8 h-8 md:w-10 md:h-10 animate-pulse" />
              </span>
            </h1>
            
            <p className="text-lg md:text-xl text-gray-600 mb-8 leading-relaxed">
              Embark on a journey with AeroAegis where your business is not just transformed but
              transcendent. Our products lay the foundation for unparalleled success, bolstered by
              our commitment to your growth. In partnership, we venture beyond the
              conventional, turning challenges into triumphs in the digital epoch.
            </p>

            <Link to="/aeroaegis-capabilities" className="group inline-flex items-center gap-2 bg-blue-600 text-white px-8 py-4 rounded-full text-lg font-semibold transition-all hover:bg-blue-700 hover:shadow-lg hover:shadow-blue-600/30">
              Learn More
              <FaArrowRight className="transition-transform group-hover:translate-x-1" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

