import { IoCheckmarkCircleOutline } from "react-icons/io5";

import { FaCogs, FaRocket } from "react-icons/fa";
import img1 from "../../../assets/img/Industries/Aerospace/1c9ea75b-980c-4507-a0d8-7d901f21a5a1.jpg";

export default function MainSection7() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Space Technology Solutions
            </h1>
            <p className="text-lg text-gray-600">
              Unlocking the potential of space with innovative technologies and
              solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRocket className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Satellite Data Analytics
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable actionable insights from satellite data for industries
                like agriculture, defense, and telecommunications.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCogs className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Launch Optimization
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize space mission planning and execution with AI and
                advanced simulation tools.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Spacecraft Systems Design
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Implement digital twin and IoT solutions for real-time
                monitoring and control of spacecraft systems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
