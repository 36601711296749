
import img1 from "../../../../assets/img/Services/OperationalExe/890.jpg";

import { BsListCheck } from "react-icons/bs";
import { BiTachometer } from "react-icons/bi";
import { AiOutlineAppstoreAdd } from "react-icons/ai";

export default function MainSection2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Performance Assessments
            </h1> 
            <p className="text-lg text-gray-600">
            Performance assessments evaluate system performance to identify and resolve bottlenecks for optimal efficiency.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsListCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">End-to-End Evaluation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Conduct thorough assessments across the entire system to pinpoint performance issues.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiTachometer className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Bottleneck Identification</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Detect and address performance bottlenecks that hinder system efficiency.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineAppstoreAdd className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Optimization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enhance overall system performance by resolving identified issues and fine-tuning resources.
              </p>
            </div>

            {/* Feature 4 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiLike className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">All in One</h3>
              </div>
              <p className="text-gray-600 text-sm">
                All-in-one solution ensures that you can easily manage your financial needs.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}