import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineDashboard } from 'react-icons/md'; // Interactive Data Dashboards
import { MdOutlineInsertChartOutlined } from 'react-icons/md';
import img1 from "../../../../assets/img/Services/AI&ML/luke-chesser-JKUTrJ4vK00-unsplash (1).jpg"

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Visualization & Dashboards
            </h1>
            <p className="text-lg text-gray-600">
            Create intuitive, interactive dashboards using tools like Power BI, Tableau, and Looker to make insights accessible.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDashboard className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900"> Interactive Data Dashboards</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Design user-friendly dashboards with tools like Power BI, Tableau, and Looker for real-time insights.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineInsertChartOutlined className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Simplified Data Visualization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Transform complex data into clear and actionable visuals for easier understanding and decision-making.
              </p>
            </div>

          

         
          </div>
        </div>

      
      </div>
    </div>
  )
}