import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { LiaIndustrySolid } from "react-icons/lia";
import { LiaRobotSolid } from "react-icons/lia";
import img1 from "../../../../assets/img/Services/AI&ML/91243.jpg";

export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Custom Generative Models
            </h1>
            <p className="text-lg text-gray-600">
            Build industry-specific generative AI models tailored to business needs.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaIndustrySolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Industry-Specific Solutions</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Develop generative AI models tailored to unique business requirements, optimizing processes like content creation, data analysis, and decision-making.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaRobotSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Personalized AI Innovation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Customize generative models to address niche challenges, enabling businesses to automate tasks, enhance creativity, and drive innovation.
              </p>
            </div>

      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}