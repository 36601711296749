import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { FaCogs, FaRobot } from "react-icons/fa" 
import img1 from '../../../assets/img/Industries/Aerospace/80f8e203-7e9e-47f3-9d73-b5cbf036327d.jpg'

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Flight Operations Optimization
            </h1>
            <p className="text-lg text-gray-600">
              Enhance flight safety, efficiency, and sustainability with AI-powered solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRobot className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Route Optimization</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage AI to plan the most efficient flight paths, reducing fuel consumption and emissions.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCogs className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Autonomous Systems</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Develop AI-driven navigation and autonomous control systems for unmanned aerial vehicles (UAVs).
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Flight Data Analytics</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Analyze flight data in real-time to enhance safety, reduce operational risks, and optimize performance.
              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}