import React from 'react';
import hands_img from '../../../../assets/img/Partnerships/hands.jpg';
import { AiOutlineAppstore } from 'react-icons/ai';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { PiChartLine } from "react-icons/pi";
import { IoCloudUploadOutline } from "react-icons/io5";

export default function Section1() {
  return (
    <div>
      <div className="container mx-auto px-4 py-12 md:py-15 md:px-10">
        {/*  <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
                  <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
                    <span className="inline-block mb-1 sm:mb-4">Strategic Technology Partnerships</span>
                    <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
                  </h2>
                 
                </div> */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900 inline-block mb-1 sm:mb-4">
                Strategic Technology Partnerships
              </h1>
              <p className="text-lg text-gray-600">
                AeroAegis collaborates with leading technology companies to
                ensure our solutions remain at the forefront of innovation. By
                integrating the latest advancements, we deliver unmatched value
                and capability to our clients.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    AI and Machine Learning
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Working with AI pioneers to infuse intelligence into all our
                  products, driving automation and smarter decision-making.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineAppstore className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Blockchain Technology
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Leveraging secure blockchain protocols to enhance transparency
                  and trust across our solutions, particularly in industries
                  like finance and supply chain.
                </p>
              </div>

              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <PiChartLine className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    IoT and Edge Computing
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Partnering with IoT leaders to create real-time, connected
                  ecosystems that enable predictive analytics and operational
                  efficiency.
                </p>
              </div>

              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <IoCloudUploadOutline className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Advanced Cloud Infrastructure
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Collaborating with global cloud providers to deliver optimized
                  and scalable infrastructure for enterprises
                </p>
              </div>
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={hands_img}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
      <footer className="bg-blue-600 text-white py-8 my-20">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">
          These collaborations ensure that AeroAegis products, including AeroPulse AI, AeroEdge IIoT, and AeroInfra Vue, consistently lead the market in innovation, efficiency, and reliability.
          </p>
        </div>
      </footer>
    </div>
  );
}
{
  /* <div>
      <section className="dark:bg-gray-800 dark:text-gray-100 mt-10">
        <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
            <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
              Strategic Technology Partners
            </h3>
            <div className="flex space-x-2 sm:space-x-4 pt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leading">
                  We collaborate with leading technology companies to integrate
                  the latest advancements directly into our products.
                </p>
              </div>
            </div>

            <div className="flex space-x-2 sm:space-x-4 pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leading">
                  These partnerships allow us to leverage emerging technologies
                  - such as advanced AI algorithms, blockchain security
                  protocols, and next-generation cloud infrastructure - to
                  enhance the functionality, efficiency, and security of our
                  solutions.
                </p>
              </div>
            </div>

            <div className="flex space-x-2 sm:space-x-4 pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leading">
                  By doing so, we ensure that our products, including Maturity
                  Spectrum 360, SmartChaos Lab, AeroPulse AI, AeroInfra Vue
                  stay at the forefront of innovation, delivering
                  unmatched value to our clients.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={hands_img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
      </section>
    </div> */
}
