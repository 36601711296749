import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";

import { MdOutlineCloudDone } from "react-icons/md";
import img1 from "../../../assets/img/Industries/banking&finance/2149074561.jpg";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Decentralized Finance (DeFi) Solutions
            </h1>
            <p className="text-lg text-gray-600">
              Unlock the power of blockchain technology to revolutionize
              financial services.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiCoinStack className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Smart Contracts Development
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate financial transactions with secure and self-executing
                blockchain-based smart contracts.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  DeFi Lending Platforms
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Create transparent and efficient peer-to-peer lending and
                borrowing systems.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCloudDone className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Tokenization of Assets
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable fractional ownership of real-world assets like real
                estate and commodities through blockchain tokenization.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
