import React from 'react';
import feature_img from '../../../assets/img/Products/AeroPerformai/AI.png';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { AiOutlineAppstore } from 'react-icons/ai';
import { IoCloudUploadOutline } from "react-icons/io5";

export default function Feature2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={feature_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              AI-Driven Insights
            </h1>
            <p className="text-lg text-gray-600">
              AeroPulse AI leverages advanced AI and machine learning to analyze
              data, detect anomalies, and uncover patterns. Predictive insights
              enable proactive issue resolution, ensuring optimal cloud
              performance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Advanced AI and Machine Learning
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                At the heart of AeroPulse AI are advanced AI and machine
                learning algorithms, designed to sift through vast amounts of
                data to identify patterns, anomalies, and potential issues.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCloudUploadOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Predictive Cloud Performance Insights
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                This allows us to offer predictive insights into your cloud
                performance, giving you the foresight to address problems before
                they impact your operations.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
                      <div className="flex items-center space-x-2">
                        <AiOutlineSolution className="w-6 h-6 text-indigo-600" />
                        <h3 className="font-semibold text-gray-900">
                        Proactive Issue Resolution
                        </h3>
                      </div>
                      <p className="text-gray-600 text-sm">
                      Detect and resolve inefficiencies before they escalate.
                      </p>
                    </div> */}

            {/* Feature 4 */}
            {/*  <div className="space-y-3">
                      <div className="flex items-center space-x-2">
                        <MdOutlineIntegrationInstructions className="w-6 h-6 text-indigo-600" />
                        <h3 className="font-semibold text-gray-900">
                          Improved Collaboration
                        </h3>
                      </div>
                      <p className="text-gray-600 text-sm">
                        Enhance team productivity by integrating tools that support
                        shared workflows and version control.
                      </p>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
