import { IoCheckmarkCircleOutline } from "react-icons/io5";
import img1 from "../../../assets/img/Industries/Energy/1709213207230.jpg";
import { BsShieldLock } from "react-icons/bs"; // For secure transactions
import { FaNetworkWired } from "react-icons/fa"; // For decentralized solutions
import { HiDocumentText } from "react-icons/hi"; // For audit trails

export default function MainSection2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Blockchain for Secure Energy Trading
            </h1>
            <p className="text-lg text-gray-600">
              Transparent and Secure Transactions: Implement blockchain-based smart contracts to enable secure and transparent peer-to-peer energy trading and power purchase agreements.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsShieldLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Transparent and Secure Transactions</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Implement blockchain-based smart contracts to enable secure and transparent peer-to-peer energy trading and power purchase agreements.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <HiDocumentText className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Tamper-Proof Audit Trails</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use blockchain to create immutable records of energy generation, trading, and consumption for improved regulatory compliance and trust.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaNetworkWired className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Decentralized Renewable Energy Solutions</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Facilitate seamless integration of renewable energy sources into decentralized grids with blockchain technology, empowering a greener future.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}