export default function Section({ imageSrc, title, description, features, isImageLeft }) {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
  <div
    className={`md:grid flex flex-col-reverse grid-cols-1 md:grid-cols-2 gap-8 items-center`}
  >
    <div
      className={`relative h-[500px] rounded-3xl overflow-hidden ${
        isImageLeft ? 'md:order-2' : 'md:order-1'
      }`}
    >
      <img
        src={imageSrc}
        alt="Image description"
        className="object-cover w-full h-full rounded-3xl"
      />
    </div>
    <div
      className={`space-y-8 ${
        isImageLeft ? 'md:order-1' : 'md:order-2'
      }`}
    >
      <div className="space-y-4">
        <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
          {title}
        </h1>
        <p className="text-lg text-gray-600">{description}</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {features.map((feature, index) => (
          <div key={index} className="space-y-3">
            <div className="flex items-center space-x-2">
              <feature.icon className="w-6 h-6 text-indigo-600" />
              <h3 className="font-semibold text-gray-900">{feature.title}</h3>
            </div>
            <p className="text-gray-600 text-sm">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>

  );
}
