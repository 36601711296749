import React from "react";
import img1 from "../../../assets/img/Industries/RetailEcommerce/00.jpg";
import { Link } from "react-router-dom";

export default function FinalSection() {
  return (
    <section
      className="relative text-white py-20 md:py-10 bg-cover bg-center"
      style={{
        backgroundImage:
          `url('${img1}')`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="container mx-auto px-4 text-center relative z-10">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Partner with Aero Aegis
        </h1>
        <p className="text-xl mb-4">
          At Aero Aegis, we help retail and e-commerce businesses unlock their
          full potential by combining technology expertise, deep industry
          knowledge, and a customer-first approach. Whether you’re modernizing
          your e-commerce platform, creating immersive shopping experiences, or
          optimizing your supply chain, we have the solutions to help you
          thrive.
        </p>
        <p className="mb-4">Let’s transform retail and e-commerce together.</p>
        <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
          Contact Us
        </Link>
      </div>
    </section>
  );
}
