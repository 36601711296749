import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices } from 'react-icons/md'; 
import img1 from '../../../assets/img/Industries/banking&finance/101875.jpg'

export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Advanced Analytics and Big Data
            </h1>
            <p className="text-lg text-gray-600">
              Unlock the power of data to drive better business decisions and gain a competitive edge.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiCoinStack className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Customer Behavior Insights</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage data analytics to understand customer preferences and predict financial trends.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Real-Time Fraud Detection</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Monitor transactions in real time to detect and prevent fraudulent activities.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCloudUpload className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Regulatory Compliance Analytics</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate compliance reporting and ensure adherence to evolving regulations with advanced analytics.
              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}