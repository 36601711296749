import {
  MdOutlineAutorenew,
  MdOutlineChatBubbleOutline,
  MdOutlineViewInAr,
  MdOutlineLoyalty,
  MdSecurity,
  MdOutlineInsights,
} from "react-icons/md"; // Streamlined Model Lifecycle Management
import { MdTrendingUp } from "react-icons/md"; // Continuous Model Improvement
import { MdOutlineGavel } from "react-icons/md";
import img1 from "../../../assets/img/Industries/RetailEcommerce/4.jpg";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Analytics and Business Intelligence
            </h1>
            <p className="text-lg text-gray-600">
              Turning data into actionable insights to drive business decisions
              and performance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1: Customer Behavior Analysis */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineInsights className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Customer Behavior Analysis
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Gain actionable insights into customer preferences and buying
                patterns with advanced analytics.
              </p>
            </div>

            {/* Feature 2: Sales Performance Tracking */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Sales Performance Tracking
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Monitor sales metrics in real-time to identify trends and
                opportunities for growth.
              </p>
            </div>

            {/* Feature 3: Fraud Detection */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Fraud Detection</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enhance security and reduce fraudulent transactions with
                AI-driven anomaly detection.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
