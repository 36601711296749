import React, { useState } from 'react';
import {
  FaCloudUploadAlt,
  FaCloud,
  FaBolt,
  FaShieldAlt,
  FaChartBar,
  FaChevronRight,
  FaLightbulb,
  FaUsers,
  FaRocket,
} from 'react-icons/fa';
import { HiArrowRight, HiShare } from 'react-icons/hi';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FiLayers } from 'react-icons/fi';
import { FiMessageSquare } from 'react-icons/fi';
import ExplainableAIImg from '../../../assets/img/Blogs/ExplainableAI.jpg';
import { RiRobot2Line } from 'react-icons/ri';
import { FiBarChart } from 'react-icons/fi';
import { BiLink } from 'react-icons/bi';

const ExplainableAI = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleShare = async (platform) => {
    const url = window.location.href;
    const text = 'Check out this article about Explainable AI (XAI)!';

    switch (platform) {
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`,
          '_blank'
        );
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            url
          )}&text=${encodeURIComponent(text)}`,
          '_blank'
        );
        break;
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            url
          )}`,
          '_blank'
        );
        break;
      case 'copy':
        try {
          await navigator.clipboard.writeText(url);
          alert('Link copied to clipboard!');
        } catch (err) {
          alert('Failed to copy link.');
        }
        break;
    }
    setActiveDropdown(null);
  };

  return (
    <div className="mx-auto px-4 md:px-24 py-8 w-full max-w-7xl">
      <div className="uppercase tracking-wide text-1xl font-bold text-indigo-500">
        Blog
      </div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white">
      Demystifying AI: The Role of Explainable AI (XAI) in Building Transparent and Trustworthy Systems
      </h1>
      {/* <h2 className="mt-2 text-2xl font-semibold text-gray-700 dark:text-gray-300">
        AeroAegis Inc. Delivers Scalable, Mission-Critical Solutions for a Leading Healthcare Organization
      </h2> */}
      <div className="flex w-full justify-between mt-4 mb-8 items-center">
        <div className="flex flex-wrap gap-4">
          <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-sm font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
            <RiRobot2Line className="mr-1 h-3 w-3" />
            AI
          </span>
          <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-sm font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
            <FiMessageSquare className="mr-1 h-3 w-3" />
            Natural Language Processing (NLP)
          </span>
          <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-sm font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
            <FiBarChart className="mr-1 h-3 w-3" />
            Predictive Analytics
          </span>
          <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-900/20 px-2 py-1 text-sm font-medium text-red-800 dark:text-red-300 ring-1 ring-inset ring-red-600/20 dark:ring-red-300/20">
            <FiLayers className="mr-1 h-3 w-3" />
            Deep Learning
          </span>
        </div>
        <div className="relative">
          <button
            onClick={() => setActiveDropdown(activeDropdown ? null : 'share')}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Share"
          >
            <HiShare className="h-5 w-5" />
          </button>
          {activeDropdown === 'share' && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
              <button
                onClick={() => handleShare('facebook')}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <FaFacebook className="mr-2 h-4 w-4" />
                <span>Facebook</span>
              </button>
              <button
                onClick={() => handleShare('twitter')}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <FaTwitter className="mr-2 h-4 w-4" />
                <span>Twitter</span>
              </button>
              <button
                onClick={() => handleShare('linkedin')}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <FaLinkedin className="mr-2 h-4 w-4" />
                <span>LinkedIn</span>
              </button>
              <button
                onClick={() => handleShare('copy')}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <BiLink className="mr-2 h-4 w-4" />
                <span>Copy link</span>
              </button>
            </div>
          )}
        </div>
     
      </div>

      <div className="aspect-[16/9] relative rounded-2xl overflow-hidden">
        <img
          src={ExplainableAIImg}
          alt="Person working on tablet"
          className="object-cover w-full h-full"
        />
      </div>

      <div className="mb-8 mt-6">
        <h2 className="text-2xl font-bold mb-4">
          What is Explainable AI (XAI)?{' '}
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Explainable AI (XAI) refers to artificial intelligence systems
          designed to provide clear, understandable justifications for their
          decisions and actions. Unlike traditional AI models, which often
          operate as "black boxes," XAI aims to make AI systems more transparent
          and interpretable. It enables stakeholders, including developers,
          decision-makers, and end-users, to understand why a model made a
          specific prediction or took a certain action.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          XAI leverages techniques such as feature importance analysis,
          visualizations, and natural language explanations to provide insights
          into the decision-making process. This transparency is essential in
          sensitive and high-stakes applications like healthcare, finance, and
          autonomous systems.
        </p>
      </div>
      {/* Differences Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-6 text-gray-900">
          How Does Explainable AI Differ from Traditional AI?
        </h2>
        <div className="overflow-x-auto shadow-md rounded-lg">
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-50 text-left">
                <th className="border border-gray-300 px-4 py-3 font-semibold">
                  Aspect
                </th>
                <th className="border border-gray-300 px-4 py-3 font-semibold">
                  Traditional AI
                </th>
                <th className="border border-gray-300 px-4 py-3 font-semibold">
                  Explainable AI
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              <tr>
                <td className="border border-gray-300 px-4 py-3">
                  Transparency
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Operates as a "black box," difficult to interpret.
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Provides insights into decision-making processes.
                </td>
              </tr>
              <tr className="bg-gray-50">
                <td className="border border-gray-300 px-4 py-3">Goal</td>
                <td className="border border-gray-300 px-4 py-3">
                  Focuses on accuracy and performance.
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Balances performance with interpretability.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-3">Trust</td>
                <td className="border border-gray-300 px-4 py-3">
                  Trust is based on empirical results.
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Builds trust through clear explanations.
                </td>
              </tr>
              <tr className="bg-gray-50">
                <td className="border border-gray-300 px-4 py-3">
                  Regulations
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Often struggles to meet compliance needs in regulated
                  industries.
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Meets regulatory requirements by providing audit trails and
                  explanations.
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-3">
                  End-User Understanding
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Limited to AI developers and data scientists.
                </td>
                <td className="border border-gray-300 px-4 py-3">
                  Accessible and understandable for non-technical users.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="text-gray-700 dark:text-gray-300 my-6">
          For example, a traditional AI model might predict a credit score based
          on inputs, but XAI explains why certain factors—like payment history
          or credit utilization—contributed to the score.{' '}
        </p>
      </section>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          What Are the Pros of Explainable AI?
        </h2>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong> Increased Trust</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              One of the most significant advantages of XAI is the ability to
              build trust among users. Traditional AI systems often appear
              opaque and mysterious, leading to skepticism and resistance,
              especially in industries where decision transparency is critical.
              XAI provides clear, comprehensible explanations of why a model
              made a specific decision, enabling users to validate its accuracy
              and fairness. This trust is crucial in sensitive domains like
              healthcare, where a misdiagnosis could have life-altering
              consequences.
            </p>
          </li>
          <li className="mb-4">
            <strong> Improved Decision-Making</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              By offering deeper insights into how decisions are made, XAI
              empowers stakeholders to make better-informed choices. For
              example, in financial services, an explainable credit scoring
              model can provide lenders with detailed justifications for
              approving or rejecting a loan application. These insights can be
              used to refine policies, address customer concerns, and improve
              overall operational efficiency.
            </p>
          </li>
          <li className="mb-4">
            <strong> Bias Mitigation</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              AI systems are only as good as the data they are trained on.
              Unfortunately, biases in training data can lead to discriminatory
              outcomes. XAI helps identify and address such biases by revealing
              the factors influencing a model’s predictions. For instance, if an
              AI model for hiring disproportionately favors one demographic over
              another, explainability techniques can pinpoint the problematic
              features, allowing developers to adjust the system and promote
              fairness.
            </p>
          </li>
          <li className="mb-4">
            <strong> Regulatory Compliance</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              In an era of increasing regulatory scrutiny, compliance is
              non-negotiable for organizations deploying AI systems. Frameworks
              like the General Data Protection Regulation (GDPR) in Europe and
              other AI-specific regulations demand transparency and
              accountability. XAI provides the tools needed to generate audit
              trails and demonstrate compliance by explaining how decisions are
              made, which can protect organizations from legal liabilities and
              reputational damage.
            </p>
          </li>
          <li className="mb-4">
            <strong> Enhanced Debugging</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Debugging complex AI models can be a challenging and
              time-consuming task. XAI simplifies this process by exposing the
              inner workings of the model. By understanding why a system made a
              certain prediction, developers can quickly identify errors or
              anomalies and fine-tune the model for improved accuracy and
              reliability. This capability is especially valuable in iterative
              development cycles, where rapid feedback and adjustments are
              critical.
            </p>
          </li>
          <li className="mb-4">
            <strong> Broader Adoption</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Many industries, particularly those with strict accountability
              standards like healthcare, finance, and government, have been slow
              to adopt AI due to concerns about opacity and ethical
              implications. By demystifying AI and making it more interpretable,
              XAI paves the way for broader acceptance. Organizations can
              confidently integrate AI solutions knowing they can explain their
              outcomes to regulators, customers, and other stakeholders.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Applications of Explainable AI with Examples and Use Cases{' '}
        </h2>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Healthcare</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> XAI models can assist in diagnosing
                diseases while providing detailed justifications for their
                predictions.
              </li>
              <li>
                <strong>Use Case:</strong> An XAI system analyzing radiology
                images for cancer detection could highlight the specific regions
                of a scan that influenced its decision, such as areas with
                irregular growths. This helps radiologists verify the findings
                and increases confidence in automated diagnostics.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Finance</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> Explainable AI in fraud detection
                identifies unusual patterns in transactions and explains its
                reasoning.
              </li>
              <li>
                <strong>Use Case:</strong> A bank’s XAI-powered fraud detection
                system might flag a transaction as suspicious and justify it by
                pointing out anomalies such as large amounts being transferred
                to a new payee or a high frequency of transactions from
                different locations in a short time. This enables investigators
                to quickly assess the risk.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Legal and Compliance</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> XAI tools can assess legal documents
                and flag areas of concern with explanations
              </li>
              <li>
                <strong>Use Case:</strong> A compliance-focused XAI system might
                review contracts and highlight clauses that deviate from
                standard practices, explaining why these sections pose potential
                risks. This can save lawyers significant time in manual review
                processes.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Autonomous Vehicles</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> XAI enhances decision-making
                transparency in self-driving cars.
              </li>
              <li>
                <strong>Use Case:</strong> If an autonomous car decides to brake
                suddenly, an XAI system can explain the contributing factors,
                such as detecting a pedestrian stepping into the road. This is
                crucial for debugging, regulatory compliance, and building
                passenger trust.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Retail and Marketing</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> Personalized recommendation systems
                backed by XAI can explain their suggestions.
              </li>
              <li>
                <strong>Use Case:</strong>An e-commerce platform’s XAI system
                might suggest products and provide explanations like "You
                purchased a similar item last month" or "Customers with your
                preferences also bought this." This increases customer
                satisfaction and engagement.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Human Resources</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> XAI-powered hiring systems can
                identify top candidates and justify their rankings.
              </li>
              <li>
                <strong>Use Case:</strong> An HR system might explain that a
                particular candidate was ranked higher due to their relevant
                certifications and years of experience, helping recruiters make
                more confident hiring decisions and reducing bias concerns.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Education</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> Adaptive learning platforms use XAI to
                tailor content to individual learners.
              </li>
              <li>
                <strong>Use Case:</strong> A platform could explain why it
                recommended certain exercises for a student, such as "Based on
                your recent quiz performance, you need more practice in
                algebra." This feedback helps educators and students track
                progress effectively.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Energy Sector</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Example:</strong> XAI optimizes energy usage in smart
                grids.
              </li>
              <li>
                <strong>Use Case:</strong> A smart grid system could explain why
                it reduced energy flow to specific areas during peak hours,
                citing data such as consumption trends and grid stability
                concerns. This builds trust among stakeholders.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Bridging the Gap Between Traditional AI and XAI{' '}
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          While traditional AI delivers exceptional performance, XAI addresses
          its limitations by adding layers of interpretability. Together, these
          approaches can complement each other, enabling organizations to
          harness AI's full potential while ensuring accountability and trust.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
          Explainable AI represents the next evolution in artificial
          intelligence, focusing on transparency and accountability. While
          traditional AI remains foundational in many applications, XAI is vital
          for fostering trust, meeting compliance standards, and ensuring
          ethical deployment. As AI continues to influence every facet of our
          lives, understanding and leveraging XAI will be essential for
          responsible innovation.
        </p>
      </div>
    </div>
  );
};

export default ExplainableAI;
