import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineBlock, MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices } from 'react-icons/md'; 
import img1 from "../../../assets/img/Industries/RetailEcommerce/c.jpg"
import { SiHiveBlockchain } from "react-icons/si";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Supply Chain and Inventory Management
            </h1>
            <p className="text-lg text-gray-600">
            Efficiently managing the flow of goods, information, and inventory to meet customer demands and minimize costs.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCloudUpload className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">IoT-Enabled Tracking</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Monitor inventory in real-time with IoT sensors, ensuring accurate stock levels and efficient replenishment.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDevices className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Demand Forecasting</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Use predictive analytics to anticipate market demand and optimize inventory management.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <SiHiveBlockchain className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Blockchain for Traceability</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Ensure transparency and authenticity across the supply chain with blockchain technology.
              </p>
            </div>

      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}