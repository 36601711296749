import React from "react";
import AnimationRevealPage from "../../../../helpers/AnimationRevealPage";
import PartnershipSection from "./PartnershipSection";
import PartnersList1 from "./PartnersList1";
import PartnersList2 from "./PartnersList2";
import Section1 from "./Section1";
import Section2 from "./Section2";
import LastSection from "./LastSection";
import Section3 from "./Section3";
import FooterFinalPage from "./FooterFinalPage";

export default function index() {
  return (
    <div>
      <PartnershipSection /> 
      <PartnersList1 /> 
      <PartnersList2 />
      <AnimationRevealPage>
        <Section1 /> 
        <Section2 /> 
        <Section3 /> 
        <LastSection /> 
      </AnimationRevealPage>
      <FooterFinalPage />
    </div>
  );
}
