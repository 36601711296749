import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo_img from "../assets/img/logo.png";
import { RiArrowDropDownLine } from "react-icons/ri";

import {
  FaBars,
  FaTimes,
  FaChevronRight,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";



const MenuItem = ({ item, onNavigate }) => {
  const hasSubItems = item.items && item.items.length > 0;
  const hasLinks = item.links && item.links.length > 0;
  const hasSingleSubmenu = hasSubItems && item.items.length === 1;

  const handleClick = () => {
    if (hasSingleSubmenu) {
      onNavigate(item.items[0]);
    } else {
      onNavigate(item);
    }
  };

  return (
    <div className="py-3 border-b">
      <div
        className="flex items-center justify-between cursor-pointer text-xl"
        onClick={handleClick}
      >
        <span className="font-semibold">{item.title}</span>
        {
          //(hasSubItems && !hasSingleSubmenu)
          hasSubItems || hasLinks ? (
            <FaChevronRight className="text-gray-600" />
          ) : null
        }
      </div>
    </div>
  );
};

const MobileMenu = ({ menuData, isSticky, location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuStack, setMenuStack] = useState([
    { title: "Menu", items: menuData },
  ]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const appDiv = document.querySelector(".App");
    if (appDiv) {
      if (isMenuOpen) {
        appDiv.classList.remove("overflow-auto");
        appDiv.classList.add("overflow-hidden");
      } else {
        appDiv.classList.remove("overflow-hidden");
      }
    }
  }, [isMenuOpen]);

  const navigateToSubMenu = (item) => {
    if (item.items) {
      setMenuStack([...menuStack, item]);
    } else if (item.links) {
      setMenuStack([...menuStack, { title: item.title, links: item.links }]);
    }
  };

  const navigateBack = () => {
    if (menuStack.length > 1) {
      setMenuStack(menuStack.slice(0, -1));
    }
  };

  const currentMenu = menuStack[menuStack.length - 1];

  return (
    <div className="relative">
      <button
        onClick={toggleMenu}
        className={`text-2xl p-2 focus:outline-none relative z-[10000] ${
          location?.pathname === "/" && !isSticky
            ? isMenuOpen
              ? "text-gray-800 hover:text-blue-900"
              : "text-white"
            : "text-gray-800 hover:text-blue-900"
        }`}
      >
        {isMenuOpen ? <FaXmark size={24} /> : <FaBars size={24} />}
      </button>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-white z-[9999] h-[100dvh] overflow-y-auto p-4">
          <div className="h-full flex flex-col">
            <div className="flex-grow">
              <div className="mt-16">
                {menuStack.length > 1 && (
                  <button
                    onClick={navigateBack}
                    className="mb-4 flex items-center text-blue-600 absolute top-2 text-lg left-2"
                  >
                    <FaChevronRight className="transform rotate-180 mr-2" />
                    Back
                  </button>
                )}
                <h2 className="text-3xl font-bold mb-4">{currentMenu.title}</h2>
                {currentMenu.items ? (
                  currentMenu.items.map((item, index) => (
                    <MenuItem
                      key={index}
                      item={item}
                      onNavigate={navigateToSubMenu}
                    />
                  ))
                ) : currentMenu.links ? (
                  <div>
                    {currentMenu.links.map((link, index) => (
                      <Link
                        onClick={() => {
                          setIsMenuOpen(false);
                          setMenuStack([{ title: "Menu", items: menuData }]);
                        }}
                        key={index}
                        to={link.href}
                        className="block py-2 text-blue-600 hover:underline flex items-center justify-between"
                      >
                        {link.text}
                        <FaExternalLinkAlt className="text-gray-600" />
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-center">
              <Link
                onClick={() => {
                  setIsMenuOpen(false);
                  setMenuStack([{ title: "Menu", items: menuData }]);
                }}
                to="/contact-us"
                className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#252f80] hover:bg-slate-800 focus:shadow-outline focus:outline-none"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
 
  // dropdown disapper
  const handleClick = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };
  // Sticky Header
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isScrolled = scrollPosition > 50;

      setIsSticky(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // // Force user to use mobile viewport when on mobile
  // useEffect(() => {
  //   const viewport = document.getElementsByName("viewport")[0];
  //   if (isMobile) {
  //     viewport.setAttribute(
  //       "content",
  //       "width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no"
  //     );
  //   } else {
  //     viewport.setAttribute("content", "width=device-width,initial-scale=1");
  //   }
  // }, [isMobile]);

  const headerClass = isSticky
    ? "fixed top-0 left-0 w-full bg-base-100/80 backdrop-blur  shadow-md z-50  transition-all duration-300 ease-in-out transform translate-y-0 "
    : location?.pathname === "/"
    ? "bg-transparent backdrop-blur z-50 relative"
    : "";

  const menuData = [
    {
      title: "About Us",
      items: [
        {
          title: "Discover About Us",
          links: [
            { text: "Discover AeroAegis", href: "/discover-aeroaegis" },
            { text: "Our Capabilities", href: "/aeroaegis-capabilities" },
          ],
        },
        {
          title: "Our Values & Teams",
          links: [
            { text: "Leadership & Values", href: "/leadership-team" },
            {
              text: "Partnership & Collaborations",
              href: "/partnerships-collaborations",
            },
          ],
        },
        {
          title: "Investor Relations",
          links: [{ text: "Investor Relation", href: "/investor-relations" }],
        },
        {
          title: "Careers",
          links: [{ text: "Join Our Team", href: "/careers" }],
        },
      ],
      image: {
        src: "https://images.unsplash.com/photo-1551434678-e076c223a692",
        alt: "About Us",
        title: "About Us",
        description: "Get to know our mission, vision, and values",
      },
    },
    {
      title: "Products & Platforms",
      items: [
        {
          title: "Cloud Transformation & Resilience",
          links: [
            { text: "Maturity Spectrum 360", href: "/maturity-spectrum-360" },
            { text: "SmartChaos Lab", href: "/smartchaos-lab" },
          ],
        },
        {
          title: "AI-Driven Insights & Automation",
          links: [
            { text: "AeroGenix", href: "/aero-genix" },
            { text: "AeroPulse AI", href: "/aeropulse-ai" },
            { text: "AeroInfra Vue", href: "/infra-vue" },
          ],
        },
        {
          title: "Industrial IoT & Edge Intelligence",
          links: [{ text: "AeroEdge IIoT", href: "/aeroedge-iiot" }],
        },
        {
          title: "Products",
          links: [{ text: "View all Products", href: "/products" }],
        },
      ],
      image: {
        src: "https://images.unsplash.com/photo-1518770660439-4636190af475",
        alt: "Cloud Solutions",
        title: "Products & Platforms",
        description: "Discover our cutting-edge products",
        link: "/products",
      },
    },
    // {
    //   title: "Solutions",
    //   items: [
    //     {
    //       title: "Our Offerings",
    //       links: [
    //         { text: "AI & ML Solutions", href: "/ai-ml-solutions" },
    //         { text: "Data Insights & Analytics", href: "/data-insights-analytics" },
    //         { text: "Blockchain Solution", href: "/blockchain-solution" },
    //       ],
    //     },
    //   ],
    //   image: {
    //     src: "https://images.unsplash.com/photo-1498050108023-c5249f4df085",
    //     alt: "Solutions",
    //     title: "Innovative Solutions",
    //     description: "Tailored solutions for your unique challenges",
    //   },
    // },
    {
      title: "Engineering Excellence",
      items: [
        {
          title: "Engineering Excellence",
          links: [
            {
              text: "R&D Focus",
              href: "/engineering-excellence/research-and-development-focus",
            },
            {
              text: "Product Engineering Processes",
              href: "/engineering-excellence/product-engineering",
            },
            {
              text: "IP Development",
              href: "/engineering-excellence/ip-development",
            },
            {
              text: "Centers of Excellence (COEs)",
              href: "/engineering-excellence/center-of-excellence",
            },
          ],
        },
      ],
      image: {
        src: "https://images.unsplash.com/photo-1498050108023-c5249f4df085",
        alt: "Solutions",
        title: "Engineering Excellence",
        description: "Tailored solutions for your unique engineering needs",
      },
    },
    {
      title: "Services",

      items: [
        {
          title: "Product Engineering",

          links: [
            {
              text: "Custom Product Development",

              href: "/services/product-engineering/custom-product-development",
            },

            {
              text: "Cloud-Native Product Engineering",

              href: "/services/product-engineering/cloud-native-product-engineering",
            },

            {
              text: "AI/ML-Embedded Product Solutions",

              href: "/services/product-engineering/embedded-product-solutions",
            },

            {
              text: "IoT-Enabled Product Engineering",

              href: "/services/product-engineering/iot-enabled-product-engineering",
            },
          ],
        },

        {
          title: "Strategic Consulting",

          links: [
            {
              text: "Digital Transformation Consulting",

              href: "/services/strategic-consulting/digital-transformation",
            },

            {
              text: "AI and Data Strategy Consulting",

              href: "/services/strategic-consulting/ai-and-data",
            },

            {
              text: "Cloud Strategy & Migration Consulting",

              href: "/services/strategic-consulting/cloud-migration",
            },

            {
              text: "Blockchain Advisory Services",

              href: "/services/strategic-consulting/blockchain-advisory",
            },
          ],
        },

        {
          title: "System Integration ",

          links: [
            {
              text: "Enterprise Application Integration",

              href: "/services/system-integration/enterprise-application-integration",
            },

            {
              text: "Cloud Migration and Integration",

              href: "/services/system-integration/cloud-migration-integration",
            },

            {
              text: "Automation & RPA Solutions",

              href: "/services/system-integration/system-rpa-solutions",
            },

            {
              text: "API and Microservices Integration",

              href: "/services/system-integration/api-microservices-integration",
            },
          ],
        },

        {
          title: "Operational Excellence",

          links: [
            {
              text: "DevOps & Site Reliability Engineering (SRE)",

              href: "/services/operational-excellence/devops-and-sre",
            },

            {
              text: "Chaos Engineering Services",

              href: "/services/operational-excellence/chaos-engineering-solutions",
            },

            {
              text: "Performance Optimization",

              href: "/services/operational-excellence/performance-optimization",
            },

            {
              text: "Managed Services & Support",

              href: "/services/operational-excellence/managed-service-support",
            },
          ],
        },

        {
          title: "AI and Data ",

          links: [
            {
              text: "AI/ML Model Development",

              href: "/services/ai-data/ai-ml-model-development",
            },

            {
              text: "Data Analytics & Insights",

              href: "/services/ai-data/data-analytics-insights",
            },

            {
              text: "Data Engineering Services",

              href: "/services/ai-data/data-engineering",
            },

            {
              text: "Generative AI Solutions",

              href: "/services/ai-data/generative-ai-solutions",
            },
          ],
        },

        {
          title: "IoT & Edge Solutions",

          links: [
            {
              text: "Smart Infrastructure Deployment",

              href: "/services/iot-edge-solutions/smart-infra-deployment",
            },

            {
              text: "Edge Computing Integration",

              href: "/services/iot-edge-solutions/edge-computing-integration",
            },

            {
              text: "Predictive Maintenance Services",

              href: "/services/iot-edge-solutions/predictive-maintainance-service",
            },

            {
              text: "Digital Twin Solutions",

              href: "/services/iot-edge-solutions/digital-twin-solutions",
            },
          ],
        },
      ],

      image: {
        src: "https://images.unsplash.com/photo-1521791136064-7986c2920216",

        alt: "Our Services",

        title: "Expert Services",

        description: "End-to-end solutions for your business needs",
      },
    },

    {
      title: "Industries",
      items: [
        {
          title: "Industries",
          links: [
            { text: "Healthcare", href: "/industries/healthcare" },
            { text: " Manufacturing", href: "/industries/manufacturing" },
            {
              text: "Logistics & Supply Chain",
              href: "/industries/logistics-and-supplychain",
            },
            { text: "Finance & Banking", href: "/industries/finance-banking" },
            {
              text: "Retail & E-Commerce",
              href: "/industries/retail-ecommerce",
            },
            { text: "Aerospace", href: "/industries/aerospace" },
            {
              text: "Telecom & Networks",
              href: "/industries/telecom-and-network",
            },
            {
              text: "Energy & Utilities",
              href: "/industries/energy-utilities",
            },
            {
              text: "Pharmaceuticals & Life Sciences",
              href: "/industries/pharmaceuticals-life-sciences",
            },
            {
              text: "Automotive",
              href: "/industries/automotive",
            },
          ],
        },
      ],
      image: {
        src: "https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?q=80&w=1473&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        alt: "Industries",
        title: "Industry Expertise",
        description: "Explore tailored solutions for a variety of industries",
      },
    },
    {
      title: "Resources",
      items: [
        {
          title: "Resources",
          links: [
            { text: "Blogs", href: "/blogs" },
            { text: "Whitepapers", href: "/white-papers" },
            { text: "Case Studies", href: "/case-studies" },
          ],
        },
      ],
      image: {
        src: "https://images.unsplash.com/photo-1519337265831-281ec6cc8514",
        alt: "Resources",
        title: "Explore Resources",
        description: "Access our knowledge base to stay informed",
      },
    },
  ];

  const MegaMenuItem = ({ title, items, image }) => {
    return (
      <div className="group">
        <button
          className={`
           flex items-center space-x-1 py-2 font-medium transition-colors
           ${
             location?.pathname === "/" && !isSticky
               ? "text-white hover:text-purple-100"
               : "text-gray-700  hover:text-blue-900"
           }
           `}
        >
          <span>{title}</span>
          <RiArrowDropDownLine className="h-6 w-6" />
        </button>
        <div className="max-h-[500px] overflow-auto absolute left-1/2 -translate-x-1/2 top-[110%] min-w-full w-auto rounded-lg bg-white shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50 transform translate-y-2 group-hover:translate-y-0">
          <div className="container mx-auto py-12 px-8">
            <div className="grid grid-cols-12 gap-12">
              <div className="col-span-8">
                <div
                  className={`${
                    items?.length <= 1
                      ? "grid-cols-2 gap-6"
                      : items?.length === 2
                      ? "grid grid-cols-2 gap-8"
                      : "grid grid-cols-3 gap-12"
                  }`}
                >
                  {items.map((section, idx) => (
                    <div key={idx} className="space-y-4">
                      <h3 className="font-semibold mr-10 text-gray-900 text-lg border-b border-gray-100 pb-3">
                        {section.title}
                      </h3>
                      <ul
                        className={`space-y-2 ${
                          items?.length <= 1 ? "text-lg" : ""
                        }`}
                      >
                        {section.links.map((link, linkIdx) => (
                          <li key={linkIdx}>
                            <Link
                              to={link.href}
                              className="text-gray-600 hover:text-blue-900 flex items-center justify-between hover:bg-gray-200 px-2 rounded-md cursor-pointer group/item py-1.5"
                            >
                              <span className="group-hover/item:translate-x-1 transition-transform duration-200">
                                {link.text}
                              </span>
                              <FaExternalLinkAlt className="h-4 w-4 opacity-100 ml-1.5" />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              {image && (
                <Link to={image?.link || "#"} className="col-span-4 h-full">
                  <div className="h-full max-h-[400px]">
                    <div className="relative h-full w-full rounded-xl overflow-hidden shadow-lg">
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="object-cover h-full w-full transform hover:scale-105 transition-transform duration-700"
                      />
                      <div className="absolute inset-0 bg-gradient-to-tr from-blue-900/90 to-transparent">
                        <div className="absolute bottom-0 left-0 p-8 h-full text-white">
                          <h4 className="text-2xl font-bold">{image.title}</h4>
                          <p className="mt-3 text-gray-100">
                            {image.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={headerClass}>
      <div className="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-2">
        <div className="relative flex items-center w-full justify-between">
          <Link to="/">
            {/* {
              isSticky && (
                <img src={logo_img} className=" w-24 lg:w-36 "/>
              ) || location?.pathname === "/" && (
                <div 
                className="my-1 px-3.5 py-0.5 rounded-full bg-white scale-90 border-l-2 border-[#222f81]"
                >
                   <img src={logo_img} className=" w-24 h-12  "/>
                </div>
              ) || (
                <img src={logo_img} className=" w-24 lg:w-36 "/>
              )
            } */}

            <img src={logo_img} className=" w-24 lg:w-24 xl:w-36 " />
          </Link>

          <nav className="hidden lg:flex items-center space-x-8 md:space-x-2 lg:space-x-3 xl:space-x-8 overflow-hidden">
            {menuData.map((item, index) => (
              <MegaMenuItem key={index} {...item} />
            ))}
          </nav>

          <div className="hidden lg:flex items-center space-x-8">
            <Link
              to="/contact-us"
              className="inline-flex items-center justify-center h-12 lg:h-9 lg:text-sm xl:text-md xl:h-12 px-6 lg:px-3  xl:px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#252f80] hover:bg-slate-800 focus:shadow-outline focus:outline-none"
            >
              Contact Us
            </Link>
          </div>

          <div className="lg:hidden">
            <MobileMenu
              menuData={menuData}
              isSticky={isSticky}
              location={location}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
