
import img1 from '../../../../assets/img/Services/IotEdgeSolution/processOptimisation.jpg'; 

import { GoGraph } from "react-icons/go";
import { BiAnalyse } from "react-icons/bi";
import { FiAlertCircle } from "react-icons/fi";

import { BiSolidCity } from "react-icons/bi";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Process Optimization
            </h1>
            <p className="text-lg text-gray-600">
            Leverage predictive models to refine workflows, production lines, and infrastructure systems. Achieve higher efficiency and resource utilization.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GoGraph className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Predictive Workflow Refinement</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Optimize workflows with data-backed predictive modeling.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiAnalyse className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Production Line Efficiency
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Streamline operations for maximum throughput and quality.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiSolidCity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Sustainable Infrastructure
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enhance infrastructure management with smarter optimization strategies.
              </p>
            </div> 

              {/* Feature 4 */}
             {/*  <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoLockClosedOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Scalable Solutions
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Scale your CI/CD pipeline to meet the growing demands of your
                projects.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
