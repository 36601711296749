import React from 'react'
import img from '../../../assets/img/discover_aeroaegis/tail.jpg'
export default function FinalSection() {
  return (
    <div>
          <div className="text-center px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:flex-row">
      <div className="max-w-xl mb-2 md:mx-auto sm:text-center lg:max-w-4xl ">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-400">
          
          </p>
        </div>
        <h2 className=" max-w-full  lg:text-2xl font-semibold leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="34f481be-159a-4846-821d-9ca19fb6bcc5"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#34f481be-159a-4846-821d-9ca19fb6bcc5)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative"> When </span>
          </span>{' '}
          you choose AeroAegis, you're not just adopting a solution; you're joining forces with a team of innovators, experts, and partners who are dedicated to shaping your digital future. Embrace transformation with confidence – choose AeroAegis as your trusted ally on the journey to success.
        </h2>
      
      </div>
        
      </div>
      <div className="relative">
        <img
          className="object-cover w-full h-56 sm:h-96"
          src={img}
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50" />
      </div>
    </div>
  )
}
