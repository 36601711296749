import HeroSection from "./HeroSection";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import LastSection from "./LastSection";
import Leaderships from "./Leaderships";
import Section5 from "./Section5";

function Azure() { 
  return ( 
    <>
      <HeroSection />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      {/* <Leaderships /> */}
      <LastSection />
    </>
  );
}
export default Azure;
