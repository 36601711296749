import React from "react";
import main_section_img from "../../../assets/img/Products/AeroPerformai/landingImg.jpg";
import { MdKeyboardArrowDown } from "react-icons/md";
export default function Section1() {
  return (
    <div>
      {/* <section class=" mt-5 px-4  pt-20 pb-20 relative">
        <div
          class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
          style={{
            backgroundImage: `url($ {main_section_img})`,
          }}
        ></div>
        <div className="max-w-2xl mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
          <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
          AeroPulse AI is your next-generation partner in cloud performance management. Designed to seamlessly integrate with any cloud environment, AeroPulse AI offers a comprehensive solution for monitoring, predicting, and optimizing the performance of your cloud-hosted applications and systems. Through cutting-edge artificial intelligence and machine learning technologies, AeroPulse AI ensures your infrastructure operates at peak efficiency, enabling your business to thrive in today's digital landscape.
          </p>
        </div>
      </section> */}
      <section
        className="relative text-white py-20 md:py-10 bg-cover bg-center"
        style={{
          backgroundImage: `url(${main_section_img})`,
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="container mx-auto px-4 text-center relative z-10">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Transform Workflows with Agentic AI
          </h1>
          <p className="text-xl mb-8">
          AeroGenix is the ultimate platform for enterprises to create, deploy, and scale Generative AI (GenAI) and Agentic AI solutions seamlessly. Whether you aim to automate repetitive tasks, enhance decision-making, or supercharge customer experiences, AeroGenix equips you with the tools to harness the full power of AI—your way.
          </p>
          <div className=" flex justify-center items-center flex-col space-y-2">
            <button
              to={"/contact-us"}
              className="bg-white cursor-pointer text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300"
            >
              Explore more
            </button>
            <MdKeyboardArrowDown
              className=" text-white animate-bounce "
              size={20}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
