import React, { useState } from 'react';
import AgenticAIImg from "../../../assets/img/Blogs/AgenticAI.jpg"
import {
  FaCloudUploadAlt,
  FaCloud,
  FaBolt,
  FaShieldAlt,
  FaChartBar,
  FaChevronRight,
  FaLightbulb,
  FaUsers,
  FaRocket,
} from 'react-icons/fa';
import { HiArrowRight, HiShare } from 'react-icons/hi';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { RiRobot2Line } from "react-icons/ri";
import { FiLayers } from "react-icons/fi";
import { FiMessageSquare } from "react-icons/fi";
import { FiTrendingUp } from "react-icons/fi";
import { FiRepeat } from "react-icons/fi";
import { BiLink } from 'react-icons/bi';

const AgenticAI = () => {
    const [activeDropdown, setActiveDropdown] = useState(null);
  
    const handleShare = async (platform) => {
      const url = window.location.href;
      const text = 'Check out this article about Agentic AI!';
  
      switch (platform) {
        case 'facebook':
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              url
            )}`,
            '_blank'
          );
          break;
        case 'twitter':
          window.open(
            `https://twitter.com/intent/tweet?url=${encodeURIComponent(
              url
            )}&text=${encodeURIComponent(text)}`,
            '_blank'
          );
          break;
        case 'linkedin':
          window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              url
            )}`,
            '_blank'
          );
          break;
        case 'copy':
          try {
            await navigator.clipboard.writeText(url);
            alert('Link copied to clipboard!');
          } catch (err) {
            alert('Failed to copy link.');
          }
          break;
      }
      setActiveDropdown(null);
    };

  return (
    <div className="mx-auto px-4 md:px-24 py-8 w-full max-w-7xl">
      <div className="uppercase tracking-wide text-1xl font-bold text-indigo-500">
        Blog
      </div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white">
        Agentic AI: Redefining Autonomy in Artificial Intelligence
      </h1>
      {/* <h2 className="mt-2 text-2xl font-semibold text-gray-700 dark:text-gray-300">
        AeroAegis Inc. Delivers Scalable, Mission-Critical Solutions for a
        Leading Healthcare Organization
      </h2> */}
      <div className='flex justify-between mt-4 mb-8 items-center'>
      <div className="flex flex-wrap gap-4">
        <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-sm font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
          <RiRobot2Line className="mr-1 h-3 w-3" />
          AI
        </span>
        <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-sm font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
          <FiTrendingUp className="mr-1 h-3 w-3" />
          Machine Learning
        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-sm font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
          <FiMessageSquare className="mr-1 h-3 w-3" />
          Natural Language Processing (NLP)
        </span>
        <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-900/20 px-2 py-1 text-sm font-medium text-red-800 dark:text-red-300 ring-1 ring-inset ring-red-600/20 dark:ring-red-300/20">
          <FiRepeat className="mr-1 h-3 w-3" />
          Reinforcement Learning
        </span>
        <span className="inline-flex items-center rounded-md bg-purple-50 dark:bg-purple-900/20 px-2 py-1 text-sm font-medium text-purple-800 dark:text-purple-300 ring-1 ring-inset ring-purple-600/20 dark:ring-purple-300/20">
          <FiLayers className="mr-1 h-3 w-3" />
          Advanced AI
        </span>
      </div>
      <div className="relative">
                <button
                  onClick={() => setActiveDropdown(activeDropdown ? null : 'share')}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  aria-label="Share"
                >
                  <HiShare className="h-5 w-5" />
                </button>
                {activeDropdown === 'share' && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
                    <button
                      onClick={() => handleShare('facebook')}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <FaFacebook className="mr-2 h-4 w-4" />
                      <span>Facebook</span>
                    </button>
                    <button
                      onClick={() => handleShare('twitter')}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <FaTwitter className="mr-2 h-4 w-4" />
                      <span>Twitter</span>
                    </button>
                    <button
                      onClick={() => handleShare('linkedin')}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <FaLinkedin className="mr-2 h-4 w-4" />
                      <span>LinkedIn</span>
                    </button>
                    <button
                      onClick={() => handleShare('copy')}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <BiLink className="mr-2 h-4 w-4" />
                      <span>Copy link</span>
                    </button>
                  </div>
                )}
              </div>
      </div>

      <div className="aspect-[16/9] relative rounded-2xl overflow-hidden">
        <img
          src={AgenticAIImg}
          alt="Person working on tablet"
          className="object-cover w-full h-full"
        />
      </div>

      <div className="mb-8 mt-6">
        <h2 className="text-2xl font-bold mb-4">What is Agentic AI? </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Agentic AI refers to artificial intelligence systems designed to act
          autonomously to achieve specific goals within predefined boundaries.
          These systems possess the ability to sense their environment, make
          decisions, and take actions without constant human intervention.
          Unlike conventional AI models, which require user inputs to function,
          agentic AI operates as an independent entity, capable of learning from
          its surroundings and adapting its strategies to achieve better
          outcomes over time.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          At the core of Agentic AI lies the concept of agency, which implies
          autonomy, goal-directed behavior, and the ability to make decisions.
          These AI systems leverage advanced technologies such as machine
          learning, natural language processing (NLP), and reinforcement
          learning to function effectively in dynamic environments.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          How Does Agentic AI Differ from Traditional AI?{' '}
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          While both Agentic AI and traditional AI rely on sophisticated
          algorithms and computational models, they differ significantly in
          their design, functionality, and applications:
        </p>
        <ol
          className="list-decimal list-inside text-gray-700 dark:text-gray-300 mb-4"
          type="1"
        >
          <li className="mb-4">
            <strong>Autonomy:</strong>
            <ul className="list-disc list-inside ml-6 space-y-1.5">
              <li>
                Traditional AI systems typically require human intervention to
                initiate tasks or interpret outputs. They operate as tools that
                assist users in achieving specific objectives.
              </li>
              <li>
                Agentic AI systems are autonomous entities capable of
                independently sensing, deciding, and acting to achieve goals
                without requiring ongoing human oversight.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Decision-Making:</strong>
            <ul className="list-disc list-inside ml-6 space-y-1.5">
              <li>
                Traditional AI follows predefined rules or models to generate
                results. It relies heavily on structured inputs and cannot
                easily adapt to unexpected scenarios.
              </li>
              <li>
                Agentic AI employs dynamic decision-making processes, often
                leveraging reinforcement learning or other adaptive techniques
                to respond to changing environments.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Learning Capabilities:</strong>
            <ul className="list-disc list-inside ml-6 space-y-1.5">
              <li>
                Traditional AI systems are often static, with their learning
                limited to the training phase. Once deployed, their ability to
                improve or adapt is restricted.
              </li>
              <li>
                Agentic AI continuously learns and refines its behavior in
                real-time, enabling it to handle evolving tasks and environments
                effectively.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Goal Orientation:</strong>
            <ul className="list-disc list-inside ml-6 space-y-1.5">
              <li>
                Traditional AI is task-specific, designed to excel at a
                particular function (e.g., image recognition, language
                translation). It requires reconfiguration or retraining for new
                tasks.
              </li>
              <li>
                Agentic AI is goal-oriented and capable of generalizing its
                knowledge to pursue different objectives, often within a broader
                operational framework.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Interactivity:</strong>
            <ul className="list-disc list-inside ml-6 space-y-1.5">
              <li>
                Traditional AI primarily interacts with humans, responding to
                specific inputs and providing outputs accordingly.
              </li>
              <li>
                Agentic AI interacts with both humans and other systems, forming
                complex networks of autonomous agents that collaborate to
                achieve larger goals.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Adaptability: </strong>
            <ul className="list-disc list-inside ml-6 space-y-1.5">
              <li>
                Traditional AI struggles in unstructured or unpredictable
                environments, as it depends on predefined data and parameters.
              </li>
              <li>
                Agentic AI thrives in dynamic and unstructured settings by
                adapting its strategies and actions based on real-time feedback
                and contextual understanding.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Pros of Agentic AI</h2>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4 text-gray-700 dark:text-gray-300 mt-2">
            <strong>Autonomy</strong>
            <p className="ml-4">
              Agentic AI systems can operate independently, reducing the need
              for constant human oversight. This autonomy allows businesses to
              delegate complex and time-consuming tasks to AI, freeing up human
              resources for more strategic roles. For example, autonomous
              warehouse robots can manage inventory logistics without requiring
              continuous input from human operators
            </p>
          </li>
          <li className="mb-4 text-gray-700 dark:text-gray-300 mt-2">
            <strong>Scalability</strong>
            <p className="ml-4">
              These systems can handle multiple tasks simultaneously across
              various environments, enabling businesses to scale their
              operations effectively. For instance, a fleet of autonomous
              delivery drones can be deployed to manage logistics across
              multiple regions, meeting demand without significant additional
              infrastructure.
            </p>
          </li>
          <li className="mb-4 text-gray-700 dark:text-gray-300 mt-2">
            <strong>Adaptability</strong>
            <p className="ml-4">
              Through continuous learning and feedback mechanisms, Agentic AI
              adapts to changing conditions and improves its performance over
              time. For example, an AI system managing a smart grid can adjust
              to fluctuations in energy demand and supply, optimizing
              distribution in real-time.
            </p>
          </li>
          <li className="mb-4 text-gray-700 dark:text-gray-300 mt-2">
            <strong>Cost Efficiency</strong>
            <p className="ml-4">
              By automating repetitive and resource-intensive processes, these
              systems help organizations save on labor costs and reduce errors.
              For instance, manufacturing robots with agentic capabilities can
              streamline production lines, minimize waste, and lower operational
              expenses.
            </p>
          </li>
          <li className="mb-4 text-gray-700 dark:text-gray-300 mt-2">
            <strong>24/7 Availability</strong>
            <p className="ml-4">
              Unlike humans, Agentic AI systems can function around the clock,
              ensuring uninterrupted operations. This is particularly valuable
              in industries like customer service, where AI chatbots and virtual
              assistants provide support at any time, improving customer
              satisfaction and reducing response times.
            </p>
          </li>
          <li className="mb-4 text-gray-700 dark:text-gray-300 mt-2">
            <strong>Decision Support</strong>
            <p className="ml-4">
              These systems analyze vast amounts of data to offer actionable
              insights and recommendations. For example, in financial markets,
              Agentic AI systems can identify emerging trends and suggest
              investment strategies, enabling informed and timely
              decision-making.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Applications of Agentic AI </h2>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Healthcare: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Autonomous Diagnostics:</strong> AI systems can analyze
                medical images (e.g., X-rays, MRIs) and detect abnormalities
                such as tumors or fractures with high precision. For instance,
                DeepMind’s AI has demonstrated success in identifying eye
                diseases from retinal scans.
              </li>
              <li>
                <strong>Remote Patient Monitoring:</strong> Wearable devices
                integrated with Agentic AI can track patient vitals like heart
                rate, glucose levels, or blood pressure in real-time and alert
                healthcare providers of any anomalies. This can reduce emergency
                room visits and improve chronic disease management.
              </li>
              <li>
                <strong>Surgical Assistance: </strong> Robotic systems like da
                Vinci Surgical System utilize Agentic AI to assist surgeons in
                performing minimally invasive procedures with enhanced
                precision.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Finance: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Algorithmic Trading:</strong> Agentic AI can analyze
                market data, identify patterns, and execute trades autonomously
                to maximize returns. For example, Renaissance Technologies
                employs AI-driven models for high-frequency trading.
              </li>
              <li>
                <strong>Fraud Detection:</strong> These systems can continuously
                monitor transactions and flag suspicious activities, such as
                unauthorized access or unusual spending patterns, helping banks
                prevent financial fraud.
              </li>
              <li>
                <strong>Customer Risk Profiling: </strong> AI agents can analyze
                credit histories and behavioral data to determine loan
                eligibility or insurance premiums.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Customer Service: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Intelligent Virtual Assistants:</strong> Chatbots
                powered by Agentic AI, such as those used by Amazon or Google,
                can handle complex customer inquiries, resolve issues, and
                personalize responses without human intervention.
              </li>
              <li>
                <strong>Sentiment Analysis:</strong> AI systems can analyze
                customer interactions to gauge satisfaction levels and provide
                feedback for service improvement.
              </li>
              <li>
                <strong>Automated Ticketing: </strong> Companies like Zendesk
                use AI to sort, prioritize, and respond to support tickets,
                reducing resolution times.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Transportation: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Autonomous Vehicles:</strong> Self-driving cars, like
                those developed by Tesla or Waymo, use Agentic AI to navigate
                complex traffic scenarios, avoid collisions, and optimize
                routes.
              </li>
              <li>
                <strong>Fleet Management:</strong> Logistics companies like DHL
                use AI systems to monitor delivery routes, predict delays, and
                optimize fuel consumption.
              </li>
              <li>
                <strong>Traffic Management: </strong> Smart cities leverage AI
                to manage traffic signals dynamically, reducing congestion and
                improving commute times.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Manufacturing: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Predictive Maintenance:</strong> Companies like GE and
                Siemens deploy Agentic AI systems to monitor machinery
                performance and predict potential breakdowns, minimizing
                downtime.
              </li>
              <li>
                <strong>Quality Control:</strong> Vision-based AI systems can
                inspect products on assembly lines to detect defects in
                real-time, ensuring high standards.
              </li>
              <li>
                <strong>Supply Chain Optimization: </strong> AI agents can
                monitor inventory levels and automatically reorder supplies when
                necessary.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Retail: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Dynamic Pricing:</strong> Retail giants like Amazon use
                AI to adjust product prices based on demand, competition, and
                stock levels.
              </li>
              <li>
                <strong>Personalized Recommendations:</strong> AI systems
                analyze customer purchase history to suggest products,
                increasing sales and improving user experience.
              </li>
              <li>
                <strong>Store Automation: </strong> Unstaffed stores like Amazon
                Go utilize AI to manage inventory, billing, and security
                autonomously.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Education: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Personalized Learning Paths:</strong>Platforms like Khan
                Academy use AI to assess a student’s progress and adapt content
                to suit their learning pace and style.
              </li>
              <li>
                <strong>Automated Grading:</strong> AI agents can grade essays,
                quizzes, and assignments, providing instant feedback to
                students.
              </li>
              <li>
                <strong>Virtual Tutors: </strong> AI-driven chatbots can assist
                students in clarifying doubts and improving subject
                comprehension.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Environmental Monitoring: </strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2">
              <li>
                <strong>Wildlife Conservation:</strong> Drones equipped with
                Agentic AI can monitor endangered species, track migration
                patterns, and detect poaching activities.
              </li>
              <li>
                <strong>Climate Risk Prediction:</strong> AI systems analyze
                weather data to forecast events like hurricanes or droughts,
                aiding in disaster preparedness.
              </li>
              <li>
                <strong>Sustainable Farming: </strong> Autonomous tractors and
                irrigation systems optimize resource usage, increasing crop
                yield and reducing waste.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
          Agentic AI is transforming industries by enabling machines to operate
          autonomously, make decisions, and continuously adapt to their
          environments. Its applications span diverse domains, from healthcare
          to manufacturing, showcasing its potential to enhance efficiency,
          accuracy, and scalability. However, with great power comes great
          responsibility. The development and deployment of Agentic AI must
          prioritize ethical considerations, transparency, and accountability to
          ensure that these systems serve humanity's best interests. As we move
          forward, Agentic AI is poised to redefine how we interact with
          technology, unlocking new possibilities and challenges for society.
        </p>
      </div>
    </div>
  );
};

export default AgenticAI;
