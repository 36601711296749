import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineAutorenew } from "react-icons/md"; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from "react-icons/md"; // Continuous Model Improvement
import { MdOutlineGavel } from "react-icons/md";
import { WiCloudRefresh } from "react-icons/wi"; //Cloud
import img1 from "../../../assets/img/Industries/Manufacturing/cloud-storage.jpg";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Cloud Network"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Cloud-Native Manufacturing Platforms
            </h1>
            <p className="text-lg text-gray-600">
              Empowering manufacturers with scalable, agile, and secure
              solutions to optimize production, enhance efficiency, and drive
              innovation in a connected world.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineAutorenew className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Unified Operations
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Integrate production, inventory, and logistics with cloud-native
                platforms for seamless collaboration across geographies.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Scalable Infrastructure
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Handle increasing data volumes from connected devices and
                systems without disruption.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <WiCloudRefresh className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Disaster Recovery
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Safeguard operations with robust cloud-based disaster recovery
                solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
