import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdOutlineLightbulb } from 'react-icons/md';
import img1 from "../../../assets/img/Industries/Energy/smart-grid.jpg";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { PiSpeedometerLight } from "react-icons/pi";

export default function MainSection2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Our Solutions for Energy & Utilities</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Smart Grid Management with IoT and AI
            </h1>
            <p className="text-lg text-gray-600">
              Optimize energy distribution, enhance grid reliability, and reduce costs with IoT and AI-driven solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Real-Time Monitoring and Optimization</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage IoT sensors and AI algorithms to monitor grid performance in real-time, ensuring optimal energy distribution and minimizing wastage.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <PiSpeedometerLight className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Dynamic Demand Response</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Implement AI-driven demand forecasting to balance energy supply and demand, reducing costs and enhancing efficiency.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLightbulb className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Fault Detection and Resilience</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable proactive fault detection with IoT and predictive analytics to minimize downtime and improve grid reliability.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}