import img1 from "../../../assets/img/EngineeringExcellence/R&D/ai_image.jpg";

import { AiOutlineRobot, AiOutlineAppstoreAdd } from 'react-icons/ai';


export default function MainSection1() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Key Areas of R&D Innovation
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Artificial Intelligence (AI) and Generative AI (GenAI)
            </h1>
            <p className="text-lg text-gray-600">
              AeroAegis leverages AI to transform automation, content creation,
              and decision-making. Our focus on LLMs powers domain-specific
              tools for smarter insights and enhanced experiences.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineRobot className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Revolutionizing AI in Enterprises
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                We leverage the power of AI and Generative AI to redefine
                possibilities in automation, content creation, decision-making,
                and customer experience. From developing smarter algorithms to
                training sophisticated models, AeroAegis is shaping the future
                of AI-powered enterprises.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineAppstoreAdd className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Advancing Domain-Specific AI Solutions
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Our R&D in Large Language Models (LLMs) focuses on creating
                domain-specific AI tools, enabling enhanced natural language
                understanding, contextual decision-making, and predictive
                insights.
              </p>
            </div>
            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineHealthAndSafety className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Generative AI for Diagnostics
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Accelerate and enhance diagnostic accuracy with AI-powered
                imaging and data analysis tools.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
