import React from "react";
import hero_img from "../../../assets/img/EngineeringExcellence/R&D/pexels-pixabay-256381.jpg"
;
// export default function FinalSection() {
//   return (
//     <section
//     className="relative text-white py-20 bg-cover bg-center"
//     style={{
//       backgroundImage: `url('${hero_img}')`,
//     }}
//   >
//     {/* Overlay */}
//     <div className="absolute inset-0 bg-black bg-opacity-50"></div>

//     {/* Content */}
//     <div className="container mx-auto px-4 text-center relative z-10">
//       <h1 className="text-4xl md:text-6xl font-bold mb-4">
//         Partner with Aero Aegis
//       </h1>
//       <p className="text-xl mb-8">
//         At Aero Aegis, we combine technology expertise, industry knowledge, and a
//         patient-first mindset to deliver transformative solutions that drive
//         results. Whether it’s through AI innovation, cloud modernization, or
//         IoT-enabled systems, we’re here to lead your healthcare organization into
//         the future.
//       </p>
//       <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
//         Contact Us
//       </button>
//     </div>
//   </section>
//   )
// }

const AeroAegisLanding = () => {
  return (
    <div className=" bg-gray-100">
      {/* Hero Section */}
      <section
        className="relative text-white py-20 bg-cover bg-center"
        style={{
          backgroundImage: `url('${hero_img}')`,
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="container mx-auto px-4 text-center relative z-10">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Our Vision for the Future
          </h1>
          <p className="text-xl mb-8">
            AeroAegis Inc. envisions a world where emerging technologies drive
            business success, societal progress, and sustainable growth. Through
            our R&D initiatives, we aim to lead the way in enabling businesses
            to harness the transformative potential of AI, quantum computing,
            blockchain, and more. By staying at the forefront of innovation,
            AeroAegis empowers industries to unlock new possibilities and
            redefine the boundaries of technology.
          </p>
          {/* <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </button> */}
        </div>
      </section>

      {/* Our Product Engineering Process Workflow */}
      {/* <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
          Our Product Engineering Process Workflow
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<FaBrain className="text-4xl text-blue-600" />}
              title="Technology Expertise"
              description="Deep expertise in AI frameworks (TensorFlow, PyTorch), data tools (Apache Spark, Snowflake), and cloud platforms (Azure, AWS, Alibaba Cloud, GCP)."
            />
            <FeatureCard
              icon={<FaCog className="text-4xl text-blue-600" />}
              title="End-to-End Solutions"
              description="From data infrastructure to advanced AI/ML models, we cover the entire lifecycle."
            />
            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="Industry-Focused Innovation"
              description="Tailored solutions to solve unique challenges across industries like healthcare, finance, manufacturing, and logistics."
            />
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Scalable & Future-Ready"
              description="Solutions designed to scale seamlessly and support emerging technologies like Generative AI."
            />
            <FeatureCard
              icon={<FaDatabase className="text-4xl text-blue-600" />}
              title="Data-Driven Approach"
              description="Transform raw data into actionable intelligence that drives measurable business outcomes."
            />
            <FeatureCard
              icon={<FaChartLine className="text-4xl text-blue-600" />}
              title="Accelerate Innovation"
              description="Drive efficiency and create value with our AI and data solutions."
            />
          </div>
        </div>
      </section> */}

      {/* Footer */}
      {/* <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg px-12">
          Join us on the journey of innovation as we shape the future of technology. Let AeroAegis Inc. be your trusted partner in navigating the uncharted territories of emerging technologies.
            🚀
          </p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </button>
        </div>
      </footer> */}
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default AeroAegisLanding;
