import React, { useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import pic1_img from "../../../assets/img/Profile/pic1.jpeg";
import pic2_img from "../../../assets/img/Profile/nithin_pic.png";
import pic3_img from "../../../assets/img/Profile/Avinaash_Photo.jpg";
import pic4_img from "../../../assets/img/Profile/bhaskar-nagaraj-profile-pic.jpeg";

const teamMembers = [
  {
    name: "Dr. Madhu Kumar Reddy",
    role: "Founder And President (CEO)",
    image: pic1_img,
    description:
      "Dr. Madhu Kumar Reddy, Founder and CEO of AeroAegis Inc., is a visionary technologist with over 18 years of experience in the IT industry. He has held key roles at Intel, Samsung R&D, and Hewlett Packard, driving innovation in AI, Cloud Computing, and Edge Technologies. Dr. Madhu holds three advanced doctoral degrees: a Ph.D. in Cloud & Edge Computing, a Ph.D. in Artificial Intelligence & Machine Learning, and a Doctorate in Business Administration focused on AI and Large Language Models (LLM). A prolific researcher, he has authored papers on Generative AI, Game Theory, and Artificial Super Intelligence, published in leading journals. His book, available on Amazon Kindle, reinforces his status as a tech thought leader. An internationally recognized speaker, Dr. Madhu shares his insights on AI and digital transformation at global conferences, inspiring the next generation of innovators. AeroAegis, under his leadership, is pioneering AI-driven digital transformation across industries.",
    linkedin: "https://www.linkedin.com/in/madhukumarreddy/",
    highlights: [
      "18+ years of IT industry experience",
      "Three doctoral degrees",
      "Published researcher and author",
      "International speaker",
    ],
  },
  {
    name: "Dr. Nithin Manmohan",
    role: "Co-Founder And CTO (Chief Architect)",
    image: pic2_img,
    description:
      "Dr. Nithin Manmohan, our Chief Architect, is a pillar of technical excellence with over 13 years of diverse IT experience. He has mastered advanced domains like Artificial Intelligence (AI), Machine Learning (ML), Natural Language Processing (NLP), Big Data Analytics, and Cloud Development, which drive innovation in today’s digital landscape. Dr. Nithin holds a Master’s degree in Computer Science and a Doctorate in Cloud Computing, where his groundbreaking research has advanced cloud technologies. This blend of academic rigor and practical expertise enables him to tackle complex challenges with a unique perspective. His career includes pivotal roles at top technology companies such as HP and Sapient Consulting. Dr. Nithin’s relentless pursuit of excellence and visionary outlook propel our organization forward, ensuring we stay at the cutting edge of technology and inspiring others to push IT boundaries.",
    linkedin: "https://www.linkedin.com/in/nithinmanmohan/",
    highlights: [
      "13+ years in IT",
      "AI/ML expert",
      "Cloud architecture specialist",
      "Technical innovation leader",
    ],
  },
  {
    name: "Bhaskar Nagaraja",
    role: "CIO (Chief Information Officer)",
    image: pic4_img,
    description:
      "With over 25 years in the IT industry, Bhaskar is a visionary leader driving technological innovation. At Intel, he excelled in developing groundbreaking products, particularly in Artificial Intelligence (AI), Machine Learning (ML), and the Internet of Things (IoT). His expertise has earned him recognition as a thought leader, transforming advanced technologies into practical, real-world applications that enhance business success and daily life. At AeroAegis, Bhaskar's strategic insights and technical knowledge guide the company through the complex tech landscape, ensuring it remains at the forefront of innovation. He actively collaborates with the team, offering a deep understanding of emerging technologies. Known for inspiring and mentoring future tech innovators, Bhaskar fosters a culture of continuous learning and innovation, empowering his teams to explore new ideas and push boundaries.",
    linkedin: "https://www.linkedin.com/in/bhaskarnagaraja/",
    highlights: [
      "25+ years industry experience",
      "Digital transformation expert",
      "Enterprise architecture specialist",
      "Technology innovation leader",
    ],
  },
  {
    name: "Avinaash Pathrae",
    role: "Head Of IT Infrastructure And Operations",
    image: pic3_img,
    description:
      "With over 13 years in the IT industry, Avinaash Pathrae has established himself as a leader in technological advancements at Aeroaegis. His commitment to innovation and excellence in infrastructure and operations has made him a key figure in shaping the company's technological strategy. Avinaash is particularly skilled in cloud computing, demonstrating proficiency across Microsoft Azure, Amazon Web Services (AWS), and Google Cloud Platform (GCP). His expertise enables Aeroaegis to implement robust, scalable, and secure infrastructure solutions that meet modern business needs. Avinaash also excels in operations, infrastructure, and platform engineering, designing systems that are reliable, scalable, and adaptable. His holistic approach ensures Aeroaegis remains at the cutting edge of technology, providing the agility and performance needed to succeed in a competitive landscape.",
    linkedin: "https://linkedin.com",
    highlights: [
      "13+ years in IT operations",
      "Infrastructure management expert",
      "Cloud operations specialist",
      "Digital transformation leader",
    ],
  },
];

export default function TeamSection() {
  const [expandedCards, setExpandedCards] = useState({});

  const toggleCard = (index) => {
    setExpandedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Our Leadership Team
          </h2>
          <p className="text-xl text-gray-600">
            Meet the visionaries driving innovation and excellence
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
            >
              <div className="p-6">
                <div className="flex flex-col sm:flex-row items-center gap-6 mb-6">
                  <div className="relative w-48 h-48 sm:w-40 sm:h-40 flex-shrink-0">
                    <div className="absolute  inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-full" />
                    <img
                      src={member.image}
                      alt={member.name}
                      className="relative rounded-full w-full h-full overflow-hidden object-cover  border-4 border-white shadow-md"
                    />
                  </div>

                  <div className="flex-1 text-center sm:text-left">
                    <h3 className="text-2xl font-bold text-gray-900 mb-1">
                      {member.name}
                    </h3>
                    <p className="text-blue-600 font-medium mb-3">
                      {member.role}
                    </p>

                    <div className="mb-4">
                      <div className="grid grid-cols-2 gap-2">
                        {member.highlights.map((highlight, i) => (
                          <div
                            key={i}
                            className="text-sm text-gray-600 bg-gray-100 rounded-full px-3 py-1 flex items-center  text-start"
                          >
                            {highlight}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative">
                  <p
                    className={`text-gray-600 text-sm ${
                      expandedCards[index] ? "" : "line-clamp-3"
                    }`}
                  >
                    {member.description}
                  </p>

                  {!expandedCards[index] && (
                    <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent" />
                  )}
                </div>

                <div className="flex items-center justify-between mt-4 pt-4 border-t">
                  <button
                    onClick={() => toggleCard(index)}
                    className="flex items-center gap-2 text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors"
                    aria-expanded={expandedCards[index]}
                  >
                    {expandedCards[index] ? (
                      <>
                        <IoChevronUpOutline className="w-4 h-4" />
                        Show Less
                      </>
                    ) : (
                      <>
                        <IoChevronDownOutline className="w-4 h-4" />
                        Read More
                      </>
                    )}
                  </button>

                  <a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
                  >
                    <FaLinkedin className="w-5 h-5" />
                    <span className="text-sm font-medium">Connect</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
