import React from "react";
import {
  FaCloud,
  FaChartBar,
  FaShieldAlt,
  FaCloudUploadAlt,
  FaBolt,
  FaLightbulb,
  FaUsers,
  FaRocket,
  FaCheckCircle,
  FaClock,
  FaFileAlt,
  FaMoneyBillAlt,
  FaUserCheck,
} from "react-icons/fa";

const CaseStudyPage = () => {
  return (
    <div className="mx-auto px-4 md:px-24 py-8 w-full max-w-7xl">
      <div className="uppercase tracking-wide text-1xl font-bold text-indigo-500">
        Case Study
      </div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white">
        Transforming Banking Operations with Agent AI
      </h1>
      <h2 className="mt-2 text-2xl font-semibold text-gray-700 dark:text-gray-300">
        AeroAegis Inc. Empowers a Global Bank with Agent AI for Enhanced
        Customer Service
      </h2>
      <div className="mt-4 mb-8 flex flex-wrap gap-4">
        <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-sm font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
          <FaCloud className="mr-1 h-3 w-3" />
          Agent AI
        </span>
        <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-sm font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
          <FaChartBar className="mr-1 h-3 w-3" />
          Banking
        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-sm font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
          <FaShieldAlt className="mr-1 h-3 w-3" />
          Compliance
        </span>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Case Study Description</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          A global bank partnered with AeroAegis to implement Agent AI,
          enhancing customer service and operational efficiency.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Client Background</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          A global leader in retail and corporate banking, serving over 50
          million customers.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Challenges Faced by the Client
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-2">
          The bank faced challenges with inconsistent customer service,
          operational inefficiencies, scalability, and compliance.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Inconsistent Customer Experience</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Fragmented communication channels led to inconsistent service.
              </li>
              <li>
                Slow response times negatively impacted customer satisfaction.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Operational Inefficiencies</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>High dependency on manual processes increased workloads.</li>
              <li>Rising operational costs to sustain 24/7 support.</li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Scalability Concerns</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>Legacy systems couldn't handle growing customer volumes.</li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Regulatory Compliance and Security</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Ensuring GDPR and PCI DSS compliance with AI integration was
                critical.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">AeroAegis's Approach</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis designed a tailored solution using Agent AI to modernize the
          bank's customer service.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Comprehensive Assessment</strong>
            <p className="ml-6 mt-2">
              AeroAegis assessed existing workflows to identify inefficiencies
              and pain points.
            </p>
          </li>
          <li className="mb-4">
            <strong>Defined a Roadmap</strong>
            <p className="ml-6 mt-2">
              Implemented Agent AI across channels to unify and automate
              customer interactions.
            </p>
          </li>
          <li className="mb-4">
            <strong>Collaboration with Compliance Teams</strong>
            <p className="ml-6 mt-2">
              Ensured seamless integration and adherence to industry
              regulations.
            </p>
          </li>
          <li className="mb-4">
            <strong>Designed Predictive Analytics</strong>
            <p className="ml-6 mt-2">
              Created real-time dashboards to provide actionable insights.
            </p>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Solution Implementation</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis deployed Agent AI across email, chat, and voice channels for
          consistent customer experience.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Unified Customer Engagement Platform</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>Deployed Agent AI across email, chat, and voice channels.</li>
              <li>
                Automated repetitive tasks, reducing agent workload by 80%.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Context-Aware Conversations</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Integrated NLP and sentiment analysis for empathetic responses.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Enhanced Security and Compliance</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Implemented end-to-end encryption and automated GDPR compliance.
              </li>
              <li>Established AI-driven audit trails.</li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>AI-Powered Insights and Analytics</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Built real-time dashboards for tracking key performance
                indicators.
              </li>
              <li>
                Utilized predictive analytics for proactive customer engagement.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">Key Outcomes and Benefits</h2>
        <ol class="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li class="mb-4">
            <strong>Customer Satisfaction Boost</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>Improved CSAT scores by 40% within six months.</li>
              <li>Achieved a 70% reduction in average response times.</li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Cost Savings</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                Automated 80% of routine queries, reducing operational costs.
              </li>
              <li>
                Reallocated resources to focus on high-value interactions.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Operational Efficiency</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                Unified communication channels, reducing duplicate tickets by
                50%.
              </li>
              <li>
                Enhanced scalability, supporting increased customer volumes.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Compliance Assurance</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>Ensured full compliance with GDPR and PCI DSS.</li>
              <li>Reduced manual compliance efforts by 60%.</li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Conclusion and Call to Action
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
          The bank's digital transformation with Agent AI enhanced customer
          service, reduced costs, and ensured compliance.
        </p>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaCheckCircle className="mr-2 text-blue-500" />
              Unified Platform
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Consistent customer experience across email, chat, and voice.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaClock className="mr-2 text-yellow-500" />
              Automated Tasks
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Reduced agent workload by 80% through automation.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaFileAlt className="mr-2 text-green-500" />
              Compliance
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Ensured full compliance with GDPR and PCI DSS.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaMoneyBillAlt className="mr-2 text-purple-500" />
              Cost Efficiency
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Significantly reduced operational costs through automation.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 p-4 mb-8">
        <p className="text-blue-700 dark:text-blue-300">
          AeroAegis's Agent AI solution delivers personalized customer
          experiences, cost efficiencies, and regulatory compliance.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Why Choose AeroAegis Inc.?</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis combines AI expertise, digital transformation, and financial
          services knowledge.
        </p>
        <div className="grid md:grid-cols-3 gap-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaLightbulb className="text-3xl text-blue-500 mb-2" />
            <h3 className="font-semibold mb-2">Innovative Solutions</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Tailored AI solutions to meet unique business needs
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaUsers className="text-3xl text-green-500 mb-2" />
            <h3 className="font-semibold mb-2">Expert Team</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Deep industry insights and proven methodologies
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaRocket className="text-3xl text-purple-500 mb-2" />
            <h3 className="font-semibold mb-2">Accelerated Transformation</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Strategic partnerships with Microsoft Azure and Alibaba Cloud
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Let's Transform Your Business Together
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Partner with AeroAegis for intelligent, scalable, and secure AI-driven
          solutions.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Visit{" "}
          <a
            href="http://www.aeroaegis.com"
            className="text-blue-500 hover:underline"
          >
            www.aeroaegis.com
          </a>{" "}
          or reach out to our experts for a personalized consultation. Together,
          we can build innovative, resilient, and scalable solutions that drive
          business success.
        </p>
        <p className="text-gray-700 dark:text-gray-300 font-semibold">
          Revolutionize your banking services with AeroAegis!
        </p>
      </div>
    </div>
  );
};

export default CaseStudyPage;
