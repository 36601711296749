import React from "react";
import img4 from "../../assets/img/careers/1813.jpg";
import { MdOutlineSchedule, MdOutlineSupport } from "react-icons/md";
export default function Section4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img4}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Work-Life Balance
            </h1>
            <p className="text-lg text-gray-600">
              We understand the importance of work-life balance. Our flexible
              work arrangements and supportive environment ensure that you can
              excel in your role while maintaining a healthy personal life.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSchedule className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Flexible Work Arrangements
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Achieve your professional goals with the flexibility to balance
                your work and personal commitments effectively.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSupport className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Supportive Environment
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Thrive in a workplace that prioritizes your well-being, ensuring
                you have the support needed to excel both professionally and
                personally.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
            <div className="flex items-center space-x-2">
              <FaPaintBrush className="w-6 h-6 text-indigo-600" />
              <h3 className="font-semibold text-gray-900">Aesthetic Appeal</h3>
            </div>
            <p className="text-gray-600 text-sm">
            Create visually appealing interfaces that align with brand identity.
            </p>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
