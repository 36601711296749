import React from "react";
import img2 from "../../assets/img/careers/65643.jpg";
import { MdOutlineSchool, MdOutlineGroup } from "react-icons/md";

export default function Section2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img2}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Professional Growth
            </h1>
            <p className="text-lg text-gray-600">
              We are committed to nurturing your professional development. From
              mentorship programs to continuous learning opportunities, we
              provide you with the tools to thrive and advance in your career.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSchool className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Mentorship Programs
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Gain insights and guidance from experienced professionals to
                accelerate your career growth and achieve your goals.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineGroup className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Continuous Learning Opportunities
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enhance your skills with access to cutting-edge resources,
                workshops, and training tailored to your career aspirations.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
            <div className="flex items-center space-x-2">
              <FaPaintBrush className="w-6 h-6 text-indigo-600" />
              <h3 className="font-semibold text-gray-900">Aesthetic Appeal</h3>
            </div>
            <p className="text-gray-600 text-sm">
            Create visually appealing interfaces that align with brand identity.
            </p>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
