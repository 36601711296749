
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { MdOutlineEngineering } from "react-icons/md";
import { MdOutlineDevicesOther } from "react-icons/md";
import { GiArtificialIntelligence } from "react-icons/gi"
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { TiGroupOutline } from "react-icons/ti";

const WhatsetsUsApart = () => {
  return (
    <section className="w-full px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8   mx-auto">
      <div>
        <span className="block mb-4 text-sm md:text-[18px] text-indigo-500 font-medium">
          Innovating Business Excellence
        </span>
        <h3 className="text-4xl md:text-6xl font-semibold">
          What Sets Us Apart
        </h3>
        <p className="mb-2 text-base text-gray-700 md:text-lg my-4 md:text-justify">
          At the core of AeroAegis lies a relentless pursuit of innovation. We
          are not just technologists but problem-solvers, delivering products
          and solutions that enable businesses to evolve and excel in a rapidly
          changing digital landscape. Here's what makes us stand out:
        </p>

        <div className="p-6 ">
          {/* <ul className="space-y-4 list-disc list-inside text-gray-800">
        <li>
          <span className="font-bold">Product-Centric Excellence:</span> Each product in our portfolio is meticulously engineered to address real-world challenges, offering unparalleled performance, scalability, and adaptability. Whether it’s the Maturity Spectrum 360 for digital transformation or the AeroEdge IIoT platform for industrial innovation, our tools are designed to deliver measurable impact.
        </li>
        <li>
          <span className="font-bold">Integrated Innovation:</span> Our AI-first approach ensures that every product and platform leverages cutting-edge technologies like generative AI, edge AI, and advanced automation frameworks to drive intelligent outcomes.
        </li>
        <li>
          <span className="font-bold">R&D Leadership:</span> With investments in quantum computing, Web3, and blockchain solutions, AeroAegis is constantly exploring the frontiers of technology, ensuring our clients remain future-ready.
        </li>
        <li>
          <span className="font-bold">Value-Added Services:</span> Beyond products, our Centers of Excellence (COE) provide consulting and engineering services to help clients maximize the value of our technologies, ensuring seamless integration and scalable success.
        </li>
        <li>
          <span className="font-bold">Customer-Centric Approach:</span> Our solutions are tailored to align with the unique needs of businesses across industries, empowering them to achieve operational efficiency, data-driven decision-making, and sustainable growth.
        </li>
      </ul> */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineEngineering className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Product-Centric Excellence
                </h3>
              </div>
              <p className="text-gray-600 text-sm md:text-md">
                Each product in our portfolio is meticulously engineered to
                address real-world challenges, offering unparalleled
                performance, scalability, and adaptability. Whether it’s the
                Maturity Spectrum 360 for digital transformation or the AeroEdge
                IIoT platform for industrial innovation, our tools are designed
                to deliver measurable impact.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDevicesOther className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Integrated Innovation
                </h3>
              </div>
              <p className="text-gray-600 text-sm md:text-md">
                Our AI-first approach ensures that every product and platform
                leverages cutting-edge technologies like generative AI, edge AI,
                and advanced automation frameworks to drive intelligent
                outcomes.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiArtificialIntelligence className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">R&D Leadership</h3>
              </div>
              <p className="text-gray-600 text-sm md:text-md">
                With investments in quantum computing, Web3, and blockchain
                solutions, AeroAegis is constantly exploring the frontiers of
                technology, ensuring our clients remain future-ready.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaHandsHelpingSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Value-Added Services
                </h3>
              </div>
              <p className="text-gray-600 text-sm md:text-md">
                Beyond products, our Centers of Excellence (COE) provide
                consulting and engineering services to help clients maximize the
                value of our technologies, ensuring seamless integration and
                scalable success.
              </p>
            </div>

            {/* Feature 5 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TiGroupOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Customer-Centric Approach
                </h3>
              </div>
              <p className="text-gray-600 text-sm md:text-md">
                Our solutions are tailored to align with the unique needs of
                businesses across industries, empowering them to achieve
                operational efficiency, data-driven decision-making, and
                sustainable growth.
              </p>
            </div>
          </div>
        </div>
        <div className="p-6">
          <Link
            to={'/discover-aeroaegis'}
            className="bg-[#252f80] hover:bg-opacity-90 text-white font-medium py-2 px-4 rounded transition-all active:scale-95"
          >
            Learn more
          </Link>
        </div>
      </div>
      <ShuffleGrid />
    </section>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const squareData = [
  {
    id: 1,
    src: 'https://images.unsplash.com/photo-1518432031352-d6fc5c10da5a?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // AI visualization
  },
  {
    id: 2,
    src: 'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Digital matrix
  },
  {
    id: 3,
    src: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Server room
  },
  {
    id: 4,
    src: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Digital network
  },
  {
    id: 5,
    src: 'https://images.unsplash.com/photo-1535378917042-10a22c95931a?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // VR headset
  },
  {
    id: 6,
    src: 'https://images.unsplash.com/photo-1488229297570-58520851e868?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Data visualization
  },
  {
    id: 7,
    src: 'https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Blockchain concept
  },
  {
    id: 8,
    src: 'https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Tech gadgets
  },
  {
    id: 9,
    src: 'https://images.unsplash.com/photo-1597733336794-12d05021d510?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Robot arm
  },
  {
    id: 10,
    src: 'https://images.unsplash.com/photo-1504384764586-bb4cdc1707b0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Circuit board
  },
  {
    id: 11,
    src: 'https://images.unsplash.com/photo-1593508512255-86ab42a8e620?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Digital transformation
  },
  {
    id: 12,
    src: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Cloud computing
  },
  {
    id: 13,
    src: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // AI neural networks
  },
  {
    id: 14,
    src: 'https://images.unsplash.com/photo-1550745165-9bc0b252726f?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Cybersecurity
  },
  {
    id: 15,
    src: 'https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // AI robot
  },
  {
    id: 16,
    src: 'https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80', // Digital innovation
  },
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: 'spring' }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: 'cover',
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-4 h-[600px] gap-1">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default WhatsetsUsApart;