import React from 'react'
import img1 from '../../assets/img/Investor_relations/img1.jpg'
import img2 from '../../assets/img/Investor_relations/2518.jpg'
export default function OtherSection() {
  return (
    <div>
       <div class="relative group">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img1}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-4xl lg:text-6xl font-bold transition duration-300  group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center my-20 md:my-40 lg:my-36'>Overview</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    At AeroAegis, we bridge the gap between technology and business needs, offering solutions that revolutionize industries and empower enterprises to thrive in a rapidly evolving digital landscape. With a proven track record in product engineering, consulting, and R&D-driven innovation, we are uniquely positioned to deliver exceptional value.
    </p>
  </div>
</div>
        <section class=" mt-2 ">
        <div class="relative group">
  <img
    class="object-cover w-full h-full sm:h-96"
    src={img2}
    alt=""
  />
  <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-4xl lg:text-6xl font-bold transition duration-300  group-hover:opacity-0  hover:bg-opacity-100">
  <p className=' text-center  my-24 md:my-40 lg:my-36'>Why Invest in AeroAegis?</p>
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition duration-700  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
    <p className='absolute text-center text-[9px] md:text-lg lg:text-2xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
    Investing in AeroAegis is an opportunity to be part of a visionary organization that integrates deep tech innovation with business impact. Our approach prioritizes scalable, future-ready solutions backed by research, making us a leader in transforming industries across the globe.
    </p>
  </div>
</div>
        </section>
      
    </div>
  )
}
