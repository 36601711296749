import React from "react";

import hero_img from "../../../assets/img/EngineeringExcellence/COE/2149595843.jpg";
import Img1 from "../../../assets/img/EngineeringExcellence/COE/2150038859 (1).jpg";
import Img2 from "../../../assets/img/EngineeringExcellence/COE/2149399281.jpg";
import Img3 from "../../../assets/img/EngineeringExcellence/COE/125206.jpg";
import Img4 from "../../../assets/img/EngineeringExcellence/COE/2149241211.jpg";
import Img5 from "../../../assets/img/EngineeringExcellence/COE/2149126949.jpg";
import Img6 from "../../../assets/img/EngineeringExcellence/COE/617312.jpg";
import Img7 from "../../../assets/img/EngineeringExcellence/COE/2149408315.jpg";
import Img8 from "../../../assets/img/EngineeringExcellence/COE/2149250206.jpg";
import Img9 from "../../../assets/img/EngineeringExcellence/COE/2149524396.jpg";
import Img10 from "../../../assets/img/EngineeringExcellence/COE/2148746476.jpg";

import { IoCheckmarkCircleOutline, IoCodeSlashOutline } from "react-icons/io5";
import { BiLinkAlt } from "react-icons/bi";
import {
  FaAtom,
  FaBalanceScale,
  FaCubes,
  FaDigitalTachograph,
  FaLeaf,
  FaLightbulb,
  FaLock,
  FaMoneyBillAlt,
  FaProjectDiagram,
  FaShieldAlt,
  FaSyncAlt,
  FaTools,
  FaUserAlt,
  FaUserTie,
} from "react-icons/fa";

import {
  FaBrain,
  FaChartBar,
  FaChartPie,
  FaCity,
  FaCloud,
  FaCoins,
  FaCreativeCommonsSamplingPlus,
  FaDatabase,
  FaDollarSign,
  FaFileContract,
  FaGlobe,
  FaHandshake,
  FaIndustry,
  FaNetworkWired,
  FaRecycle,
  FaRobot,
  FaServer,
  FaSolarPanel,
  FaUsers,
} from "react-icons/fa6";
import HeroSection from "./reuse/HeroSection";
import FeatureSection from "./reuse/FeatureSection";
import Section from "./reuse/Section";
import FinalSection from "./reuse/FinalSection";
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";

const index = () => {
  const HeroData = {
    title: "Centers of Excellence (COEs)",
    description:
      "At AeroAegis Inc., our Centers of Excellence (COEs) are the nerve centers of innovation, expertise, and excellence. These specialized hubs drive transformative value for our customers by fostering deep domain knowledge, technological innovation, and industry best practices. Each COE is dedicated to advancing specific technology domains, empowering organizations to unlock their full potential in a competitive digital landscape.",
    image: hero_img, // Replace with an appropriate image path
  };

  // Features data for "Key Benefits"
  const features = [
    {
      icon: <FaUsers />,
      title: "Expert Teams",
      description:
        "Each COE is led by domain specialists with deep expertise in their respective fields.",
    },
    {
      icon: <FaLightbulb />,
      title: "Innovation-Focused",
      description:
        "Continuous research ensures we stay ahead of industry trends and deliver cutting-edge solutions.",
    },
    {
      icon: <FaHandshake />,
      title: "Customer-Centric Approach",
      description:
        "Tailored solutions aligned with your unique business needs and objectives.",
    },
    {
      icon: <FaGlobe />,
      title: "Global Collaboration",
      description:
        "Partnerships with technology leaders and research institutions drive collaborative innovation.",
    },
  ];

  // Section data
  const sectionData = [
    {
      imageSrc: Img1, // Replace with an appropriate image
      title: "API Development & Integration",
      description:
        "Design and implement RESTful and GraphQL APIs that enable communication across applications and platforms.",
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: "RESTful APIs",
          description:
            "Develop secure, scalable RESTful APIs for diverse use cases.",
        },
        {
          icon: IoCodeSlashOutline,
          title: "GraphQL Integration",
          description: "Simplify data fetching with powerful GraphQL APIs.",
        },
        {
          icon: BiLinkAlt,
          title: "Cross-Platform Communication",
          description:
            "Enable seamless interaction across systems and platforms.",
        },
      ],
    },
    {
      imageSrc: Img2, // Replace with an appropriate image
      title: "Cloud Center of Excellence (CCoE)",
      description:
        "The Cloud COE drives innovation in cloud technologies, helping businesses adopt, manage, and optimize cloud solutions.",
      features: [
        {
          icon: FaCloud,
          title: "Cloud Strategy and Migration",
          description:
            "Expertise in seamless migration to public, private, or hybrid cloud environments.",
        },
        {
          icon: FaServer,
          title: "Cloud-Native Architecture",
          description:
            "Building scalable, secure, and resilient applications using microservices, serverless computing, and containerization.",
        },
        {
          icon: FaDollarSign,
          title: "Cost Optimization",
          description:
            "Tools and frameworks to minimize cloud spending and maximize ROI.",
        },
        {
          icon: FaSyncAlt,
          title: "Multi-Cloud and Hybrid Cloud",
          description:
            "Tailored solutions to leverage the best of different cloud platforms like AWS, Azure, Alibaba Cloud, and GCP.",
        },
      ],
    },
    {
      imageSrc: Img3, // Replace with an appropriate image
      title: "AI/ML Center of Excellence",
      description:
        "The AI/ML COE drives cutting-edge advancements in artificial intelligence and machine learning to help businesses automate, predict, and innovate.",
      features: [
        {
          icon: FaRobot,
          title: "Custom AI Models",
          description:
            "Development of industry-specific machine learning and deep learning models.",
        },
        {
          icon: FaCreativeCommonsSamplingPlus,
          title: "Generative AI (GenAI)",
          description:
            "Solutions for content creation, personalization, and decision-making.",
        },
        {
          icon: FaDatabase,
          title: "AI at Scale",
          description:
            "Scalable AI frameworks powered by Large Language Models (LLMs) for enterprise adoption.",
        },
        {
          icon: FaBalanceScale,
          title: "AI Ethics and Governance",
          description:
            "Implementing ethical AI frameworks to ensure unbiased and responsible AI applications.",
        },
      ],
    },
    {
      imageSrc: Img4, // Replace with an appropriate image
      title: "Big Data & Analytics Center of Excellence",
      description:
        "Our Big Data & Analytics COE specializes in unlocking actionable insights from data to drive strategic business decisions.",
      features: [
        {
          icon: FaDatabase,
          title: "Data Engineering",
          description:
            "Building robust data pipelines for real-time and batch processing.",
        },
        {
          icon: FaChartBar,
          title: "Predictive and Prescriptive Analytics",
          description:
            "Leveraging advanced algorithms to forecast trends and optimize decision-making.",
        },
        {
          icon: FaChartPie,
          title: "Data Visualization",
          description:
            "Interactive dashboards and visual storytelling to make insights accessible.",
        },
        {
          icon: FaMoneyBillAlt,
          title: "Data Monetization",
          description:
            "Helping businesses extract maximum value from their data assets.",
        },
      ],
    },
    {
      imageSrc: Img5, // Replace with an appropriate image
      title: "Digital Transformation Center of Excellence",
      description:
        "The Digital Transformation COE helps organizations modernize their operations, processes, and customer experiences.",
      features: [
        {
          icon: FaSyncAlt,
          title: "Process Modernization",
          description:
            "Redefining business workflows for efficiency and agility.",
        },
        {
          icon: FaNetworkWired,
          title: "Smart Ecosystems",
          description:
            "Integrating IoT, AI, and automation to create intelligent, connected ecosystems.",
        },
        {
          icon: FaUserTie,
          title: "Employee Enablement",
          description:
            "Digital tools and training for workforce transformation.",
        },
        {
          icon: FaUserAlt,
          title: "Customer Experience (CX)",
          description:
            "Enhancing engagement through personalized, omnichannel digital experiences.",
        },
      ],
    },
    // Add similar structured objects for other COEs
    {
      imageSrc: Img6, // Replace with an appropriate image
      title: "Robotic Process Automation (RPA) Center of Excellence",
      description:
        "The RPA COE empowers businesses to automate repetitive tasks and enhance operational efficiency.",
      features: [
        {
          icon: FaRobot,
          title: "Process Automation",
          description: "Automating end-to-end workflows for faster operations.",
        },
        {
          icon: FaBrain,
          title: "Intelligent Automation",
          description: "Combining RPA with AI/ML for smarter decision-making.",
        },
        {
          icon: FaProjectDiagram,
          title: "Hyperautomation",
          description:
            "Integrating advanced technologies for comprehensive automation solutions.",
        },
        {
          icon: FaShieldAlt,
          title: "RPA Governance",
          description: "Ensuring scalable and compliant automation frameworks.",
        },
      ],
    },
    {
      imageSrc: Img7, // Replace with an appropriate image
      title: "Industrial IoT (IIoT) & IoT Center of Excellence",
      description:
        "The IIoT & IoT COE delivers next-gen solutions for connected devices and intelligent industrial operations.",
      features: [
        {
          icon: FaIndustry,
          title: "Smart Manufacturing",
          description:
            "IIoT solutions for predictive maintenance, quality control, and operational insights.",
        },
        {
          icon: FaCloud,
          title: "IoT Platform Development",
          description:
            "Building secure, scalable platforms for device connectivity and data exchange.",
        },
        {
          icon: FaDigitalTachograph,
          title: "Digital Twin Technology",
          description:
            "Creating virtual replicas of physical systems for real-time monitoring and optimization.",
        },
        {
          icon: FaLock,
          title: "IoT Security",
          description:
            "Implementing robust frameworks to secure IoT devices and data.",
        },
      ],
    },
    {
      imageSrc: Img8, // Replace with an appropriate image
      title: "Blockchain & Web3 Center of Excellence",
      description:
        "The Blockchain COE pioneers decentralized technologies to enhance transparency, security, and efficiency.",
      features: [
        {
          icon: FaCubes,
          title: "Enterprise Blockchain Solutions",
          description:
            "Supply chain transparency, identity management, and financial systems.",
        },
        {
          icon: FaFileContract,
          title: "Smart Contracts",
          description:
            "Developing and deploying self-executing contracts for automated workflows.",
        },
        {
          icon: FaNetworkWired,
          title: "Web3 Innovation",
          description:
            "Building decentralized apps (dApps) and protocols for the future internet.",
        },
        {
          icon: FaCoins,
          title: "Tokenization",
          description:
            "Enabling businesses to tokenize assets for improved liquidity and accessibility.",
        },
      ],
    },
    {
      imageSrc: Img9, // Replace with an appropriate image
      title: "Quantum Computing Center of Excellence",
      description:
        "The Quantum Computing COE focuses on leveraging quantum technologies to solve complex business challenges.",
      features: [
        {
          icon: FaAtom,
          title: "Quantum Algorithms",
          description:
            "Developing algorithms for optimization, cryptography, and simulations.",
        },
        {
          icon: FaBrain,
          title: "Quantum-AI Integration",
          description:
            "Exploring synergies between AI and quantum computing for next-gen applications.",
        },
        {
          icon: FaTools,
          title: "Enterprise Readiness",
          description:
            "Preparing organizations for the quantum revolution with consulting and pilot projects.",
        },
      ],
    },
    {
      imageSrc: Img10, // Replace with an appropriate image
      title: "Sustainability & Green Tech Center of Excellence",
      description:
        "The Sustainability COE drives environmentally-conscious innovation and practices.",
      features: [
        {
          icon: FaLeaf,
          title: "Green IT Solutions",
          description: "Energy-efficient data centers and cloud deployments.",
        },
        {
          icon: FaRecycle,
          title: "Sustainable AI",
          description: "Optimizing AI models for reduced carbon footprints.",
        },
        {
          icon: FaCity,
          title: "Smart Cities",
          description:
            "Solutions for energy management, waste reduction, and urban efficiency.",
        },
        {
          icon: FaSolarPanel,
          title: "Renewable Tech Integration",
          description:
            "Leveraging IoT and AI to optimize renewable energy systems.",
        },
      ],
    },
  ];

  return (
    <>
      <HeroSection
        title={HeroData.title}
        description={HeroData.description}
        image={hero_img}
      />

      <AnimationRevealPage>
        <FeatureSection
          mainTitle="Why AeroAegis COEs Stand Out"
          description="Design and implement APIs and microservices for a scalable, modular architecture."
          features={features}
        />
      </AnimationRevealPage>

      <div className="flex flex-col  justify-center  lg:justify-center lg:flex-row px-4">
        <h2 className="max-w-4xl  font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Key Areas of Centers of Excellence (COEs)
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <AnimationRevealPage>
        {sectionData.map((section, index) => (
          <Section
            key={index}
            imageSrc={section.imageSrc}
            title={section.title}
            description={section.description}
            features={section.features}
            isImageLeft={index % 2 !== 0} // Alternate image position
          />
        ))}
      </AnimationRevealPage>
      <FinalSection />
    </>
  );
};

export default index;
