import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import { MdOutlineInsights } from 'react-icons/md'; // Tailored Predictive Analytics
import { MdOutlineLightbulb } from 'react-icons/md';
import img1 from "../../../assets/img/Industries/Pharmaceuticals/2.jpg";
import { RxTimer } from "react-icons/rx";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { PiSpeedometerLight } from "react-icons/pi";
import { FaFlask, FaChartLine, FaDna } from "react-icons/fa"; // New icons for AI-Driven Drug Discovery

export default function MainSection2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">AI-Driven Drug Discovery and Development</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Accelerated Time-to-Market
            </h1>
            <p className="text-lg text-gray-600">
              Harness the power of AI and machine learning to analyze vast datasets, identify potential drug candidates, and optimize development cycles, reducing time-to-market significantly.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaFlask className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Predictive Modeling</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI to simulate drug interactions and predict efficacy, enabling researchers to prioritize the most promising candidates.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaChartLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Optimized Development Cycles</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage AI to streamline drug development processes, reducing costs and improving efficiency.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaDna className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Precision Medicine</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage AI to analyze genomic data and develop personalized treatment solutions tailored to individual patient needs.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}