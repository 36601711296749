import React from 'react'
import industry_img from "../../../assets/img/iiot/features/pexels-googledeepmind-18069696.jpg"
import { FiCpu } from 'react-icons/fi';
import { MdBuild } from 'react-icons/md';
export default function Feature2() { 
  return (
    <div>
	{/* <section className="dark:bg-gray-800 pt-5 dark:text-gray-100">
   
		 
   <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
   <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
		   <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
			   <img src={industry_img} alt="" className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96" />
		   </div>
	   </div>
	   <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
	   <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">Predictive Maintenance
</h3>
<div className="flex space-x-2 sm:space-x-4">
			<svg
			  xmlns="http://www.w3.org/2000/svg"
			  fill="none"
			  viewBox="0 0 24 24"
			  stroke="currentColor"
			  className="flex-shrink-0 w-6 h-6"
			>
			  <path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
			  ></path>
			</svg>

			<div className="space-y-2">
			  <p className="text-lg font-medium leadi">
			  <div className=' inline font-bold'>Functionality :</div> Utilizes AI algorithms to predict equipment failures and schedule maintenance proactively.				
			  </p>
			</div>
		  </div>
<div className="flex space-x-2 sm:space-x-4">
			<svg
			  xmlns="http://www.w3.org/2000/svg"
			  fill="none"
			  viewBox="0 0 24 24"
			  stroke="currentColor"
			  className="flex-shrink-0 w-6 h-6"
			>
			  <path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
			  ></path>
			</svg>

			<div className="space-y-2">
			  <p className="text-lg font-medium leadi">
			  <div className=' inline font-bold'>Benefits :</div> Minimizes downtime, extends equipment life, and reduces maintenance costs.			
			  </p>
			</div>
		  </div>
		 
		 
	   </div>
	   
   </div>
</section> */}
 <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={industry_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
			Predictive Maintenance
            </h1>
            <p className="text-lg text-gray-600">
			Uses AI algorithms to predict equipment failures and schedule maintenance, minimizing downtime and extending equipment lifespan. This proactive approach reduces maintenance costs and enhances operational efficiency.</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiCpu className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">AI Predictive Maintenance</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Utilizes AI algorithms to predict equipment failures and schedule maintenance proactively.	
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdBuild className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Equipment Management</h3>         
              </div>
              <p className="text-gray-600 text-sm">
			  Minimizes downtime, extends equipment life, and reduces maintenance costs.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaPaintBrush className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Aesthetic Appeal</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Create visually appealing interfaces that align with brand identity.
              </p>
            </div> */}

          </div>
        </div>

      
      </div>
    </div>
</div>
  )
}
