import React from "react";
import img1 from "../../../assets/img/aeroaegis_capabilities/IIot.png";
import { MdOutlineInsights, MdOutlineDeviceHub } from "react-icons/md";
import { BiAnalyse, BiNetworkChart } from "react-icons/bi";

export default function MainSection5() {
  return (
    <section className=" pt-5 ">
      {/* <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
              <div className="lg:w-1/2 xl:w-3/5 ">
                <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                  <img
                    src={aeroedge_iiot_img}
                    alt=""
                    className="w-52"
                  />
                </div>
              </div>
              <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white ">
                <h3 className="text-2xl font-bold tracki sm:text-3xl ">
                AeroEdge IIoT
                </h3>
                <div className="flex space-x-2 sm:space-x-4 pt-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    ></path>
                  </svg>
                  <div className="space-y-2">
                    <p className="text-lg font-medium leadi">
                    AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration, thus empowering industries.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Edge AI technology in action"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                AeroEdge IIoT
              </h1>
              <p className="text-lg text-gray-600">
                Leverages Edge AI to empower industries with real-time data and
                analytics.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineInsights className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Real-Time Data Processing
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Process data in real time to enhance operational efficiency
                  and decision-making.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <BiAnalyse className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Predictive Analytics
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Utilize predictive models to forecast and prevent potential
                  issues proactively.
                </p>
              </div>

              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <BiNetworkChart className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    System Integration
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Seamlessly integrate with existing systems for comprehensive
                  operations.
                </p>
              </div>

              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineDeviceHub className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Edge AI Capabilities
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Harness advanced Edge AI to empower industries with
                  intelligent solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
