import React from "react";
import img1 from "../../../assets/img/discover_aeroaegis/8.jpg";
import {
  HiOutlineUserGroup,
  HiOutlineLightBulb,
  HiOutlineBriefcase,
} from "react-icons/hi";
import { MdOutlineHandshake as HiOutlineHandshake } from "react-icons/md";

export default function MainSection9() {
  return (
    <section className=" pt-5 mb-10">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>

          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Customer-Centricity: At the Heart of Everything We Do
              </h1>
              <p className="text-lg text-gray-600">
                Our commitment is to exceed client expectations and drive mutual
                success.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineUserGroup className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Client-First Approach
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  We prioritize understanding your challenges and aspirations to
                  provide tailored solutions.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineLightBulb className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Innovative Solutions
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Leveraging innovation to deliver impactful, future-ready
                  results.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineHandshake className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Long-Term Relationships
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Building partnerships that thrive on trust and mutual growth.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineBriefcase className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Business-Centric Insights
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Tailored strategies that align with your business context and
                  goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
