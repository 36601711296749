import Iiot_img from "../../../../assets/img/Partnerships/Iiot.jpg";

import { AiOutlineCloudServer } from "react-icons/ai";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoIosGitNetwork } from "react-icons/io";

function Section3() {
  return (
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
             <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
               <div className="space-y-8">
                 <div className="space-y-4">
                   <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                   Benefits for Our Clients
                   </h1>
                   <p className="text-lg text-gray-600">
                   Through this collaboration, our clients gain access to:
                   </p>
                 </div>
       
                 <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                   {/* Feature 1 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <AiOutlineCloudServer className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Scalable and Secure Infrastructure
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     Alibaba Cloud's
                  global network ensures robust performance and security for
                  your applications and data.
                     </p>
                   </div>
       
                   {/* Feature 2 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Innovative AI and Machine Learning Tools
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     Leverage
                  advanced AI capabilities to drive innovation and maintain a
                  competitive edge.
                     </p>
                   </div>
                   {/* Feature 3 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <IoIosGitNetwork className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Comprehensive IIoT Solutions
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     Implement end-to-end IIoT
                     systems that enhance operational efficiency and productivity.
                     </p>
                   </div>
                 </div>
               </div>
       
               <div className="relative h-[500px] rounded-3xl overflow-hidden">
                 <img
                   src={Iiot_img}
                   alt="Workspace with laptop and plant"
                   className="object-cover w-full h-full rounded-3xl"
                 />
               </div>
             </div>
           </div>
  );
}
export default Section3;
{/* <div>
      <section className="dark:bg-gray-800 dark:text-gray-100 mt-10">
        <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
            <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
              Benefits for Our Clients 
            </h3>
            <p className="text-lg font-medium leading">
              Through this collaboration, our clients gain access to:
            </p>
            <div className="flex space-x-2 sm:space-x-4 pt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leading">
                  <b>Scalable and Secure Infrastructure: </b> Alibaba Cloud's
                  global network ensures robust performance and security for
                  your applications and data.
                </p>
              </div>
            </div>

            <div className="flex space-x-2 sm:space-x-4 pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leading">
                  <b>Innovative AI and Machine Learning Tools: </b> Leverage
                  advanced AI capabilities to drive innovation and maintain a
                  competitive edge.
                </p>
              </div>
            </div>
            <div className="flex space-x-2 sm:space-x-4 pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leading">
                  <b>Comprehensive IIoT Solutions: </b>Implement end-to-end IIoT
                  systems that enhance operational efficiency and productivity.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src=''
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
      </section>
    </div> */}