import { MdOutlineSpeed, MdOutlineCloud } from 'react-icons/md';
import { AiOutlineSync } from 'react-icons/ai';
import { IoMdCloud } from 'react-icons/io';
import img1 from "../../../../assets/img/Services/IotEdgeSolution/2149406777.jpg"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Edge-Cloud Integration
            </h1>
            <p className="text-lg text-gray-600">
            Build integrated solutions that balance edge processing with cloud scalability.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSpeed className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Real-Time Data Processing</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enable low-latency decision-making by processing critical data at the edge while offloading complex analytics to the cloud.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCloud className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Scalable Workload Management</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Dynamically distribute workloads between edge and cloud to optimize resource utilization and ensure seamless scalability.
              </p>
            </div>

           
          </div>
        </div>

      
      </div>
    </div>
  )
}