import React from 'react'
import { MdTrendingUp, MdAttachMoney, MdPieChart } from 'react-icons/md'

const FP = () => {
    const features = [
      {
          icon:
          <MdTrendingUp className='w-6 h-6'/>,
          title: "Revenue Growth",
          desc: "We forecast strong growth driven by increasing adoption of our AI and cloud solutions, expanded product portfolios, and new strategic partnerships."
      },
      {
          icon:
          <MdAttachMoney className='w-6 h-6'/>,
          title: "Profit Margins",
          desc: "A focus on operational efficiency and innovative technologies ensures robust and scalable profit margins."
      },
      {
          icon:
          <MdPieChart className='w-6 h-6' />,
          title: "Return on Investment (ROI)",
          desc: "With a balanced strategy combining products, services, and R&D, we are confident in delivering high ROI for our investors."
      },
  ]
  return (
    <section className="relative py-28 bg-white">
    <div className="relative z-10 max-w-screen-xl mx-auto px-4 text-gray-700 justify-between gap-24 lg:flex md:px-8">
        <div className="max-w-xl">
            <h3 className="text-gray-900 text-3xl font-semibold sm:text-4xl">
                Financial Projections
            </h3>
            <p className="mt-3">
                AeroAegis is committed to transparency and providing investors with a clear understanding of our financial outlook, supported by rigorous market research and strategic planning.
            </p>
        </div>
        <div className="mt-12 lg:mt-0">
            <ul className="grid gap-8 sm:grid-cols-2">
                {features.map((item, idx) => (
                    <li key={idx} className="flex gap-x-4">
                        <div className="flex-none w-12 h-12 bg-gray-100 text-[#27337D] rounded-lg flex items-center justify-center">
                            {item.icon}
                        </div>
                        <div>
                            <h4 className="text-lg text-[#27337D] font-semibold">
                                {item.title}
                            </h4>
                            <p className="mt-3">
                                {item.desc}
                            </p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </div>
    <div
        className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]"
        style={{
            background: "linear-gradient(152.92deg, rgba(0, 156, 255, 0.2) 4.54%, rgba(98, 182, 255, 0.26) 34.2%, rgba(0, 156, 255, 0.1) 77.55%)"
        }}
    ></div>
</section>
  )
}

export default FP