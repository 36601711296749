
import img1 from "../../../../assets/img/Services/StrategicConsulting/15082.jpg"
import { FaCompass, FaDocker, FaLightbulb, FaPaintBrush, FaUserAlt } from "react-icons/fa"
import { GoGraph } from "react-icons/go"
import { AiOutlineAppstoreAdd } from "react-icons/ai";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Maturity Spectrum Assessment
            </h1>
            <p className="text-lg text-gray-600">Assess your organization’s digital maturity using our proprietary frameworks, such as <strong>Maturity Spectrum 360</strong>, to identify areas of improvement.
</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">


           {/* Feature 2 */}
           <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineAppstoreAdd className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Digital Maturity Evaluation</h3>         
              </div>
              <p className="text-gray-600 text-sm">
              Assess your organization's current digital maturity level.
              </p>
            </div>


            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaLightbulb className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Actionable Insights</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Gain actionable insights to improve digital maturity.
              </p>
            </div>


          </div>
        </div>

      
      </div>
    </div>
  )
}