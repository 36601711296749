import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { MdOutlineAutorenew } from 'react-icons/md'; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from 'react-icons/md'; // Continuous Model Improvement
import { MdOutlineGavel } from 'react-icons/md';
import img1 from "../../../../assets/img/Services/AI&ML/MLOps-use-cases.png"

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            MLOps Implementation
            </h1>
            <p className="text-lg text-gray-600">
            Automate model lifecycle management with MLOps for monitoring, retraining, and governance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineAutorenew className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Streamlined Model Lifecycle Management</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Automate deployment, monitoring, and maintenance of models with MLOps practices.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Continuous Model Improvement</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enable automated retraining to ensure models adapt to changing data and maintain accuracy.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineGavel className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Enhanced Governance and Compliance</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Implement robust governance frameworks for secure, transparent, and compliant AI operations.
              </p>
            </div>

         
          </div>
        </div>

      
      </div>
    </div>
  )
}