import React from "react";
import { FaClock, FaChartBar, FaClipboardCheck } from "react-icons/fa";

export default function RealImpactSection() {
  return (
    <section className="py-10 bg-gradient-to-b from-indigo-50 to-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
          Real Impact: A Case Study
        </h2>
        <div className="bg-white rounded-xl shadow-2xl overflow-hidden">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 p-8 lg:p-12">
              <h3 className="text-2xl font-bold text-indigo-800 mb-6">
                Leading Retail E-Commerce Transformation
              </h3>
              <p className="text-gray-600 mb-8">
                A leading e-commerce retailer partnered with Aero Aegis to
                enhance its personalization and supply chain efficiency. By
                implementing AI-driven recommendation engines and IoT-based
                inventory tracking, we delivered:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaClock className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                  25%
                  </h4>
                  <p className="text-gray-700">
                  Increase in Conversion Rates
                  </p>
                </div>
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaChartBar className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                  30%
                  </h4>
                  <p className="text-gray-700">
                  Reduction in Stock-Outs and Overstock
                  </p>
                </div>
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaClipboardCheck className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                  15% 
                  </h4>
                  <p className="text-gray-700">
                  Improvement in Customer Retention Rates
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 relative">
              <img
                src="https://images.unsplash.com/photo-1522684462852-01b24e76b77d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Retailed E-Commerce"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-indigo-800 opacity-20"></div>
              <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-indigo-900 to-transparent">
                <p className="text-white text-lg font-semibold">
                  Empowering Retailers through innovative e-commerce technology
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
