import React, { useState, useEffect } from "react";
import { FaPlane, FaRocket, FaBoxOpen, FaCogs } from "react-icons/fa";
import img1 from "../../../assets/img/Industries/Aerospace/d263b1a3-b3b3-45bf-8540-35bf3a2d1353.jpg";
import img2 from "../../../assets/img/Industries/Aerospace/8d5016ff-6469-43e2-9da6-6a9624d37d32.jpg";
import img3 from "../../../assets/img/Industries/Aerospace/3774b65c-0bd4-4a92-a5a8-164383c6a15b.jpg";
import img4 from "../../../assets/img/Industries/Aerospace/26474c72-b883-4245-b815-fc8d44e4a011.jpg";

const AerospaceCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl md:w-80 w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const AerospaceSection = () => {
  const aerospaceAreas = [
    {
      title: "Aircraft Manufacturing",
      description:
        "Modernize production lines with smart factory solutions, additive manufacturing, and advanced robotics.",
      icon: <FaPlane />,
      imageUrl: img1,
    },
    {
      title: "Flight Operations",
      description:
        "Enhance safety and efficiency with AI-driven analytics, autonomous navigation, and IoT-based monitoring systems.",
      icon: <FaCogs />,
      imageUrl: img2,
    },
    {
      title: "Supply Chain and Logistics",
      description:
        "Ensure transparency, compliance, and reliability across the aerospace supply chain with blockchain and predictive analytics.",
      icon: <FaBoxOpen />,
      imageUrl: img3,
    },
    {
      title: "Space Exploration",
      description:
        "Support space missions with advanced technology solutions for satellite systems, data analytics, and mission control.",
      icon: <FaRocket />,
      imageUrl: img4,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
          Transforming Key Areas of Aerospace
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
          Leveraging cutting-edge technology to revolutionize the aerospace
          industry.
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-4 gap-14">
          {aerospaceAreas.map((area, index) => (
            <AerospaceCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AerospaceSection;
