import { IoShieldCheckmark } from "react-icons/io5" 
import { FaLock } from "react-icons/fa" 
import { MdOutlineVerifiedUser } from 'react-icons/md'; 
import img1 from '../../../assets/img/Industries/Aerospace/9b89d5c8-b5be-4269-9001-f562169c7efb.jpg'

export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Cybersecurity and Compliance
            </h1>
            <p className="text-lg text-gray-600">
              Protect critical aerospace operations with robust cybersecurity measures and ensure compliance with industry regulations.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Critical Infrastructure Protection</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Safeguard aerospace systems from cyber threats with advanced cybersecurity measures.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineVerifiedUser className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Regulatory Compliance Automation</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Streamline compliance with global aerospace standards using AI and analytics-driven reporting tools.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoShieldCheckmark className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Data Security</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Protect sensitive aerospace data with secure, encrypted systems.
              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}