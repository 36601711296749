import React from 'react';
import { FaCloudUploadAlt, FaCloud, FaBolt, FaShieldAlt, FaChartBar, FaChevronRight, FaLightbulb, FaUsers, FaRocket } from 'react-icons/fa';

const CaseStudyPage = () => {
  return (
    <div className="mx-auto px-4 md:px-24 py-8 w-full max-w-7xl">
      <div className="uppercase tracking-wide text-1xl font-bold text-indigo-500">Case Study</div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white">
        Revolutionizing Healthcare with Cloud-Native, Event-Driven Architecture, and Big Data Analytics
      </h1>
      <h2 className="mt-2 text-2xl font-semibold text-gray-700 dark:text-gray-300">
        AeroAegis Inc. Delivers Scalable, Mission-Critical Solutions for a Leading Healthcare Organization
      </h2>
      <div className="mt-4 mb-8 flex flex-wrap gap-4">
        <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-sm font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
          <FaCloud className="mr-1 h-3 w-3" />
          Cloud-Native
        </span>
        <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-sm font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
          <FaChartBar className="mr-1 h-3 w-3" />
          Big Data
        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-sm font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
          <FaShieldAlt className="mr-1 h-3 w-3" />
          Healthcare
        </span>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Case Study Description</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          The healthcare industry is undergoing a digital revolution, driven by the need for better patient care, operational efficiency, and regulatory compliance. A leading healthcare organization approached AeroAegis Inc. to modernize its legacy systems and improve its data handling capabilities. The client faced challenges in processing real-time data across multiple applications while ensuring compliance with strict industry regulations.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis Inc., in collaboration with Alibaba Cloud, delivered a comprehensive cloud-native solution. Leveraging the Maturity Spectrum 360 framework, the solution was designed to assess and enhance the organization's cloud readiness. AeroAegis's SmartChaosLab provided a simulated environment to validate the system's resilience and scalability.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Key components of the solution included an event-driven architecture for real-time data processing and big data analytics for actionable insights. These innovations enabled the client to scale their operations, enhance decision-making, and improve patient care delivery—all while reducing operational costs.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          This case study demonstrates AeroAegis's expertise in mission-critical application development and its ability to deliver tailored, future-proof solutions for complex industries like healthcare.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Client Background</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          The healthcare industry is a dynamic and highly regulated sector where precision, speed, and data security are critical. Organizations in this space manage vast amounts of sensitive patient information, requiring advanced systems to ensure seamless data processing, compliance, and real-time decision-making.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Our client, a prominent player in the healthcare industry, operates a network of hospitals and clinics, catering to millions of patients annually. Their legacy infrastructure, comprising on-premises data centers and monolithic applications, had reached its limits. As patient numbers grew and healthcare data increased exponentially, the organization faced significant challenges, including:
        </p>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
          <li>Data Silos: Disparate systems across departments created inefficiencies and hindered real-time data sharing.</li>
          <li>Slow Response Times: Legacy systems struggled to process high volumes of data in real time, impacting patient care and operational efficiency.</li>
          <li>Compliance and Security Risks: Managing sensitive patient data while adhering to healthcare regulations such as HIPAA presented ongoing challenges.</li>
          <li>Scalability Constraints: The organization's infrastructure could not support the growing demand for digital services, including telemedicine and remote patient monitoring.</li>
        </ul>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Recognizing the need for a transformative solution, the client sought AeroAegis Inc.'s expertise in cloud-native development, big data analytics, and event-driven architecture to modernize their digital ecosystem. AeroAegis's proven track record in delivering mission-critical applications for the healthcare sector made them the ideal partner for this project.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          By embarking on this journey, the client aimed to achieve:
        </p>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
          <li>Enhanced Patient Outcomes through faster and more accurate decision-making.</li>
          <li>Operational Excellence by optimizing data flows and automating key processes.</li>
          <li>Future-Proof Infrastructure capable of scaling with the organization's needs.</li>
        </ul>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          The partnership with AeroAegis Inc. marked a pivotal step in the client's digital transformation journey, setting a new benchmark for healthcare innovation.
        </p>
      </div>

      <div className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Challenges Faced by the Client</h2>
  <p className="text-gray-700 dark:text-gray-300 mb-2">
    The healthcare organization faced several critical challenges that hindered its ability to deliver optimal patient care and operate efficiently. These challenges stemmed from legacy systems, evolving industry demands, and the complex regulatory environment inherent to healthcare.
  </p>
  <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
    <li className="mb-4">
      <strong>Legacy Infrastructure Limitations</strong>
      <p className="text-gray-700 dark:text-gray-300 mt-2">
        The client’s existing IT infrastructure was built on a monolithic architecture with on-premises data centers. While these systems had served the organization for years, they were ill-equipped to handle the increasing volume and complexity of healthcare data. Key limitations included:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Inflexibility: The monolithic systems were difficult to modify or scale, leading to long development cycles for new features.</li>
        <li>High Maintenance Costs: Keeping legacy systems operational consumed a significant portion of the IT budget, leaving little room for innovation.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Inefficient Data Management and Processing</strong>
      <p className="text-gray-700 dark:text-gray-300 mt-2">
        With the rise of digital health services such as telemedicine, wearable devices, and remote patient monitoring, the organization struggled to process and analyze data in real time.
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Data Silos: Patient data was fragmented across multiple systems, resulting in delays and inaccuracies in decision-making.</li>
        <li>Latency Issues: The lack of real-time data processing capability hindered critical operations such as emergency response and clinical decision support.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Compliance and Security Challenges</strong>
      <p className="text-gray-700 dark:text-gray-300 mt-2">
        Healthcare organizations are bound by stringent regulations like HIPAA and GDPR, requiring robust data security and privacy measures. The client’s legacy systems posed several risks:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Vulnerabilities in Data Protection: Outdated security protocols left sensitive patient data exposed to potential breaches.</li>
        <li>Regulatory Non-Compliance: Manual processes for compliance monitoring and reporting were time-consuming and prone to errors, risking hefty penalties.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Scalability and Performance Bottlenecks</strong>
      <p className="text-gray-700 dark:text-gray-300 mt-2">
        The client experienced significant growth in patient numbers, with an increased demand for digital services. However, their existing infrastructure could not keep up.
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Performance Degradation: The systems frequently faced downtime or slow response times, affecting critical applications such as patient record management and appointment scheduling.</li>
        <li>Inability to Scale: Adding new services or handling peak workloads, such as during pandemics or emergencies, was a persistent challenge.</li>
      </ul>
    </li>
    <li>
      <strong>Limited Analytics and Decision-Making Capabilities</strong>
      <p className="text-gray-700 dark:text-gray-300 mt-2">
        The client recognized the growing importance of data-driven decision-making in healthcare. However, their existing systems lacked the ability to provide real-time insights or predictive analytics.
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Reactive Decision-Making: Without advanced analytics, the organization could only react to issues after they occurred, rather than preventing them proactively.</li>
        <li>Missed Opportunities: The absence of actionable insights hindered the organization’s ability to innovate and optimize patient care.</li>
      </ul>
    </li>
  </ol>
</div>




<div className="mb-8">
  <h2 className="text-2xl font-bold mb-4">AeroAegis's Approach</h2>
  <p className="text-gray-700 dark:text-gray-300 mb-4">
    To address the client’s challenges, AeroAegis Inc. adopted a holistic, phased approach that combined deep industry expertise with cutting-edge technology. Our strategy centered on delivering a robust, cloud-native, event-driven solution powered by big data analytics, all while ensuring regulatory compliance and system resilience. The approach was underpinned by AeroAegis’s proprietary frameworks—Maturity Spectrum 360 and SmartChaosLab—and our strategic partnership with Alibaba Cloud.
  </p>
  <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
    <li className="mb-4">
      <strong>Comprehensive Assessment with Maturity Spectrum 360</strong>
      <p className="ml-6 mt-2">
        AeroAegis initiated the project with a thorough assessment of the client’s existing IT landscape using our Maturity Spectrum 360 framework. This framework provides a structured methodology to evaluate an organization’s cloud readiness and identify gaps across critical domains such as infrastructure, data management, and security.
      </p>
      <p className="ml-6 mt-2">
        Key findings from the assessment included:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Low maturity in cloud-native adoption, requiring foundational improvements in microservices and containerization.</li>
        <li>Inefficient data pipelines that could not support real-time analytics.</li>
        <li>Security protocols that needed enhancement to meet healthcare compliance standards.</li>
      </ul>
      <p className="ml-6 mt-2">
        Based on these insights, a tailored roadmap was developed to guide the client’s digital transformation journey.
      </p>
    </li>
    <li className="mb-4">
      <strong>Solution Design with SmartChaosLab</strong>
      <p className="ml-6 mt-2">
        To ensure the robustness of the proposed solution, AeroAegis leveraged its SmartChaosLab framework. This environment enabled us to simulate various real-world scenarios, including high-traffic conditions, security threats, and system failures. The simulations helped validate the system’s:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Resilience: Ensuring the architecture could handle mission-critical workloads without downtime.</li>
        <li>Scalability: Verifying the solution could seamlessly scale during peak demand.</li>
        <li>Compliance: Testing data security measures against healthcare regulations.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Cloud-Native Architecture Development</strong>
      <p className="ml-6 mt-2">
        AeroAegis designed and implemented a cloud-native architecture that transformed the client’s IT environment. Key components included:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Microservices Architecture: Replacing monolithic applications with modular, independently deployable services.</li>
        <li>Containerization and Orchestration: Utilizing Docker and Kubernetes to manage and scale workloads efficiently.</li>
        <li>Event-Driven Architecture: Leveraging tools like Apache Kafka to enable real-time data streaming and event processing.</li>
      </ul>
      <p className="ml-6 mt-2">
        These enhancements ensured that the client’s systems were flexible, scalable, and capable of supporting future innovations.
      </p>
    </li>
    <li className="mb-4">
      <strong>Big Data Analytics and Insights</strong>
      <p className="ml-6 mt-2">
        AeroAegis integrated a powerful big data analytics platform, leveraging Alibaba Cloud’s MaxCompute and DataWorks for large-scale data processing and management. The solution provided:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Real-Time Analytics: Delivering actionable insights to improve clinical decision-making and operational efficiency.</li>
        <li>Predictive Analytics: Enabling proactive healthcare interventions, such as predicting patient readmission risks.</li>
        <li>Centralized Data Lake: Consolidating data from multiple sources to break down silos and improve data accessibility.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Strengthening Security and Compliance</strong>
      <p className="ml-6 mt-2">
        Given the sensitive nature of healthcare data, AeroAegis implemented advanced security measures:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Data Encryption: Ensuring data protection both at rest and in transit.</li>
        <li>Access Control and Monitoring: Using Alibaba Cloud’s Security Center to enforce strict access controls and provide continuous monitoring.</li>
        <li>Automated Compliance Reporting: Streamlining regulatory reporting to ensure adherence to HIPAA and other standards.</li>
      </ul>
    </li>
    <li>
      <strong>Collaborative Execution through COE Teams</strong>
      <p className="ml-6 mt-2">
        AeroAegis’s Centers of Excellence (COEs) played a pivotal role in the project’s success. Our COEs brought together experts in cloud, data, and healthcare to ensure best practices were applied at every stage.
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li>Cloud COE: Focused on architecture design, migration, and cloud-native development.</li>
        <li>Data and Analytics COE: Drove the big data and analytics implementation, delivering insights to the client’s key stakeholders.</li>
        <li>Healthcare COE: Ensured the solution met the unique demands of the healthcare industry, including compliance and patient-centric outcomes.</li>
      </ul>
    </li>
  </ol>
</div>


<div className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Solution Implementation</h2>
  <p className="text-gray-700 dark:text-gray-300 mb-4">
    The solution implementation phase was a critical component of the client’s digital transformation journey. AeroAegis Inc. employed a systematic approach to design and deploy a cloud-native, event-driven, and big data-enabled architecture. This solution was tailored to meet the unique needs of the healthcare industry, ensuring scalability, security, and real-time data processing.
  </p>
  <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
    <li className="mb-4">
      <strong>Cloud-Native Architecture</strong>
      <p className="ml-6 mt-2">
        The foundation of the solution was a fully cloud-native architecture designed for high availability and agility. Key design elements included:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li><strong>Microservices-Based Design:</strong> AeroAegis replaced the client’s monolithic applications with a microservices architecture. This modular approach allowed independent development, deployment, and scaling of each service, improving system flexibility and reducing time-to-market for new features.</li>
        <li><strong>Containerization with Docker and Kubernetes:</strong> All microservices were containerized using Docker, ensuring consistency across development, testing, and production environments. Kubernetes was employed for orchestration, automating deployment, scaling, and management of containerized applications.</li>
        <li><strong>Serverless Computing:</strong> Where appropriate, serverless functions were deployed to handle intermittent workloads, reducing infrastructure management overhead and optimizing costs.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Event-Driven Architecture</strong>
      <p className="ml-6 mt-2">
        Given the need for real-time data processing, an event-driven architecture (EDA) was designed and implemented. Key components included:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li><strong>Apache Kafka for Event Streaming:</strong> Apache Kafka served as the backbone for event streaming, enabling real-time data ingestion and processing across multiple systems. This ensured low-latency communication and quick response times for mission-critical applications, such as emergency patient alerts and real-time monitoring of medical devices.</li>
        <li><strong>Event Sourcing:</strong> Event sourcing was implemented to maintain a reliable log of all system changes, ensuring data integrity and enabling detailed auditing and replay capabilities.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Big Data and Analytics Platform</strong>
      <p className="ml-6 mt-2">
        To unlock the full potential of the client’s healthcare data, AeroAegis designed an advanced big data and analytics platform:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li><strong>Centralized Data Lake:</strong> A centralized data lake was established using Alibaba Cloud Object Storage Service (OSS) to store structured and unstructured data. This served as a single source of truth, breaking down data silos and enabling seamless access for analytics.</li>
        <li><strong>Data Processing with MaxCompute:</strong> Alibaba Cloud MaxCompute was used for large-scale data processing, enabling both batch and real-time analytics on massive datasets.</li>
        <li><strong>Data Integration and Workflow Orchestration:</strong> DataWorks, Alibaba Cloud’s data integration and workflow orchestration service, was utilized to streamline data pipelines, ensuring efficient and reliable data flow across systems.</li>
        <li><strong>Real-Time Dashboards and Insights:</strong> Interactive dashboards powered by Quick BI provided stakeholders with real-time insights, enabling proactive decision-making. Predictive analytics models were also deployed to forecast patient trends, optimize resource allocation, and improve patient outcomes.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Security and Compliance by Design</strong>
      <p className="ml-6 mt-2">
        Given the sensitive nature of healthcare data, security was a top priority. AeroAegis designed a robust security framework, ensuring compliance with industry regulations like HIPAA:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li><strong>Data Encryption and Access Control:</strong> All data was encrypted at rest and in transit using Alibaba Cloud Key Management Service (KMS). Role-based access control (RBAC) ensured that only authorized personnel could access sensitive information.</li>
        <li><strong>Threat Detection and Monitoring:</strong> Alibaba Cloud Security Center was implemented to provide continuous threat detection, vulnerability management, and automated incident response.</li>
        <li><strong>Automated Compliance Reporting:</strong> Compliance reporting processes were automated, ensuring that regulatory requirements were met efficiently and accurately.</li>
      </ul>
    </li>
    <li className="mb-4">
      <strong>Integration with Legacy Systems</strong>
      <p className="ml-6 mt-2">
        To ensure a smooth transition, AeroAegis implemented a hybrid solution that integrated with the client’s existing legacy systems. This hybrid model allowed critical operations to continue uninterrupted while new capabilities were gradually rolled out. Key integration points included:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li><strong>Data Synchronization:</strong> Real-time data synchronization was achieved between legacy systems and the new cloud platform using Change Data Capture (CDC) tools.</li>
        <li><strong>APIs for Interoperability:</strong> RESTful APIs were developed to facilitate seamless communication between legacy and cloud-native systems.</li>
      </ul>
    </li>
    <li>
      <strong>Continuous Delivery and DevOps Practices</strong>
      <p className="ml-6 mt-2">
        To ensure rapid, reliable delivery of new features and updates, AeroAegis introduced a robust DevOps pipeline:
      </p>
      <ul className="list-disc list-inside ml-6 mt-2">
        <li><strong>Continuous Integration/Continuous Deployment (CI/CD):</strong> Automated CI/CD pipelines were established using Jenkins and GitLab, enabling frequent, reliable updates with minimal downtime.</li>
        <li><strong>Infrastructure as Code (IaC):</strong> Tools like Terraform and Ansible were employed to automate infrastructure provisioning and management, ensuring consistency and reducing manual errors.</li>
      </ul>
    </li>
  </ol>
</div>


<div class="mb-8">
  <h2 class="text-2xl font-bold mb-4">Key Outcomes and Benefits</h2>
  <ol class="list-decimal list-inside text-gray-700 dark:text-gray-300">
    <li class="mb-4">
      <strong>Enhanced System Performance and Scalability</strong>
      <ul class="list-disc list-inside ml-6 mt-2">
        <li><strong>Increased Uptime and Reliability:</strong> Mission-critical applications now operate with near-zero downtime, ensuring uninterrupted access to healthcare services.</li>
        <li><strong>Dynamic Scalability:</strong> The use of Kubernetes and serverless computing enabled the system to scale dynamically in response to varying workloads. This was particularly beneficial during peak times, such as during public health emergencies, when demand for services spiked.</li>
      </ul>
    </li>

    <li class="mb-4">
      <strong>Real-Time Data Processing and Insights</strong>
      <ul class="list-disc list-inside ml-6 mt-2">
        <li><strong>Faster Decision-Making:</strong> Real-time analytics dashboards provided instant insights into patient data, enabling quicker clinical decisions and improving patient outcomes.</li>
        <li><strong>Predictive Analytics:</strong> Predictive models empowered the organization to anticipate patient needs, optimize resource allocation, and reduce patient readmission rates.</li>
      </ul>
    </li>

    <li class="mb-4">
      <strong>Improved Patient Care and Outcomes</strong>
      <ul class="list-disc list-inside ml-6 mt-2">
        <li><strong>Proactive Healthcare Interventions:</strong> Real-time monitoring of patient health metrics allowed for early detection of critical conditions, enabling timely interventions.</li>
        <li><strong>Personalized Treatment Plans:</strong> Data-driven insights supported the development of personalized care plans, improving patient satisfaction and outcomes.</li>
      </ul>
    </li>

    <li class="mb-4">
      <strong>Regulatory Compliance and Enhanced Security</strong>
      <ul class="list-disc list-inside ml-6 mt-2">
        <li><strong>Automated Compliance Reporting:</strong> Compliance processes were streamlined, reducing the risk of penalties and freeing up resources for other priorities.</li>
        <li><strong>Enhanced Data Security:</strong> With advanced encryption, access controls, and continuous monitoring, the client significantly reduced their risk of data breaches and maintained patient trust.</li>
      </ul>
    </li>

    <li class="mb-4">
      <strong>Operational Efficiency and Cost Savings</strong>
      <ul class="list-disc list-inside ml-6 mt-2">
        <li><strong>Lower Infrastructure Costs:</strong> By moving to a cloud-native environment and utilizing serverless computing, the client reduced their reliance on costly on-premises infrastructure.</li>
        <li><strong>Streamlined Operations:</strong> Automated workflows and real-time data synchronization minimized manual intervention, increasing efficiency across departments.</li>
      </ul>
    </li>

    <li>
      <strong>Foundation for Innovation</strong>
      <ul class="list-disc list-inside ml-6 mt-2">
        <li><strong>Future-Ready Architecture:</strong> The scalable and flexible design enables the organization to quickly adopt emerging technologies, such as AI-driven diagnostics and IoT-enabled medical devices.</li>
        <li><strong>Improved Agility:</strong> With faster development cycles and a robust DevOps pipeline, the client can rapidly introduce new features and services to stay ahead in a competitive industry.</li>
      </ul>
    </li>
  </ol>
</div>


      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Conclusion and Call to Action</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
          The healthcare organization's digital transformation journey, guided by AeroAegis Inc., highlights the critical role of cloud-native solutions, event-driven architectures, and big data analytics in addressing the complex challenges of modern healthcare. By leveraging AeroAegis's proprietary frameworks—Maturity Spectrum 360 and SmartChaosLab—alongside cutting-edge Alibaba Cloud technologies, the client achieved unparalleled improvements in scalability, operational efficiency, and patient outcomes.
        </p>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaCloudUploadAlt className="mr-2 text-blue-500" />
              Cloud-Native Architecture
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Implemented a microservices-based design with containerization using Docker and Kubernetes, enabling scalable and flexible healthcare applications.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaBolt className="mr-2 text-yellow-500" />
              Event-Driven Architecture
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Utilized Apache Kafka for event streaming, enabling real-time data processing and improving responsiveness in critical healthcare scenarios.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaShieldAlt className="mr-2 text-green-500" />
              Security and Compliance
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Implemented end-to-end encryption, role-based access control, and automated compliance reporting to ensure HIPAA compliance and data protection.
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FaChartBar className="mr-2 text-purple-500" />
              Big Data Analytics
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Developed a centralized data lake and implemented real-time analytics dashboards, enabling data-driven decision making and predictive healthcare interventions.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 p-4 mb-8">
        <p className="text-blue-700 dark:text-blue-300">
          This case study demonstrates AeroAegis Inc.'s expertise in delivering mission-critical solutions tailored to the unique needs of highly regulated industries like healthcare. From enhancing real-time decision-making capabilities to ensuring stringent compliance with data privacy regulations, AeroAegis has proven to be a trusted partner in driving digital transformation.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Why Choose AeroAegis Inc.?</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis Inc. combines deep industry expertise, innovative proprietary frameworks, and strategic technology partnerships to deliver end-to-end digital transformation solutions. Our team of experts from various Centers of Excellence (COEs) ensures that each solution is customized to meet the unique demands of our clients. Whether you're looking to modernize your legacy systems, harness the power of big data, or build scalable, cloud-native applications, AeroAegis has the tools, expertise, and vision to make it happen.
        </p>
        <div className="grid md:grid-cols-3 gap-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaLightbulb className="text-3xl text-blue-500 mb-2" />
            <h3 className="font-semibold mb-2">Innovative Solutions</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">Cutting-edge technologies tailored to your needs</p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaUsers className="text-3xl text-green-500 mb-2" />
            <h3 className="font-semibold mb-2">Expert Team</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">Diverse COEs ensuring comprehensive solutions</p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <FaRocket className="text-3xl text-purple-500 mb-2" />
            <h3 className="font-semibold mb-2">Accelerated Transformation</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">Rapid implementation for faster business growth</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-bold mb-4">Let's Transform Your Business Together</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Are you ready to take your organization to the next level? AeroAegis Inc. is here to help. Contact us today to explore how our consulting and managed services can accelerate your digital transformation journey.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Visit <a href="http://www.aeroaegis.com" className="text-blue-500 hover:underline">www.aeroaegis.com</a> or reach out to our experts for a personalized consultation. Together, we can build innovative, resilient, and scalable solutions that drive business success.
        </p>
        <p className="text-gray-700 dark:text-gray-300 font-semibold">
          Let's shape the future—one solution at a time.
        </p>
      </div>

    </div>
  );
};

export default CaseStudyPage;