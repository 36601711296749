import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/ProductEngineering/3.png"
import { FaCompass, FaDocker, FaPaintBrush, FaUserAlt } from "react-icons/fa"
import { GoGraph } from "react-icons/go"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Containerization & Orchestration
            </h1>
            <p className="text-lg text-gray-600">
            Leverage Docker and Kubernetes to simplify deployment and optimize resource usage.
                     </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">


           {/* Feature 2 */}
           <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaDocker className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Simplify Deployment</h3>         
              </div>
              <p className="text-gray-600 text-sm">
              Docker allows easy packaging of applications into containers, ensuring consistency across environments.
              </p>
            </div>


            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GoGraph className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Enhanced Scalability</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Kubernetes scales applications seamlessly by managing clusters of containers based on demand.
              </p>
            </div>


          </div>
        </div>

      
      </div>
    </div>
  )
}