import React from 'react'
import HeroSection from './HeroSection'
import BlogsCard from './BlogsCardSection'

const index = () => {
  return (
    <>
    <HeroSection />
    <BlogsCard />
    </>
  )
}

export default index