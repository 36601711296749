import { 
  FaLightbulb, 
  FaUsers, 
  FaHandshake, 
  FaStar, 
  FaUserCircle, 
  FaRunning, 
  FaHandsHelping 
} from 'react-icons/fa'

export default function ValuesSection() {
  const values = [
    {
      icon: FaLightbulb,
      title: "Innovation",
      description: "We believe in continuously pushing the boundaries of what is possible, pioneering new ideas, and embracing a future-oriented mindset. We strive to bring cutting edge solutions to our clients."
    },
    {
      icon: FaUsers,
      title: "Integrity",
      description: "We are committed to upholding the highest standards of honesty and ethics in all our dealings. We believe in doing the right thing, even when no one is watching."
    },
    {
      icon: FaHandsHelping,
      title: "Collaboration",
      description: "We believe that the best results come from working together. We foster a collaborative culture internally and strive to build strong, lasting partnerships with our clients."
    },
    {
      icon: FaStar,
      title: "Excellence",
      description: "We are passionate about what we do and are driven to deliver top-quality work in all our endeavors. We continually challenge ourselves to exceed expectations and set new benchmarks for excellence."
    },
    {
      icon: FaUserCircle,
      title: "Client Centricity",
      description: "We place our clients at the heart of everything we do. We are committed to understanding our clients' needs and providing solutions that are tailored to help them succeed."
    },
    {
      icon: FaRunning,
      title: "Agility",
      description: "We understand that the business and technology landscapes are constantly changing. We remain flexible and adaptable, always ready to adjust our strategies to best meet our clients' evolving needs."
    },
    {
      icon: FaHandshake,
      title: "Respect",
      description: "We value and respect all individuals – our employees, our clients, and our partners. We promote a diverse and inclusive environment that encourages open communication and mutual respect."
    }
  ]

  return (
    <div className="bg-gradient-to-br from-white to-blue-50 py-20 sm:py-16 md:py-20 px-4 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold mb-4">
            Our <span className="text-blue-600">Values</span>
          </h2>
          <div className="w-24 h-1 bg-blue-600 mx-auto rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 justify-center">
          {values.map((value, index) => {
            const Icon = value.icon
            return (
              <div
                key={index}
                className={`${
                  index === values.length - 1 ? 'md:col-span-2  lg:col-span-3 md:max-w-md lg:max-w-lg mx-auto' : ''
                }`}
              >
                <div className="group bg-white p-6 rounded-xl h-full shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300">
                  <div className="flex items-center mb-4">
                    <div className="bg-blue-100 p-3 rounded-lg group-hover:bg-blue-600 transition-colors duration-300">
                      <Icon className="w-6 h-6 text-blue-600 group-hover:text-white" />
                    </div>
                    <h3 className="text-xl font-semibold ml-4 text-gray-800">
                      {value.title}
                    </h3>
                  </div>
                  <p className="text-gray-600 leading-relaxed">
                    {value.description}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}