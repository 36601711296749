import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import img2 from "../../assets/img/HomePage/pexels-pranjal-srivastava-2403251.jpg";
import img1 from "../../assets/img/HomePage/photo-1522202176988-66273c2fd55f.jpg";
import { FaRocket, FaShieldAlt, FaLightbulb } from 'react-icons/fa';

const Innovation = () => {
  return (
    <div className="bg-white">
      <TextParallaxContent
        imgUrl={img1}
        subheading="Innovation"
        heading="Our Core Philosophy"
      >
        <Content1 />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl={img2}
        subheading="Transformation"
        heading="How We Empower Your Business"
      >
        <Content2 />
      </TextParallaxContent>
    </div>
  );
};

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[550px]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 500,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

const Content1 = () => (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-20  md:grid-cols-12">
    <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
      Innovation: Our Core Philosophy
    </h2>
    <div className="col-span-1 md:col-span-8">
      <p className="mb-4 text-md text-neutral-600 md:text-2xl">
        Innovation drives everything we do at AeroAegis. Our product-centric
        approach is supported by an ecosystem of specialized services that
        ensure seamless integration, optimal performance, and tangible business
        outcomes. Whether it's deploying AeroPulse AI for predictive analytics
        or enabling digital resilience with SmartChaos Lab, our team is
        dedicated to helping enterprises achieve their most ambitious goals. 
      </p>

      <button className="w-full rounded bg-[#222f79] mt-2 md:px-9 md:py-4 py-3 px-4 text-lg md:text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit">
        Learn more <FiArrowUpRight className="inline" />
      </button>
    </div>
  </div>
);

const Content2 = () => (
  <div className="mb-5">
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-10 md:grid-cols-12">
    <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
      How We Empower Your Business
    </h2>
    <div className="col-span-1 md:col-span-8">
      <p className="mb-4  text-md text-neutral-600 md:text-2xl">
        AeroAegis transforms technology into growth engines for businesses.
        Whether you’re embarking on a digital transformation journey, optimizing
        operations with cloud resilience tools, or innovating at the edge with
        IoT solutions, AeroAegis empowers you to:
      </p>

   
    </div>
  </div>
  <FeatureSection/>


  </div>
);



const FeatureCard = ({ icon, title, description ,color }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
    <div className="text-4xl mb-4" style={{ color: color }}>{icon}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const FeatureSection = () => {
  const features = [
    {
      icon: <FaRocket />,
      color: "#009cb3",
      title: "Scalable Solutions",
      description: "Overcome challenges with intelligent, adaptable solutions designed for growth."
    },
    {
      icon: <FaShieldAlt />,
      color: "#f5ae45",
      title: "Risk Minimization",
      description: "Safeguard your business with robust, AI-driven tools that mitigate risks."
    },
    {
      icon: <FaLightbulb />,
      color: "#ff8372",
      title: "Digital Opportunities",
      description: "Unlock new possibilities in the ever-evolving landscape of digital innovation."
    }
  ];

  return (
   
      <div className="container mx-auto px-4 md:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
        {/* <button className="w-full rounded bg-[#222f79] mt-2 px-9 py-4 text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit">
  Learn more <FiArrowUpRight className="inline" />
</button> */}
      </div>
   
  );
};

export default Innovation;
