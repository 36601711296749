import { IoCheckmarkCircleOutline } from "react-icons/io5";

import { FaCogs, FaRobot } from "react-icons/fa";
import img1 from "../../../assets/img/Industries/Aerospace/12433673-1ae9-43c9-a0b7-9bf0e28cba81.jpg";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Blockchain for Aerospace Supply Chain
            </h1>
            <p className="text-lg text-gray-600">
              Revolutionize aerospace supply chain management with enhanced
              transparency, security, and efficiency through blockchain
              technology.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRobot className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  End-to-End Traceability
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Ensure authenticity and compliance of aerospace components
                through blockchain-enabled traceability.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCogs className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Smart Contracts</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate procurement and payment processes for seamless vendor
                collaboration.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Counterfeit Prevention
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use blockchain to prevent counterfeit parts from entering the
                aerospace supply chain.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
