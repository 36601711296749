import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/OperationalExe/15019.jpg";

import { SiTestcafe } from "react-icons/si";
import { TbWaveSawTool } from "react-icons/tb";
import { TbWorld } from "react-icons/tb";
import { MdTrendingUp } from "react-icons/md";

export default function MainSection2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
         
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Failure Simulation
            </h1>
            <p className="text-lg text-gray-600">
            Failure simulation helps you test system resilience and reliability under controlled conditions to prepare for real-world disruptions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TbWaveSawTool className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Controlled Disruptions</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Simulate server crashes, latency spikes, and network outages to evaluate system performance.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <SiTestcafe className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Resilience Testing</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Identify weaknesses and improve system reliability by testing under stress.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TbWorld className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Real-World Readiness</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Prepare systems to handle unexpected failures effectively
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Continuous Improvement</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Use insights from simulations to enhance fault tolerance and recovery strategies.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div> 
  )
}