import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { IoLockClosedOutline } from 'react-icons/io5';
import { BiLike } from 'react-icons/bi';
import img1 from '../../../../assets/img/Services/OperationalExe/proactive.jpg';

import { RxMagnifyingGlass } from "react-icons/rx";
import { BsShieldCheck } from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Proactive Incident Identification
            </h1>
            <p className="text-lg text-gray-600">
              Proactive incident identification uncovers potential issues early
              to prevent disruptions for end users.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RxMagnifyingGlass className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Early Detection</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Identify hidden weaknesses and failure points before they
                escalate.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsShieldCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  User Experience Protection
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Prevent incidents from impacting end users by addressing issues
                proactively.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                < AiOutlineCheckCircle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Enhanced Reliability
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Strengthen system performance through preemptive issue
                resolution.
              </p>
            </div>

            {/* Feature 4 */}
            {/*   <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiLike className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">All in One</h3>
              </div>
              <p className="text-gray-600 text-sm">
                All-in-one solution ensures that you can easily manage your
                financial needs.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  ); 
}
