import React from "react";
import { MdOutlineSmartToy } from "react-icons/md";
import { RiRoadMapLine } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import { AiOutlineBarChart } from "react-icons/ai";

import img1 from "../../../assets/img/aeroaegis_capabilities/ms360.png";

export default function MainSection2() {
  return (
    <section className=" ">
      {/* <span class="mb-10 mt-10 block text-center text-4xl font-bold text-[#23307F]">
             <span className=' text-black'>Our Revolutionary Products</span>
            <div>
              <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black"></p>
            </div>
          </span>
          <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
            <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F]">
              <h3 className="text-2xl font-bold tracki sm:text-3xl ">
              Maturity Spectrum 360
              </h3>
              <div className="flex space-x-2 sm:space-x-4 pt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                <div className="space-y-2">
                  <p className="text-lg font-medium leadi">
                  A comprehensive platform that provides organizations with a
clear roadmap for digital transformation, identifying growth opportunities and areas for
improvement.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 xl:w-3/5 ">
              <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                <img
                  src={mslogo_img}
                  alt="innovative"
                  className="w-52"
                />
              </div>
            </div>
          </div> */}

      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <span class="mb-10 mt-10 block text-center text-4xl font-bold text-[#23307F]">
        Core Offerings:
             <span className='ml-2 text-black'>Our Revolutionary Products</span>
            <div>
              <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black"></p>
            </div>
          </span>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Maturity Spectrum 360
              </h1>
              <p className="text-lg text-gray-600">
                A platform providing a roadmap for digital transformation,
                highlighting growth and improvement opportunities.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <RiRoadMapLine className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Clear Transformation Roadmap
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Offering actionable steps for organizations to achieve digital
                  maturity effectively.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <BiSearchAlt className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Growth Opportunity Analysis
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Identifying potential areas for organizational improvement and
                  innovation.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineSmartToy className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    AI-Powered Insights
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Leveraging AI to generate predictive insights and data-driven
                  recommendations.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <AiOutlineBarChart className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Performance Metrics
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Monitoring key metrics to track progress and ensure successful
                  transformation.
                </p>
              </div>
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
