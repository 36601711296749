import {
  MdOutlineTimeline,
  MdOutlineSyncAlt,
  MdOutlineVisibility,
} from "react-icons/md";

import img1 from "../../../assets/img/Industries/Automotive/Blockchain_in_Supply_Chain_Management.jpg";
import { AiOutlineLock } from "react-icons/ai";
import { FaTruck } from "react-icons/fa";
import { FaFileContract } from "react-icons/fa";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Blockchain for Supply Chain and Manufacturing Transparency
            </h1>
            <p className="text-lg text-gray-600">
            Leverage blockchain technology to revolutionize manufacturing processes with enhanced traceability, streamlined operations, and end-to-end transparency. Create tamper-proof records for each component in the supply chain to ensure authenticity and compliance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineTimeline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Enhanced Traceability
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use blockchain to create a tamper-proof record of every
                component in the supply chain, ensuring authenticity and
                compliance at every stage of production.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSyncAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Streamlined Operations
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Implement blockchain-powered smart contracts to automate
                payments, inventory management, and supplier transactions,
                reducing delays and costs.
              </p>
            </div>
            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineVisibility className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  End-to-End Manufacturing Transparency
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Provide real-time visibility into manufacturing processes,
                ensuring quality control and accountability from raw material
                sourcing to final assembly.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
