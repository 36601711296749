import React from 'react'
import main_img from "../../../assets/img/Products/InfraVue/15445.jpg"
import { MdKeyboardArrowDown } from "react-icons/md";
export default function MainSection1() {
  return (
    <div>
         {/* <section class=" mt-5  pt-10 pb-10 relative">
          <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat  bg-fixed"
            style={{
              backgroundImage: `url(${main_img})`,
            }}
          ></div>
          <div className="max-w-2xl px-4 mb-10 md:mx-auto text-center lg:max-w-[62rem] md:mb-12">
            <p className="relative tracking-wide font-semibold lg:text-3xl   text-white md:text-lg">
            Infrastructure design, particularly in the cloud age, is an intricate ballet of interconnected systems, platforms, and services. While its complexity offers robustness and scalability, it often becomes a barrier for those who aren't seasoned infrastructure architects. AeroInfra Vue's central mission is to break down these complexities, making infrastructure design an intuitive process, irrespective of one's technical expertise.
            </p>
          </div>
        </section> */}

<section
      className="relative text-white py-20 md:py-10 bg-cover bg-center"
      style={{
        backgroundImage:
          `url(${main_img})`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="container mx-auto px-4 text-center relative z-10">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
        Blueprinting Tomorrow's Infrastructure Today
        </h1>
        <p className="text-xl mb-8">
        Infrastructure design, particularly in the cloud age, is an intricate ballet of interconnected systems, platforms, and services. While its complexity offers robustness and scalability, it often becomes a barrier for those who aren't seasoned infrastructure architects. AeroInfra Vue's central mission is to break down these complexities, making infrastructure design an intuitive process, irrespective of one's technical expertise.
        </p>
        <div className=' flex justify-center items-center flex-col space-y-2'>
        <button to={'/contact-us'} className="bg-white cursor-pointer text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
          Explore more
        </button>
        <MdKeyboardArrowDown className=' text-white animate-bounce ' size={20}/>
      </div>
      </div>
    </section>
          
          
    </div>
  )
}
