import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineAutorenew } from "react-icons/md"; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from "react-icons/md"; // Continuous Model Improvement
import { MdOutlineGavel } from "react-icons/md";
import img1 from "../../../assets/img/Industries/TelecomandNetworks/blockchain.png";

import { CiCircleCheck } from "react-icons/ci";
import { IoAlertCircleOutline } from "react-icons/io5";
import { RiBillLine } from "react-icons/ri";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Blockchain for Telecom
            </h1>
            <p className="text-lg text-gray-600">
              Secure transactions, streamline billing, and enhance identity
              management with blockchain-powered solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoAlertCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Fraud Prevention
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage blockchain to secure transactions, eliminate SIM card
                fraud, and protect data.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiBillLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Billing and Roaming Settlements
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Simplify and automate billing processes and cross-border roaming
                agreements with blockchain smart contracts.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <CiCircleCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Identity Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable secure and decentralized identity verification for
                customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
