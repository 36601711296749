import React from 'react'
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaClock, FaBuilding } from 'react-icons/fa'
import map_img from "../../assets/img/newmap.png";
const locations = [
  {
    id: 1,
    name: 'Aero Aegis Ltd (Corporate Office)',
    address: '128 City Road, London, United Kingdom, EC1V 2NX',
    phone: ' +44 7888 863174',
    // email: 'chicago@company.com',
    // hours: '9:00 AM - 5:00 PM CST',
    left: '45%',
    top: '32%'
  },
  {
    id: 2,
    name: 'Aero Aegis Inc (HQ)',
    address: '8, The Green, STE A, Dover, Delaware 19901',
    phone: '+1 (302) 497-4435',

    left: '25%',
    top: '43%'
  },
  {
    id: 3,
    name: 'AeroAegis Enterprise L.L.C - FZ',
    address: 'Meydan Grandstand, 6th floor, Meydan Road, Nad AlSheba, Dubai, U.A.E',
    phone: '+971555303571',
    left: '63%',
    top: '57%'
  },
  {
    id: 4,
    name: 'AeroAegis Pvt Ltd (Development Center)',
    address: ' 1509, 5th stage, 2nd cross, BEML layout, R.R.Nagar, Bangalore, Karnataka',
    phone: '02269718456',
  
    left: '70%',
    top: '62%'
  }
]

export default function LocationsMap() {
  return (
    <section className="w-full max-w-7xl mx-auto px-4 py-16">
      <div className="text-center mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#1e3a8a]">
          Our Locations
          <div className="h-1 w-32 bg-[#1e3a8a] mx-auto mt-2"></div>
        </h2>
      </div>
      
      <div className="relative w-full">
        {/* Map Background */}
        <div className="w-full aspect-[2/1] bg-[#e5e9f3] border border-gray-200 rounded-lg">
          <img 
            src={map_img}
            alt="World Map"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Location Markers */}
        {locations.map(location => (
          <div
            key={location.id}
            className="absolute w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 group"
            style={{ left: location.left, top: location.top }}
          >
            <div className="relative">
              <FaMapMarkerAlt 
                className="text-[#1e3a8a] w-6 h-6 cursor-pointer hover:text-[#2563eb] transition-colors z-10" 
              />
              
              {/* Detailed Info Card on Hover */}
              <div className="absolute bottom-full pointer-events-none left-1/2 transform -translate-x-1/2 mb-4 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out z-20">
                <div className="bg-white p-4 rounded-lg shadow-xl w-72">
                  <div className="text-[#1e3a8a] font-bold text-lg mb-2 flex items-center gap-2">
                    <FaBuilding />
                    {location.name}
                  </div>
                  
                  <div className="space-y-2 text-gray-600">
                    <div className="flex items-start gap-2">
                      <FaMapMarkerAlt className="mt-1 min-w-[1rem]" />
                      <span>{location.address}</span>
                    </div>
                    
                    <div className="flex items-center gap-2">
                      <FaPhone />
                      <a href={`tel:${location.phone}`} className="hover:text-[#2563eb] transition-colors">
                        {location.phone}
                      </a>
                    </div>
                    
                    {/* <div className="flex items-center gap-2">
                      <FaEnvelope />
                      <a href={`mailto:${location.email}`} className="hover:text-[#2563eb] transition-colors">
                        {location.email}
                      </a>
                    </div> */}
                    
                    {/* <div className="flex items-center gap-2">
                      <FaClock />
                      <span>{location.hours}</span>
                    </div> */}
                  </div>
                  
                  {/* Decorative Arrow */}
                  <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-white rotate-45"></div>
                </div>
              </div>
            </div>
            
            {/* Pulsing Effect */}
            <div className="absolute w-8 h-8 bg-[#1e3a8a] rounded-full opacity-20 animate-ping -left-1 -top-1"></div>
          </div>
        ))}
      </div>

      {/* Mobile-Friendly Location List */}
      <div className="mt-12 grid md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8 lg:gap-12">
        {locations.map(location => (
          <div key={location.id} className="bg-white p-4 rounded-lg border border-gray-200 shadow-md hover:shadow-lg transition-shadow">
            <div className="text-[#1e3a8a] font-bold text-lg mb-2 flex items-center gap-2">
              <FaBuilding />
              {location.name}
            </div>
            
            <div className="space-y-2 text-gray-600 text-sm">
              <div className="flex items-start gap-2">
                <FaMapMarkerAlt className="mt-1 min-w-[1rem]" />
                <span>{location.address}</span>
              </div>
              
              <div className="flex items-center gap-2">
                <FaPhone />
                <a href={`tel:${location.phone}`} className="hover:text-[#2563eb] transition-colors">
                  {location.phone}
                </a>
              </div>
              
              {/* <div className="flex items-center gap-2">
                <FaEnvelope />
                <a href={`mailto:${location.email}`} className="hover:text-[#2563eb] transition-colors">
                  {location.email}
                </a>
              </div> */}
              
              {/* <div className="flex items-center gap-2">
                <FaClock />
                <span>{location.hours}</span>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

