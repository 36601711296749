
  import React from 'react';
  import {
    FaRocket,
    FaChartLine,
    FaCog, 
    FaIndustry,
    FaDatabase,
    FaBrain,
  } from 'react-icons/fa';

  import { GiHealthNormal } from "react-icons/gi";
  import { IoShieldCheckmarkSharp } from "react-icons/io5";
  import { PiBuildingApartmentFill } from "react-icons/pi"
  import { FaShoppingCart } from "react-icons/fa";
  import { BsBank2 } from "react-icons/bs";
  import { MdOutlineEmojiTransportation } from "react-icons/md";
  import { RiGovernmentFill } from "react-icons/ri";
  import { GiElectric } from "react-icons/gi";
  import { MdOutlineWifiPassword } from "react-icons/md";

  
  const Section3 = () => {
    return (
      <div className="min-h-screen bg-gray-100">
        {/* Hero Section */}
        {/* <section className="bg-blue-600 text-white py-20">
          <div className="container mx-auto px-4 text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Transform Your Operations with IoT & Edge Solutions
            </h1>
            <p className="text-xl mb-8">
            At AeroAegis Inc., we deliver <strong>IoT and Edge solutions</strong> that enable organizations to build connected, intelligent systems for smarter decision-making and optimized performance. Whether you are deploying smart infrastructure, enabling real-time edge computing, or creating digital twins, our solutions ensure innovation, scalability, and measurable outcomes.
            </p>
            <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
              Get Started
            </button>
          </div>
        </section> */}

<div className="space-y-4 container mx-auto px-4 text-center pt-20">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Industry Alliances
            </h1>
            <p className="text-lg text-gray-600">
            Our industry-specific collaborations enable AeroAegis to understand and address the unique challenges across diverse sectors. By working closely with industry leaders, we develop solutions that anticipate trends and deliver meaningful results.
            </p>
          </div>
  
        {/* Why Choose AeroAegis Section */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            {/* <h2 className="text-3xl font-bold text-center mb-12">
            Why Choose AeroAegis for IoT & Edge Solutions?
            </h2> */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureCard
                icon={<GiHealthNormal className="text-4xl text-blue-600" />}
                title="Healthcare"
                description="Advanced analytics for patient care, IoT-enabled smart hospitals, and AI-driven diagnostics are transforming healthcare delivery and efficiency."
              />
              <FeatureCard
                icon={<IoShieldCheckmarkSharp className="text-4xl text-blue-600" />}
                title="Finance"
                description="Blockchain-based solutions for secure transactions, fraud detection, and AI-driven predictive insights for risk management."
              />
              <FeatureCard
                icon={<FaIndustry className="text-4xl text-blue-600" />}
                title="Manufacturing"
                description='Industrial IoT solutions that enable smart factory operations, real-time asset monitoring, and optimized supply chain management.'
              />
              <FeatureCard
                icon={<FaShoppingCart className="text-4xl text-blue-600" />}
                title="Retail"
                description=" AI-powered personalization, inventory management, and predictive analytics that enhance customer engagement and operational efficiency."
              />
              <FeatureCard
                icon={<GiElectric className="text-4xl text-blue-600" />}
                title="Energy & Utilities"
                description="Smart grid management with IoT-enabled devices, predictive analytics for energy efficiency, and AI-powered forecasting for resource optimization and cost reduction."
              />
              <FeatureCard
                icon={< BsBank2 className="text-4xl text-blue-600" />}
                title="Banking"
                description="AI-driven fraud detection, blockchain-backed security for secure digital transactions, and predictive analytics to enhance credit scoring and customer onboarding. Solutions designed for digital banking transformation and real-time customer insights."
              />
              <FeatureCard
                icon={<MdOutlineWifiPassword className="text-4xl text-blue-600" />}
                title="Telecommunications"
                description="AI and big data analytics to optimize network operations, predict service disruptions, and improve customer experience through real-time insights and 5G-enabled IoT deployments."
              />
              <FeatureCard
                icon={<MdOutlineEmojiTransportation className="text-4xl text-blue-600" />}
                title="Transportation & Logistics"
                description="IoT and AI solutions for fleet management, route optimization, and real-time tracking to streamline supply chain processes and improve operational efficiency"
              />
              <FeatureCard
                icon={<RiGovernmentFill className="text-4xl text-blue-600" />}
                title="Public Sector & Government"
                description="Blockchain and AI solutions for secure citizen data management, smart city initiatives powered by IoT, and digital platforms to enhance transparency and governance."
              />
            </div>
          </div>
        </section>
  
        {/* Footer */}
        <footer className="bg-blue-600 text-white py-8">
          <div className="container mx-auto px-4 text-center">
            <p className="mb-4 text-lg">
            Through these partnerships, AeroAegis ensures that its solutions are not only industry-relevant but also future-ready.
            </p>
          </div>
        </footer>
      </div>
    );
  };
  
  const FeatureCard = ({ icon, title, description }) => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex items-center justify-center mb-4">{icon}</div>
        <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
        <p className="text-gray-600 text-center">{description}</p>
      </div>
    );
  };
  
  
  
  export default Section3;
