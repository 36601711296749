import { motion, useTransform, useScroll } from "framer-motion";
import { useRef, useState } from "react";
import { HiArrowUpRight } from "react-icons/hi2";
import {
  FaChevronDown as ChevronRight,
  FaArrowDown,
  FaExternalLinkAlt,
} from "react-icons/fa";
import mslogo_img from "../../assets/img/Products/View_All_Products/ms360-logo-1.svg";
import aerogenix_img from "../../assets/img/Products/AeroGenix/automation.png";
import smart_img from "../../assets/img/Products/SmartChaosLab/smartchaoslab-only-image-logo.png";
import infravue_img from "../../assets/img/Products/InfraVue/infra-vue-image-only-logo.png";
import Sys_img from "../../assets/img/Products/View_All_Products/sysar-logo.svg";
import aeroai_img from "../../assets/img/Products/AeroPerformai/aeroperform_logo.svg";
import aeroedge_img from "../../assets/img/iiot/aero_edge_iiot.jpg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

const Example = () => {
  return (
    <>
      <div className="max-w-xl mt-5 px-2  md:mt-10 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-5">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-blue-800">
            Our Premier Products
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="ea469ae8-e6ec-4aca-8875-fc402da4d16e"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#ea469ae8-e6ec-4aca-8875-fc402da4d16e)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative ml-1">Pioneering</span>
          </span>{" "}
          Products for Digital Transformation
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          AeroAegis brings a visionary suite of products designed to
          revolutionize how organizations operate, scale, and compete in a
          technology-driven world. Our platforms deliver actionable insights,
          unparalleled efficiency, and resilience, driving enterprises to the
          forefront of innovation.
        </p>
      </div>
      <div className="flex flex-col mt-5 items-center justify-center">
        <span className="font-semibold uppercase text-neutral-500">
          Scroll down
        </span>
        <MdKeyboardArrowDown
          className="text-neutral-500 animate-bounce mt-2"
          size={25}
        />
      </div>
      <HorizontalScrollCarousel />
      {/* <div className="flex h-48 items-center justify-center">
        <span className="font-semibold uppercase text-neutral-500">
          Scroll up
        </span>
      </div> */}
    </>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-110%"]);

  return (
    <section ref={targetRef} className="relative h-[500vh]">
      {/* Grid Matrix Background */}
      <div className="absolute inset-0 z-0 pointer-events-none">
        <div className="grid-background"></div>
      </div>
      <div className="sticky top-0 z-40 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-4 gap-x-8">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>
      <div
        className={`absolute bottom-[15rem] sm:left-[2rem] md:left-[2rem] lg:left-[3rem] xl:left-0  overflow-hidden md:bottom-[10rem] w-full flex justify-center container mx-auto items-center z-10`}
      >
        <div className="flex flex-col justify-center items-center">
          <div class="relative text-center text-3xl  md:text-7xl">
            <p class="text-gray-800 font-bold">Empowering</p>
            <p class=" text-clip text-gradient-primary py-4  font-bold">
              Digital
            </p>
            <p class="text-gray-800 pt-2 font-bold">Evolution</p>
          </div>
          <div className="flex justify-center mt-12">
            <FaArrowDown className="text-6xl text-blue-500 animate-bounce" />
          </div>
        </div>
      </div>
    </section>
  );
};

const Card = ({ card }) => {
  const gradients = [
    "linear-gradient(135deg, #AECBFA50, #CCFF9050)",
    "linear-gradient(135deg,#F28B8250, #AECBFA50)",
    "linear-gradient(135deg, #AECBFA50, #CCFF9050)",
    "linear-gradient(135deg,#F28B8250, #AECBFA50)",
    "linear-gradient(135deg, #AECBFA50, #CCFF9050)",
  ];

  const cardStyle = {
    backgroundImage: gradients[card?.id % gradients.length],
  };
  return (
    <div
      key={card.id}
      className="group relative h-[490px] w-[320px] border border-gray-300 md:h-[430px] md:w-[430px] bg-white rounded-2xl overflow-hidden transition-all duration-500 hover:shadow-2xl hover:-translate-y-1"
    >
      {/* Gradient Background */}
      <div
        className={`absolute inset-0 bg-gradient-to-br from-blue-100 via-sky-100 to-cyan-100 opacity-0 group-hover:opacity-100 transition-opacity duration-500`}
      ></div>

      {/* Content Container */}
      <div className="relative p-8 h-full flex flex-col">
        {/* Icon */}
        <div className="mb-8">
          <img src={card?.url} className=" w-[60px] h-[50px]" alt="logo" />
        </div>

        {/* Title */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 group-hover:text-gray-900 transition-colors">
          {card?.title}
        </h2>

        {/* Description */}
        <p className="text-gray-600 flex-grow group-hover:text-gray-700 transition-colors">
          {card?.desc}
        </p>

        <p className=" mb-8 text-sm md:text-md font-semibold mt-2">
          {card?.slogan}
        </p>

        {/* Button */}
        <div className="relative">
          <Link
            to={card?.link}
            className="w-full flex items-center justify-center gap-2 text-white bg-gray-900 hover:bg-gray-800 px-6 py-3.5 rounded-xl transition-all duration-300 group/btn"
          >
            <span className="font-medium">Learn More</span>
            <HiArrowUpRight className="transition-transform duration-300 group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1" />
          </Link>
          {/* Button Glow Effect */}
          <div className="absolute inset-0 bg-gray-900 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500 -z-10"></div>
        </div>
      </div>
    </div>
  );
};

export default Example;

const cards = [
  {
    url: mslogo_img,
    title: "Maturity Spectrum 360",
    desc: "A powerful platform that provides a roadmap for enterprise digital transformation, ensuring alignment between business objectives and technology strategies.",
    slogan:
      "Discover how Maturity Spectrum 360 can redefine your organization’s growth journey.",
    link: "/maturity-spectrum-360",
    id: 1,
  },
  {
    url: smart_img,
    title: "SmartChaos Lab",
    desc: "An industry-leading solution leveraging chaos engineering and AI to ensure cloud resilience, optimize performance, and safeguard critical infrastructure.",
    slogan: "Learn how SmartChaos Lab enables seamless cloud operations.",
    link: "/smartchaos-lab",
    id: 2,
  },
  {
    url: aerogenix_img,
    title: "AeroGenix",
    desc: "AeroGenix is the ultimate platform for enterprises to create, deploy, and scale Generative AI (GenAI) and Agentic AI solutions seamlessly.",
    slogan: "Unleash the potential of AI with AeroGenix.",
    link: "/aero-genix",
    id: 0,
  },
  {
    url: aeroedge_img,
    title: "AeroEdge IIoT",
    desc: "Combining advanced edge computing with AI, AeroEdge IIoT delivers real-time insights and predictive analytics for industrial automation, empowering organizations with actionable intelligence.",
    slogan: "Empower your enterprise with AeroEdge IIoT.",
    link: "/aeroedge-iiot",
    id: 4,
  },
  {
    url: aeroai_img,
    title: "AeroPulse AI",
    desc: "An advanced AI-powered platform for predictive intelligence, offering real-time insights to enhance performance, prevent disruptions, and drive smarter decisions.",
    slogan: "Unleash the potential of AI with AeroPulse.",
    link: "/aeropulse-ai",
    id: 3,
  },
  {
    url: infravue_img,
    title: "AeroInfra Vue",
    slogan: "Reimagine infrastructure management with AeroInfra Vue.",
    desc: "A zero-code infrastructure platform simplifying deployment and design, empowering organizations of all sizes to manage complex systems with ease.",
    link: "/infra-vue",
    id: 5,
  },
];
