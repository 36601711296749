import React from "react";

import { FaCloud, FaChartBar, FaMicrochip } from "react-icons/fa";

import alibabaCloudLogo from "../../../../assets/img/Partnerships/alibabaCloudLogo.svg";
import { Link } from "react-router-dom";


export default function PatnersList1() {
  return (
    <section className="bg-white  px-4 sm:px-6 lg:px-8">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">
              Our Strategic Partners
            </span>

            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>

        <div className="min-h-screen bg-gradient-to-b from-white to-blue-50">
          {/* Hero Section */}

          <div className="container mx-auto px-4 py-16 md:py-24">
            <div className="flex justify-center">
            <img src={alibabaCloudLogo} alt="alibaba cloud logo" />
            </div>
            <div className="max-w-4xl mx-auto text-center mb-16">
              <h1 className="text-4xl md:text-6xl font-bold text-blue-900 mb-6 tracking-tight">
                Alibaba Cloud Partnership
              </h1>

              <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                As an official Alibaba Cloud partner, AeroAegis combines its
                expertise with Alibaba's advanced cloud infrastructure to
                deliver scalable, secure, and AI-driven solutions.
              </p>
              <Link to="/partnerships/alibaba-cloud ">
              <button className="mt-8 px-8 py-4 bg-blue-900 text-white rounded-full font-semibold hover:bg-blue-800 transform hover:scale-105 transition-all duration-200 shadow-lg">
                Explore more
              </button></Link>
            </div>

            {/* Features Grid */}

            <div className="grid md:grid-cols-3 gap-8 mt-16">
              {/* Multi-Cloud Card */}

              <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-xl mb-6">
                  <FaCloud className="w-8 h-8 text-blue-900" />
                </div>

                <h3 className="text-xl font-bold text-blue-900 mb-4">
                  Empowering Multi-Cloud Strategies
                </h3>

                <p className="text-gray-600 leading-relaxed">
                  We leverage Alibaba's cloud to craft tailored multi-cloud
                  architectures that enhance flexibility, security, and
                  performance.
                </p>
              </div>

              {/* Analytics Card */}

              <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-xl mb-6">
                  <FaChartBar className="w-8 h-8 text-blue-900" />
                </div>

                <h3 className="text-xl font-bold text-blue-900 mb-4">
                  Revolutionizing Big Data Analytics
                </h3>

                <p className="text-gray-600 leading-relaxed">
                  Advanced analytics tools integrated with Alibaba Cloud's
                  capabilities enable organizations to extract actionable
                  insights and gain competitive advantages.
                </p>
              </div>

              {/* IoT Card */}

              <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-xl mb-6">
                  <FaMicrochip className="w-8 h-8 text-blue-900" />
                </div>

                <h3 className="text-xl font-bold text-blue-900 mb-4">
                  IoT Optimization for Enterprises
                </h3>

                <p className="text-gray-600 leading-relaxed">
                  Harnessing Alibaba Cloud's IoT solutions, we create smarter,
                  connected systems that transform industries like
                  manufacturing, healthcare, and logistics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}