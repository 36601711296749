import React from "react";
import { FaRocket, FaCogs, FaShieldAlt } from 'react-icons/fa';
import { SiGooglecloud } from 'react-icons/si';

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-center mb-6 lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Why Choose Aero Aegis for Aerospace?</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                { icon: <FaRocket />, title: "Proven Expertise in Aerospace Innovation", description: "We bring a deep understanding of aerospace challenges, from manufacturing and supply chain complexities to regulatory compliance and mission-critical operations." },
                { icon: <SiGooglecloud />, title: "End-to-End Solutions", description: "From smart manufacturing to predictive maintenance and flight operations optimization, we provide comprehensive services tailored to the aerospace sector." },
                { icon: <FaCogs />, title: "Focus on Safety and Compliance", description: "Our solutions are designed to meet strict aerospace standards such as AS9100, FAA, EASA, and ISO certifications, ensuring operational excellence." },
                { icon: <FaShieldAlt />, title: "Future-Ready Technology", description: "By leveraging AI, IoT, blockchain, and advanced analytics, we help aerospace companies build resilient, scalable, and innovative ecosystems." },
              ].map((item, index) => (
                <div key={index} className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">{item.icon}</div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

      </div>
    </div>
  );
}