import madhu_img from "../../../../assets/img/Partnerships/pic1.jpeg";
import cedric_img from "../../../../assets/img/Partnerships/cedric_saber.png";

export default function Leaderships() {
  return (
    <section id="partnership" className="py-16 bg-blue-50 mt-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
          Leadership Perspectives
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8"> 
          {[
            { 
              quote:
                "Our partnership with Alibaba Cloud marks a significant milestone in our mission to provide cutting-edge digital transformation solutions. By leveraging Alibaba Cloud's state-of-the-art infrastructure, we are poised to deliver unparalleled services to our clients, driving innovation and efficiency across key markets.",
              author: "Dr. Madhu Kumar Reddy",
              role: "Founder & CEO of AeroAegis",
              imageUrl: madhu_img,
            },
            {
              quote:
                "We are excited to collaborate with AeroAegis to empower businesses in Europe, MENA, and UAE with our advanced cloud technologies. This partnership underscores our commitment to supporting enterprises in their digital transformation journeys.",
              author: "Cedric Saber",
              role: "Regional Director at Alibaba Cloud",
              imageUrl: cedric_img,
            },
          ].map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
}
const TestimonialCard = ({ quote, author, role, imageUrl }) => (
  <blockquote className="bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
    <p className="text-gray-700 mb-4">"{quote}"</p>
    <footer className="flex gap-7 items-center">
      <img
        src={imageUrl}
        alt="profile"
        className="w-[45px] h-[45px] object-cover object-top border-2 border-[#232F7F] rounded-full"
      />
      <p className="text-gray-600">
        <b className="text-gray-800">{author}</b>
        <br></br>
        {role}
      </p>
    </footer>
  </blockquote>
);
