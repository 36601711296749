import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineAutorenew, MdOutlineBuild } from "react-icons/md"; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from "react-icons/md"; // Continuous Model Improvement
import { MdOutlineGavel } from "react-icons/md";
import img1 from "../../../assets/img/EngineeringExcellence/ProductEngineering/4.png";
import { FaRegChartBar } from "react-icons/fa";
import { AiOutlineBulb, AiOutlineLineChart } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineScale } from "react-icons/hi";
import {GiCycle} from "react-icons/gi"

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              End-to-End Product Lifecycle Management
            </h1>
            <p className="text-lg text-gray-600">
              Comprehensive product lifecycle management that spans ideation,
              development, deployment, and continuous optimization, ensuring
              products remain innovative, scalable, and aligned with market
              needs.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineBuild className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Seamless Prototyping and Development
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Transform your ideas into reality with our streamlined
                prototyping and engineering process, ensuring efficient product
                development.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiCycle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Holistic Lifecycle Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                From inception to deployment and beyond, we manage every stage
                of the product lifecycle for sustained innovation.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineLineChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Continuous Monitoring and Optimization
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Post-deployment analysis and performance tracking ensure your
                product evolves with customer demands and stays competitive.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <HiOutlineScale className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Scalability and Feature Enhancements
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable seamless scalability and integrate new features to meet
                growing market demands effectively.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
