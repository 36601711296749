import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineSchool } from 'react-icons/md'; // Advanced Model Training
import { MdOutlineCloudDone } from 'react-icons/md'; 
import img1 from "../../../../assets/img/Services/AI&ML/23402.jpg"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Model Training & Optimization
            </h1>
            <p className="text-lg text-gray-600">
            Use advanced tools like TensorFlow, PyTorch, Scikit-learn, and AWS SageMaker to train accurate and efficient models.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSchool className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Advanced Model Training</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage frameworks like TensorFlow, PyTorch, and Scikit-learn to build precise and robust models.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCloudDone className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Optimized Performance with Cloud Tools</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Utilize AWS SageMaker and other platforms for efficient model training and deployment.
              </p>
            </div>

           
          </div>
        </div>

      
      </div>
    </div>
  )
}