import React from "react";
import { FaLightbulb, FaPencilRuler, FaRocket, FaCogs } from 'react-icons/fa';
import { GoGraph } from "react-icons/go";

import { GrSystem } from "react-icons/gr";
import { LuTimerReset } from "react-icons/lu";
import { MdOutlineRestorePage } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md italic">
            "Optimize resilience 
            <span className=" ml-1"> by testing failure scenarios with tools like SmartChaos Lab."</span>
          </p>
        </div>
 
      <section className="py-4 bg-base-100">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { icon: <GrSystem />, title: "Resilient Systems", description: "Build highly resilient and fault-tolerant systems." },
              { icon: <LuTimerReset />, title: "Downtime Mitigation", description: "Proactively mitigate downtime and performance bottlenecks." },
              { icon: <MdOutlineRestorePage />, title: "Disaster Recovery", description: "Improve disaster recovery preparedness with real-world testing." },
              { icon: <IoSettingsOutline />, title: "Sustainability", description: "Increase customer confidence in system reliability" },
            ].map((item, index) => (
              <div key={index} className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
                <div className="card-body items-center text-center">
                  <div className="text-4xl text-primary mb-4">{item.icon}</div>
                  <h3 className="card-title text-xl mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      </div>
    </div>
  );
}
