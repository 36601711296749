import React from 'react';
import feature_img from '../../../assets/img/Products/AeroGenix/110863.jpg';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { AiOutlineCluster } from "react-icons/ai";
import { AiOutlineSliders } from "react-icons/ai";

export default function Feature3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Bring Your Own Model (BYOM)
            </h1>
            <p className="text-lg text-gray-600">
              Use your existing AI models or fine-tune pre-trained GenAI models
              with ease:
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineCluster className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Model Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Seamlessly upload and manage models from TensorFlow, PyTorch, or
                Hugging Face.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSliders className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  GenAI Fine-Tuning
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Fine-tune GenAI models like GPT for industry-specific needs.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">MLOps Lifecycle</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Full lifecycle management for your models with MLOps.
              </p>
            </div>

            {/* Feature 4 */}
            {/*  <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <MdOutlineIntegrationInstructions className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                         Improved Collaboration
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                       Enhance team productivity by integrating tools that support
                       shared workflows and version control.
                     </p>
                   </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={feature_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
/*  <div>
      <section className="dark:bg-gray-800 dark:text-gray-100">
        <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm lg:py-10 sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
            <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
            Noise Reduction with Fuzzy Logic 
            </h3>
            <div className="flex space-x-2 sm:space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leadi">
                Our platform employs fuzzy logic to filter out the 'noise' - irrelevant data and alerts that can overwhelm and distract your team.
                </p>
              </div>
            </div>
            <div className="flex space-x-2 sm:space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>

              <div className="space-y-2">
                <p className="text-lg font-medium leadi">
                With AeroPulse AI, you receive only the most pertinent information, enabling you to focus on what truly matters.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={feature_img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
              />
            </div>
          </div>
        </div>
      </section>
    </div> */
