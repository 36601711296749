import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/OperationalExe/testing.png"

import { TfiPulse } from "react-icons/tfi";
import { MdOutlineEventAvailable } from "react-icons/md";
import { GoGear } from "react-icons/go";

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Resilience Testing
            </h1>
            <p className="text-lg text-gray-600">
            Resilience testing evaluates system behavior under stress to ensure high availability and fault tolerance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">System Behavior Analysis</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Test how systems respond to various stress scenarios to identify vulnerabilities.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TfiPulse className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Fault Tolerance</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Optimize systems to recover seamlessly from failures or disruptions.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineEventAvailable className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">High Availability</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Ensure consistent performance and uptime even during adverse conditions.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GoGear className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Improved Reliability</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Strengthen system robustness through continuous testing and optimization.
              </p>
            </div> 
          </div>
        </div>

      
      </div>
    </div>
  )
}