import React from "react";
import { GrDeploy } from "react-icons/gr";
import { TbSettingsAutomation } from "react-icons/tb";
import { MdModelTraining } from "react-icons/md";
import { FaCog } from "react-icons/fa";
import { GrCompliance } from "react-icons/gr";
import { FaShieldAlt } from "react-icons/fa";

import { SiKubernetes } from "react-icons/si";
import { IoCloudDone } from "react-icons/io5";
import { RiCpuLine } from "react-icons/ri";
import { FiCloud } from "react-icons/fi";
import { LiaIndustrySolid } from "react-icons/lia";

export default function BusinessFeature1() {
  const whyChooseData = [
    {
      title: "Build AI-Powered Agents",
      feature1: {
        icon1: (
          <GrDeploy className="w-10 h-8 lg:mt-1 xl:mt-2 lg:w-[17px] lg:h-[17px] text-indigo-600" />
        ),
        feature:
          "Design and deploy autonomous agents with an intuitive drag-and-drop interface.",
      },
      feature2: {
        icon2: (
          <TbSettingsAutomation className="w-10 h-7 lg:mt-1 lg:w-[17px] lg:h-[17px]  text-indigo-600" />
        ),
        feature:
          "Automate workflows like customer service, data analysis, and document processing.",
      },
    },
    {
      title: "Leverage Cutting-Edge AI",
      feature1: {
        icon1: (
          <RiCpuLine className="w-10 h-7 lg:mt-1 lg:w-[17px] lg:h-[17px]  text-indigo-600" />
        ),
        feature:
          "Fine-tune powerful pre-trained models like GPT, LLaMA, and more.",
      },
      feature2: {
        icon2: (
          <LiaIndustrySolid className="w-8 h-8 lg:mt-1 lg:w-[17px] lg:h-[17px] text-indigo-600" />
        ),
        feature: "Train your own models for industry-specific needs.",
      },
    },
    {
      title: "Ensure Compliance & Security",
      feature1: {
        icon1: (
          <GrCompliance className="w-10 h-8 lg:mt-1 lg:w-[17px] lg:h-[17px] text-indigo-600" />
        ),
        feature:
          "Stay compliant with industry regulations using built-in compliance checks.",
      },
      feature2: {
        icon2: (
          <FaShieldAlt className="w-10 h-7 lg:mt-1 lg:w-[17px] lg:h-[17px] text-indigo-600" />
        ),
        feature:
          "Maintain a secure environment with end-to-end encryption and audit logging.",
      },
    },
    {
      title: "Scale Seamlessly",
      feature1: {
        icon1: (
          <FiCloud className="w-12 h-7 lg:mt-1 lg:w-[17px] lg:h-[17px]  text-indigo-600" />
        ),
        feature:
          "Cloud-agnostic deployment ensures compatibility with AWS, Azure, GCP, and on-premise solutions.",
      },
      feature2: {
        icon2: (
          <SiKubernetes className="w-10 h-7 lg:mt-1 lg:w-[17px] lg:h-[17px] text-indigo-600" />
        ),
        feature:
          "Real-time scaling powered by Kubernetes ensures performance under heavy workloads.",
      },
    },
  ];
  return (
    <div className="px-4 py-4 my-auto md:px-12  md:py-12 bg-[#F7F7FC] ">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8 text-center">
        <h2 className="max-w-2xl lg:max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block md:mb-1 mb-4 ">
          What Can AeroGenix Do For Your Business?
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-10">
        {whyChooseData ? (
          whyChooseData.map((item) => {
            return (
              <div className="bg-[#EFF0F7] border rounded-md border-gray-200 shadow-md py-8 px-9 ">
                <div className="">
                  <h1 className="text-2xl font-semibold leading-tight text-gray-900">
                    {item.title}
                  </h1>
                </div>
                <div className="mt-5">
                  <div className="flex flex-row  gap-4">
                    {item.feature1.icon1}
                    <p className="text-gray-600 text-base">
                      {item.feature1.feature}
                    </p>
                  </div>
                  <div className="flex flex-row  gap-4 mt-3">
                    {" "}
                    {item.feature2.icon2}
                    <p className="text-gray-600 text-base">
                      {item.feature2.feature}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <h1>No data</h1>
          </div>
        )}
      </div>
    </div>
  );
}
