import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/StrategicConsulting/20.png"
import { FaCalendarAlt, FaCompass, FaImage, FaPaintBrush, FaReact, FaUserAlt } from "react-icons/fa"
import {GoGraph} from "react-icons/go"
import { MdIntegrationInstructions } from "react-icons/md"
import { AiOutlineRocket } from "react-icons/ai"
import { IoMdTrain } from "react-icons/io"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Data Advocacy
            </h1>
            <p className="text-lg text-gray-600">
            Help organizations adopt AI/ML-powered decision-making frameworks to transform operations and gain a competitive edge.
			      </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineRocket className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">AI/ML Integration Strategy</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Guidance on seamlessly integrating AI/ML into organizational processes for enhanced decision-making.

			                </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoMdTrain className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900"> Continuous Learning & Improvement</h3>         
              </div>
              <p className="text-gray-600 text-sm">
              Foster an environment of continuous AI/ML learning to keep operations ahead of the curve.
              </p>
            </div>


          </div>
        </div>

      
      </div>
    </div>
  )
}