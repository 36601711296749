import React from 'react';
import { FaClock, FaChartBar, FaClipboardCheck } from 'react-icons/fa';

import img1 from '../../../assets/img/EngineeringExcellence/Ip/marketing.png';
import { FaCogs } from 'react-icons/fa';

export default function Benifits() {
  return (
    <section className="py-10 bg-gradient-to-b from-indigo-50 to-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
          Benefits of AeroAegis IPs
        </h2>
        <div className="bg-white rounded-xl shadow-2xl overflow-hidden">
          <div className="lg:w-full p-8 lg:p-12">
            <h3 className="text-2xl font-bold text-indigo-800 mb-6">
              Delivering accelerated time-to-market, cost efficiency, and
              future-ready solutions with the flexibility to meet specific
              business needs.
            </h3>
            <p className="text-gray-600 mb-8">
              AeroAegis IPs enable businesses to deploy solutions faster, reduce
              development costs, and ensure future readiness with the
              integration of emerging technologies. Our modular, customizable
              frameworks offer adaptability, allowing businesses to meet
              specific needs while maintaining high-quality standards.
            </p>
            <div className="flex flex-col lg:flex-row lg:gap-12 gap-5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:w-1/2 w-full mx-auto">
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaClock className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                    Accelerated Time-to-Market
                  </h4>
                  <p className="text-gray-700">
                    Our ready-to-use frameworks and platforms enable businesses
                    to deploy solutions faster without compromising on quality.
                  </p>
                </div>
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaChartBar className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                    Cost Efficiency
                  </h4>
                  <p className="text-gray-700">
                    By leveraging our prebuilt IPs, organizations reduce
                    development costs while gaining access to world-class
                    innovations.
                  </p>
                </div>
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaCogs className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                    Customization and Flexibility
                  </h4>
                  <p className="text-gray-700">
                    Our modular IPs can be tailored to suit specific business
                    needs, providing maximum value and adaptability.
                  </p>
                </div>
                <div className="bg-indigo-100 rounded-lg p-6 text-center">
                  <FaClipboardCheck className="text-4xl text-indigo-600 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                    Future-Ready Solutions
                  </h4>
                  <p className="text-gray-700">
                    By integrating emerging technologies, AeroAegis IPs
                    future-proof businesses and prepare them for the next wave
                    of digital transformation.
                  </p>
                </div>
              </div>
              <div className="relative lg:w-1/2">
                <img src={img1} className="object-cover w-full h-full" />
                <div className="absolute inset-0 bg-indigo-800 opacity-20"></div>
                <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-indigo-900 to-transparent">
                  <p className="text-white text-lg font-semibold">
                    Accelerated delivery, cost-efficient, customizable,
                    future-ready solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}