import React from 'react'
import last from '../../assets/img/Investor_relations/last.jpg'
export default function FinalSection() {
  return (
    <div className="bg-gradient-to-r from-[#223078] via-[#3a4d84] to-[#223078] px-6 py-12 w-full rounded font-sans">
      <div className="max-w-2xl mx-auto text-center">
        <h1 className="text-white sm:text-5xl text-3xl font-extrabold">
          Join us on this journey of innovation and growth
        </h1>
        <div className="my-10">
          <p className="text-white text-sm">
            Explore how AeroAegis’s roadmap translates into meaningful solutions and exceptional opportunities for our partners and investors.
          </p>
        </div>

        <hr className="border-gray-300" />
{/* 
        <div className="mt-10 flex flex-col sm:flex-row justify-center sm:gap-6 gap-4">
          <button
            type="button"
            className="px-6 py-3 rounded text-[#223078] text-sm tracking-wider font-semibold border border-white outline-none bg-white hover:bg-transparent hover:text-white transition-all duration-300"
          >
            Get Started
          </button>
          <button
            type="button"
            className="px-6 py-3 rounded text-[#223078] text-sm tracking-wider font-semibold border border-white outline-none bg-white hover:bg-transparent hover:text-white transition-all duration-300"
          >
            Learn More
          </button>
        </div> */}
      </div>
    </div>
  )
}
