import React from "react";
import { Link } from "react-router-dom";
import mslogo_img from "../../../assets/img/Products/View_All_Products/ms360-logo-1.svg";
import smart_img from "../../../assets/img/Products/SmartChaosLab/smartchaoslab-only-image-logo.png";
import infravue_img from "../../../assets/img/Products/InfraVue/infra-vue-image-only-logo.png";
import aerogenix_img from "../../../assets/img/Products/AeroGenix/automation.png";
import aeroai_img from "../../../assets/img/Products/AeroPerformai/aeroperform_logo.svg";
import iiot_img from "../../../assets/img/iiot/aero_edge_iiot.jpg";
import { HiArrowUpRight } from "react-icons/hi2";

const cards = [
    {
      "id": 1,
      "url": mslogo_img,
      "title": "Maturity Spectrum 360",
      "desc": "Comprehensive, AI driven framework that assesses organization's maturity level, pinpoints growth opportunities, and charts a course for success.",
      "slogan": "Discover your potential",
      "link": "/maturity-spectrum-360"
    },
    
    {
      "id": 2,
      "url": smart_img,
      "title": "SmartChaos Lab",
      "desc": "AI based Chaos Engineering Tool that automatically identifies weaknesses and potential outages in infrastructures by inducing chaos tests and provides resolutions.",
      "slogan": "Chaos Engineering Simplified",
      "link": "/smartchaos-lab"
    },
    {
      "id": 3,
      "url": iiot_img,
      "title": "AeroEdge IIOT",
      "desc": "AeroEdge IIoT leverages advanced Edge AI capabilities to deliver real-time data processing, predictive analytics, and comprehensive system integration.",
      "slogan": "Revolutionize Edge AI",
      "link": "/aeroedge-iiot"
    },
    {
      "id": 4,
      "url": aeroai_img,
      "title": "AeroPulse AI",
      "desc": "A next generation cloud performance management offering a comprehensive solution for monitoring, predicting, and optimizing the performance of your cloud-hosted applications and systems.",
      "slogan": "Optimize Your Cloud",
      "link": "/aeropulse-ai"
    },
    {
      "id": 5,
      "url": infravue_img,
      "title": "AeroInfra Vue",
      "desc": "Zero-code approach to simplifying the intricate procedures involved in infrastructure design and deployment.",
      "slogan": "Simplify Infrastructure",
      "link": "/infra-vue"
    },
    {
      "id": 6,
      "url": aerogenix_img,
      "title": "AeroGenix",
      "desc": "AeroGenix is the ultimate platform for enterprises to create, deploy, and scale Generative AI (GenAI) and Agentic AI solutions seamlessly.",
      "slogan": "Autonomous AI",
      "link": "/aero-genix"
    },
   
  ]
  

export default function MainSection1() {
  return (
    <div>
      <section class="bg-white dark:bg-gray-900 relative ">
      {/* Grid Matrix Background */}
      <div className="absolute inset-0 z-0 pointer-events-none">
        <div className="grid-background"></div>
      </div>
        <div class="container px-6 py-5 mx-auto">
          {/* <h1 class="text-2xl text-center font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">
            explore our <br /> awesome{" "}
            <span class="underline decoration-blue-500">Products</span>
          </h1> */}

          <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Explore Our Awesome Products</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
         
        </div>

        <div class="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 w-full mt-8 mb-8">
          {cards.map(card => (
                  <Card key={card.id} card={card} />
              ))}
        </div>

        </div>
      </section>
    </div>
  );
}



const Card = ({ card }) => {

    return (
      <div
        key={card.id}
        className={`
            group relative  border border-gray-300 bg-white rounded-2xl 
            overflow-hidden transition-all duration-500 hover:shadow-2xl hover:-translate-y-1
            md:w-[100%] h-auto min-h-[400px] w-full
            `}
      >
        {/* Gradient Background */}
        <div
          className={`absolute inset-0 bg-gradient-to-br from-blue-100 via-sky-100 to-cyan-100 opacity-0 group-hover:opacity-100 transition-opacity duration-500`}
        ></div>
  
        {/* Content Container */}
        <div className="relative p-8 h-full flex flex-col">
          {/* Icon */}
          <div className="mb-8">
            <img src={card?.url} className=" w-[50px] h-[50px]" alt="logo" />
          </div>
  
          {/* Title */}
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 group-hover:text-gray-900 transition-colors">
            {card?.title}
          </h2>
  
          {/* Description */}
          <p className="text-gray-600 flex-grow group-hover:text-gray-700 transition-colors 
            sm:line-clamp-2 md:line-clamp-4 lg:line-clamp-none
          ">
              {card?.desc}
          </p>

  
          <p className=" mb-8 font-semibold mt-2">{card?.slogan}</p>
  
          {/* Button */}
          <div className="mt-auto">
            { /* <button className="w-full flex items-center justify-center gap-2 text-white bg-gray-900 hover:bg-gray-800 px-6 py-3.5 rounded-xl transition-all duration-300 group/btn">
              <span className="font-medium">Learn More</span>
              <HiArrowUpRight className="transition-transform duration-300 group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1" />
            </button>*/ }
            <Link
              to={card.link}
              class="w-full flex items-center justify-center gap-2 text-white bg-gray-900 hover:bg-gray-800 px-6 py-3.5 rounded-xl transition-all duration-300 group/btn"
            >
              <span className="font-medium">Learn More</span>
              <HiArrowUpRight className="transition-transform duration-300 group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1" />
            </Link>
            {/* Button Glow Effect */}
            <div className="absolute inset-0 bg-gray-900 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500 -z-10"></div>
          </div>
        </div>
      </div>
    );
};


// `
// <div class="p-8  relative space-y-3 border border-gray-300  bg-white rounded-2xl overflow-hidden transition-all duration-500 hover:shadow-2xl hover:-translate-y-1">
// <div
//   className={`absolute inset-0 bg-gradient-to-br from-blue-100 via-sky-100 to-cyan-100 opacity-0 group-hover:opacity-100 transition-opacity duration-500`}
// ></div>

// {/* Content Container */}
// <div className="relative p-8 h-full flex flex-col">
//   {/* Icon */}
//   <div className="mb-8">
//     <img
//       src={mslogo_img}
//       className=" w-[60px] h-[50px]"
//       alt="logo"
//     />
//   </div>

//   {/* Title */}
//   <h2 className="text-2xl font-semibold text-gray-800 mb-4 group-hover:text-gray-900 transition-colors">
//     Maturity Spectrum 360
//   </h2>

//   {/* Description */}
//   <p className="text-gray-600 flex-grow group-hover:text-gray-700 transition-colors">
//     Comprehensive, AI driven framework that assesses
//     organization's maturity level, pinpoints growth opportunities,
//     and charts a course for success.
//   </p>

//   <p className=" mb-8 font-semibold mt-2">{card?.slogan}</p>

//   {/* Button */}
//   <div className="relative">
//     <Link to="/maturity-spectrum-360">
//       <button className="w-full flex items-center justify-center gap-2 text-white bg-gray-900 hover:bg-gray-800 px-6 py-3.5 rounded-xl transition-all duration-300 group/btn">
//         <span className="font-medium">Learn More</span>
//         <HiArrowUpRight className="transition-transform duration-300 group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1" />
//       </button>
//     </Link>
//     {/* Button Glow Effect */}
//     <div className="absolute inset-0 bg-gray-900 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500 -z-10"></div>
//   </div>
// </div>

// {/* <span class="inline-block text-[#252f80] dark:text-blue-400">
//      <img className=' w-12' src={mslogo_img}></img>
//   </span>

//   <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Maturity Spectrum 360</h1>

//   <p class="text-gray-500 dark:text-gray-300">
//   Comprehensive, AI driven framework that assesses organization's maturity level, pinpoints growth opportunities, and charts a course for success.


//   </p>

//   <Link to="/maturity-spectrum-360"> <a  class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
//       <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
//       </svg>
//   </a></Link> */}
// </div>

// <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">
// <span class="inline-block text-[#252f80] dark:text-blue-400">
//   <img className=" w-12" src={smart_img}></img>
// </span>

// <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
//   SmartChaos Lab
// </h1>

// <p class="text-gray-500 dark:text-gray-300">
//   AI based Chaos Engineering Tool that automatically identifies
//   weaknesses and potential outages in infrastructures by inducing
//   chaos tests and provides resolutions.
// </p>

// <Link to="/smartchaos-lab">
//   {" "}
//   <a class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       class="w-6 h-6"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         stroke-linecap="round"
//         stroke-linejoin="round"
//         stroke-width="2"
//         d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
//       />
//     </svg>
//   </a>
// </Link>
// </div>

// <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">
// <span class="inline-block text-blue-500 dark:text-blue-400">
//   <img className=" w-16" src={aeroai_img}></img>
// </span>

// <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
//   AeroPulse AI
// </h1>

// <p class="text-gray-500 dark:text-gray-300">
//   A next generation cloud performance management offering a
//   comprehensive solution for monitoring, predicting, and
//   optimizing the performance of your cloud-hosted applications and
//   systems.
// </p>

// <Link to="/aeropulse-ai">
//   {" "}
//   <a class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       class="w-6 h-6"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         stroke-linecap="round"
//         stroke-linejoin="round"
//         stroke-width="2"
//         d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
//       />
//     </svg>
//   </a>
// </Link>
// </div>

// <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-[#252f80] rounded-xl">
// <span class="inline-block text-[#252f80] dark:text-blue-400">
//   <img className=" w-12" src={infravue_img}></img>
// </span>

// <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
//   AeroInfra Vue
// </h1>

// <p class="text-gray-500 dark:text-gray-300">
//   Zero-code approach to simplifying the intricate procedures
//   involved in infrastructure design and deployment.
// </p>

// <Link to="/infra-vue">
//   {" "}
//   <a class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       class="w-6 h-6"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         stroke-linecap="round"
//         stroke-linejoin="round"
//         stroke-width="2"
//         d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
//       />
//     </svg>
//   </a>
// </Link>
// </div>

// {/* <div class="p-8 bg-slate-100 space-y-3  border-2 border-blue-200 dark:border-blue-300 rounded-xl">
//   <span class="inline-block text-blue-500 dark:text-blue-400">
//       <img className=' w-12' src={Sys_img}></img>
//   </span>

//   <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">AeroSys AR</h1>

//   <p class="text-gray-500 dark:text-gray-300">
//   System Analysis and Remediation, an intuitive tool that allows users to visually interact with real-time system components.
//   </p>

//   <Link to="/sys-ar">  <a  class="inline-flex p-2 mt-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
//       <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
//       </svg>
//   </a></Link>
// </div> */}

// <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">
// <span class="inline-block text-[#252f80] dark:text-blue-400">
//   <img className=" w-12" src={iiot_img}></img>
// </span>

// <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
//   AeroEdge IIOT
// </h1>

// <p class="text-gray-500 dark:text-gray-300">
//   AeroEdge IIoT leverages advanced Edge AI capabilities to deliver
//   real-time data processing, predictive analytics, and
//   comprehensive system integration.
// </p>

// <Link to="/aeroedge-iiot">
//   {" "}
//   <a class="inline-flex mt-2 p-2 text-[#252f80] capitalize transition-colors duration-300 transform bg-blue-100 rounded-full rtl:-scale-x-100 dark:bg-blue-500 dark:text-white hover:underline hover:text-blue-600 dark:hover:text-blue-500">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       class="w-6 h-6"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         stroke-linecap="round"
//         stroke-linejoin="round"
//         stroke-width="2"
//         d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
//       />
//     </svg>
//   </a>
// </Link>
// </div>`