import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineCloudUpload } from "react-icons/md"; // Cloud-Based Model Deployment
import { MdOutlineDevices } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md"; //Security
import { TiTickOutline } from "react-icons/ti"; //Tick

import img1 from "../../../assets/img/Industries/Manufacturing/blockchain-technology.jpg";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Blockchain for Transparency and Trust
            </h1>
            <p className="text-lg text-gray-600">
              Empowering businesses with secure, transparent, and tamper-proof
              solutions to build trust and streamline operations.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TiTickOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Supply Chain Traceability
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Ensure transparency and authenticity across the supply chain,
                reducing risks of counterfeit parts and materials.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDevices className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  {" "}
                  Smart Contracts
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate vendor payments and contract execution to streamline
                procurement processes.
              </p>
            </div>
            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Product Lifecycle Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Track and manage products from design to end-of-life with secure
                and immutable records.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Connected Block Cubes"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
