import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { MdOutlineAutorenew } from 'react-icons/md'; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from 'react-icons/md'; // Continuous Model Improvement
import { MdOutlineGavel } from 'react-icons/md';
import img1 from "../../../assets/img/EngineeringExcellence/Ip/focus.jpeg"
import { FaRegChartBar } from 'react-icons/fa';
import { AiOutlineBulb } from 'react-icons/ai';
import { AiOutlineSearch } from 'react-icons/ai';

import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export default function MainSection5() { 
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Focus on Emerging Frontiers
            </h1>
            <p className="text-lg text-gray-600">
            Pioneering advancements in AI, Quantum Computing, and Web3 technologies to deliver transformative solutions for modern enterprises.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">AI and Generative AI</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Developing domain-specific AI models and algorithms for automation, natural language processing, and computer vision.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Quantum Computing</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Advancing proprietary algorithms for quantum optimization, cryptography, and data analysis to redefine problem-solving for enterprises.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Web3 and Blockchain</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Creating decentralized protocols, smart contract frameworks, and tokenization solutions to drive transparency and security.
              </p>
            </div>

         
          </div>
        </div>

      
      </div>
    </div>
  )
}