import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoIosRocket } from 'react-icons/io';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { IoMdWifi } from 'react-icons/io';
import img1 from "../../../../assets/img/Services/IotEdgeSolution/18126.jpg"

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Low-Latency Applications
            </h1>
            <p className="text-lg text-gray-600">
            Support time-sensitive applications like industrial automation, autonomous vehicles, and AR/VR.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineClockCircle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Time-Sensitive Operations</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Optimize low-latency networks to support critical applications in industrial automation, reducing delays and ensuring real-time responsiveness.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosRocket className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Advanced Technologies Support</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enable real-time data processing for autonomous vehicles and AR/VR applications, ensuring smooth and seamless user experiences.
              </p>
            </div>

      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}