import React from "react";
import feature_img from "../../../assets/img/Products/AeroPerformai/2150169846.jpg";
import { AiOutlineAppstore } from "react-icons/ai";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export default function Feature6() {
  return (
     <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
             <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
               <div className="relative h-[500px] rounded-3xl overflow-hidden">
                 <img
                   src={feature_img}
                   alt="Workspace with laptop and plant"
                   className="object-cover w-full h-full rounded-3xl"
                 />
               </div>
               <div className="space-y-8">
                 <div className="space-y-4">
                   <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                   Performance Stress Testing
                   </h1>
                   <p className="text-lg text-gray-600">
                   AeroPulse AI empowers businesses with controlled stress tests to simulate various system load scenarios. These insights help reinforce infrastructure resilience and prepare for unexpected demand surges.
                   </p>
                 </div>
       
                 <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                   {/* Feature 1 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Controlled System Stress Testing
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     AeroPulse AI includes a unique feature that enables controlled stress tests on your systems. By simulating scenarios such as memory spikes, I/O spikes, and more, we provide insights into how your infrastructure would react under various stress conditions.
                     </p>
                   </div>
       
                   {/* Feature 2 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <AiOutlineAppstore className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Infrastructure Resilience Insights
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     This information is invaluable for strengthening your systems and ensuring they can handle unexpected surges in demand.
                     </p>
                   </div>
       
                   {/* Feature 3 */}
                   {/* <div className="space-y-3">
                             <div className="flex items-center space-x-2">
                               <AiOutlineSolution className="w-6 h-6 text-indigo-600" />
                               <h3 className="font-semibold text-gray-900">
                               Proactive Issue Resolution
                               </h3>
                             </div>
                             <p className="text-gray-600 text-sm">
                             Detect and resolve inefficiencies before they escalate.
                             </p>
                           </div> */}
       
                   {/* Feature 4 */}
                   {/*  <div className="space-y-3">
                             <div className="flex items-center space-x-2">
                               <MdOutlineIntegrationInstructions className="w-6 h-6 text-indigo-600" />
                               <h3 className="font-semibold text-gray-900">
                                 Improved Collaboration
                               </h3>
                             </div>
                             <p className="text-gray-600 text-sm">
                               Enhance team productivity by integrating tools that support
                               shared workflows and version control.
                             </p>
                           </div> */}
                 </div>
               </div>
             </div>
           </div>
  );
}
{/* <div>
      <section className="dark:bg-gray-800 pt-5 dark:text-gray-100">
        <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
          <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img
                src={feature_img}
                alt=""
                className="rounded-lg shadow-lg dark:bg-gray-500 object-cover h-80 sm:min-h-96"
              />
            </div> 
          </div>
          <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm lg:py-10 sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white dark:text-gray-900">
            <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
            Performance Stress Testing
            </h3>
            <div className="flex space-x-2 sm:space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>
              <div className="space-y-2">
                <p className="text-lg font-medium leadi">
                AeroPulse AI includes a unique feature that enables controlled stress tests on your systems. By simulating scenarios such as memory spikes, I/O spikes, and more, we provide insights into how your infrastructure would react under various stress conditions.
                </p>
              </div>
            </div>
            <div className="flex space-x-2 sm:space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>
              <div className="space-y-2">
                <p className="text-lg font-medium leadi">
                This information is invaluable for strengthening your systems and ensuring they can handle unexpected surges in demand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> */}