
import img1 from "../../../../assets/img/Services/IotEdgeSolution/PredictiveAnalysis.jpg" 

import { SiTestcafe } from "react-icons/si";
import { GrVirtualMachine } from "react-icons/gr";
import { BsClipboard2Data } from "react-icons/bs";

export default function MainSection3() {
  return ( 
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Operational Simulation
            </h1>
            <p className="text-lg text-gray-600">
            Simulate various operational scenarios to identify inefficiencies and improve processes. Test strategies in a virtual environment for optimal outcomes.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <SiTestcafe className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Scenario Testing</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Simulate operational conditions to uncover bottlenecks and inefficiencies.

              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GrVirtualMachine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Risk-Free Optimization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Experiment with process adjustments in a virtual setting.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsClipboard2Data className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Outcome Enhancement</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Improve operational results through data-driven simulation insights.
              </p>
            </div>

            {/* Feature 4 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiLike className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">All in One</h3>
              </div>
              <p className="text-gray-600 text-sm">
                All-in-one solution ensures that you can easily manage your financial needs.
              </p>
            </div> */}
          </div>
        </div>

      
      </div>
    </div>
  )
}