import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { IoLockClosedOutline } from 'react-icons/io5';
import { BiLike } from 'react-icons/bi';
import { MdOutlineInsights } from 'react-icons/md'; // Tailored Predictive Analytics
import { MdOutlineLightbulb } from 'react-icons/md';
import img1 from '../../../assets/img/EngineeringExcellence/Ip/randd.jpeg';

import { RiLineChartLine } from "react-icons/ri";
import { AiOutlineUser } from 'react-icons/ai';
import { LiaHandsHelpingSolid } from "react-icons/lia";

export default function MainSection2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Our Approach to IP Development
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Innovation-Driven R&D
            </h1>
            <p className="text-lg text-gray-600">
              Advancing the frontier of technology through focused research on
              emerging fields like AI, Blockchain, and Quantum Computing. Our
              cross-domain collaboration fosters groundbreaking innovations and
              frameworks.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiLineChartLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Emerging Technologies
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                We dedicate significant resources to research and development,
                exploring emerging technologies like Artificial Intelligence
                (AI), Generative AI (GenAI), Blockchain, Quantum Computing,
                Web3, Neural Processing Units (NPUs), Artificial General
                Intelligence (AGI), and Artificial Super Intelligence (ASI).
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaHandsHelpingSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Cross-Domain Collaboration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Our R&D teams collaborate across domains to generate new ideas,
                algorithms, and frameworks that become the foundation for
                groundbreaking IPs.
              </p>
            </div>
            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineHealthAndSafety className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Generative AI for Diagnostics
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Accelerate and enhance diagnostic accuracy with AI-powered
                imaging and data analysis tools.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
