import React from 'react';
import ethicalc_img from '../../assets/img/Csr/ethical_conduct.jpg';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { AiOutlineAppstore } from 'react-icons/ai';
import { LiaShieldAltSolid } from "react-icons/lia";
import { LiaBalanceScaleSolid } from "react-icons/lia";

export default function Section7() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Ethical Conduct
            </h1>
            <p className="text-lg text-gray-600">
            We have a zero-tolerance policy for corruption and unethical behavior. We uphold strict ethical standards in all our business dealings, ensuring fairness, transparency, and honesty.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaShieldAltSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Zero-Tolerance for Corruption
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              We maintain a strict policy against corruption, promoting ethical behavior in all business activities to ensure integrity across all operations.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaBalanceScaleSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Fairness and Transparency
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              We uphold transparency and fairness in our business dealings, ensuring that all actions are carried out honestly and responsibly.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={ethicalc_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
{
  /* <div>
           
           <div className="container flex flex-col-reverse mx-auto lg:flex-row">
            <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
              <h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">
              Ethical Conduct 
              </h3>
              <div className="flex space-x-2 sm:space-x-4 pt-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                <div className="space-y-2">
                  <p className="text-lg font-medium leadi">
                  We uphold strict ethical standards in all our business dealings, ensuring fairness, transparency, and honesty.
                  </p>
                </div>
              </div>
              <div className="flex space-x-2 sm:space-x-4 pt-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                <div className="space-y-2">
                  <p className="text-lg font-medium leadi">
                  We have a zero-tolerance policy for corruption and unethical behavior.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
              <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                <img
                  src={ethicalc_img}
                  alt=""
                  className="rounded-lg shadow-lg dark:bg-gray-500 object-cover  h-80 sm:min-h-96"
                />
              </div>
            </div>
          </div>
    </div> */
}
