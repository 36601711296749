import React from "react";
import { MdOutlineLightbulb } from "react-icons/md"; // AI/ML Solutions
import { MdOutlineCloudQueue } from "react-icons/md"; // Cloud & Edge Scaling
import { MdOutlineSpeed } from "react-icons/md"; // Optimization
import { MdOutlineBuild } from "react-icons/md"; // Automation
import { FaLightbulb, FaPencilRuler, FaRocket, FaCogs } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { TiWorld } from "react-icons/ti";

export default function MainSection1() {
  return (
    <div>
      <div className="lg:mt-6 px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-center mb-6 lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">
              Why AeroAegis Excels in Product Engineering?
            </span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-2">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
              {[
                {
                  icon: <TiWorld />,
                  title: "Expertise Across Domains",
                  description:
                    "From AI to Web3, our cross-disciplinary expertise ensures that we create innovative, future-ready products.",
                },
                {
                  icon: <MdOutlineCloudQueue />,
                  title: "Collaboration and Transparency",
                  description:
                    "Our teams work closely with customers, keeping them involved and informed throughout the development journey.",
                },
                {
                  icon: <MdOutlineSpeed />,
                  title: "Speed and Quality",
                  description:
                    "We balance agility with engineering excellence to deliver robust, scalable, and high-performance products.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="card border lg:w-[300px] border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
                >
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">
                      {item.icon}
                    </div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
