import React, { useRef } from 'react'
import development_vid from '../../assets/videos/Homepage/video1.mp4'
import meeting_vid from '../../assets/videos/Homepage/video2.mp4'
import rnd_vid from '../../assets/videos/Homepage/video3.mp4'
import watch_vid from '../../assets/videos/Homepage/video4.mp4'
import operational_vid from '../../assets/videos/Homepage/video5.mp4'
import { FaDigitalOcean, FaCloud, FaCogs, FaChartLine } from 'react-icons/fa'

export default function WhatweOffer() {
  const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null),useRef(null)];

  const handleMouseEnter = (id) => () => {
    const index = cardData.findIndex((card) => card.id === id);
    if (videoRefs[index].current) {
      videoRefs[index].current.play();
    }
  };

  const handleMouseLeave = (id) => () => {
    const index = cardData.findIndex((card) => card.id === id);
    if (videoRefs[index].current) {
      videoRefs[index].current.pause();
      videoRefs[index].current.currentTime = 0;
    }
  };

  const cardData = [
    {
      id: 1,
      title: 'Innovative Product Suite',
      videoSource: development_vid,
      
      content1:
        "At AeroAegis, our core mission is to deliver pioneering technology solutions that redefine industry standards and propel businesses into the future. ",
      content2:"Our product suite - comprising Maturity Spectrum 360, SmartChaos Lab, AeroPulse AI, AeroInfra Vue - is the embodiment of innovation, designed to address and transform your digital challenges into opportunities.",
    },
    {
      id:2,
      title: 'Customized Technological Solutions',
      videoSource: meeting_vid,
     
        content1:
        "Understanding the diversity of business challenges, AeroAegis products are engineered for flexibility. Our solutions adapt to your unique needs, ensuring seamless integration and alignment with your strategic objectives.",
        content2: "This bespoke approach guarantees that our products not only solve current challenges but also scale with your growth.",
    },
    {
      id:3,
      title: 'Strategic Implementation Support',
      videoSource: rnd_vid,
   
      content1:
        "Following a comprehensive assessment by Maturity Spectrum 360, AeroAegis extends beyond traditional product delivery. We offer strategic support services, including the implementation of recommended solutions, to ensure that the full potential of our products is realized within your enterprise.",
        content2:"This not only includes technical deployment but also encompasses process optimization and workforce upskilling to embrace the new solutions.",
    },
    {
      id:4,
      title: 'Research & Development for Continuous Advancement',
      videoSource:
        watch_vid,
      
      content1:
        "Our dedication to innovation is sustained by a robust Research & Development (R&D) effort, focusing on the continuous evolution of our products and the exploration of new technological frontiers.", 
      content2:"This commitment ensures that AeroAegis solutions remain at the cutting edge, offering you future-proof technologies that anticipate and adapt to the evolving digital landscape.",
    },
    {
      id:5,
      title: 'Operational Excellence and Reliability',
      videoSource:
        operational_vid,
      
      content1:
        "Operational services tied to our products provide an additional layer of value, ensuring that the integration and ongoing management of our solutions are seamless, secure, and efficient. ", 
      content2:"By taking care of the operational intricacies, we enable you to concentrate on strategic business objectives, with the confidence that AeroAegis solutions are enhancing your operational efficiency and system reliability.",
    },
    
  ];

  return (
    <div className="bg-gray-50 py-16  lg:pt-20 pb-0">

<div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
      <div className="relative">
        {/* Decorative background elements */}
        {/* <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div> */}
        
        {/* Main heading */}
        <div className="relative flex flex-col items-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 bg-white px-4 relative">
            What We Offer
            {/* Custom underline */}
            <div className="absolute -bottom-2 left-0 right-0 h-1 bg-blue-600 rounded-full" />
          </h2>
          <h1 className="mt-4 text-4xl sm:text-5xl font-extrabold text-center">
            <span className="text-gray-900">Revolutionary Products</span>{' '}
            <span className="text-blue-700">at the Core of</span>
            <br />
            <span className="text-gray-900">Digital Transformation</span>
          </h1>
        </div>

        {/* Description with animated icons */}
        <div className="mt-12 relative">
          <div className="absolute inset-0 flex items-center justify-center opacity-10">
            <div className="w-48 h-48 bg-blue-100 rounded-full filter blur-xl" />
          </div>
          
          <p className="text-lg md:text-xl text-gray-600 text-center leading-relaxed relative z-10 max-w-4xl mx-auto">
            At AeroAegis, we offer more than just products; we provide solutions that drive transformation. Our suite of innovative tools is designed to meet the dynamic needs of businesses, enabling them to thrive in an ever-evolving digital landscape.
          </p>
        </div>

        {/* Feature icons */}
        <div className="mt-16 grid grid-cols-2 gap-8 md:grid-cols-4">
          {[
            { icon: FaDigitalOcean, text: "Digital Transformation" },
            { icon: FaCloud, text: "Cloud Resilience" },
            { icon: FaCogs, text: "Infrastructure Design" },
            { icon: FaChartLine, text: "Performance Management" },
          ].map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center group hover:-translate-y-1 transition-transform duration-300"
            >
              <div className="w-16 h-16 rounded-lg bg-blue-100 flex items-center justify-center group-hover:bg-blue-200 transition-colors duration-300">
                <feature.icon className="w-8 h-8 text-blue-600 group-hover:text-blue-700 transition-colors duration-300" />
              </div>
              <p className="mt-4 text-sm font-medium text-gray-700">{feature.text}</p>
            </div>
          ))}
        </div>

        {/* Bottom decorative line */}
       <div className="mt-16 w-full border-t border-gray-200" /> 
      </div>
    </div>

      {/* <div  className='px-4 mx-auto  sm:max-w-xl md:max-w-full  md:px-24 lg:px-8' >
        <p className="py-5 px-10 lg:px-36 text-center text-lg text-gray-700 ">
        At AeroAegis, we offer more than just products; we provide solutions that drive transformation. Our suite of innovative tools is designed to meet the dynamic needs of businesses, enabling them to thrive in an ever-evolving digital landscape. From digital transformation and cloud resilience to application performance management and infrastructure design, our products stand at the forefront of technological advancement.
        </p>
        </div> */}
      <div className="flex flex-wrap justify-center gap-0 row-gap-0 mb-8  sm:row-gap-0 ">
        {cardData.map((card) => (
          <a
            key={card.id}
            aria-label="View Item"
            onMouseEnter={handleMouseEnter(card.id)}
            onMouseLeave={handleMouseLeave(card.id)}
            className={card.id === 5 ? 'w-full' : 'w-full lg:w-1/2'}
          >
             <div className={`relative group h-[300px] lg:h-[650px] sm:w-full overflow-hidden transition duration-700 transform shadow-lg hover:scale-[1.01] hover:z-40 lg:w-full md:w-full`}>
              {card.videoSource ? (
                <video
                  ref={videoRefs[card.id - 1]}
                  className="object-cover  w-full h-full lg:h-full md:h-full   "
                  muted
                  loop
                >
                  <source src={card.videoSource} type="video/mp4" />
                </video>
              ) : (
                <img
                  className="object-cover w-full h-64 md:h-64 xl:h-80"
                  src={card.imageSource}
                  alt=""
                />
              )}
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
          <h1 className='text-gray-200 flex mt-20 md:text-4xl md:mt-36 lg:mt-72  text-center lg:text-center justify-center font-semibold align-content-center text-4xl lg:text-6xl group-hover:hidden'>{card.title}</h1>
        </div>
              <div className="absolute  inset-0 px-6 py-2 transition-opacity duration-700 bg-black bg-opacity-35 opacity-0  hover:scale-[1.01] hover:opacity-100 flex justify-center items-center">
                <div className=' py-5 text-[12px] lg:text-lg md:text-base text-center max-w-lg'>
                <p className="font-bold text-gray-100 pb-2 text-lg md:text-4xl lg:text-5xl lg:pb-5">
                  {card.title}
                </p>
                <p className="  tracking-wide text-gray-300">
              
                  {card.content1}
                </p>
                <p className=" mt-3  tracking-wide text-gray-300">
           
                  {card.content2}
                </p>
                </div>
               
              </div>
            </div>
          </a>
        ))}
      </div>
  
    </div>
  );
}