import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import { MdOutlineInsights } from 'react-icons/md'; // Tailored Predictive Analytics
import { MdOutlineLightbulb } from 'react-icons/md';
import img1 from "../../../assets/img/Industries/TelecomandNetworks/5gopt.png";
import { RxTimer } from "react-icons/rx";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";

import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { PiSpeedometerLight } from "react-icons/pi";
 
export default function MainSection2() { 
  return (
    
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
       <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Transformative Solutions for Telecom & Networks</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2> 
          
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
      
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            5G Deployment and Optimization
            </h1>
            <p className="text-lg text-gray-600">
            Optimize network rollouts, enhance low-latency services with edge computing, and unlock revenue streams through 5G monetization platforms.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosCheckmarkCircleOutline  className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Network Planning and Design</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage AI and advanced analytics to optimize 5G network rollouts for maximum coverage and performance.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <PiSpeedometerLight className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Edge Computing Integration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enable low-latency services by processing data closer to the user, enhancing speed and responsiveness.
              </p>
            </div>

            {/* Feature3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLightbulb className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">5G Monetization Platforms</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Build platforms that enable new revenue streams, such as IoT, smart cities, and connected vehicles.
              </p>
            </div>

           
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}