import React from 'react';
import {
  MdOutlineDashboardCustomize,
} from 'react-icons/md';
import AI_img from "../../../../assets/img/Partnerships/consulting.webp";
import { AiOutlineLineChart } from "react-icons/ai";
import { PiCertificate } from "react-icons/pi";

export default function Section2() { 
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={AI_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Enhanced Consulting Services
            </h1>
            <p className="text-lg text-gray-600">
            AeroAegis's ISV certification empowers us to offer specialized consulting services, ensuring clients achieve maximum value from Azure technologies.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <PiCertificate className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Tailored Consulting Services
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              With ISV certification, AeroAegis is equipped to provide advanced consulting services customized for Azure technologies, addressing diverse client needs effectively.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineLineChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Optimized Azure Solutions
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              From cloud migration strategies to workload optimization, AeroAegis ensures clients benefit from best practices and seamless integration, driving performance improvements on Azure.
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
