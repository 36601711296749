import React from 'react';
import { Link } from 'react-router-dom';
import city_img from '../../assets/img/Whitepapers/digital.jpg';
import laptop_img from '../../assets/img/Whitepapers/23679.jpg';
import blueprint_img from '../../assets/img/Whitepapers/blueprint.jpg';
import ms360_logo from '../../assets/img/Products/MS360/ms360-logo-1.svg';
import smartchaoslab_img from '../../assets/img/Products/SmartChaosLab/smartchaoslab-only-image-logo.png';
import infravue_img from '../../assets/img/Products/InfraVue/infra-vue-image-only-logo.png';
import { FaArrowRight } from 'react-icons/fa';


const mockWhitepapers = [
  {
    id: '1',
    title: 'Maturity Spectrum 360',
    subtitle: 'Unleashing the Power of Navigating Digital Transformation',
    description: 'This white paper delves into the significance of digital transformation, elucidating the complexities organizations face. We explore how Maturity Spectrum 360 acts as a catalyst for this transformation journey, integrating cutting-edge AI algorithms, customizable modules, and seamless integration capabilities.',
    image: city_img,
    icon: <img src={ms360_logo} className="w-6 h-6" alt="" />,
    url: '/navigating-digital-transformation-unleashing-power-maturity-spectrum-360',
  },
  {
    id: '2',
    title: 'SmartchaosLab',
    subtitle: 'Revolutionizing Application Reliability with Chaos Engineering',
    description: 'SmartchaosLab is a cutting-edge platform that brings chaos engineering to the forefront of application reliability improvement. By intelligently simulating controlled failures in cloud environments, SmartchaosLab empowers organizations to identify weaknesses, enhance system resilience, and optimize application performance.',
    image: laptop_img,
    icon: <img src={smartchaoslab_img} className="w-6 h-6" alt="" />,
    url: '/smartchaoslab-revolutionizing-application-reliability-with-chaos-engineering',
  },
  {
    id: '3',
    title: 'InfraVue',
    subtitle: "Blueprinting Tomorrow's Infrastructure Today",
    description: 'InfraVue is a transformative feature, radically simplifying the traditionally complex realm of infrastructure design. It offers a seamless, user-friendly approach, enabling both technical and non-technical individuals to conceptualize and implement infrastructure components with unprecedented ease.',
    image: blueprint_img,
    icon: <img src={infravue_img} className="w-6 h-6" alt="" />,
    url: '/blueprinting-tomorrows-infrastructure-today',
  },
];



const WhitepapersSection = () => {
  return (
    <section className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Explore Our Whitepapers</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {mockWhitepapers.map((whitepaper) => (
            <div key={whitepaper.id} className="flex flex-col bg-white rounded-xl shadow-2xl overflow-hidden">
              {/* Image Section */}
              <div>
                <img
                  src={whitepaper.image}
                  alt={whitepaper.title}
                  className="w-full h-64 object-cover"
                />
              </div>
              {/* Content Section */}
              <div className="flex-grow flex flex-col p-6">
                <div className="flex items-center mb-4">
                  {whitepaper.icon}
                  <h3 className="ml-3 text-2xl font-bold text-gray-900">{whitepaper.title}</h3>
                </div>
                <h4 className="text-xl text-blue-600 mb-4">{whitepaper.subtitle}</h4>
                <p className="text-gray-600 flex-grow">{whitepaper.description}</p>
                {/* Button at the Bottom */}
                <Link
                  to={whitepaper.url}
                  className="mt-6 inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition duration-300 self-start"
                >
                  Explore
                  <FaArrowRight className="ml-2" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhitepapersSection;
