import React from 'react';
import { FaFlask, FaChartLine, FaShieldAlt, FaDatabase } from 'react-icons/fa'; // New icons for Pharmaceuticals & Life Sciences

export default function MainSection1() {
  return (
    <div> 
      <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-center mb-6 lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-6xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">
              Why Choose AeroAegis for Pharmaceuticals & Life Sciences?
            </span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-20">
              {[
                {
                  icon: <FaFlask />,
                  title: 'Domain Expertise',
                  description:
                    'With deep experience in pharmaceuticals and life sciences, AeroAegis delivers tailored solutions for drug development, clinical trials, and patient care.',
                },
                {
                  icon: <FaChartLine />,
                  title: 'Innovative Technology Stack',
                  description:
                    'Our solutions integrate AI, blockchain, and advanced analytics to transform complex processes into streamlined workflows.',
                },
                {
                  icon: <FaShieldAlt />,
                  title: 'Regulatory Compliance Assurance',
                  description:
                    'We ensure adherence to global standards, including FDA, EMA, HIPAA, and GDPR, enabling organizations to focus on innovation with confidence.',
                },
                {
                  icon: <FaDatabase />,
                  title: 'Data-Driven Insights',
                  description:
                    'By leveraging real-time data and predictive analytics, we help organizations make informed decisions and achieve better outcomes faster.',
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 lg:w-[300px]"
                >
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">
                      {item.icon}
                    </div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}