import React from "react";
import img1 from "../../../assets/img/discover_aeroaegis/7.jpg";
import {
  HiOutlineUsers,
  HiOutlineLightBulb,
  HiOutlineGlobeAlt,
  HiOutlinePuzzle,
} from "react-icons/hi";

export default function MainSection8() {
  return (
    <section className=" ">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Collaborative Ecosystem: Amplifying Impact Together
              </h1>
              <p className="text-lg text-gray-600">
                Building partnerships to drive comprehensive digital excellence.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineUsers className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Strategic Partnerships
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Collaborating with tech leaders to enrich our ecosystem.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineLightBulb className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Innovative Solutions
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Driving innovation through collective expertise and insights.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineGlobeAlt className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Global Collaboration
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Partnering globally to create a seamless tech ecosystem.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlinePuzzle className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Comprehensive Approach
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Offering integrated strategies for impactful digital
                  solutions.
                </p>
              </div>
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
