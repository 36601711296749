import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineSchool } from 'react-icons/md'; // Advanced Model Training
import { MdOutlineCloudDone } from 'react-icons/md'; 
import img1 from "../../../assets/img/EngineeringExcellence/ProductEngineering/2.jpeg"
import { FaSync, FaExchangeAlt, FaCodeBranch, FaRocket } from 'react-icons/fa';
import { MdOutlineLocalHospital } from 'react-icons/md';
import { GiArtificialIntelligence } from 'react-icons/gi';

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Agile Development Methodology
            </h1>
            <p className="text-lg text-gray-600">
            Agile Development Methodology emphasizes flexibility, adaptability, and rapid delivery in product engineering. By leveraging iterative processes, it ensures products evolve efficiently while maintaining high quality and reliability.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaSync className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Iterative Development
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Agile uses short development cycles, enabling frequent iterations and improvements.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaExchangeAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Flexibility to Change
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Agile easily accommodates evolving requirements, ensuring the product aligns with user needs.
              </p>
            </div>

            
            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCodeBranch className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Continuous Integration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Agile promotes regular integration of code, reducing risks and ensuring a stable product.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRocket className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Faster Time-to-Market

                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              With its rapid cycles, Agile accelerates product delivery, keeping you ahead of competitors.
              </p>
            </div>



           
          </div>
        </div>

      
      </div>
    </div>
  )
}