import {
  MdOutlineLocalShipping,
  MdOutlineEco,
  MdOutlineWifi,
} from "react-icons/md";

import img1 from "../../../assets/img/Industries/Automotive/shutterstock_1424891870-1024x683.jpg";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Driving Innovation and Sustainability
            </h1>
            <p className="text-lg text-gray-600">
              AeroAegis is committed to helping the automotive industry embrace
              the future with intelligent, efficient, and sustainable solutions:
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLocalShipping className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Smarter Fleet Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize fleet performance with IoT-driven insights, reducing
                operational costs and improving reliability.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineEco className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Sustainable Manufacturing
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable eco-friendly practices with blockchain-based supply chain
                management and optimized resource utilization.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineWifi className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Connected Mobility
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Foster the development of connected vehicles, enabling seamless
                integration with smart city infrastructures.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
