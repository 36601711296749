import React from "react";
import hero_img from "../../../assets/img/EngineeringExcellence/ProductEngineering/hero.jpg";
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaDatabase,
  FaBrain,
} from "react-icons/fa";
// export default function FinalSection() {
//   return (
//     <section
//     className="relative text-white py-20 bg-cover bg-center"
//     style={{
//       backgroundImage: `url('${hero_img}')`,
//     }}
//   >
//     {/* Overlay */}
//     <div className="absolute inset-0 bg-black bg-opacity-50"></div>

//     {/* Content */}
//     <div className="container mx-auto px-4 text-center relative z-10">
//       <h1 className="text-4xl md:text-6xl font-bold mb-4">
//         Partner with Aero Aegis
//       </h1>
//       <p className="text-xl mb-8">
//         At Aero Aegis, we combine technology expertise, industry knowledge, and a
//         patient-first mindset to deliver transformative solutions that drive
//         results. Whether it’s through AI innovation, cloud modernization, or
//         IoT-enabled systems, we’re here to lead your healthcare organization into
//         the future.
//       </p>
//       <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
//         Contact Us
//       </button>
//     </div>
//   </section>
//   )
// }

const AeroAegisLanding = () => {
  return (
    <div className=" bg-gray-100">
      {/* Hero Section */}
      <section
        className="relative text-white py-20 bg-cover bg-center"
        style={{
          backgroundImage: `url('${hero_img}')`,
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="container mx-auto px-4 text-center relative z-10">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Driving Business Success Through Engineering Excellence
          </h1>
          <p className="text-xl mb-8">
          At AeroAegis Inc., our product engineering processes are designed to transform ideas into reality, enabling businesses to solve complex challenges and seize new opportunities. With a focus on quality, innovation, and customer success, we are your trusted partner in building products that drive the future of technology.

          </p>
          {/* <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </button> */}
        </div>
      </section>

      {/* Our Product Engineering Process Workflow */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
          Our Product Engineering Process Workflow
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center justify-center">
            <FeatureCard
              icon={<FaBrain className="text-4xl text-blue-600" />}
              title="Ideation and Market Research"
              description="Aligning with customer needs and market trends to define innovative product ideas."
            />
            <FeatureCard
              icon={<FaCog className="text-4xl text-blue-600" />}
              title="Concept Design and Prototyping"
              description="Creating prototypes to visualize, test, and validate product ideas."
            />
            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="Industry-Focused Innovation"
              description="Tailored solutions to solve unique challenges across industries like healthcare, finance, manufacturing, and logistics."
            />
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Agile Development and Testing"
              description="Building products using iterative development cycles and robust testing frameworks."
            />
            <FeatureCard
              icon={<FaDatabase className="text-4xl text-blue-600" />}
              title="Deployment and Go-To-Market"
              description="Ensuring seamless deployment and strategic market entry to maximize impact."
            />
            <FeatureCard
              icon={<FaChartLine className="text-4xl text-blue-600" />}
              title="Support and Continuous Improvement"
              description="Offering ongoing support and updates to enhance product value and adaptability."
            />
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg px-12">
          Join us on the journey of innovation as we shape the future of technology. Let AeroAegis Inc. be your trusted partner in navigating the uncharted territories of emerging technologies.
            🚀
          </p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </button>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default AeroAegisLanding;
