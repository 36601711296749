import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/ProductEngineering/2149036878.jpg";
import { FaBullseye, FaChartLine, FaMap, FaNetworkWired, FaPlug, FaRocket, FaSync } from "react-icons/fa";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Sensor Integration & Data Acquisition
            </h1>
            <p className="text-lg text-gray-600">
            Integrate sensors, devices, and gateways to collect accurate, real-time data.
						 </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaNetworkWired className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Gateway Management</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Manage and monitor the gateway devices responsible for transmitting data.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaPlug className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Sensor Integration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Integrate various sensors seamlessly to collect real-time data.
              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}