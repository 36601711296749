import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { IoIosTrendingUp } from "react-icons/io";
import { FaChartLine } from 'react-icons/fa'; // Trend Forecasting
import { MdOutlinePersonSearch } from 'react-icons/md'; // Behavior Analysis
import { MdOutlineAssessment } from 'react-icons/md'; // Outcome Prediction
import img1 from "../../../../assets/img/Services/AI&ML/2149250216.jpg"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Predictive Analytics
            </h1>
            <p className="text-lg text-gray-600">
            Use advanced statistical models and machine learning to forecast trends, behaviors, and outcomes.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosTrendingUp className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Trend Forecasting</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Predict future market trends and business opportunities using advanced statistical techniques.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePersonSearch className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Behavior Analysis</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Anticipate customer behaviors and preferences to optimize strategies and improve engagement.
              </p>
            </div>
            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineAssessment className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Outcome Prediction</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Use machine learning models to estimate potential outcomes and support decision-making.
              </p>
            </div>

           
          </div>
        </div>

      
      </div>
    </div>
  )
}