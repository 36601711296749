import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/AI&ML/2150010142.jpg"
import { BsDatabaseCheck } from "react-icons/bs";
import { BsDatabaseLock } from "react-icons/bs";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Data Quality & Governance
            </h1>
            <p className="text-lg text-gray-600">
            Implement frameworks for data cleansing, validation, and governance to ensure data accuracy and compliance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsDatabaseCheck className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Data Cleansing and Validation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Automate data cleansing and validation processes to ensure accuracy and consistency across datasets.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsDatabaseLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Robust Data Governance</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Establish data governance frameworks to maintain compliance, security, and transparency in data management.
              </p>
            </div>

          

         
          </div>
        </div>

      
      </div>
    </div>
  )
}