import React from 'react';
import { FaChartLine, FaCog, FaIndustry, FaDatabase, FaBrain } from 'react-icons/fa';
import { FaRocket } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const FinalSection = () => {
  const systemIntegrationData = {
    title: "Why Choose AeroAegis for System Integration Services?",
    description:
      "At AeroAegis Inc., our System Integration Services empower enterprises to build connected, intelligent, and future-ready ecosystems. Whether integrating cloud platforms, modernizing legacy systems, or enabling intelligent workflows, we deliver solutions that drive innovation and business success.",
      features: [
        {
          icon: <FaCog className="text-4xl text-blue-600" />,
          title: "Technology Expertise",
          description: "Platforms: MuleSoft, Dell Boomi, Azure Integration. Cloud: Azure, AWS, GCP, Alibaba. Automation: UiPath, Blue Prism, Automation.",
        },
        {
          icon: <FaDatabase className="text-4xl text-blue-600" />,
          title: "End-to-End Solutions",
          description: "From strategy and design to deployment and support, we ensure seamless system integration.",
        },
        {
          icon: <FaIndustry className="text-4xl text-blue-600" />,
          title: "Business-Centric Approach",
          description: "Solutions tailored to your specific needs, ensuring measurable results.",
        },
        {
          icon: <FaChartLine className="text-4xl text-blue-600" />,
          title: "Future-Ready Architectures",
          description: "Scalable, flexible systems that adapt to changing business demands and technologies.",
        },
        {
          icon: <FaBrain className="text-4xl text-blue-600" />,
          title: "Proven Frameworks",
          description: "Leverage best practices and tools to ensure reliability, security, and performance.",
        },
        {
          icon: <FaRocket className="text-4xl text-blue-600" />,
          title: "Innovative Solutions",
          description: "Cutting-edge technologies and creative approaches to solve complex problems.",
        },
      ],
  
    footerText:
      "Let’s streamline your systems and unlock new opportunities together. Connect with us today! 🚀",
  };
  

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Operational Excellence Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Empowering Enterprises with Future-Ready System Integration Solutions</h1>
          <p className="text-xl mb-8">{systemIntegrationData.description}</p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </button>
        </div>
      </section> 
      <FeatureSection {...systemIntegrationData} />
    </div>
  );
};

const FeatureSection = ({ title, description, features, footerText }) => {
  return (
    <>
      {/* Section Title and Description */}
      <section className="py-20">
        <div className="container mx-auto px-4">

          <h2 className="text-3xl font-bold text-center mb-12">{title}</h2>
         
          {/* Feature Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">{footerText}</p>
          <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </Link>
        </div>
      </footer>
    </>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default FinalSection;




{/* <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Unlock the Power of AI and Data</h1>
          <p className="text-xl mb-8">At AeroAegis Inc., our <strong>AI and Data Services</strong> enable businesses to unlock the power of data and artificial intelligence. Whether you’re building custom AI models, streamlining data pipelines, or leveraging generative AI for automation, we deliver solutions that accelerate innovation, drive efficiency, and create value.</p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </button>
        </div>
      </section> */}