import React, { useState } from "react"
import { HiArrowRight, HiShare } from 'react-icons/hi'
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa'
import { BiLink } from 'react-icons/bi'
import AgenticAI from "../../assets/img/Blogs/AgenticAI.jpg"
import ExplainableAI from "../../assets/img/Blogs/ExplainableAI.jpg"
import { Link } from 'react-router-dom';

export default function BlogCardSection() {
  const [blogs] = useState([
    {
      id: 1,
      date: 'August 14, 2024',
      title: 'Demystifying AI: The Role of Explainable AI (XAI) in Building Transparent and Trustworthy Systems',
      description:
        'Explainable AI (XAI) provides clear justifications for AI decisions, making systems transparent and interpretable. It uses techniques like feature analysis and visualizations to enhance trust in applications like healthcare and finance.',
      image: ExplainableAI,
      link: 'explainable-ai'
    },
    {
      id: 2,
      date: 'March 23, 2024',
      title: 'Agentic AI: Redefining Autonomy in Artificial Intelligence',
      description:
        'Agentic AI systems act autonomously, making decisions and taking actions to achieve goals without human intervention. They leverage technologies like machine learning and NLP to adapt and perform in dynamic environments.',
      image: AgenticAI,
      link: 'agentic-ai'
    },
  ])

  const [activeDropdown, setActiveDropdown] = useState(null)

  const handleShare = async (platform, blog) => {
    const url = `${window.location.origin}/${blog.link}`
    const text = `Check out this article: ${blog.title}`

    switch (platform) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank')
        break
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`, '_blank')
        break
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank')
        break
      case 'copy':
        try {
          await navigator.clipboard.writeText(url)
          alert('Link copied to clipboard!')
        } catch (err) {
          alert('Failed to copy link.')
        }
        break
    }
    setActiveDropdown(null)
  }

  return (
    <section className="max-w-6xl mx-auto px-4 py-12">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Our Latest Blogs</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-indigo-600 scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>

      <div className="space-y-12">
        {blogs.map((blog) => (
          <article key={blog.id} className="flex flex-col md:flex-row gap-8">
            <div className="md:w-1/2">
              <img
                src={blog.image}
                alt=""
                className="w-full h-[300px] object-cover rounded-lg"
              />
            </div>
            <div className="md:w-1/2 space-y-4">
              <div className="flex items-center justify-between">
                <time className="text-sm text-gray-500">{blog.date}</time>
                <div className="relative">
                  <button
                    onClick={() => setActiveDropdown(activeDropdown === blog.id ? null : blog.id)}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                    aria-label="Share"
                  >
                    <HiShare className="h-5 w-5" />
                  </button>
                  {activeDropdown === blog.id && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
                      <button
                        onClick={() => handleShare('facebook', blog)}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <FaFacebook className="mr-2 h-4 w-4" />
                        <span>Facebook</span>
                      </button>
                      <button
                        onClick={() => handleShare('twitter', blog)}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <FaTwitter className="mr-2 h-4 w-4" />
                        <span>Twitter</span>
                      </button>
                      <button
                        onClick={() => handleShare('linkedin', blog)}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <FaLinkedin className="mr-2 h-4 w-4" />
                        <span>LinkedIn</span>
                      </button>
                      <button
                        onClick={() => handleShare('copy', blog)}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <BiLink className="mr-2 h-4 w-4" />
                        <span>Copy link</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <h3 className="text-2xl font-semibold text-gray-900">
                {blog.title}
              </h3>
              <p className="text-gray-600 pb-4">{blog.description}</p>
              <Link
                to={blog.link}
                className="px-6 py-2 rounded-full inline-flex items-center gap-2 hover:opacity-90 transition-opacity bg-indigo-600 text-white"
              >
                Read more
                <HiArrowRight className="w-4 h-4" />
              </Link>
            </div>
          </article>
        ))}
      </div>
    </section>
  )
}

/* import React from 'react';
import { HiArrowRight } from 'react-icons/hi';

import AgenticAI from "../../assets/img/Blogs/AgenticAI.jpg"
import ExplainableAI from "../../assets/img/Blogs/ExplainableAI.jpg"
import { Link } from 'react-router-dom';

export default function BlogCardSection() {
  const blogs = [
    {
      id: 1,
      date: 'FEBRUARY 10, 2023',
      title: 'What is Explainable AI (XAI)?',
      description:
        'Explainable AI (XAI) provides clear justifications for AI decisions, making systems transparent and interpretable. It uses techniques like feature analysis and visualizations to enhance trust in applications like healthcare and finance.',
      image: ExplainableAI,
    link: 'explainable-ai'
    },
    {
      id: 2,
      date: 'March 10, 2023',
      title: 'Agentic AI: Redefining Autonomy in Artificial Intelligence ',
      description:
        'Agentic AI systems act autonomously, making decisions and taking actions to achieve goals without human intervention. They leverage technologies like machine learning and NLP to adapt and perform in dynamic environments.',
      image: AgenticAI,
    link: 'agentic-ai'
    },
  ];

  return (
    <section className="max-w-6xl mx-auto px-4 py-12">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Our Latest Blogs</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>

      <div className="space-y-12">
        {blogs.map((blog) => (
          <article key={blog.id} className="flex flex-col md:flex-row gap-8">
            <div className="md:w-1/2">
              <img
                src={blog.image}
                alt=""
                className="w-full h-[300px] object-cover rounded-lg"
              />
            </div>
            <div className="md:w-1/2 space-y-4">

              <h3 className="text-2xl font-semibold text-gray-900">
                {blog.title}
              </h3>
              <p className="text-gray-600">{blog.description}</p>
              <Link to={blog.link}>
              <button
                className={`px-6 py-2 mt-[15%] rounded-full inline-flex items-center gap-2 hover:opacity-90 transition-opacity bg-indigo-600 text-white`} 
              >
                Read more
                <HiArrowRight className="w-4 h-4" />
              </button></Link>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
} */
              {/* <time className="text-sm text-gray-500">{blog.date}</time> */}
