import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { MdOutlineAutorenew } from 'react-icons/md'; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from 'react-icons/md'; // Continuous Model Improvement
import { MdOutlineGavel } from 'react-icons/md';
import img1 from "../../../assets/img/EngineeringExcellence/ProductEngineering/6.jpeg"
import { AiOutlineSchedule } from 'react-icons/ai';
import { AiOutlineSetting } from 'react-icons/ai';
import { AiOutlineAlert } from 'react-icons/ai';
import {FaExpandArrowsAlt, FaCubes, FaCloud, FaDocker} from "react-icons/fa"



export default function MainSection7() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Cloud-Native Architecture
            </h1>
            <p className="text-lg text-gray-600">
            AeroAegis excels in creating innovative, cloud-native solutions tailored for performance, scalability, and cost-effectiveness. With expertise in microservices, containerization, and serverless computing, their solutions are built to thrive in modern cloud ecosystems.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaExpandArrowsAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Scalable Solutions</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Designs that grow with your needs, enabling effortless scaling in dynamic environments.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCubes className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Microservices Architecture</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Modular systems ensure high efficiency and streamlined operations with simplified maintenance.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaDocker className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Containerized Deployment</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Secure and portable deployments through containerization for consistent performance across platforms.
              </p>
            </div>

             {/* Feature 4 */}
             <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCloud className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Serverless Efficiency</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Secure and portable deployments through containerization for consistent performance across platforms.
              </p>
            </div>

         
          </div>
        </div>

      
      </div>
    </div>
  )
}