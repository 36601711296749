import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlinePhone, MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
import img1 from "../../../assets/img/Industries/RetailEcommerce/b.jpg";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Omnichannel Retail Solutions
            </h1>
            <p className="text-lg text-gray-600">
              Streamlined strategies and tools to unify customer experiences
              across all retail channels.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSchool className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Unified Commerce Platforms
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Integrate online, offline, and mobile channels for a seamless
                shopping experience.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCloudDone className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Click-and-Collect Optimization
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable hassle-free in-store pickup and curbside delivery for
                online orders.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePhone className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Mobile Commerce Enablement:
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Create feature-rich mobile apps with AI-powered search,
                recommendations, and secure payment gateways.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
