import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { IoLockClosedOutline } from 'react-icons/io5';
import { BiLike } from 'react-icons/bi';
import img1 from '../../../../assets/img/Services/OperationalExe/PerformanceTesting.png';
import { IoSpeedometerOutline } from "react-icons/io5";
import { MdOutlineAssessment } from "react-icons/md";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Load and Stress Testing
            </h1>
            <p className="text-lg text-gray-600">
            Load and stress testing simulate real-world traffic to assess system scalability and ensure it can handle high demand.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoSpeedometerOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Traffic Simulation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Use tools like JMeter, Gatling, LoadRunner, and K6 to simulate real-world user traffic.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineAssessment className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Scalability Testing
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Evaluate system performance under varying load conditions to ensure scalability.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineExclamationCircle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Stress Assessment
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Test system limits to identify potential weaknesses under extreme traffic volumes.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoSettingsOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Performance Optimization</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Fine-tune infrastructure to handle high traffic efficiently and maintain stability.
              </p>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}
