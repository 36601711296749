import React from 'react'
import pic1_img from "../../assets/img/Profile/pic1.jpeg";
import pic2_img from "../../assets/img/Profile/nithin_pic.png";
import pic3_img from "../../assets/img/Profile/Avinaash_Photo.jpg";
import pic4_img from "../../assets/img/Profile/bhaskar-nagaraj-profile-pic.jpeg";
import pic5_img from '../../assets/img/Profile/aravinda-profile-pic.jpeg'


import { FaLinkedinIn, FaTwitter } from 'react-icons/fa'
const LeadershipSec = () => {
  const team = [
    {
      avatar: '/placeholder.svg?height=400&width=400',
      name: 'Dr. Madhu Kumar Reddy',
      title: 'President & CEO',
      desc: 'A visionary leader with nearly two decades of experience across global tech giants like Intel and Samsung, specializing in AI, DevOps, and cloud technologies.',
      linkedin: 'https://www.linkedin.com/in/madhukumarreddy/',
      twitter: 'javascript:void(0)',
    },
    {
      avatar: '/placeholder.svg?height=400&width=400',
      name: 'Dr. Nithin Manmohan',
      title: 'CTO & Chief Architect',
      desc: 'An expert in AI/ML, NLP, and data analytics, with a history of innovation at companies like HP.',
      linkedin: 'https://www.linkedin.com/in/nithinmanmohan/',
      twitter: 'javascript:void(0)',
    },
    {
      avatar: '/placeholder.svg?height=400&width=400',
      name: 'Bhaskar Nagaraja',
      title: 'CIO',
      desc: 'A senior IT leader with 25+ years of experience in AI/ML, currently serving as a director at Intel, driving product R&D.',
      linkedin: 'https://www.linkedin.com/in/bhaskarnagaraja/',
      twitter: 'javascript:void(0)',
    },
    {
      avatar: '/placeholder.svg?height=400&width=400',
      name: 'Dr. Aravind C V',
      title: 'Head of COEs',
      desc: 'A renowned research scholar with 20+ years of experience in AI, LLMs, and cloud-edge computing, holding patents and significant industry accolades.',
      linkedin: 'https://www.linkedin.com/in/dr-aravinda-c-v-b21a5115/',
      twitter: 'javascript:void(0)',
    },
    {
      avatar: '/placeholder.svg?height=400&width=400',
      name: 'Avinaash Pathrae',
      title: 'Head of IT & Operations',
      desc: 'A skilled leader with a proven track record in driving operational efficiency and IT strategy.',
      linkedin: 'javascript:void(0)',
      twitter: 'javascript:void(0)',
    },
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
            Leadership and Expertise
          </h2>
          <p className="text-xl text-gray-600 leading-relaxed">
            The AeroAegis leadership team brings together industry pioneers with
            deep expertise in AI, cloud computing, big data, and emerging
            technologies:
          </p>
        </div>
        <div className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {team.slice(0, 4).map((member, idx) => (
                <div
                  key={idx}
                  className={`group relative bg-white border border-gray-200 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden
                  ${idx === 3 ? 'lg:hidden' : ''}`}
                >
                  <div className="p-6">
                    <div className="text-center">
                      <h3 className="text-xl font-bold text-gray-900 mb-1">
                        {member.name}
                      </h3>
                      <p className="text-lg font-medium text-blue-600 mb-4">
                        {member.title}
                      </p>
                      <p className="text-gray-600 mb-6 line-clamp-4">
                        {member.desc}
                      </p>
                    </div>
                    <div className="flex justify-center space-x-4">
                      <a
                        href={member.linkedin}
                        target="_blank"
                        className="p-3 bg-gray-100 text-gray-600 hover:bg-blue-600 hover:text-white rounded-full transition-colors duration-300"
                      >
                        <FaLinkedinIn className="w-5 h-5" />
                      </a>
                      <a
                        href={member.twitter}
                        className="p-3 bg-gray-100 text-gray-600 hover:bg-blue-400 hover:text-white rounded-full transition-colors duration-300"
                      >
                        <FaTwitter className="w-5 h-5" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col sm:flex-row justify-center mt-8 gap-8">
              {team.slice(3).map((member, idx) => (
                <div
                  key={idx}
                  className={`group w-full md:w-1/2 relative bg-white border border-gray-200 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden
                  ${idx === 0 ? 'hidden lg:block' : ''}
                  `}
                >
                  <div className="p-6">
                    <div className="text-center">
                      <h3 className="text-xl font-bold text-gray-900 mb-1">
                        {member.name}
                      </h3>
                      <p className="text-lg font-medium text-blue-600 mb-4">
                        {member.title}
                      </p>
                      <p className="text-gray-600 mb-6 line-clamp-3">
                        {member.desc}
                      </p>
                    </div>
                    <div className="flex justify-center space-x-4">
                      <a
                        href={member.linkedin}
                        className="p-3 bg-gray-100 text-gray-600 hover:bg-blue-600 hover:text-white rounded-full transition-colors duration-300"
                      >
                        <FaLinkedinIn className="w-5 h-5" />
                      </a>
                      <a
                        href={member.twitter}
                        className="p-3 bg-gray-100 text-gray-600 hover:bg-blue-400 hover:text-white rounded-full transition-colors duration-300"
                      >
                        <FaTwitter className="w-5 h-5" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeadershipSec;
