import { FiDatabase } from 'react-icons/fi';
import { IoIosSettings } from 'react-icons/io';
import { MdOutlineSchool } from 'react-icons/md'; // Advanced Model Training
import { MdOutlineCloudDone } from 'react-icons/md'; 
import img1 from "../../../../assets/img/Services/IotEdgeSolution/2149400051.jpg"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Device Connectivity and Integration
            </h1>
            <p className="text-lg text-gray-600">
            Integrate sensors, actuators, and IoT devices for seamless data collection and system control.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiDatabase className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Seamless Data Collection</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Integrate sensors and IoT devices to continuously collect real-time data, ensuring accurate monitoring and analytics across systems.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosSettings className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Efficient System Control</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Use actuators and connected devices to enable automated control and response, improving operational efficiency and reducing manual intervention.
              </p>
            </div>

           
          </div>
        </div>

      
      </div>
    </div>
  )
}