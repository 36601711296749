import React from 'react'
import { FaArrowRight, FaChartBar, FaCloud, FaShieldAlt } from 'react-icons/fa'
import {Link} from "react-router-dom"
import banking_case_study from '../../assets/img/banking.jpg'
import { useNavigate } from 'react-router-dom'
import { HiOutlineCloud, HiOutlineChartBar, HiOutlineShieldCheck } from "react-icons/hi"
//import { FaArrowRight} from "react-icons/fa"


export default function Section1() {
  const navigate = useNavigate()

  

  return (
<div className=" w-full p-6">
  <div className="bg-white border border-gray-200 w-full  h-full rounded-xl shadow-lg overflow-hidden">
    <div>
      <div>
        <img src={banking_case_study} alt="Banking Case Study" className="w-full h-52 object-cover" />
      </div>
      <div className="p-8">
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Case Study</div>
        <h2 className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
          Transforming Banking Operations with Agent AI
        </h2>
        <p className="mt-2 text-gray-600 dark:text-gray-300">
          Streamlined banking operations and enhanced customer satisfaction with Agent AI.
        </p>
        <div className="mt-4 flex flex-wrap gap-4">
          <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
            <HiOutlineCloud className="mr-1 h-3 w-3" />
            Cloud-Native
          </span>
          <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
            <HiOutlineChartBar className="mr-1 h-3 w-3" />
            Analytics
          </span>
          <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
            <HiOutlineShieldCheck className="mr-1 h-3 w-3" />
            Security
          </span>
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Key Outcomes:</h3>
          <ul className="mt-2 list-disc list-inside text-gray-600 dark:text-gray-300">
            <li>Boosted customer satisfaction by 40%</li>
            <li>Reduced operational costs by automating tasks</li>
            <li>Enhanced scalability and regulatory compliance</li>
          </ul>
        </div>
        <div className="mt-6 lg:mt-[125px] flex justify-center">
          <Link
            to="/banking-casestudy"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Read Full Case Study
            <FaArrowRight className="ml-2 -mr-1 h-4 w-4" />
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

  