import React from "react";
import img1 from "../../../assets/img/aeroaegis_capabilities/aerogenix.png";
import { MdOutlineVisibility, MdOutlineConstruction } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { BiAnalyse } from "react-icons/bi";
import { LuBrain } from "react-icons/lu";
import { RiRadarLine } from "react-icons/ri";
import { RiAiGenerate } from "react-icons/ri";
import { GoDatabase } from "react-icons/go";



export default function MainSection6() {
  return (
    <section className="dark:bg-gray-800 ">
      {/* <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
            <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] dark:text-gray-900">
              <h3 className="text-2xl font-bold tracki sm:text-3xl ">
              AeroSys AR
              </h3>
              <div className="flex space-x-2 sm:space-x-4 pt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                <div className="space-y-2">
                  <p className="text-lg font-medium leadi">
                  An innovative application that leverages augmented reality for real-world
system analysis, defect identification, and automated repair, enhancing system
management processes.

                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 xl:w-3/5 dark:bg-gray-800">
              <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                <img
                  src={Sys_img}
                  alt=""
                  className="w-52"
                />
              </div>
            </div>
          </div> */}
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                AeroGenix
              </h1>
              <p className="text-lg text-gray-600">
                Unleash the Power of AI for Your Enterprise Workflows Build,
                Deploy, and Manage AI Agents Tailored to Your Business Needs.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <RiAiGenerate className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    End-to-End AI Enablement
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  From data integration to deployment and ROI measurement,
                  AeroGenix offers a complete solution.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <GoDatabase className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    BYOD & BYOM Flexibility
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Your data. Your models. AeroGenix works the way you do.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <LuBrain className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Intelligent Agents
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Create sophisticated autonomous agents with no coding
                  expertise required.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Security & Compliance First
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  AeroGenix prioritizes your enterprise's trust and safety.
                </p>
              </div>
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Augmented reality visualization"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
