import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { TbMessageChatbot } from "react-icons/tb";
import { SiGoogleassistant } from "react-icons/si";
import img1 from "../../../../assets/img/Consulting/csi.jpg"

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Conversational AI
            </h1>
            <p className="text-lg text-gray-600">
            Deploy advanced chatbots and virtual assistants powered by large language models (LLMs) for seamless customer support.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TbMessageChatbot className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900"> AI-Powered Chatbots</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Implement advanced chatbots using large language models (LLMs) to provide 24/7 customer support and handle inquiries efficiently.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <SiGoogleassistant className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Virtual Assistants</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Deploy virtual assistants to assist with tasks like booking, troubleshooting, and providing personalized recommendations for an improved customer experience.
              </p>
            </div>

          

         
          </div>
        </div>

      
      </div>
    </div>
  )
}