import React from 'react';
import feature_img from '../../../assets/img/Products/AeroPerformai/97922.jpg';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { AiOutlineAppstore } from 'react-icons/ai';
import { AiOutlineLineChart } from 'react-icons/ai';
import { AiOutlineSecurityScan } from 'react-icons/ai';

export default function Feature5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Proactive Anomaly Detection
            </h1>
            <p className="text-lg text-gray-600">
              AeroPulse AI combines real-time data with predictive analytics to
              identify and prevent potential performance issues. This proactive
              approach ensures timely interventions and maximized system uptime.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineLineChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Real-Time Data Analysis
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leveraging real-time data and predictive analytics, AeroPulse AI
                not only identifies existing performance issues but also
                predicts potential future anomalies..
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSecurityScan className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Predictive Anomaly Prevention
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                This proactive approach allows for timely interventions,
                minimizing the risk of downtime and ensuring continuous system
                availability.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
                         <div className="flex items-center space-x-2">
                           <AiOutlineSolution className="w-6 h-6 text-indigo-600" />
                           <h3 className="font-semibold text-gray-900">
                           Proactive Issue Resolution
                           </h3>
                         </div>
                         <p className="text-gray-600 text-sm">
                         Detect and resolve inefficiencies before they escalate.
                         </p>
                       </div> */}

            {/* Feature 4 */}
            {/*  <div className="space-y-3">
                         <div className="flex items-center space-x-2">
                           <MdOutlineIntegrationInstructions className="w-6 h-6 text-indigo-600" />
                           <h3 className="font-semibold text-gray-900">
                             Improved Collaboration
                           </h3>
                         </div>
                         <p className="text-gray-600 text-sm">
                           Enhance team productivity by integrating tools that support
                           shared workflows and version control.
                         </p>
                       </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={feature_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
