import Iiot_img from "../../../../assets/img/Partnerships/solution.jpg";

import { AiOutlineCloudServer } from "react-icons/ai";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoIosGitNetwork } from "react-icons/io";

function Section3() { 
  return (
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
             <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
               <div className="space-y-8">
                 <div className="space-y-4">
                   <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                   Accelerated Solution Development
                   </h1>
                   <p className="text-lg text-gray-600">
                   AeroAegis leverages its Azure certification to streamline the development of innovative, enterprise-grade solutions.
                   </p>
                 </div>
       
                 <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                   {/* Feature 1 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Accelerated Solution Development
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     Certification enables AeroAegis to rapidly develop custom solutions leveraging Azure's powerful capabilities, ensuring swift and efficient delivery.
                     </p>
                   </div>
       
                   {/* Feature 2 */}
                   <div className="space-y-3">
                     <div className="flex items-center space-x-2">
                       <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                       <h3 className="font-semibold text-gray-900">
                       Integration of Advanced Technologies
                       </h3>
                     </div>
                     <p className="text-gray-600 text-sm">
                     With access to Azure's tools like AI, machine learning, and analytics, AeroAegis empowers enterprises to gain a competitive edge through innovative, technology-driven solutions.
                     </p>
                   </div>
               
                 </div>
               </div>
       
               <div className="relative h-[500px] rounded-3xl overflow-hidden">
                 <img
                   src={Iiot_img}
                   alt="Workspace with laptop and plant"
                   className="object-cover w-full h-full rounded-3xl"
                 />
               </div>
             </div>
           </div>
  );
}
export default Section3;
