import React from 'react';


import hero_img from '../../../../assets/img/CloudMigration/business.jpg';
import Img1 from '../../../../assets/img/Services/systemIntegration/one/2149399282.jpg';
import Img2 from '../../../../assets/img/Services/systemIntegration/one/2149595830.jpg';
import Img3 from '../../../../assets/img/Services/systemIntegration/one/2150377138.jpg';
import Img4 from '../../../../assets/img/Services/systemIntegration/one/132557.jpg';

import {
  IoCheckmarkCircleOutline,
  IoLockClosedOutline,
} from 'react-icons/io5';
import { BiCoinStack, BiLike } from 'react-icons/bi';
import { FaCogs, FaLightbulb, FaPencilRuler, FaRocket } from 'react-icons/fa';
import HeroSection from '../components/HeroSection';
import FeatureSection from '../components/FeatureSection';
import Section from '../components/Section';
import AnimationRevealPage from '../../../../helpers/AnimationRevealPage';

const index = () => {

  const HeroData = {
    title: "Enterprise Application Integration",
    description: "Businesses often operate in complex environments with disparate systems and applications. Our <strong> Enterprise Application Integration (EAI)</strong> services unify your IT landscape, enabling <strong> seamless communication, data flow, and efficiency </strong>across platforms.",
    image: hero_img
  }

  // Features data for "Key Benefits"
  const features = [
    {
      icon: <FaLightbulb />,
      title: 'Unified Workflows',
      description: 'Unified workflows and improved operational efficiency.',
    },
    {
      icon: <FaPencilRuler />,
      title: 'Real-Time Data Exchange',
      description:
        'Real-time, accurate data exchange for better visibility and collaboration.',
    },
    {
      icon: <FaRocket />,
      title: 'Cost-Effective Modernization',
      description: 'Cost-effective modernization of existing IT investments.',
    },
    {
      icon: <FaCogs />,
      title: 'Scalable Integrations',
      description: 'Scalable integrations for future growth.',
    },
  ];

  // Section data
  const sectionData = [
    {
      imageSrc: Img1,
      title: 'Legacy System Modernization',
      description:
        'Connect legacy systems with modern SaaS platforms and enterprise applications without disruption.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Seamless Transition',
          description:
            'Ensure minimal downtime and smooth migration to modern platforms.',
        },
        {
          icon: BiCoinStack,
          title: 'Cost Efficiency',
          description: 'Optimize IT spending while modernizing legacy systems.',
        },
        {
          icon: IoLockClosedOutline,
          title: 'Data Integrity',
          description: 'Maintain data accuracy and reliability during migration.',
        },
        {
          icon: BiLike,
          title: 'Enhanced Functionality',
          description:
            'Upgrade legacy systems with advanced features and tools.',
        },
      ],
    },
    {
      imageSrc: Img2,
      title: 'End-to-End Integration',
      description:
        'Integrate ERP, CRM, HRMS, supply chain systems, and more for a unified ecosystem.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Holistic Ecosystem',
          description: 'Create a unified ecosystem for seamless operations.',
        },
        {
          icon: BiCoinStack,
          title: 'Improved Collaboration',
          description:
            'Facilitate better teamwork across departments with integrated tools.',
        },
        {
          icon: IoLockClosedOutline,
          title: 'Data Security',
          description: 'Ensure secure data exchange across integrated systems.',
        },
        {
          icon: BiLike,
          title: 'Centralized Management',
          description: 'Simplify management with centralized system control.',
        },
      ],
    },
    {
      imageSrc: Img3,
      title: 'Platform Expertise',
      description:
        'Utilize industry-leading tools like MuleSoft, Dell Boomi, Azure Integration Services, and IBM WebSphere for robust and scalable integration.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Diverse Toolset',
          description:
            'Access a range of tools tailored for various integration needs.',
        },
        {
          icon: BiCoinStack,
          title: 'Scalability',
          description:
            'Ensure scalable solutions for growing business requirements.',
        },
        {
          icon: IoLockClosedOutline,
          title: 'Expert Support',
          description:
            'Rely on industry experts for seamless implementation and maintenance.',
        },
        {
          icon: BiLike,
          title: 'Custom Configurations',
          description:
            'Leverage customizable options to address unique business challenges.',
        },
      ],
    },
    {
      imageSrc: Img4,
      title: 'Real-Time Data Synchronization',
      description:
        'Enable real-time data flow across integrated systems to improve decision-making.',
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: 'Real-Time Insights',
          description:
            'Get instant access to actionable insights for better decision-making.',
        },
        {
          icon: BiCoinStack,
          title: 'Operational Efficiency',
          description:
            'Streamline operations with synchronized and up-to-date data.',
        },
        {
          icon: IoLockClosedOutline,
          title: 'Enhanced Accuracy',
          description: 'Reduce errors with synchronized real-time data.',
        },
        {
          icon: BiLike,
          title: 'Improved Agility',
          description:
            'Quickly adapt to market changes with real-time data access.',
        },
      ],
    },
  ];

  return (
    <>
      <HeroSection
        title={ HeroData.title}
        description={HeroData.description}
        image={hero_img}
      />

<AnimationRevealPage >

      <FeatureSection
        mainTitle="Key Benefits"
        description="Seamlessly integrate legacy systems, SaaS platforms, and enterprise applications."
        features={features}
      />
      </AnimationRevealPage>
      <AnimationRevealPage>
      <div className="flex px-2 flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="md:px-10 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          
        </div>
      {sectionData.map((section, index) => (
     
        <Section
          key={index}
          imageSrc={section.imageSrc}
          title={section.title}
          description={section.description}
          features={section.features}
          isImageLeft={index % 2 !== 0}// Alternate image position
        />
       
      ))}
       </AnimationRevealPage>
    </>
  );
};

export default index;
