import React from "react";
import { BsDatabaseCheck } from "react-icons/bs";
import { FaLightbulb, FaPencilRuler, FaRocket, FaCogs } from 'react-icons/fa';
import { GoGraph } from "react-icons/go";
import { MdOutlineDataExploration } from "react-icons/md";
import { MdOutlineInsights } from "react-icons/md";
import { GrOptimize } from "react-icons/gr";

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md italic">
            "Build robust data pipelines 
            <span className=" ml-1">for real-time and batch processing."</span>
          </p>
        </div>

      <section className="py-4 bg-base-100">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { icon: <BsDatabaseCheck />, title: " Reliability", description: "Ensure data availability, reliability, and consistency across your organization." },
              { icon: <MdOutlineDataExploration />, title: "Scalability", description: "Build scalable infrastructure to handle massive volumes of data." },
              { icon: <MdOutlineInsights />, title: "Real-time", description: "Enable real-time insights with efficient data streaming and processing." },
              { icon: <GrOptimize />, title: "Optimization", description: "Reduce costs and improve performance with optimized data workflows." },
            ].map((item, index) => (
              <div key={index} className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
                <div className="card-body items-center text-center">
                  <div className="text-4xl text-primary mb-4">{item.icon}</div>
                  <h3 className="card-title text-xl mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      </div>
    </div>
  );
}
