
import img1 from "../../../../assets/img/Services/StrategicConsulting/blockchain.jpeg"
import { FaCompass, FaDesktop, FaPaintBrush, FaProjectDiagram, FaServer, FaUserAlt } from "react-icons/fa"
import {AiOutlineSafety} from "react-icons/ai"

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Blockchain Maturity Assessment
            </h1>
            <p className="text-lg text-gray-600">
            Evaluate your readiness for blockchain adoption, from infrastructure to governance.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaServer className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                Infrastructure Readiness
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Assess your technical foundation for blockchain integration.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaProjectDiagram className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Operational Scalability

                </h3>         
              </div>
              <p className="text-gray-600 text-sm">
              Measure your capacity to scale blockchain operations.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaPaintBrush className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Aesthetic Appeal</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Create visually appealing interfaces that align with brand identity.
              </p>
            </div> */}

          </div>
        </div>

      
      </div>
    </div>
  )
}