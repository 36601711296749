import React from 'react'
import hero_img from '../../assets/img/Csr/2150196700.jpg'
/* export default function Herosection() { 
  return (
    <div className="relative flex mb-10 flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
    <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
      <svg
        className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
        viewBox="0 0 100 100"
        fill="currentColor"
        preserveAspectRatio="none slice"
      >
        <path d="M50 0H100L50 100H0L50 0Z" />
      </svg>
      <img
        className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
        src={hero_img}
        alt="hero"
      />
    </div>
    <div className="relative flex flex-col-reverse items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-6 lg:max-w-screen-xl">
      <div className="lg:mb-16 pt-2 lg:my-40 lg:max-w-lg lg:pr-5">
        <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
        <h2 className="mb-5  text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Corporate Social Responsibility
        </h2>
        <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
        At Aero Aegis Inc., we believe that our responsibilities extend beyond our business operations. We are committed to making a positive impact on society and the environment through our Corporate Social Responsibility (CSR) initiatives. Our CSR program is rooted in our core values of integrity, innovation, and excellence, and it reflects our dedication to being a responsible corporate citizen.
        </p>
      </div>
    </div>
  </div>
  )
} */

export default function Herosection() { 
  return (
    <div>
      <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-6/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src={hero_img}
            alt=""
          />
        </div>
        <div className="relative flex flex-col-reverse items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-6 lg:max-w-screen-xl">
          <div className="lg:mb-16 pt-2 lg:my-20 lg:max-w-xl lg:pr-5">
            <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
            Corporate Social Responsibility
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
            At Aero Aegis Inc., we believe that our responsibilities extend beyond our business operations. We are committed to making a positive impact on society and the environment through our Corporate Social Responsibility (CSR) initiatives. Our CSR program is rooted in our core values of integrity, innovation, and excellence, and it reflects our dedication to being a responsible corporate citizen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
