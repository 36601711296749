import React from 'react';
import feature_img from '../../../assets/img/Products/AeroGenix/2150169845.jpg';
import { AiOutlineSolution } from 'react-icons/ai';
import { AiOutlineLineChart } from 'react-icons/ai';
import { AiOutlineSecurityScan } from 'react-icons/ai';

export default function Feature5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              ROI and Impact Tracking
            </h1>
            <p className="text-lg text-gray-600">
              Measure the real value AeroGenix brings to your business:
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineLineChart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Built-In Dashboards
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Built-in dashboards to track time savings, cost reductions, and
                productivity gains
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSolution className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Predictive Analytics
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Predictive analytics to forecast the impact of AI adoption.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSecurityScan className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Workflow Heatmaps
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Workflow-specific heatmaps for actionable insights.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={feature_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
