import { GiArtificialIntelligence } from 'react-icons/gi';
import { AiOutlineSafety } from 'react-icons/ai'; 

import img1 from "../../../assets/img/EngineeringExcellence/R&D/agi.jpg"



export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Artificial General Intelligence (AGI) and Artificial Super Intelligence (ASI)
            </h1>
            <p className="text-lg text-gray-600">
            AeroAegis is exploring AGI to develop machines with human-like cognitive abilities for problem-solving and learning.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GiArtificialIntelligence className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Advancing AGI for Human Cognition</h3>
              </div>
              <p className="text-gray-600 text-sm">
              With a bold vision, AeroAegis is actively exploring AGI to develop machines capable of human-like cognitive abilities. This includes solving complex problems, reasoning, and learning across diverse domains.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSafety className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Ethical ASI Integration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Our ambitious research into ASI aims to define ethical boundaries and ensure the safe integration of superintelligent systems into society, unlocking unparalleled capabilities while upholding human-centric values.
              </p>
            </div>
{/* Feature 3 */}
{/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiRefreshCcw className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Disaster Recovery and Backup</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Ensure compliance and data resilience with robust backup and recovery solutions.
              </p>
            </div> */}
      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}