import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
import img1 from "../../../assets/img/Industries/Pharmaceuticals/3.jpg";

import { GiChart } from "react-icons/gi";
import { CiRoute } from "react-icons/ci";
import { SiTestcafe } from "react-icons/si";
import { BsShieldLock } from "react-icons/bs"; // For Enhanced Transparency
import { HiDocumentText } from "react-icons/hi"; // For Streamlined Consent Management
import { FaRegClock } from "react-icons/fa"; // For Real-Time Monitoring

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Blockchain for Secure Clinical Trial Management
            </h1>
            <p className="text-lg text-gray-600">
              Leverage blockchain technology to enhance transparency, streamline
              consent management, and enable real-time monitoring of clinical
              trials.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsShieldLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Enhanced Transparency
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use blockchain to create immutable and tamper-proof records of
                clinical trial data, ensuring integrity and trust across all
                stakeholders.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <HiDocumentText className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Streamlined Consent Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Implement blockchain-based smart contracts to manage informed
                consent securely, improving compliance and participant trust.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRegClock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Real-Time Monitoring
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable real-time tracking of clinical trial progress, enhancing
                oversight and reducing delays in regulatory reporting.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
