import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices } from 'react-icons/md'; 
import img1 from "../../../assets/img/EngineeringExcellence/ProductEngineering/7.jpg"
import { RiExchangeLine } from 'react-icons/ri';
import { FaBrain, FaProjectDiagram, FaRobot, FaChartLine } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';



export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Continuous Innovation and R&D Integration
            </h1>
            <p className="text-lg text-gray-600">
            Empowering innovation through a seamless integration of R&D expertise and product engineering, driving groundbreaking advancements and ensuring a competitive edge.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaBrain className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">AI-Powered Solutions

                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage the latest advancements in Artificial Intelligence (AI) to deliver innovative and efficient solutions tailored to customer needs.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaProjectDiagram className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Seamless R&D Integration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Collaborate closely with the R&D division to ensure product engineering incorporates state-of-the-art technologies.
              </p>
            </div>
          {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRobot className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Future-Ready Technologies
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Stay ahead of the curve by integrating emerging fields like AGI (Artificial General Intelligence) and ASI (Artificial Super Intelligence).
              </p>
            </div>          
            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaChartLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Disruptive Customer Value
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Deliver products that redefine industry standards, providing customers with a competitive advantage.
              </p>
            </div>
      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}