import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices } from 'react-icons/md';
import img1 from '../../../assets/img/EngineeringExcellence/Ip/modular.png';
import { RiExchangeLine } from 'react-icons/ri';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { FiRefreshCcw } from 'react-icons/fi';

import { BsDatabaseLock } from "react-icons/bs";

export default function MainSection6() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Scalable and Modular Architecture
            </h1>
            <p className="text-lg text-gray-600">
              Designing IPs with scalability and modularity to adapt seamlessly
              to diverse business and IT ecosystem needs.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsDatabaseLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Scalability and Modularity
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Every IP we develop is designed for scalability and modularity,
                ensuring it can adapt to varying business requirements and
                integrate seamlessly into diverse IT ecosystems.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineCloudServer className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Interoperability and Cloud-Native Designs
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Our focus on interoperability and cloud-native designs ensures
                that our IPs align with multi-cloud and hybrid cloud strategies.
              </p>
            </div>
            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FiRefreshCcw className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Disaster Recovery and Backup</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Ensure compliance and data resilience with robust backup and recovery solutions.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
