import React from 'react';

export default function FeatureCard({ icon, title, description }) {
  return (
    <div className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
      <div className="card-body items-center text-center">
        <div className="text-4xl text-primary mb-4">{icon}</div>
        <h3 className="card-title text-xl mb-2">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
