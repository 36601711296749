import { BiChip } from 'react-icons/bi';
import { AiOutlineRocket } from 'react-icons/ai';
import img1 from "../../../assets/img/EngineeringExcellence/R&D/Quantum-computing.jpg"

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Quantum Computing
            </h1>
            <p className="text-lg text-gray-600">
            AeroAegis is advancing research in quantum computing to tackle problems that are beyond the reach of classical computation. By collaborating with industry pioneers, we aim to unlock new opportunities in optimization, cryptography, and material discovery, accelerating innovation across industries.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiChip className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Quantum Computing Research</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Advancing quantum computing to solve complex problems that classical systems can't handle, unlocking new opportunities in various industries.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineRocket className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">New Frontiers in Innovation</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Collaborating with industry leaders to explore quantum computing’s potential in optimization, cryptography, and material discovery.
              </p>
            </div>
{/* Feature 3 */}
{/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaFileContract className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Smart Contracts</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Streamline claims and automate complex transactions for payers and insurers.
              </p>
            </div> */}
      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}