import React from "react";
import HeroSection from "./NewHeroSection";
import AnimationRevealPage from "../../../helpers/AnimationRevealPage";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature3 from "./Feature3";
import Feature4 from "./Feature4";
import Feature5 from "./Feature5"; 
import Feature6 from "./Feature6";
import Section1 from "./Section1";
import FinalSection from "./FinalSection";
import Services from "./Services";
import WhyChoose1 from "./WhyChoose1"
import WhyChoose2 from "./WhyChoose2"
import WhyChoose3 from "./WhyChoose3"
import WhyChoose4 from "./WhyChoose4"
import WhyStandsOut from "./WhyStandsOut"
import Howitworks from "./HowItWorks"
import Businessfeature from "./BusinessFeature1"
export default function index() {
  return (
    <div> 
      <HeroSection />
      <Section1/>
      <AnimationRevealPage>
        <WhyChoose1/>
        <WhyChoose2/>
        <WhyChoose3/>
        <WhyChoose4/>
        <WhyStandsOut/>
        <Feature1 />
        <Feature2 />
        <Feature3 />
        <Feature4 />
        <Feature5 />
        <Feature6 />
        <Howitworks/>
        <Services />
     <Businessfeature/>
      </AnimationRevealPage>
      <FinalSection/>
    </div>
  );
}
