import digital from '../../../../assets/img/Partnerships/ecosystem.png';

import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { BsShieldCheck } from 'react-icons/bs';
import { BsDatabaseUp } from 'react-icons/bs';
import { LiaHandshakeSolid } from "react-icons/lia";

export default function Section4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={digital}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Exclusive Access to Azure Ecosystem
            </h1>
            <p className="text-lg text-gray-600">
              AeroAegis's certification unlocks privileged access to Azure's
              comprehensive ecosystem, enhancing capabilities and fostering
              innovation.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineDashboardCustomize className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Exclusive Access to Azure Resources
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                As a certified partner, AeroAegis benefits from privileged
                access to Azure's comprehensive ecosystem, including specialized
                training, dedicated support, and co-marketing opportunities.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LiaHandshakeSolid className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Enhanced Collaboration and Innovation
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                This partnership fosters closer collaboration with Microsoft,
                empowering AeroAegis to deliver innovative, cost-effective
                solutions tailored to client needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
