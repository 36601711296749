import React, { useState, useEffect } from "react";
import alibaba_cloud_banner from "../../assets/img/HomePage/AlibabaCloud.original_XUezb1l.png";
import banner2_img from "../../assets/img/HomePage/banner2.png";
import banner1_img from "../../assets/img/HomePage/Untitled design.png";
import banner3_img from "../../assets/img/HomePage/azure.png";
import { FiPause, FiPlay, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Hero() {
  const [isPaused, setIsPaused] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      image: banner1_img,
      title:
        "Welcome to AeroAegis Engineering the Future, Powering Innovation.",
      subtitle:
        "AeroAegis is a trailblazing technology company at the forefront of AI-first products, cloud solutions, and edge computing innovation. With an unrelenting focus on product excellence, research, and advanced engineering, we create groundbreaking solutions that empower businesses to lead in the era of digital transformation and technological revolution.",
      link: "/aeroaegis-capabilities",
    },
    {
      image: alibaba_cloud_banner,
      title:
        "AeroAegis Partners with Alibaba Cloud to Enhance Digital Transformation Services ",
      link: "/partnerships/alibaba-cloud",
      subtitle:
        "Our strategic partnership with Alibaba Cloud, a global leader in cloud computing and artificial intelligence, enhances our capabilities to offer comprehensive services in Big Data, Cloud Computing, Edge AI, and the Industrial Internet of Things (IIoT).",
    },
    {
      image: banner3_img,
      title: "AeroAegis Partners with Microsoft Azure ISV Partner  ",
      link: "/partnerships/microsoft-azure",
      subtitle:
        "As a certified Microsoft Azure Independent Software Vendor (ISV) Partner, AeroAegis expands its reach and deepens its capabilities with one of the world’s leading cloud platforms.",
    },
    {
      image: banner2_img,
      title: "AeroAegis: Engineering the Future, Today",
      link: "/aeroaegis-capabilities",
      subtitle: "Because innovation is not just what we do—it’s who we are.",
    },
  ];

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isPaused]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="absolute min-h-screen h-screen w-full overflow-hidden bg-gray-900">
      {/* Slides */}
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000
            ${index === currentSlide ? "opacity-100" : "opacity-0"}`}
        >
          <img
            src={slide.image}
            alt={slide.title}
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black/60" />
        </div>
      ))}

      {/* Content */}
      <div className="absolute inset-0 flex items-center">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="max-w-3xl xl:max-w-5xl ">
            <h1 className="mb-6 text-2xl  sm:mt-0 font-bold leading-tight text-white sm:text-4xl md:text-5xl lg:text-5xl xl:mt-2 xl:text-5xl">
              {slides[currentSlide].title}
            </h1>
            <p className="mb-8 text-sm text-gray-300 md:text-xl lg:text-lg xl:text-xl">
              {slides[currentSlide].subtitle}
            </p>

            <Link
              to={slides[currentSlide].link}
              className="rounded-full bg-blue-600 px-8 py-3 text-sm md:text-base font-medium text-white transition-colors hover:bg-blue-700"
            >
              Know more
            </Link>
          </div>
        </div>
      </div>

      {/* Navigation Controls */}
      <div className="absolute top-[80%]  w-full md:w-auto md:right-6 md:top-1/2 md:-translate-y-1/2 flex justify-center md:flex-col gap-4">
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="rounded-full bg-white/10 p-3 text-white backdrop-blur-sm transition-colors hover:bg-white/20"
          aria-label={isPaused ? "Play" : "Pause"}
        >
          {isPaused ? <FiPlay size={24} /> : <FiPause size={24} />}
        </button>
        <button
          onClick={prevSlide}
          className="rounded-full bg-white/10 p-3 text-white backdrop-blur-sm transition-colors hover:bg-white/20"
          aria-label="Previous slide"
        >
          <FiChevronLeft size={24} />
        </button>
        <button
          onClick={nextSlide}
          className="rounded-full bg-white/10 p-3 text-white backdrop-blur-sm transition-colors hover:bg-white/20"
          aria-label="Next slide"
        >
          <FiChevronRight size={24} />
        </button>
      </div>
    </div>
  );
}
