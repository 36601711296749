import img3 from '../../../assets/img/Products/AeroGenix/deploy.svg';
import img1 from '../../../assets/img/Products/AeroGenix/Data points-rafiki.svg';
import img4 from '../../../assets/img/Products/AeroGenix/monitoring.svg';
import img2 from '../../../assets/img/Products/AeroGenix/people creating robot-rafiki.svg';

export default function Benifits() {
  const steps = [
    {
      number: "1",
      title: "Connect Your Data",
      description: "Integrate with your data sources—cloud storage, databases, or streaming platforms like Kafka—using our Knowledge Hub Connectors.",
      image: img1,
    },
    {
      number: "2", 
      title: "Build Your Agents",
      description: "Easily design workflows using our Agentic AI Framework. Deploy agents that understand tasks, execute them autonomously, and collaborate across workflows.",
      image: img2,
    },
    {
      number: "3",
      title: "Deploy with Confidence",
      description: "Launch AI models and agents to production environments with ModelOps&nbsp;and automated pipelines&nbsp;for monitoring and retraining.",
      image: img3,
    },
    {
      number: "4",
      title: "Track ROI in Real Time",
      description: "See the impact AeroGenix is making with advanced dashboards for metrics like time saved, operational cost reduction, and increased productivity.",
      image: img4,
    }
  ]

  return (
    <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
      <div className="flex flex-col justify-center items-center lg:justify-center lg:flex-row ">
            <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group text-center">
              <span className="inline-block mb-1 sm:mb-4 mx-auto">
                How It Works?
              </span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
            </h2>
          </div>
        <p className="mt-2 text-xl text-gray-600">
          Four Steps to Get You Up and Running
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {steps.map((step) => (
          <div 
            key={step.number}
            className="bg-white  rounded-lg p-8 shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-200"
          >
            <div className="flex flex-col  md:flex-row justify-between items-start gap-6">
              <div className="space-y-4">
                <span className="inline-block text-indigo-600 font-medium">
                  Step {step.number}
                </span>
                <h3 className="text-xl font-semibold text-gray-900">
                  {step.title}
                </h3>
                <p className="text-gray-500">
                  {step.description}
                </p>
              </div>
              <div className="flex-shrink-0">
                <img 
                  src={step.image}
                  alt={`Step ${step.number} - ${step.title}`}
                  className="w-32 h-32 object-contain"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}