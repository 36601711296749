import React from "react";

import { VscAzureDevops } from "react-icons/vsc";
import { IoCloudUploadOutline } from "react-icons/io5";
import azureLogo from '../../../../assets/img/Partnerships/azureLogo.png';
import { Link } from "react-router-dom";
import { TbPackages } from "react-icons/tb";

export default function PatnersList1() {
  return (
    <section className="bg-white  px-4 sm:px-6 lg:px-8">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      

        <div className="min-h-screen bg-gradient-to-b from-white to-blue-50">
          {/* Hero Section */}

          <div className="container mx-auto px-4 py-16 md:py-24">
            <div className="flex justify-center mb-2">
            <img src={azureLogo} alt="alibaba cloud logo" className=" w-48" />
            </div>
            <div className="max-w-4xl mx-auto text-center mb-16">
              <h1 className="text-4xl md:text-6xl font-bold text-blue-900 mb-6 tracking-tight">
              Microsoft Azure ISV Partner
              </h1>

              <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              As a certified Microsoft Azure Independent Software Vendor (ISV)
              Partner, AeroAegis expands its reach and deepens its capabilities
              with one of the world’s leading cloud platforms.
              </p>
              <Link to="/partnerships/microsoft-azure ">
              <button className="mt-8 px-8 py-4 bg-blue-900 text-white rounded-full font-semibold hover:bg-blue-800 transform hover:scale-105 transition-all duration-200 shadow-lg">
                Explore more
              </button></Link>
            </div>

            {/* Features Grid */}

            <div className="grid md:grid-cols-3 gap-8 mt-16">
              {/* Multi-Cloud Card */}

              <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-xl mb-6">
                  <VscAzureDevops className="w-8 h-8 text-blue-900" />
                </div>

                <h3 className="text-xl font-bold text-blue-900 mb-4">
                Azure-Native Product Optimization
                </h3>

                <p className="text-gray-600 leading-relaxed">
                AeroAegis products like Maturity Spectrum 360&nbsp;and SmartChaos
                    Lab&nbsp;are seamlessly integrated with Azure services for
                    optimized performance and scalability.
                </p>
              </div>

              {/* Analytics Card */}

              <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-xl mb-6">
                  <TbPackages className="w-8 h-8 text-blue-900" />
                </div>

                <h3 className="text-xl font-bold text-blue-900 mb-4">
                AI/ML at Enterprise Scale
                </h3>

                <p className="text-gray-600 leading-relaxed">
                Leveraging Azure’s advanced AI/ML frameworks, we enable
                    clients to deploy intelligent systems at scale, accelerating
                    insights and automation.
                </p>
              </div>

              {/* IoT Card */}

              <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-xl mb-6">
                  <IoCloudUploadOutline className="w-8 h-8 text-blue-900" />
                </div>

                <h3 className="text-xl font-bold text-blue-900 mb-4">
                Hybrid Cloud Expertise
                </h3>

                <p className="text-gray-600 leading-relaxed">
                Combining AeroAegis’s solutions with Azure’s infrastructure
                    ensures seamless migration, hybrid cloud flexibility, and
                    robust security for enterprise systems.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}