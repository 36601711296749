import React, { useRef } from "react";
import trust_img from "../../assets/videos/our promise/video1.mp4";
import excellence_img from "../../assets/videos/our promise/video2.mp4";
import innovation_img from "../../assets/videos/our promise/innovation.mp4";
import clients_img from "../../assets/videos/our promise/video4.mp4";
import colab_vid from "../../assets/videos/our promise/video5.mp4";
export default function Section6() {
  const videoRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleMouseEnter = (id) => () => {
    const index = cardData.findIndex((card) => card.id === id);
    if (videoRefs[index].current) {
      videoRefs[index].current.play();
    }
  };

  const handleMouseLeave = (id) => () => {
    const index = cardData.findIndex((card) => card.id === id);
    if (videoRefs[index].current) {
      videoRefs[index].current.pause();
      videoRefs[index].current.currentTime = 0;
    }
  };

  const cardData = [
    {
      id: 1,
      title: "Trustworthy Innovation",
      videoSource: trust_img,

      content1:
        "At AeroAegis, our foundation is built on trust. We pledge transparency, reliability, and integrity not just in our interactions but in every product we design and deliver. ",
      content2:
        "Trust us to be your partners in navigating the digital landscape, with solutions that are secure, dependable, and ethical.",
    },
    {
      id: 2,
      title: "Uncompromising Excellence",
      videoSource: excellence_img,

      content1:
        "Excellence isn't just a goal; it's our standard. We are committed to delivering products that not only meet but exceed your expectations and set new benchmarks fo the industry. ",
      content2:
        "With AeroAegis, expect exceptional solutions that drive tangible outcomes, ensuring that your business not only keeps pace with digital evolution but leads it.",
    },
    {
      id: 3,
      title: "Innovative By Nature",
      videoSource: innovation_img,

      content1:
        "Excellence isn't just a goal; it's our standard. We are committed to delivering products that not only meet but exceed your expectations and set new benchmarks for the industry.",
      content2:
        " With AeroAegis, expect exceptional solutions that drive tangible outcomes, ensuring that your business not only keeps pace with digital evolution but leads it.",
    },
    {
      id: 4,
      title: "Client-Centric Commitment",
      videoSource: clients_img,

      content1:
        "Innovation fuels us. It's the core of who we are and what we do at AeroAegis. We vow to continuously challenge the status quo, creating and refining products that are not only innovative but truly transformative.",
      content2:
        "Our commitment is to bring you technology solutions that are ahead of their time, helping your organization to unlock new opportunities and navigate future challenges with confidence.",
    },
    {
      id: 5,
      title: "Collaborative Growth",
      videoSource: colab_vid,

      content1:
        "We believe in growing together. Your insights and feedback are invaluable to us, driving our innovation and refinement. We are committed to a collaborative approach, actively seeking and integrating your input to ensure our products and solutions not only meet but exceed your specific needs.",
      content2:
        "  With AeroAegis, your voice is heard, and your success is amplified.",
    },
  ];

  return (
    <div className="bg-gray-50 px-0 py-16 mx-auto max-w-full  md:px-0 lg:px-0 lg:py-20">
      {/* <div className="flex flex-col mb-6 lg:justify-center lg:flex-row md:mb-0">
        <h2 className="max-w-4xl mb-5  text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-2 group">
          <span className="inline-block text-center mb-1 sm:mb-4">Our Promise: <span className='text-[#252f80]'>Empowering</span> Your Digital Future</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-[#252f80] scale-x-30 group-hover:scale-x-100" />
        </h2>
        
      </div> */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="relative">
          {/* Decorative background elements */}
          {/* <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div> */}

          {/* Main heading */}
          <div className="relative flex flex-col items-center">
            <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 bg-white px-4 relative">
              Our Promise
              {/* Custom underline */}
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-blue-600 rounded-full" />
            </h2>
            <h1 className="mt-4 text-4xl sm:text-5xl font-extrabold text-center">
              <span className="text-gray-900">Empowering</span>{" "}
              <span className="text-blue-700">Your Digital Future</span>
              <br />
            </h1>
          </div>

          {/* Description with animated icons */}
          <div className="mt-12 relative">
            <div className="absolute inset-0 flex items-center justify-center opacity-10">
              <div className="w-48 h-48 bg-blue-100 rounded-full filter blur-xl" />
            </div>

            <p className="text-md md:text-xl text-gray-600 text-center leading-relaxed relative z-10 max-w-4xl mx-auto">
              Our foundation is built on trust and integrity, ensuring
              transparency and reliability in every solution we deliver.
              Excellence is our benchmark, and we are committed to providing
              exceptional products that set new standards in the industry.
              Innovation is our lifeblood, driving us to push the boundaries of
              what's possible and deliver solutions that transform businesses.
              At AeroAegis, we place our clients at the center of everything we
              do, tailoring our products and services to meet their unique needs
              and ensure their success in the digital age.
            </p>
          </div>

          {/* Bottom decorative line */}
          <div className="mt-16 w-full border-t border-gray-200" />
        </div>
      </div>

      {/* <div >
        <p className="py-5 px-10 lg:px-36 text-center text-lg text-gray-700 ">
        Our foundation is built on trust and integrity, ensuring transparency and reliability in every solution we deliver. Excellence is our benchmark, and we are committed to providing exceptional products that set new standards in the industry. Innovation is our lifeblood, driving us to push the boundaries of what's possible and deliver solutions that transform businesses. At AeroAegis, we place our clients at the center of everything we do, tailoring our products and services to meet their unique needs and ensure their success in the digital age.
        </p>
        </div> */}
      <div className="flex flex-wrap justify-center gap-0 row-gap-0 mb-8  sm:row-gap-0 ">
        {cardData.map((card) => (
          <a
            key={card.id}
            aria-label="View Item"
            onMouseEnter={handleMouseEnter(card.id)}
            onMouseLeave={handleMouseLeave(card.id)}
            className={card.id === 5 ? "w-full" : "w-full lg:w-1/2"}
          >
            <div
              className={`relative group h-[300px] lg:h-[650px] overflow-hidden transition duration-700 transform shadow-lg hover:scale-[1.01] hover:z-40 lg:w-full`}
            >
              {card.videoSource ? (
                <video
                  ref={videoRefs[card.id - 1]}
                  className="object-cover  w-full h-full  "
                  muted
                  loop
                >
                  <source src={card.videoSource} type="video/mp4" />
                </video>
              ) : (
                <img
                  className="object-cover w-full h-64 md:h-64 xl:h-80"
                  src={card.imageSource}
                  alt=""
                />
              )}
              <div className="absolute inset-0 px-6 py-4 transition-opacity duration-700 bg-black bg-opacity-55">
                <h1 className="text-gray-200 flex mt-24 md:text-4xl md:mt-36 lg:mt-72  text-center lg:text-center justify-center font-semibold align-content-center text-4xl lg:text-6xl group-hover:hidden">
                  {card.title}
                </h1>
              </div>
              <div className="absolute  inset-0 px-6 py-2 transition-opacity duration-700 bg-black bg-opacity-35 opacity-0  hover:scale-[1.01] hover:opacity-100 flex justify-center items-center">
                <div className=" py-5 text-[12px] sm:text-md  lg:text-lg md:text-base text-center max-w-lg">
                  <p className="font-bold text-lg text-gray-100 pb-2 md:text-4xl lg:text-5xl lg:pb-5">
                    {card.title}
                  </p>
                  <p className=" tracking-wide text-gray-300">
                    {card.content1}
                  </p>
                  <p className=" mt-3  tracking-wide text-gray-300">
                    {card.content2}
                  </p>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
