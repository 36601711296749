import React, { useState } from 'react';
import { FaRocket, FaGlobe, FaHandshake, FaLightbulb, FaChartLine } from 'react-icons/fa';

const milestones = [
  {
    year: 2023,
    events: [
      { date: 'August 8, 2023', description: 'Incorporation of AeroAegis Private Limited in Bangalore, India', icon: <FaRocket /> },
      { date: 'October 3, 2023', description: 'Establishment of AeroAegis HQ in Delaware, US', icon: <FaGlobe /> },
      { date: 'October 15, 2023', description: 'Launch of AeroAegis Limited in Central London, UK', icon: <FaGlobe /> },
    ],
  },
  {
    year: 2024,
    events: [
      { date: 'April 2024', description: 'Strategic Partnership with Microsoft Azure', icon: <FaHandshake /> },
      { date: 'October 2024', description: 'Expansion into Dubai with AeroAegis Enterprise L.L.C-FZ', icon: <FaGlobe /> },
      { date: 'October 2024', description: 'Strengthened consulting offerings in AI/ML, blockchain, and cloud migration', icon: <FaLightbulb /> },
    ],
  },
  {
    year: 2025,
    events: [
      { date: 'March 12, 2025', description: 'Introduction of SmartChaos Lab', icon: <FaLightbulb /> },
      { date: 'April 12, 2025', description: 'Launch of Maturity Spectrum 360', icon: <FaChartLine /> },
      { date: 'November 25, 2025', description: 'Launch of AeroEdge IIoT', icon: <FaRocket /> },
      { date: 'December 31, 2025', description: 'Release of AeroInfra Vue', icon: <FaRocket /> },
    ],
  },
  {
    year: 2026,
    events: [
      { date: '2026', description: 'Global Expansion: New offices in Singapore, Germany, and Australia', icon: <FaGlobe /> },
      { date: '2026', description: 'Accelerated R&D in quantum computing, NPUs, and next-gen AI frameworks', icon: <FaLightbulb /> },
      { date: '2026', description: 'Enhanced Partnerships with Alibaba Cloud and Microsoft Azure', icon: <FaHandshake /> },
    ],
  },
];

const RoadMap = () => {
  const [activeYear, setActiveYear] = useState(2023);

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-extrabold text-center mb-8 text-indigo-900">
          AeroAegis Journey
        </h2>
        <p className="text-xl text-center mb-12 max-w-3xl mx-auto text-indigo-700">
          Our roadmap represents AeroAegis's unwavering commitment to shaping the future of technology through innovative products, strategic consulting services, and deep-tech research.
        </p>

        {/* Year Selector */}
        <div className="flex flex-wrap justify-center mb-12 gap-4">
          {milestones.map((milestone) => (
            <button
              key={milestone.year}
              className={`px-6 py-2 rounded-full text-lg font-semibold transition-all duration-300 shadow-md ${
                activeYear === milestone.year
                  ? 'bg-indigo-600 text-white shadow-lg scale-105'
                  : 'bg-white text-indigo-600 hover:bg-indigo-100'
              }`}
              onClick={() => setActiveYear(milestone.year)}
            >
              {milestone.year}
            </button>
          ))}
        </div>

        {/* Timeline */}
        <div className="relative">
          <div className="absolute left-0 md:left-1/2 top-0 bottom-0 w-1 bg-indigo-300 transform -translate-x-1/2"></div>
          {milestones
            .find((m) => m.year === activeYear)
            .events.map((event, index) => (
              <div
                key={index}
                className={`flex flex-col md:flex-row items-center mb-12 ${
                  index % 2 === 0 ? 'md:flex-row-reverse' : ''
                }`}
              >
                <div className="w-full md:w-5/12 px-4 py-4 md:py-0">
                  <div
                    className={`bg-white rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl ${
                      index % 2 === 0 ? 'md:text-right' : 'text-left'
                    }`}
                  >
                    <div className="text-xl mb-2 font-bold text-indigo-600">{event.date}</div>
                    <p className="text-lg text-gray-700">{event.description}</p>
                  </div>
                </div>
                <div className="w-12 h-12 bg-indigo-500 rounded-full flex items-center justify-center text-white text-2xl shadow-md z-10 my-4 md:my-0">
                  {event.icon}
                </div>
                <div className="w-full md:w-5/12 px-4 hidden md:block"></div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RoadMap;

