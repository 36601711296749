
import React, { useState, useEffect } from 'react';
import {
  FaHospital,
  FaPills,
  FaFileInvoiceDollar,
  FaMedkit,
} from 'react-icons/fa';

import img1 from '../../../../assets/img/Partnerships/innovationImg.jpg';
import img2 from '../../../../assets/img/Partnerships/uptime.jpg';
import img3 from '../../../../assets/img/Partnerships/feature7.jpg';
import img4 from '../../../../assets/img/Partnerships/global.png';

const BenifitsCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl w-80 overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 w-full">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Benifits = () => {
  const benifitsAreas = [
    {
      title: 'Driving Innovation',
      description:
        'Drive innovation and transformative change.',
      icon: <FaHospital />,
      imageUrl: img1,
    },
    {
      title: 'Value Enhancement',
      description:
        'Enhance the value of our products and solutions.',
      icon: <FaPills />,
      imageUrl: img2,
    },
    {
      title: 'Challenge Resolution',
      description:
        'Address the complex challenges of our clients and industries.',
      icon: <FaMedkit />,
      imageUrl: img3,
    },
    {
      title: 'Global Impact',
      description:
        "Create a lasting impact across the global technology ecosystem.",
      icon: <FaFileInvoiceDollar />,
      imageUrl: img4,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
        Our Commitment to Collaboration
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
        As we continue to grow, AeroAegis remains committed to fostering partnerships that:
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-4 gap-14">
          {benifitsAreas.map((area, index) => (
            <BenifitsCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benifits;
