import React from 'react';
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaDatabase,
  FaBrain,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FinalSection = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Transform Your Operations with IoT & Edge Solutions
          </h1>
          <p className="text-xl mb-8">
          At AeroAegis Inc., we deliver <strong>IoT and Edge solutions</strong> that enable organizations to build connected, intelligent systems for smarter decision-making and optimized performance. Whether you are deploying smart infrastructure, enabling real-time edge computing, or creating digital twins, our solutions ensure innovation, scalability, and measurable outcomes.
          </p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </button>
        </div>
      </section>

      {/* Why Choose AeroAegis Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
          Why Choose AeroAegis for IoT & Edge Solutions?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<FaBrain className="text-4xl text-blue-600" />}
              title="End-to-End IoT Expertise"
              description="From device integration to advanced analytics, we deliver complete IoT and edge solutions."
            />
            <FeatureCard
              icon={<FaCog className="text-4xl text-blue-600" />}
              title="Advanced Technology Stack"
              description={<span>Expertise in tools like <strong>AWS IoT Core, Azure IoT Hub, Google IoT, and Alibaba Cloud IoT.</strong></span>}
            />
            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="AI and Cloud Integration"
              description='Combine IoT with AI/ML and edge-cloud architectures for enhanced intelligence and scalability.'
            />
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Industry-Specific Solutions"
              description="Tailored deployments for manufacturing, smart cities, logistics, healthcare, and more."
            />
            <FeatureCard
              icon={<FaDatabase className="text-4xl text-blue-600" />}
              title="Proactive Innovation"
              description={<span>Stay ahead with emerging trends like <strong>Digital Twins, Edge AI, and Predictive Maintenance.</strong></span>}
            />
            <FeatureCard
              icon={<FaChartLine className="text-4xl text-blue-600" />}
              title="Collaborative Approach"
              description={
                'Partnering with clients to deliver seamless IoT implementations that align with business goals.'
              }
            />
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">
          Ready to create intelligent, connected ecosystems? Let’s partner to drive your IoT and Edge strategy forward! 🚀
          </p>
          <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </Link>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};



export default FinalSection;