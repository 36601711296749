import React from 'react'
import Herosection from './Herosection'
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import Section1 from './Section1'


export default function index() {
  return (
    <div>
        <Herosection/>
        <AnimationRevealPage>
        <Section1/>
        
        </AnimationRevealPage>
    </div>
  )
}
