import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import { MdOutlineInsights, MdOutlineRecommend } from 'react-icons/md'; // Tailored Predictive Analytics
import { MdOutlineLightbulb } from 'react-icons/md';
import img1 from "../../../assets/img/Industries/RetailEcommerce/a.png";

export default function MainSection1() {
  return (
    
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
       <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Transformative Solutions for Retail & E-Commerce
            </span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
      
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            AI-Driven Personalization
            </h1>
            <p className="text-lg text-gray-600">
            Tailoring experiences to individual preferences using advanced AI technology.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineRecommend className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Product Recommendations</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Use AI/ML to deliver hyper-personalized product suggestions based on browsing history and purchasing behavior.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLightbulb className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Dynamic Pricing</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Optimize pricing strategies in real-time with AI-powered tools to maximize revenue and competitiveness.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineInsights className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Customer Insights</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage AI to analyze customer sentiment, predict trends, and enhance marketing campaigns.
              </p>
            </div>


           
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={"https://images.unsplash.com/photo-1726137570707-0c03e2867b16?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}