import React from 'react';
import { Link, useLocation } from "react-router-dom";
import hero_img from '../../../../assets/img/EngineeringExcellence/COE/110820.jpg'

export default function FinalSection() {
  return (
    <section
    className="relative text-white py-20 bg-cover bg-center"
    style={{
      backgroundImage: `url('${hero_img}')`,
    }}
  >
    {/* Overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
    
    {/* Content */}
    <div className="container mx-auto px-4 text-center relative z-10">
      <h1 className="text-4xl md:text-6xl font-bold mb-4">
      Empowering Your Future with AeroAegis COEs
      </h1>
      <p className="text-xl mb-8">
      With AeroAegis COEs, your organization gains a trusted partner to navigate the complexities of modern technology and achieve transformative outcomes. Let us help you shape the future with innovation, expertise, and excellence.
      </p>
      <Link
        to="/contact-us"
        className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        Contact Us
      </Link>
    </div>
  </section>
  )
}
