import { IoCheckmarkCircleOutline } from "react-icons/io5"

import { FaCogs, FaRobot } from "react-icons/fa" 
import img1 from '../../../assets/img/Industries/Aerospace/e69a0bf2-64d5-4242-9cf0-83ee614a4183.jpg'

export default function MainSection1() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Transformative Solutions for Aerospace</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">

        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Smart Manufacturing and Industry 4.0
            </h1>
            <p className="text-lg text-gray-600">
              Revolutionize aerospace manufacturing with advanced technologies and data-driven insights.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRobot className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Digital Twin Technology</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Simulate and optimize aerospace manufacturing processes, testing designs and systems virtually to reduce costs and enhance efficiency.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCogs className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">IoT-Enabled Factories</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use IoT sensors for real-time monitoring of production lines, equipment performance, and quality control.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Additive Manufacturing Integration</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage 3D printing for rapid prototyping and on-demand production of complex aerospace components.
              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}