import React from "react";
import img1 from "../../../assets/img/discover_aeroaegis/5.jpg";
import {
  HiOutlineSupport,
  HiOutlineLightBulb,
  HiOutlineTrendingUp,
  HiOutlineClipboardList,
} from "react-icons/hi";

export default function MainSection6() {
  return (
    <section className="">
      ;
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Beyond Products: Comprehensive Support and Services
              </h1>
              <p className="text-lg text-gray-600">
                AeroAegis offers strategic consulting and implementation
                services to support your digital transition.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineSupport className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    End-to-End Support
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Offering guidance and support throughout every step of your
                  digital journey.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineLightBulb className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Strategic Insights
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Providing expertise and tailored solutions to align with your
                  goals.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineTrendingUp className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Growth-Oriented Approach
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Helping you achieve scalable and sustainable growth through
                  our services.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <HiOutlineClipboardList className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Tailored Implementation
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Customizing implementation strategies for a seamless digital
                  transformation.
                </p>
              </div>
            </div>
          </div>

          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
