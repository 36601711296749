import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import { MdOutlineInsights } from 'react-icons/md'; // Tailored Predictive Analytics
import { MdOutlineLightbulb } from 'react-icons/md';
import img1 from "../../../assets/img/Industries/LogisticsandSupplyChain/AISupplyChain.jpg";
import { RxTimer } from "react-icons/rx";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
 
export default function MainSection2() { 
  return (
    
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
       <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Transformative Solutions for Logistics & Supply Chain</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
      
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            IoT-Enabled Supply Chain
            </h1>
            <p className="text-lg text-gray-600">
            Leverage IoT sensors for real-time shipment tracking, predictive fleet maintenance, and smart inventory management.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RxTimer  className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Real-Time Tracking</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Monitor shipments, assets, and fleet movement with IoT sensors and GPS tracking.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePrecisionManufacturing className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Predictive Maintenance</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Ensure fleet reliability with IoT-driven predictive insights for vehicles and equipment.
              </p>
            </div>

            {/* Feature3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLightbulb className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Smart Warehousing</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Optimize inventory management and space utilization with IoT-enabled sensors and automation.
              </p>
            </div>

           
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}