import React from "react";
import { MdOutlineCloudQueue } from "react-icons/md";
import { BiBrain } from "react-icons/bi";
import { GiGamepad } from "react-icons/gi";
import { MdOutlineSecurity } from "react-icons/md";
import img1 from "../../../assets/img/aeroaegis_capabilities/smartchaos.png";
export default function MainSection3() {
  return (
    <section className=" pt-5 ">
      {/* <div className="container flex flex-col place-items-center mx-auto lg:flex-row">
              <div className="lg:w-1/2 xl:w-3/5 ">
                <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
                  <img
                    src={smart_img}
                    alt=""
                    className=" w-52 lg:w-56 "
                  />
                </div>
              </div>
              <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F] text-white">
                <h3 className="text-2xl font-bold tracki sm:text-3xl ">
                SmartChaos Lab
                </h3>
                <div className="flex space-x-2 sm:space-x-4 pt-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    ></path>
                  </svg>
                  <div className="space-y-2">
                    <p className="text-lg font-medium leadi">
                    A cutting-edge solution for ensuring cloud resilience, integrating
advanced machine learning and game theory to protect and optimize cloud
infrastructures.

                    </p>
                  </div>
                </div>
              </div>
            </div> */}

      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>

          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                SmartChaos Lab
              </h1>
              <p className="text-lg text-gray-600">
                A cutting-edge solution for cloud resilience, integrating ML and
                game theory.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineCloudQueue className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Cloud Optimization
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Advanced tools to ensure resilience and seamless cloud
                  operations.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <BiBrain className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Machine Learning Integration
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Leveraging AI to predict and mitigate potential cloud issues.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <GiGamepad className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Game Theory Applications
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Enhancing strategic decision-making for optimized
                  infrastructure.
                </p>
              </div>
              {/* Feature 4 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Enhanced Security
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Robust security measures to protect cloud environments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
