import React from 'react';
import AnimationRevealPage from '../../../../helpers/AnimationRevealPage';
import MainSection2 from './MainSection2';
import MainSection1 from './MainSection1';
import MainSection3 from './MainSection3';
import MainSection4 from './MainSection4';
import MainSection5 from './MainSection5'; 

import HeroSection from './HeroSection';

export default function PredictiveMaintainanceService() {
  return (
    <div>
      <HeroSection />

      {/* <Content1/> */}

      <AnimationRevealPage>
        <MainSection1 />
        <MainSection2 />
        <MainSection3 />
        <MainSection4 />
        <MainSection5 />

        {/* <FinalSection /> */}
      </AnimationRevealPage>
    </div>
  );
}
