import React from 'react'
import img1 from '../../../assets/img/aeroaegis_capabilities/3504.jpg'
import {
  HiOutlineLightBulb,
  HiOutlineClipboardList,
  HiOutlineTrendingUp,
  HiOutlineCog,
} from "react-icons/hi";



export default function MainSection9() {
  return (
    <section className=" ">
   
 
    {/* <div className="container flex flex-col-reverse place-items-center mx-auto lg:flex-row">
      <div className="flex flex-col px-6 py-8 space-y-6 text-white rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-[#23307F]">
        <h3 className="text-2xl font-bold tracki sm:text-3xl ">
        Expert Consulting
        </h3>
        <div className="flex space-x-2 sm:space-x-4 pt-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="flex-shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            ></path>
          </svg>

          <div className="space-y-2">
            <p className="text-lg font-medium leadi">
            Drawing from a wealth of industry experience, our consultants
provide insights and guidance to optimize the use of our products, from strategic
planning to operational best practices.
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 xl:w-3/5 ">
        <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
          <img
            src={img1}
            alt=""
            className="rounded-lg shadow-lg object-cover  h-80 sm:min-h-96"
          />
        </div>
      </div>
    </div> */}

<div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
    <div className="space-y-8">
      <div className="space-y-4">
        <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
          Expert Consulting
        </h1>
        <p className="text-lg text-gray-600">
          Insights and guidance to optimize product usage and strategic planning.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {/* Feature 1 */}
        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <HiOutlineLightBulb className="w-6 h-6 text-indigo-600" />
            <h3 className="font-semibold text-gray-900">Strategic Planning</h3>
          </div>
          <p className="text-gray-600 text-sm">
            Developing tailored strategies to align with business objectives.
          </p>
        </div>
        {/* Feature 2 */}
        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <HiOutlineClipboardList className="w-6 h-6 text-indigo-600" />
            <h3 className="font-semibold text-gray-900">Operational Insights</h3>
          </div>
          <p className="text-gray-600 text-sm">
            Offering actionable recommendations for best practices.
          </p>
        </div>
        {/* Feature 3 */}
        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <HiOutlineTrendingUp className="w-6 h-6 text-indigo-600" />
            <h3 className="font-semibold text-gray-900">Growth Optimization</h3>
          </div>
          <p className="text-gray-600 text-sm">
            Enabling scalable solutions to achieve sustainable growth.
          </p>
        </div>
        {/* Feature 4 */}
        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <HiOutlineCog className="w-6 h-6 text-indigo-600" />
            <h3 className="font-semibold text-gray-900">Product Expertise</h3>
          </div>
          <p className="text-gray-600 text-sm">
            Maximizing product capabilities with expert guidance.
          </p>
        </div>
      </div>
    </div>

    <div className="relative h-[500px] rounded-3xl overflow-hidden">
      <img
        src={img1}
        alt="Workspace with laptop and plant"
        className="object-cover w-full h-full rounded-3xl"
      />
    </div>
  </div>
</div>


  </section>
  )
}
