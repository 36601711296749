import { AiOutlineThunderbolt } from 'react-icons/ai';
import { RiCpuLine } from 'react-icons/ri'
import img1 from "../../../assets/img/EngineeringExcellence/R&D/npu.jpg"

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Neural Processing Units (NPUs)
            </h1>
            <p className="text-lg text-gray-600">
            As AI workloads become more complex, our R&D on NPUs is revolutionizing computational efficiency. We are engineering custom hardware accelerators that optimize AI and machine learning tasks, enabling faster inference and reduced energy consumption for next-generation applications.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineThunderbolt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Computational Efficiency</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Developing custom hardware accelerators to enhance AI and machine learning performance, optimizing inference speed and energy usage.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiCpuLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Optimizing AI Workloads</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Engineering next-gen NPUs to accelerate AI tasks, reducing energy consumption while boosting processing efficiency for advanced applications.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSearch className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Fraud Detection</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Combat fraudulent activities in billing, claims, and insurance processes.
              </p>
            </div> */}

         
          </div>
        </div>

      
      </div>
    </div>
  )
}