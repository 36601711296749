import img1 from "../../../assets/img/Industries/Pharmaceuticals/2149994397 (1).jpg";

import { CiCircleCheck } from "react-icons/ci";
import { IoAlertCircleOutline } from "react-icons/io5";
import { RiBillLine } from "react-icons/ri";
import { FaDollarSign } from "react-icons/fa"; // Reducing R&D Costs
import { FaShieldAlt } from "react-icons/fa"; // Enhancing Data Security
import { FaUsers } from "react-icons/fa"; // Optimizing Collaboration

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Driving Innovation in Life Sciences
            </h1>
            <p className="text-lg text-gray-600">
              At AeroAegis, we are committed to addressing the industry's most
              pressing challenges with transformative technology solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaDollarSign className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Reducing R&D Costs
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                AI-driven efficiencies significantly lower research and
                development expenses while improving outcomes.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaShieldAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Enhancing Data Security
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Blockchain ensures the secure exchange of sensitive patient and
                trial data, safeguarding privacy and compliance.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaUsers className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Optimizing Collaboration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Our cloud-based platforms foster seamless collaboration between
                research teams, healthcare providers, and regulatory bodies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
