import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"

import Img from '../../../assets/img/Industries/banking&finance/6.jpg'

export default function MainSection1() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Transformative Solutions for Finance & Banking</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">

        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Core Banking Modernization
            </h1>
            <p className="text-lg text-gray-600">
              Upgrade your financial infrastructure with modern, efficient, and customer-centric solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiCoinStack className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Cloud-Native Core Banking Systems</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Transition legacy systems to scalable, secure, and cost-effective cloud-based platforms.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">API-Driven Banking</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable seamless integration with third-party services for enhanced customer experiences and faster innovation.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoLockClosedOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Open Banking Solutions</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Build platforms that comply with PSD2 and other open banking regulations, empowering customers and fostering innovation.
              </p>
            </div>

          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={Img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}