import React from 'react'
import img5 from '../../assets/img/careers/815.jpg'
import { MdOutlineMonetizationOn, MdOutlineStar } from 'react-icons/md';
export default function Section5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
      <div className="space-y-8">
        <div className="space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
          Recognition and Rewards
          </h1>
          <p className="text-lg text-gray-600">
          Your contributions matter, and we recognize and reward your efforts. From competitive compensation packages to recognition programs, your hard work is valued and acknowledged.


          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {/* Feature 1 */}
          <div className="space-y-3">
            <div className="flex items-center space-x-2">
              <MdOutlineMonetizationOn className="w-6 h-6 text-indigo-600" />
              <h3 className="font-semibold text-gray-900">
              Competitive Compensation
              </h3>
            </div>
            <p className="text-gray-600 text-sm">
            We offer industry-leading compensation packages to ensure your efforts are rewarded fairly and generously
            </p>
          </div>

          {/* Feature 2 */}
          <div className="space-y-3">
            <div className="flex items-center space-x-2">
              <MdOutlineStar className="w-6 h-6 text-indigo-600" />
              <h3 className="font-semibold text-gray-900">
              Recognition Programs
              </h3>
            </div>
            <p className="text-gray-600 text-sm">
            Your hard work and achievements are celebrated through initiatives designed to acknowledge and appreciate your contributions.
            </p>
          </div>
        </div>
      </div>

      <div className="relative h-[500px] rounded-3xl overflow-hidden">
        <img
          src={img5}
          alt="Workspace with laptop and plant"
          className="object-cover w-full h-full rounded-3xl"
        />
      </div>
    </div>
  </div>

  )
}
