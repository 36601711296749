import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { IoLockClosedOutline } from 'react-icons/io5';
import { MdOutlineAutorenew } from 'react-icons/md'; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from 'react-icons/md'; // Continuous Model Improvement
import { MdOutlineGavel } from 'react-icons/md';
import img1 from '../../../assets/img/EngineeringExcellence/Ip/ethical.png';
import { AiOutlineSchedule } from 'react-icons/ai';

import { IoIosCheckmarkCircleOutline } from "react-icons/io";
export default function MainSection7() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Ethical and Responsible Innovation
            </h1>
            <p className="text-lg text-gray-600">
              Committed to developing secure, compliant, and sustainable
              technologies with a focus on ethical practices and global
              standards.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineSchedule className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Security, Privacy, and Compliance
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                At AeroAegis, we are committed to ethical development practices.
                Our IPs are designed with security, privacy, and compliance at
                the forefront, ensuring responsible use of technology.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoIosCheckmarkCircleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Sustainability and Global Standards
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Our frameworks incorporate principles for fair and unbiased AI,
                sustainability, and alignment with global standards.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <AiOutlineAlert className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Fraud Detection</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Combat fraudulent activities in billing, claims, and insurance processes.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
