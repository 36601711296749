import React, { useState, useEffect } from 'react';

// Define getCookie outside the component
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const CookieConsent = () => {
  const [visible, setVisible] = useState(!getCookie('cookieConsent'));
  const [userIP, setUserIP] = useState('');
  const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    // Fetch user's IP
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org/?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIP();

    // Fetch user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error fetching location:', error);
        }
      );
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleAccept = () => {
    document.cookie = `cookieConsent=accepted; path=/; max-age=7776000`;
    document.cookie = `userIP=${userIP || 'unknown'}; path=/; max-age=7776000`;
    document.cookie = `userLocation=${userLocation.latitude || 'unknown'},${userLocation.longitude || 'unknown'}; path=/; max-age=7776000`;
    setVisible(false);
  };

  const handleDecline = () => {
    document.cookie = `cookieConsent=declined; path=/; max-age=7776000`;
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-0 w-full bg-gray-800 text-white p-4 shadow-lg flex flex-col md:flex-row justify-between items-center z-50">
    <div className="max-w-4xl mb-2 md:mb-0">
      <p className="m-0 leading-relaxed text-sm md:text-md">
        We use cookies on our website to give you the most relevant experience by remembering your
        preferences and repeat visits. By clicking “Accept,” you consent to the use of ALL the
        cookies.
      </p>
    </div>
    <div className="flex gap-2">
      <button
        onClick={handleAccept}
        className="bg-blue-800 py-3 px-6 rounded-md hover:bg-blue-700"
      >
        Accept
      </button>
      <button
        onClick={handleDecline}
        className="bg-gray-500 py-3 px-6 rounded-md hover:bg-gray-700"
      >
        Decline
      </button>
    </div>
  </div>
  
  );
};

export default CookieConsent;
