import React from 'react';
import feature_img from '../../../assets/img/Products/AeroGenix/23014.jpg';
import { TbPlugConnected } from 'react-icons/tb';
import { MdOutlineCloudDownload } from 'react-icons/md';
import { RiGitRepositoryCommitsLine } from 'react-icons/ri';

export default function Feature2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={feature_img}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Bring Your Own Data (BYOD)
            </h1>
            <p className="text-lg text-gray-600">
              AeroGenix integrates with your enterprise’s data, regardless of
              its source:
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TbPlugConnected className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Plug-and-Play Connectors
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Plug-and-play connectors for databases (SQL, NoSQL), cloud
                storage (AWS S3, Azure Blob), and data lakes.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineCloudDownload className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Real-Time Streaming
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Real-time streaming with Kafka for instant insights
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiGitRepositoryCommitsLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Document Repository
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Document repository support (SharePoint, Google Drive).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
