import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
import { GrVmMaintenance } from "react-icons/gr"; //Maintenance
import { GrOptimize } from "react-icons/gr"; //Optimize
import { TiTickOutline } from "react-icons/ti"; //Tick
import img1 from "../../../assets/img/Industries/Manufacturing/ai.jpg";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Man working with data analytics"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Advanced Analytics and AI
            </h1>
            <p className="text-lg text-gray-600">
              Harness the power of IoT to revolutionize your business processes.
              Optimize production, enhance efficiency, and enable real-time
              decision-making for a smarter, more connected operation.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GrVmMaintenance className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Predictive Maintenance
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Reduce downtime and improve equipment reliability with
                AI-powered predictive insights.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GrOptimize className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Demand Forecasting
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use advanced analytics to predict market demand and optimize
                inventory levels.
              </p>
            </div>
            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <TiTickOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Quality Control</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage AI for defect detection, anomaly analysis, and ensuring
                consistent product quality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
