import React from "react";
import { MdOutlineCloudQueue } from "react-icons/md";
import { BiBrain } from "react-icons/bi";
import { GiGamepad } from "react-icons/gi";
import { MdOutlineSecurity } from "react-icons/md";
import img1 from "../../../assets/img/aeroaegis_capabilities/rd.jpg";
import { IoIosConstruct } from "react-icons/io";
import { IoIosPeople } from "react-icons/io";

import { RiSettings3Line } from "react-icons/ri";
export default function CoreCapabilities4() {
  return (
    <section className=" pt-5 ">
      <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
        <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[500px] rounded-3xl overflow-hidden">
            <img
              src={img1}
              alt="Workspace with laptop and plant"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>

          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
                Research & Development (R&D) Leadership
              </h1>
              <p className="text-lg text-gray-600">
                Innovation drives our DNA. Our deep investments in{" "}
                <strong>R&D</strong> ensure that AeroAegis remains at the
                forefront of emerging technologies, including{" "}
                <strong>quantum computing, blockchain, Web3</strong>, and{" "}
                <strong>Neural Processing Units (NPUs)</strong>.
              </p>
              <p className="font-medium text-[18px]">Focus Areas:</p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Feature 1 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <RiSettings3Line className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Emerging Technology Development
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Building cutting-edge solutions for future industries.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <IoIosConstruct className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Proprietary Frameworks
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Innovating new methodologies to drive efficiency and
                  productivity.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <IoIosPeople className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Industry Collaboration
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Partnering with academic and research institutions to
                  incorporate the latest breakthroughs into our solutions.
                </p>
              </div>
              {/* Feature 4 */}
              {/* <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                  <h3 className="font-semibold text-gray-900">
                    Enhanced Security
                  </h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Robust security measures to protect cloud environments.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
