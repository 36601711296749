import React from "react";
import img1 from "../../../assets/img/Industries/Manufacturing/92.jpg";
import { Link } from "react-router-dom";

export default function FinalSection() {
  return (
    <section
      className="relative text-white py-20 md:py-10 bg-cover bg-center"
      style={{
        backgroundImage:
          `url(${img1})`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="container mx-auto px-4 text-center relative z-10">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Partner with Aero Aegis
        </h1>
        <p className="text-xl mb-4">
          At Aero Aegis, we empower manufacturers to build smarter, more
          sustainable operations. From strategy to execution, we deliver
          innovative solutions that drive growth, efficiency, and resilience.
        </p>
        <p className="mb-4">Let’s shape the future of manufacturing together.</p>
        <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
          Contact Us
        </Link>
      </div>
    </section>
  );
}
