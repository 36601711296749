import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack, BiChip } from "react-icons/bi"
import {RiComputerLine} from "react-icons/ri"
import { MdOutlineCloudUpload } from 'react-icons/md'; // Cloud-Based Model Deployment
import { MdOutlineDevices, MdOutlineSmartToy } from 'react-icons/md'; 
import img1 from "../../../assets/img/EngineeringExcellence/ProductEngineering/3.jpg"
import { GrShieldSecurity } from "react-icons/gr";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Focus on Emerging Technologies
            </h1>
            <p className="text-lg text-gray-600">
            Computing, Web3, and NPUs. Our focus on innovation ensures the creation of intelligent, future-ready products that drive automation, predictive insights, and data-driven decision-making.
                        </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSmartToy className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Innovative AI Integration</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leveraging AI and Generative AI to power automation, enhance decision-making, and provide predictive insights for smarter solutions.
              </p>
            </div>
            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GrShieldSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Secure Blockchain Solutions
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
              Incorporating blockchain technology to ensure transparency, security, and trust across all product functionalities.
              </p>
            </div>
          {/* Feature 3 */}
           <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiChip className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Quantum Computing Edge</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Harnessing the power of quantum computing to solve complex problems with unparalleled speed and efficiency.
              </p>
            </div>
                      {/* Feature 4 */}
           <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiComputerLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Web3 and NPUs Empowerment</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Embracing Web3 and NPUs to develop decentralized, high-performance, and future-ready products.
              </p>
            </div>
      
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}