import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import {
  MdOutlineAutorenew,
  MdOutlineChatBubbleOutline,
  MdOutlineViewInAr,
  MdOutlineLoyalty,
} from "react-icons/md"; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from "react-icons/md"; // Continuous Model Improvement
import { MdOutlineGavel } from "react-icons/md";
import img1 from "../../../assets/img/Industries/RetailEcommerce/d.jpeg";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Enhanced Customer Engagement
            </h1>
            <p className="text-lg text-gray-600">
              Boost interactions and build stronger connections with your
              audience through tailored engagement strategies.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineChatBubbleOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Conversational Commerce
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Deploy AI-powered chatbots and virtual assistants to guide
                customers through purchases and provide 24/7 support.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineViewInAr className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Augmented Reality (AR)
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable virtual try-ons and immersive shopping experiences to
                boost customer satisfaction and reduce returns.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLoyalty className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Loyalty Programs
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Build and manage customer loyalty programs with data-driven
                insights to increase retention and repeat purchases.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
