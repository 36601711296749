import React from 'react';
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaDatabase,
  FaBrain,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FinalLandingSection = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Achieve Operational Excellence with AeroAegis
          </h1>
          <p className="text-xl mb-8">
            At AeroAegis Inc., we empower organizations to achieve{' '}
            <strong>Operational Excellence</strong> through reliability,
            performance, and resilience. Whether you're optimizing
            infrastructure, automating workflows, or improving system uptime,
            our expertise ensures your business remains agile, scalable, and
            future-ready
          </p>
          <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </button>
        </div>
      </section>

      {/* Why Choose AeroAegis Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Why Choose AeroAegis for Operational Excellence?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<FaBrain className="text-4xl text-blue-600" />}
              title="End-to-End Expertise"
              description="From DevOps implementation to performance optimization and resilience testing, we deliver comprehensive services."
            />
            <FeatureCard
              icon={<FaCog className="text-4xl text-blue-600" />}
              title="Cutting-Edge Tools"
              description={
                <span>
                  Leverage industry-leading tools like{' '}
                  <strong>
                    Jenkins, Terraform, SmartChaos Lab, Grafana, JMeter,
                  </strong>{' '}
                  and more.
                </span>
              }
            />
            <FeatureCard
              icon={<FaIndustry className="text-4xl text-blue-600" />}
              title="Cloud Mastery"
              description={
                <span>
                  Expertise across multi-cloud platforms like{' '}
                  <strong>
                    AWS, Microsoft Azure, Alibaba Cloud (Official Partner), and
                    Google Cloud Platform
                  </strong>
                  .
                </span>
              }
            />
            <FeatureCard
              icon={<FaRocket className="text-4xl text-blue-600" />}
              title="Proactive Support"
              description="Offer continuous monitoring and proactive support to ensure systems remain reliable and performance-optimized."
            />
            <FeatureCard
              icon={<FaDatabase className="text-4xl text-blue-600" />}
              title="Agile Methodologies"
              description="Implement agile methodologies to enhance flexibility, enabling quick adjustments and faster delivery of solutions."
            />
            <FeatureCard
              icon={<FaChartLine className="text-4xl text-blue-600" />}
              title="Measurable Outcomes"
              description={
                'Delivering tangible improvements in uptime, performance, and operational efficiency.'
              }
            />
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">
            Ready to achieve operational excellence? Let’s collaborate to
            optimize your systems today! 🚀
          </p>
          <Link
            to={'/contact-us'}
            className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300"
          >
            Contact Us
          </Link>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

const IndustryCard = ({ image, title }) => {
  return (
    <div className="relative overflow-hidden rounded-lg shadow-md aspect-w-4 aspect-h-3">
      <img src={image} alt={title} className="object-cover w-full h-full" />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <h3 className="text-white text-xl font-semibold">{title}</h3>
      </div>
    </div>
  );
};

export default FinalLandingSection;
