import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { BiCoinStack } from "react-icons/bi"
import { IoLockClosedOutline } from "react-icons/io5"
import { BiLike } from "react-icons/bi"
import img1 from "../../../../assets/img/Services/OperationalExe/code.png"

import { IoCloudUploadOutline } from "react-icons/io5";
import { IoAnalytics } from "react-icons/io5";

export default function MainSection3() {
  return ( 
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
      <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
            Infrastructure as Code (IaC)
            </h1>
            <p className="text-lg text-gray-600">
            IaC allows you to efficiently provision and manage infrastructure using code, enabling automation, consistency, and scalability.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoCloudUploadOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Provisioning Tools</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Leverage tools like Terraform, Ansible, and CloudFormation to simplify and automate infrastructure setup.

              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoAnalytics className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Efficient Management</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Streamline infrastructure management with consistent and scalable configurations.
              </p>
            </div>

            {/* Feature 3 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoLockClosedOutline className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Security Guaranteed</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Experience the peace of mind that comes with knowing your data is secure
              </p>
            </div> */}

            {/* Feature 4 */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BiLike className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">All in One</h3>
              </div>
              <p className="text-gray-600 text-sm">
                All-in-one solution ensures that you can easily manage your financial needs.
              </p>
            </div> */}
          </div>
        </div>

      
      </div>
    </div>
  )
}